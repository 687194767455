	// ======================================================================
	// Memmbership Level
	// ======================================================================

.membership-level {
	padding: rem-calc(20) rem-calc(40) rem-calc(5);

	h3 {
		font-size: rem-calc(17);
		color: $theme-color-2;
		font-weight: 700;
		margin-bottom: rem-calc(15);
		display: table;
		border-bottom: 1px solid $gray-20;
		padding-bottom: rem-calc(25);
		width: 100%;

		.package {
			display: table-cell;
			vertical-align: bottom;
			width: 70%;
			line-height: 1.2;
		}

		img {
			display: table-cell;
			vertical-align: bottom;
			margin-right: 0;
			
			&.individual {
				margin-left: rem-calc(20);
			}
			&.household {
				margin-left: 0;
			}
		}

		.kids {
			color: #767c92;
			font-size: rem-calc(10);
			margin-top: rem-calc(6);
			display: block;
			letter-spacing: 2px;
		}
	}

	&.highlight {
		border-radius: rem-calc(20);
		background: image-url("membership/membership-badge.png") top right no-repeat #fff;
		margin: rem-calc(20);
		padding: rem-calc(25) rem-calc(20);
		width: 90%;
	}


	.membership-level-list {
		display: none;

		@include reset-ul;

		padding: 0 0 rem-calc(10);
		font-size: rem-calc(14);
		border-bottom: 1px solid $gray-20;

		li {
			clear: both;
			margin-bottom: rem-calc(16);
			@include clearfix;
			line-height: 1.5;

			.icon-check {
				color: $color-agenda;
				float: left;
				width: rem-calc(28);
				font-size: rem-calc(17);
				line-height: 1.2;
			}
			.info {
				font-size: rem-calc(13);
				float: left;
				color: $theme-color-2;
				font-weight: 700;
				width: 80%;


				.info-extra {
					display: block;
					color: #767c92;
					font-weight: 400;
				}
			}
		}
	}

	@media screen and (min-width: 480px) {
		.membership-show-benefits {
		  display: none;
		}
	  }

	.price-wrap {
		margin: rem-calc(15) rem-calc(30);
		font-size: rem-calc(15);
		color: #767c92;

		.price {
			color: $theme-color-1;
			font-size: rem-calc(25);
			font-weight: 700;


		}
		.tax {
			font-size: rem-calc(11);
			display: block;
		}
	}
}

.fancybox-inner ul.membership-level-list {
	display: block;
}


@include respond-min($small-breakpoint) {
	.membership-level {
		padding: rem-calc(25) rem-calc(22);

		h3 {

			.package {
				width: 50%;
			}
			img {
				&.individual {
					margin-left: rem-calc(40);
				}

				&.household {
					margin-left: 0;
				}
			}
		}

		.membership-level-list {
			display: block;
		}

		&.highlight {
			width: rem-calc(235);
			margin: 0;
		}
	}
}
