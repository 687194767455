.stories-listing {
    @include respond-min($medium-breakpoint) {
        display: flex;
        justify-content: flex-start;
        // align-items: center;
        flex-wrap: wrap;
        gap: 2%;
    }
    &__list-title {
        margin: rem-calc(30) 0 rem-calc(10);
    }
}

.story-teaser {
    display: flex;
    flex-direction: column;

    margin: rem-calc(15) 0;
    background-color: $gray-15;
    color: black;

    &.story-teaser--placeholder {
        position: relative;
        min-height: 250px;
        padding: rem-calc(40) rem-calc(30);
        // background-color: rgb(10, 31, 84);
        // background-color: $theme-color-1;
        background-color: #0071CE;
    }
    &.story-teaser--full.story-teaser--quote-only {
        background-color: #0071CE;
    }
    @include respond-min($medium-breakpoint) {
        flex-basis: 49%;
    }
    @include respond-min($xlarge-breakpoint) {
        flex-basis: 32%;

        &--full {
            flex-basis: 100%;
            & .story-teaser__image-wrap {
                flex-basis: 68%;
                #featured-story  & {
                    flex-basis: 100%;
                }
            }
            & .story-teaser__text-wrap {
                flex-basis: 32%;
                #featured-story  & {
                    display: none;
                }
            }
            &.story-teaser--quote-only {
                min-height: rem-calc(250);

                & .story-teaser__quote {
                    padding: rem-calc(50);
                    font-family: $font-family-2;
                    font-size: rem-calc(30) !important;
                }
            }
        }
        &--image-left {
            flex-direction: row;
        }
        &--image-right {
            flex-direction: row-reverse;
        }
    }


    &--quote-only {
        // .story-teaser--quote-only
        // background-color: red;
        // color: white;
        // font-size: 28px;
        display: flex;
        justify-content: center;
        #featured-story & .story-teaser__text-wrap {
            display: block;
        }
    }

    &--image-top {
        // width: 33.333333%;
    }
    
    &__placeholder {
        &-logo {
            height: 100%;
            min-height: rem-calc(190);
            background-image: url("/typo3conf/ext/msi_skin/Resources/Public/Images/90th.svg");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
        }
        &-share-story {
            position: absolute;
            right: rem-calc(15);
            bottom: rem-calc(10);
            color: white;
            font-size: rem-calc(15);
            font-style: italic;
            &:after {
                content: ' \2192';
                // font-size: rem-calc(30);
            }
        }
    }
    &__text-wrap {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__quote {
        margin: 0;
        padding: rem-calc(20);
        color: inherit;
        font-family: inherit;
        font-size: rem-calc(18);
        line-height: inherit;
        text-align: inherit;
        .story-teaser--full.story-teaser--quote-only & {
            color: white;
            font-size: rem-calc(22);
            line-height: 1.5;
            text-align: center;
            // @include respond-min($medium-breakpoint) {
            // // font-family: $font-family-2;

            // }
        }
    }
    &__attribution {
        color: inherit;
        font-family: inherit;
        text-align: inherit;
        .story-teaser--full.story-teaser--quote-only & {
            // background-color: $theme-color-2;
            color: white;
            font-family: $font-family-1;
            text-align: center;
        }
    }
}

.fancybox-inner #featured-story {
    padding: 20px;
}

.featured-story__text-wrap {
    padding: rem-calc(20);
    background-color: #f2f2f2;
    p {
        font-size: rem-calc(22);
    }
    .fancybox-inner & {
        padding: rem-calc(20) 0;
        background-color: white;
    }
}


#featured-story .story-teaser--quote-only .story-teaser__attribution {
    font-size: rem-calc(24);
}

.featured-story__attribution {
    font-weight: normal;
    font-size: rem-calc(22);
    &::before {
        content: "\2014 \0020";
    }
}

.featured-story__title {
    margin-bottom: rem-calc(10);
    line-height: 1.1;
    &-type {
        font-weight: normal;
    }
    .fancybox-inner & {
        display: none;
    }
}

#featured-story {
    .story-teaser {
        margin-bottom: 0;
        background-color: #0071CE;
        color: white;
        &__attribution {
            display: none;
        }
        &__image {
            width: 100%;
        }
    }
}
