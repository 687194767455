.button_teaser {
  overflow: hidden;
  border-radius: 10px;
  margin: 0 auto;
  max-width: 758px;
  width: 90%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background: url("https://www.msichicago.org/fileadmin/assets/general_msi/global_blue_headers/blurred/header_ywg_gita_blur.jpg");
  background-size: cover;
  background-position:center;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;

  @include respond-min($large-breakpoint){
    width:100%;
  }

  &:hover{
    background-size: 120%;
    transition: all 0.3s ease-in-out;

    .icon{
      color:$gray-15;
      padding-left: 5px;
      font-weight: 700;
      transition: all 0.3s ease-in-out;
    }
  }

  & > a.button_teaser__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: stretch;
    justify-content: space-evenly;
    height: 111px;

    @include respond-min(675px){
      flex-direction: row;
    }
  }

  &__title, &__link {
    letter-spacing: -1.25px;
    color: $gray-15;
    flex-basis: 100%;
    margin-bottom: 0;
    padding:25px 10px 0 10px;
    font-weight: bold;
    @include respond-min(675px){
      flex-basis: 60%;
      padding: 20px;
    }

    @include respond-min($large-breakpoint){
      flex-basis: 58%;
    }
  }

  &__link {
    letter-spacing: -0.2px;
    padding-right: 0;
    padding-top:0;
    flex-basis: 100%;
    @include respond-min(675px){
      flex-basis: 39%;
      padding-top:20px;
    }

    @include respond-min($large-breakpoint){
      flex-basis: 28%;
    }

    &__text{
      margin-right: 10px;
    }

    .icon{
      padding-left:0;
      font-weight: 400;
      transition: all 0.3s ease-in-out;
    }
  }

}


