// ======================================================================
// ACCORDION
// ======================================================================
.msi-accordion {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: rem-calc(45);

  .accordion {
    @include reset-ul;
    margin-left: 0;

    .accordion-navigation {
      //border-bottom: 1px solid $lighter-gray;
      background: $light-gray;
      border-radius: 3px;
      margin-bottom: 5px !important;
      border: 1px solid #f2f2f2;
     // transition: margin-bottom ease-in-out 0.3s;
      position: relative;


      &.active {
       // transition: margin-bottom ease-in-out 0.3s;
      }

      .content {
        display: block;
        max-height: 0;
        overflow-y: auto;
        padding: rem-calc(0 25);
        background: $light-gray !important;
        //@TODO-ALEX: Checking with Larry  (designer)  if he likes it better without animation
        //transition: all ease-in-out 0.4s;

        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
          display: none;
        }

        &.active {
          max-height: 80vh;
          padding-bottom: rem-calc(24);
          //@TODO-Upgrade ALEX: Checking with Larry (designer) if he likes it better without animation

          //transition: all ease-in-out 0.3s;
        }


        p {
          color: $text-gray;
          &:last-child {
            margin-bottom: 0;
          }
        }


        // style the list inside of the normal accordion content
        ul {
          font-size: 1rem;
          margin-left: 15px;
          list-style-type: disc;

          li {
            left: auto;
          }

          // @extend %msi-common-list;
          //
          // > li {
          // 	padding-right: 0;
          //
          // 	&:before {
          // 		@extend %icon-list-bullet;
          // 		position: relative;
          // 		left: rem-calc(-3);
          // 		top: .05em;
          // 	}
          // }
        }
      }

    }

    .accordion-navigation > a > .icon {
      //float: right;
      font-size: rem-calc(25);
      margin-right: 0;
      width: rem-calc(28);
      height: rem-calc(28);
      color: $theme-color-1;
      font-weight: lighter;
      margin-top: -5px;
      position: absolute;
      right: 28px;

      @include respond-min($large-breakpoint) {
        font-size: rem-calc(32);
        width: rem-calc(32);
        height: rem-calc(32);
      }

      &:before {
        font-weight: lighter;
      }
    }

    .accordion-navigation > a,
    .accordion-navigation > a:hover,
    .accordion-navigation > a:focus,
    .accordion-navigation.active > a {
      background-color: transparent;
    }

    .accordion-navigation.active .accordion-header > .icon:before {
      @extend %icon-anglebracket-up;
    }

    .accordion-navigation > a:hover .title,
    .accordion-navigation > a:hover .icon,
    .accordion-navigation > a:focus .title,
    .accordion-navigation > a:focus .icon {
      color: $theme-color-1;
    }

    .accordion-navigation > .content {
      margin-top: rem-calc(0);
      margin-bottom: 0;
    }

    .accordion-header {
      text-decoration: none;
      padding: rem-calc(22 10 22 23);

      @include respond-min($large-breakpoint) {
        padding: rem-calc(22 25);
      }

      .title, .faq-question {
        font-size: rem-calc(14) !important;
        font-weight: bold;
        margin-bottom: 0;
        padding-right: rem-calc(40);
        line-height: rem-calc(18) !important;
        color: $title-gray;

        @include respond-min($large-breakpoint) {
          font-size: rem-calc(16) !important;
          line-height: rem-calc(20) !important;
        }
      }

      .subtitle {
        font-size: rem-calc(10);
        letter-spacing: rem-calc(2);
        font-weight: 600;
        color: $gray;
        margin-bottom: rem-calc(0);
        margin-top: rem-calc(-9);
      }
    }

    p {
      font-size: rem-calc(16);
      line-height: rem-calc(30);
    }
  }


  .faqcategories, #faqsearch, .button, .local-menu {
    border-radius: 3px;
  }

}

// UpcomingHours accordion
.hours-accordion {
  margin-bottom: 20px;

.accordion-navigation{
  @include respond-max($small-breakpoint){
    border-radius: 5px!important;
  }
}

  &__header {
    position: relative;
    opacity: 1;
    height: rem-calc(60);
    transition: all ease-in-out 0.5s;
    padding-left: rem-calc(27) !important;

    @include respond-max($small-breakpoint){
      padding:rem-calc(10 22)!important
    }


    .icon {
      right: 18px !important;
      @include respond-max($small-breakpoint) {
        margin-top: 7px !important;
        right: 11px !important;
        font-size: rem-calc(23)!important;
      }
    }

    li.accordion-navigation.active & {
      padding: rem-calc(0 25 0 27);
      height: rem-calc(25);
      transition: all ease-in-out 0.5s;

      span {
        opacity: 0;
        transition: opacity ease-in-out 0.5s;
      }

      .icon {
        margin-top: 15px !important;
        transition: margin-top ease-in-out 0.5s;
        @include respond-max($small-breakpoint){
          margin-top: 5px !important;
        }
      }
    }

  }

  &__circle {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    opacity: 1;
    transition: opacity ease-in-out 0.5s;

    @include respond-max($small-breakpoint){
      position: absolute;
      top: 22px;
    }

    &--green {
      background-color: $check-green;
    }

    &--red {
      background-color: $pinterest-red;
    }
  }

  &__today, &__description {
    font-size: rem-calc(16);
    line-height: rem-calc(20);
    font-weight: bold;
    margin-left: 15px;
    opacity: 1;
    transition: opacity ease-in-out 0.5s;
    @include respond-max($small-breakpoint){
      margin-left: 24px;

    }

    &--open {
      color: $check-green !important;
    }

    &--closed {
      color: $pinterest-red !important;
    }
  }

  &__description {
    font-weight: 400;
    color: $text-gray;
    margin-left: 10px;

    @include respond-max($small-breakpoint) {
      display: block;
      padding-left: 13px;
      margin-left: 13px;
    }
  }

  &__content {
    border-top: none !important;
    padding: 0 27px 0 27px !important;
    transition: all ease-in-out 0.7s;


    .hours-accordion__week-hours__list {
      margin-left: 0 !important;

      .hours-accordion__week-hours {
        list-style: none;
        padding: 7px 0px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: $text-gray;

        &__day {
          flex-basis: 50%;
          @include respond-max($small-breakpoint){
            flex-basis: 40%;
          }
        }

        &__times {
          flex-basis: 48%;
          @include respond-max($small-breakpoint){
            flex-basis: 60%;
          }
        }

        &:first-of-type {
          font-weight: bold;
          padding-top: 0;
        }

        &:last-of-type {
          padding-bottom: 0;
        }

      }
    }

    &.active {
      max-height: 130vh !important;
      padding-bottom: 20px !important;
      transition: max-height ease-in-out 0.7s!important;

    }
  }

}


.faq-answer-inner-wrap {
  padding-top: 15px;
  border-top: 1px solid $gray-highlight;
}

#faqs .content >  .faq-answer{
  background: $light-gray !important;
  border-top: 2px solid transparent;
  transition: border-top ease-in-out 0.3s;

  .active {
    border-top: 2px solid $gray-25;
    transition: border-top ease-in-out 0.3s;
  }

  p {
    color: $text-gray;
    margin-bottom: 0;
  }
}

