// ======================================================================
// CE Alert
// ======================================================================

.ce-alert {
	padding: 1em;
	margin: 1em 0;
	font-weight: bold;
	font-size: 110%;
  	color: $theme-color-1-text-light;
	p {
		margin-bottom: 0;
		font-weight: bold;
		font-size: 110%;
	}
	a {
		padding-bottom: .125rem;
		border-bottom: 2px solid $theme-color-1-text-light;
	}
  h1, h2, h3, h4 ,h5, h6, p.feature-text, p.supporters, p, ul, ol, .subtitle, p, a  {
		color: $theme-color-1-text-light;
	}

	&.lc__light-gray{
		background-color: $light-gray;
	}

	&.lc__theme-color-1{
		background-color: $theme-color-1-alt !important;
	}

	&.lc__theme-color-2{
		background-color: $theme-color-2;
	}

	&.lc__theme-color-3{
		background-color: $theme-color-3;
	}
}

.ribbon {
	.ce-alert {
		padding: 14px 0;
		margin: 0;
		p {
			@extend .bh__content-wrap;
			line-height: 18px;
			padding: 0 14px;
			font-size: 16px;
			@include respond-min($xlarge-breakpoint) {
				padding: 0;
			}
		}
	}
}

