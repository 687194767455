@mixin bgimage-sizing {

	.lc__background {
		background-size: contain;

		@include respond-min($medium-breakpoint) {
			background-size: 60% auto;

			&.lc__left-of-center, &.lc__right-of-center {
				background-size: contain;
			}

		}

		@include respond-min($xlarge-breakpoint) {

			background-size: auto;

			&.lc__left-of-center, &.lc__right-of-center {
				background-size: auto;
			}

			&.lc__bgimage- {
				&cover {
					background-size: cover;
				}
				&auto {
					background-size: auto;
				}
				&contain {
					background-size: contain;
				}
			}
		}
	}
}

.layout-container {
	position: relative;
	overflow: hidden;

	@include respond-min($xlarge-breakpoint) {
		overflow: visible;
	}
}

#feature .layout-container {
	padding-top: rem-calc(90);

	@include respond-min($large-breakpoint) {
		padding-top: rem-calc(200);
	}
}

.lc__ {

	&theme-color- {
		&1 {
		background-color: $theme-color-1 !important;
		}

		&2 {
			background-color: $theme-color-2 !important;
            a {
                color: $theme-color-1-alt;
            }
            .arrow-list li:before {
                color: $theme-color-1-alt;
            }
		}

		&3 {
			background-color: $theme-color-3 !important;
		}
	}

	&light-gray {
		background-color: $light-gray !important;
	}

	&noise {
		background: $theme-color-2 url('/_assets/16c122cebb6dd10fd754319ee821f8f8/Images/pattern.png') repeat center top;
	}


	&background-2 {

		display: none;
		width: 100%;
		height: 0;
		position: absolute;
		bottom: 0;
		box-sizing: content-box;

		@include respond-min($xlarge-breakpoint) {
			display: block;
			// z-index: 1;

			&.theme-color- {
				&1 {
					background-color: $theme-color-1;
				}

				&2 {
					background-color: $theme-color-2;
				}

				&3 {
					background-color: $theme-color-3;
				}
			}

			&.light-gray {
				background-color: $light-gray;
			}

			&.white {
				background-color: white !important;
			}

			&.height-{

				&05 {
					height: rem-calc(50);
				}
				&075 {
					height: rem-calc(75);
				}

				&1 {
					height: rem-calc(100);
				}

				&15 {
					height: rem-calc(150);
				}

				&2 {
					height: rem-calc(200);
				}

				&25 {
					height: rem-calc(250);
				}


				&3 {
					height: rem-calc(300);
				}

				&35 {
					height: rem-calc(350);
				}

				&4 {
					height: rem-calc(400);
				}

				&10 {
					height: 0
				}
			}
		}
	}



	&module-3-up, &module-4-up {

		padding-top: rem-calc(25);

		.lc__background-2 {
			height: 100%;
			background-color: $theme-color-3;
			display: block;

			@include respond-min($xlarge-breakpoint) {
				height: rem-calc(330);
			}
		}

        @media only screen and (min-width:0) and (max-width: rem-calc(1023)) {
            h1, h2, h3, h4 ,h5, h6, p.feature-text, p.supporters {
                color: $theme-color-1-text-light;
            }
        }
	}

	&module-4-up {

		.lc__background-2 {
			@include respond-min($xlarge-breakpoint) {
				height: rem-calc(240);
			}

			@include respond-min($xxlarge-breakpoint) {
				height: rem-calc(330);
			}
		}
	}

	&module-3-up.three-two-one{

		.lc__background-2{
			background-color:white;
		}

		.lc__inner-wrap{
			padding-left:0;
			padding-right:0;
		}

		.lc__content-inner-wrap {
			display: block;
			width: 100%;

			@include respond-min($large-breakpoint) {
				display: flex;
				flex-flow: row wrap;
				justify-content: center;
				margin: 0 auto;
				width: 100%;
			}

			@include respond-min(815px) {
				width:80%;
			}

			@include respond-min($xlarge-breakpoint) {
				justify-content: space-between;
				margin: 0;
				width:100%;
			}


			& > div.box_teaser {
				max-width:360px;
				margin-bottom: 50px;
				width: 90%;

				@include respond-min($xsmall-breakpoint) {
					width: 100%;
				}

				@include respond-min($large-breakpoint) {
					flex-basis: 45%;
					width: 45%;
					min-width: 300px;
					//margin:0 15px 50px 15px;
				}

				@include respond-min($xlarge-breakpoint) {
					flex-basis: 31%;
					width: 31%;

				}
			}
		}


	}

	&inner-wrap {
		position: relative;
		padding: rem-calc(25) rem-calc(15);

        #f2a #content-block-1 &.lc__pad-top-default,
        #f2a #content-block-1 &.lc__pad-bottom-default {
            padding: rem-calc(30);
        }
        #f2a #content-block-1 & {
            padding-left: rem-calc(30);
            padding-right: rem-calc(30);
        }

		@include respond-min($xsmall-breakpoint) {
			padding: rem-calc(50) rem-calc(30);
		}


		@include respond-min($xlarge-breakpoint) {

			padding: rem-calc(100) rem-calc(20);
		}

	}

    &pad-top- {

        &25 {
            padding-top: rem-calc(25);
        }

        &05 {
            padding-top: rem-calc(30);
        }

        &075 {
            padding-top: rem-calc(40);
        }

        &1 {
            padding-top: rem-calc(50);
        }

        &125 {
            padding-top: rem-calc(60);
        }

        &15 {
            padding-top: rem-calc(75);
        }

        &175 {
            padding-top: rem-calc(90);
        }

        &2 {
            padding-top: rem-calc(100);
        }

        &25 {
            padding-top: rem-calc(125);
        }

        &3 {
            padding-top: rem-calc(150);
        }

        &35 {
            padding-top: rem-calc(175);
        }

        &4 {
            padding-top: rem-calc(200);
        }

        &10 {
            padding-top: 0
        }

    }

    &pad-bottom- {

        &25 {
            padding-bottom: rem-calc(25);
        }

        &05 {
            padding-bottom: rem-calc(30);
        }

        &075 {
            padding-bottom: rem-calc(40);
        }

        &1 {
            padding-bottom: rem-calc(50);
        }

        &125 {
            padding-bottom: rem-calc(60);
        }

        &15 {
            padding-bottom: rem-calc(75);
        }

        &175 {
            padding-bottom: rem-calc(90);
        }

        &2 {
            padding-bottom: rem-calc(100);
        }

        &25 {
            padding-bottom: rem-calc(125);
        }

        &3 {
            padding-bottom: rem-calc(150);
        }

        &35 {
            padding-bottom: rem-calc(175);
        }

        &4 {
            padding-bottom: rem-calc(200);
        }

        &10 {
            padding-bottom: 0
        }
    }

	@include respond-min($xlarge-breakpoint) {

		&pad-top- {

			&25 {
				padding-top: rem-calc(25);
			}

			&05 {
				padding-top: rem-calc(50);
			}

			&075 {
				padding-top: rem-calc(75);
			}

			&1 {
				padding-top: rem-calc(100);
			}

			&125 {
				padding-top: rem-calc(125);
			}

			&15 {
				padding-top: rem-calc(150);
			}

			&175 {
				padding-top: rem-calc(175);
			}

			&2 {
				padding-top: rem-calc(200);
			}

			&25 {
				padding-top: rem-calc(250);
			}

			&3 {
				padding-top: rem-calc(300);
			}

			&35 {
				padding-top: rem-calc(350);
			}

			&4 {
				padding-top: rem-calc(400);
			}

			&10 {
				padding-top: 0
			}

		}

		&pad-bottom- {

			&25 {
				padding-bottom: rem-calc(25);
			}

			&05 {
				padding-bottom: rem-calc(50);
			}

			&075 {
				padding-bottom: rem-calc(75);
			}

			&1 {
				padding-bottom: rem-calc(100);
			}

			&125 {
				padding-bottom: rem-calc(125);
			}

			&15 {
				padding-bottom: rem-calc(150);
			}

			&175 {
				padding-bottom: rem-calc(175);
			}

			&2 {
				padding-bottom: rem-calc(200);
			}

			&25 {
				padding-bottom: rem-calc(250);
			}

			&3 {
				padding-bottom: rem-calc(300);
			}

			&35 {
				padding-bottom: rem-calc(350);
			}

			&4 {
				padding-bottom: rem-calc(400);
			}

			&10 {
				padding-bottom: 0
			}
		}

		&content-offset-top- {
			&05 {
				margin-top: rem-calc(-50);
			}
			&075 {
				margin-top: rem-calc(-75);
			}

			&1 {
				margin-top: rem-calc(-100);
			}

			&15 {
				margin-top: rem-calc(-150);
			}

			&2 {
				margin-top: rem-calc(-200);
			}

			&25 {
				margin-top: rem-calc(-250);
			}

			&3 {
				margin-top: rem-calc(-300);
			}

			&35 {
				margin-top: rem-calc(-350);
			}

			&4 {
				margin-top: rem-calc(-400);
			}
		}

		&content-bottom-up- {
			&05 {
				margin-bottom: rem-calc(-50);
			}
			&075 {
				margin-bottom: rem-calc(-75);
			}

			&1 {
				margin-bottom: rem-calc(-100);
			}

			&15 {
				margin-bottom: rem-calc(-150);
			}

			&2 {
				margin-bottom: rem-calc(-200);
			}

			&25 {
				margin-bottom: rem-calc(-250);
			}

			&3 {
				margin-bottom: rem-calc(-300);
			}

			&35 {
				margin-bottom: rem-calc(-350);
			}

			&4 {
				margin-bottom: rem-calc(-400);
			}
		}

		&top-offset- {

			&05 {
				margin-top: rem-calc(-50);
			}
			&075 {
				margin-top: rem-calc(-75);
			}

			&1 {
				margin-top: rem-calc(-100);
			}

			&15 {
				margin-top: rem-calc(-150);
			}

			&2 {
				margin-top: rem-calc(-200);
			}

			&25 {
				margin-top: rem-calc(-250);
			}

			&3 {
				margin-top: rem-calc(-300);
			}

			&35 {
				margin-top: rem-calc(-350);
			}

			&4 {
				margin-top: rem-calc(-400);
			}

		}
	}

	&stack-layer {

		&-1 {
			z-index: 10;
		}

		&-2 {
			z-index: 20;
		}

		&-3 {
			z-index: 30;
		}

		&-4 {
			z-index: 40;
		}

		&-5 {
			z-index: 50;
		}

		&-6 {
			z-index: 60;
		}

		&-7 {
			z-index: 70;
		}

	}

	&background {

		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		box-sizing: content-box;
		background-repeat: no-repeat;
		background-position: center top;
	}

	&wide {
		@include respond-min($xlarge-breakpoint) {
			max-width: rem-calc(1060);
			left: 50%;
			margin-left: rem-calc(-530);
		}
	}

	&wider {
		@include respond-min($xxlarge-breakpoint) {
			max-width: rem-calc(1220);
			left: 50%;
			margin-left: rem-calc(-610);
		}
	}

	&left-of-center {
		width: 55%;
		left: 0;
	}

	&right-of-center {
		width: 55%;
		right: 0;
	}

	&bgimage-top-center {
		.lc__background {
			background-position: center top;
		}
		@include bgimage-sizing;
	}

	&bgimage-top-left {
		.lc__background {
			background-position: left top;
		}
		@include bgimage-sizing;
	}

	&bgimage-top-right {
		.lc__background {
			background-position: right top;
		}
		@include bgimage-sizing;
	}

	&bgimage-middle-right {
		.lc__background {
			background-position: right center;
		}
		@include bgimage-sizing;
	}

	&bgimage-middle-left {
		.lc__background {
			background-position: left center;
		}
		@include bgimage-sizing;
	}

	&bgimage-middle-center {
		.lc__background {
			background-position: center center;
		}
		@include bgimage-sizing;
	}

	&bgimage-bottom-right {
		.lc__background {
			background-position: right bottom;
		}
		@include bgimage-sizing;
	}

	&bgimage-bottom-left {
		.lc__background {
			background-position: left bottom;
		}
		@include bgimage-sizing;
	}


	&bgimage-bottom-center {

		.lc__background {
			background-position: center bottom;
		}
		@include bgimage-sizing;
	}

	&bleed-left .lc__background {
		@include respond-min(1100px) {
			left: 0;
			padding-right: rem-calc(640);
		}
	}

	&bleed-right .lc__background {
		@include respond-min(1100px) {
			right: 0;
			padding-left: rem-calc(640);
		}
	}

	&content  {

		.rte-content {
			p, ul, ol, dl {

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		.tai__image-group-wrap:last-child {
			margin-bottom: 0;
		}
	}

	&tagline-wrap {
		display: none;
		position: absolute;
		top: rem-calc(-13);
		left: rem-calc(40);
		@include respond-min(1100px) {
			display: block;
		}
	}

	&tagline-number {
		width: rem-calc(44);
		height: rem-calc(44);
		border-radius: rem-calc(23);
		border: 1px solid rgba(255,255,255,0.4);
		text-align: center;
		line-height: rem-calc(45);
		font-weight: 700;
		font-size: rem-calc(17);
		text-align: center;
		line-height: rem-calc(45);
		color: white;

		animation-delay: 0.5s;
	}

	&tagline-text {
		position: absolute;
		width: rem-calc(10);
		height: rem-calc(10);
		top: rem-calc(117);
		left: rem-calc(8);
		// transform-origin: 0 0;
		@include rotate(-90deg);
		@include transform-origin(0,0);

		font-family: $font-family-2;
		font-size: rem-calc(17);
		font-style: italic;
		color: white;

		animation-delay: 0.5s;
	}

	&tagline-line {
		position: absolute;
		top: rem-calc(40);
		left: rem-calc(19.8);
		background-color: white;
		height: rem-calc(30);
		width: 1px;

		animation-delay: 0.5s;
	}

	&tagline-text-inner-wrap {
		position: absolute;
		text-align: right;
		width: rem-calc(300);
		top: 2px;
		right: -2px;
	}

	//New logos

	&logos-3-up{
		& .lc__content-inner-wrap{
			display: flex;
			justify-content: center;
			align-items: stretch;
			flex-flow: row wrap;
			//margin: 0 -20px;
		}

		& .tai__image-group{
			border: 1px solid rgba(255, 255, 255, 0.1);
			//padding: 20px;
			width: 230px;
			height: 180px;
			//margin: 0px 17px;
			margin:0px 17px 15px;
			max-width: 100% !important;
			text-align: center;
			position: relative;

			& img{
				max-height: 100%;
				max-width: 100%;
				width: auto;
				height: auto;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				margin: auto;
			}
		}
	}

}




@media only screen and (min-width:0) and (max-width: rem-calc(1023))  {

	// .layout-container .lc__mobile-bleed-top, #f1a.exhibit.home .layout-container .lc__mobile-bleed-top {
	// 	padding-top: 0;
	// }

	// .layout-container .lc__mobile-bleed-bottom, #f1a.exhibit.home  .layout-container .lc__mobile-bleed-bottom {
	// 	padding-bottom: 0;
	// }

	.layout-container .lc__mobile-bleed-sides, #f1a.exhibit.home .layout-container .lc__mobile-bleed-sides {
		padding-left: 0;
		padding-right: 0;
	}
}

@media only screen and (min-width:0) and (max-width: 1023px)  {

    #f1a.exhibit.home .lc__inner-wrap {
        padding: rem-calc(30) rem-calc(30);
    }

    #f1a.exhibit.home .lc__exhibit-home-intro {

    	.lc__inner-wrap {
    		padding: 0;
    	}
    }
}

@media only screen and (min-width:0) and (max-width: 599px)  {

	.lc__ {

		&mobile-image-top {
			.lc__background {
				background-size: cover;
				background-position: center bottom;
				height: 0;
				padding-top: 100%;
				width: 100%;
			}

			.lc__content {
				padding-top: 100%;
			}
		}

		&mobile-image-bottom {
			.lc__background {
				background-size: cover;
				background-position: center top;
				height: 0;
				padding-top: 100%;
				top: auto;
				bottom: 0;
				width: 100%;
				right: auto;
				left: 0;
			}

			.lc__content {
				padding-bottom: 100%;
			}
		}

		&mobile-image-opacity- {
			&25 {
				.lc__background {
					opacity: .25;
				}
			}
			&50 {
				.lc__background {
					opacity: .5;
				}
			}
			&75 {
				.lc__background {
					opacity: .75;
				}
			}
		}

		&mobile-image-hide {
			.lc__background {
				background-image: none !important;
			}
		}

	}

}


@include respond-min(1100px) {

	.lc__bleed-right, .lc__bleed-left {

		background-color: transparent;
		// margin-left: 0;

		.lc__background {

			width: 50%;

			&.lc__wide, &.lc__wider {
				left: auto;
				margin-left: 0;
			}

		}

		.row.lc__content {

			&.wide, &.wider {
				max-width: rem-calc(940);
			}

		}

		&.lc__theme-color-1 {

			.lc__background {
				background-color: $theme-color-1;
			}

		}

		&.lc__theme-color-2 {

			.lc__background {
				background-color: $theme-color-2;
			}

		}

		&.lc__theme-color-3 {

			.lc__background {
				background-color: $theme-color-3;
			}

		}

		&.lc__light-gray {

			.lc__background {
				background-color: $light-gray;
			}

		}

	}

	.lc__number-tag-right, .lc__number-tag-left {

		.lc__left-of-center, .lc__right-of-center {
			width: 50%;
		}

	}
}



.layout-container .row.lc__content {
	max-width: rem-calc(1160);
	margin-left: auto;
	margin-right: auto;
	@include respond-min($medium-breakpoint) {

		&.narrow-left .columns {
			width: 43%;
		}

		&.narrow-right{

			@include respond-min($medium-breakpoint){
				max-width: rem-calc(1088)!important;

				.columns {
					width:49% !important;
					margin-left:50% !important;

					.lc__content-inner-wrap{
						width:auto!important;
					}
				}
			}

			@include respond-min($xlarge-breakpoint){
				padding-right: 7.5rem!important;

				.columns {
					width:45% !important;
					margin-left:58% !important;

					.lc__content-inner-wrap{
						width:83%!important;
					}
				}
			}

		}
	}

	&.lc__{
		&narrow {
			max-width: rem-calc(620);
		}

		&wide {
			@include respond-min(1100px) {
				max-width: rem-calc(1280);

				&.lc__wide-tagline {
					@include respond-min(1100px) {
						padding-left: rem-calc(60);
						padding-right: rem-calc(60);
					}
				}
			}

		}

		&wider {
			@include respond-min(1280px) {
				max-width: rem-calc(1380);
			}
		}

		&widest {
			@include respond-min(1280px) {
				max-width: rem-calc(1420);
			}
		}

		&full-width {
			padding-left:0;
			padding-right: 0;
			@include respond-min(960px) {
				max-width: 100%;
			}
		}
	}

}


.layout-container.lc__number-tag-right .row.lc__content.lc__wide.lc__wide-tagline  {

	@include respond-min(1100px) {
		padding-left: 0;
		padding-right: 9.5rem;
	}

	.lc__tagline-wrap {
		left: auto;
		right: -25px;
		//right: -40px;
	}
}

div.lc__number-tag-right > .lc__background.lazyloaded + .lc__inner-wrap{
	.lc__wide-tagline {

		@include respond-min(1100px) {
			padding-left: 0;
			padding-right: rem-calc(120);
			//padding-right: rem-calc(208);
		}

		.lc__tagline-wrap {
			left: auto;
			right: -75px!important;
		}
	}
}

.lc__number-tag-left, .lc__number-tag-right {
	.headline-group h3.title {
		margin-top: rem-calc(28);
	}
}


// Redesign 2020 New menu-container for secondary navigation. For exhibit home look custom changes below

.menu-container{
	display: flex;
	flex-direction: column-reverse;
	padding-top: 20px;

	@include respond-min($large-breakpoint){
		border-bottom: 2px solid rgba(black,0.1);
		flex-direction: row;
		justify-content: flex-start;
		align-items: stretch;
		margin-bottom:70px;

		& .--with-button{
			justify-content: space-between;
		}


		.interior &{
			margin-bottom:0;
		}
	}

	& .nav-secondary{
		display: block;
		@include respond-min($large-breakpoint) {
			flex-basis: 78%;
			display:table-cell;
			margin-top: 0;
			align-self: flex-start;
		}
	}

	& .feature-footer__button{
		display: block;
		@include respond-min($large-breakpoint) {
			flex-basis: auto ;
			display:table-cell;
			cursor: pointer;
		}

		& > a.button{
			z-index: 1000;
			border-radius: 5px!important;
			&:hover, &:focus{
				background: $theme-color-3;
				//border: 2px solid rgba(white, 0.2);
				.icon, span{
					color:white;
				}
			}
		}
	}

}


#f1a.exhibit.home .lc__number-tag-left{

	.tai__image-beside-text .tai__image-group-wrap.medium{
		@include respond-min($xlarge-breakpoint){
			width:45%;
		}

		& .tai__image-group{

			@include respond-min($xlarge-breakpoint){
				width:420px;
			}
		}
	}


	& .lc__tagline-wrap{
		top:0;
		left: 60px;
	}

	& .ce-content{
		@include respond-min($large-breakpoint){
			margin-right: 46%;
		}

		@include respond-min($xlarge-breakpoint){
			margin-left: 200px;
			margin-right: unset;
		}
	}

	.headline-group{
		@include respond-min($xlarge-breakpoint){
			margin-right: 20px;
		}
	}
}



.lc__exhibit-home-intro {

	.row.lc__content {
		max-width: 1220px;
		padding-left: 30px;
		padding-right: 30px;
	}

	.lc__inner-wrap {
		padding: 0;
	}

	.menu-container{
		display: flex;
		flex-direction: column-reverse;

		@include respond-min($large-breakpoint){
			border-bottom: 2px solid rgba(white,0.1);
			padding-top: 15px;
			flex-direction: row;
			justify-content: flex-start;
			align-items: stretch;
			margin-bottom:30px;


			& .--with-button{
				justify-content: space-between;
			}
		}

		& .nav-secondary{
			display: block;
			margin-top: 20px!important;
			@include respond-min($large-breakpoint) {
				align-self: flex-start;
				flex-basis: 78%;
				margin-top:0!important;
				display:table-cell;
			}
		}

		& .feature-footer__button{
			display: block;
			@include respond-min($large-breakpoint) {
				flex-basis: auto ;
				display:table-cell;
			}
		}
	}

	.lc__intro-content {
		//padding: rem-calc(46) rem-calc(15) rem-calc(30) rem-calc(15);
		padding: rem-calc(5) rem-calc(5) rem-calc(5) rem-calc(5);

		@include respond-min($xsmall-breakpoint) {
			//padding: rem-calc(46) rem-calc(30);
			padding: rem-calc(5) rem-calc(5);
		}

		@include respond-min($large-breakpoint) {
			//padding: rem-calc(80) rem-calc(100) rem-calc(30) rem-calc(30);
			padding: rem-calc(30) rem-calc(30) rem-calc(30) rem-calc(30);
		}

		@include respond-min($xlarge-breakpoint) {
			padding: rem-calc(30) 0 rem-calc(350) rem-calc(150);
		}
	}

	.lc__sidebar {
		//background-color:$theme-color-3;
		background-color:transparent;

		padding: rem-calc(0) 0 rem-calc(0) rem-calc(0);

		@include respond-min($medium-breakpoint) {
			padding: rem-calc(30);
		}

		// padding: 30px 15px
	}

	@include respond-min($large-breakpoint) {
		 overflow:hidden;

		 .lc__inner-wrap {
			 padding-top: 0;
			 padding-bottom: 0;
		 }

		 .lc__background-2 {
			 display: block;
			// background-color:$theme-color-3;
			 background-color:transparent;
			 position:absolute;
			 height:100%;
			 width:50%;
			 right:-300px;
			 top:0;
		 }

		 .lc__background-3 {
			 //background-color:$theme-color-3;
			 background-color:transparent;
			 position:absolute;
			 height:100%;
			 width:31.914893617%;
			 right:0;
			 top:0;
		 }

		.lc__sidebar {
			float:right;
			width:31.914893617%;
			position:relative;
			padding:20px 0 20px 20px;
			//padding:20px 0 20px 30px;
			// padding-bottom: 300px;
			// padding-top: 50px;

			@include respond-min($xlarge-breakpoint){
				padding:20px 0 20px 75px;

			}
		}

		.lc__intro-content {
			float:left;
			width:65%;
			position:relative;
			padding-top: rem-calc(15);

		}
	}


	@include respond-min($xlarge-breakpoint) {

		.lc__sidebar {
			padding-bottom: 300px;
			//padding-top: 50px;
			padding-top: 30px;
		}

		.lc__intro-content {
			@include respond-min($xlarge-breakpoint){
				padding: rem-calc(50) 0 rem-calc(335) rem-calc(100) ;
			}
		}
	}
}



.lc__home-intro {

	@include respond-min($large-breakpoint) {
		overflow:hidden;
	}

	.lc__inner-wrap {
		padding: 0;

		@include respond-min($large-breakpoint) {
			 padding-bottom: 0;
		}
	}

	.lc__background-2 {
		@include respond-min($large-breakpoint) {
			// @todo-upgrade Was "transparent" with theme-color-2 defined at end. Verify it is OK.
			background: $theme-color-2 image-url("home/homepage-slidebar-bg.jpg") no-repeat -130px top;
			position:absolute;
			height:100%;
			width:50%;
			right:-300px;
			top:0;
		}
	}

	.lc__background-3 {
		@include respond-min($large-breakpoint) {
			// @todo-upgrade Was "transparent" with theme-color-2 defined at end. Verify it is OK.
			background: $theme-color-2 image-url("home/homepage-slidebar-bg.jpg") no-repeat left top;
			position:absolute;
			height:100%;
			width:31.914893617%;
			right:0;
			top:0;
		}
	}

	.lc__intro-content {
		padding: rem-calc(46) rem-calc(15) rem-calc(30) rem-calc(15);
		.subtitle {
			font-size: rem-calc(11);
			margin-bottom: rem-calc(30);
		}
		.title {
			margin-bottom: rem-calc(30);
		}

		@include respond-min($xsmall-breakpoint) {
			padding: rem-calc(46) rem-calc(30);
		}

		@include respond-min($large-breakpoint) {
			float:left;
			width:65%;
			position:relative;
			padding: rem-calc(80) rem-calc(30) rem-calc(30) rem-calc(30);
		}

		@include respond-min($xlarge-breakpoint) {
			padding: rem-calc(100) 0 rem-calc(350) rem-calc(150);
		}
	}

	.lc__sidebar {
		background-color:$theme-color-2;
		padding: rem-calc(15) rem-calc(15);

		@include respond-min($xsmall-breakpoint) {
			padding: rem-calc(30) rem-calc(30);
		}

		@include respond-min($large-breakpoint) {
			float:right;
			width:31.914893617%;
			position:relative;
			padding: rem-calc(72) 0 rem-calc(72) 0;
			background-color: transparent;
		}

		@include respond-min($xlarge-breakpoint) {
			padding: rem-calc(108) 0 rem-calc(398) 0;
		}

		h3 {
			color: white;
			margin-bottom: rem-calc(16);
			font-size: rem-calc(24);
			margin-top: rem-calc(16);
			@include respond-min($large-breakpoint) {
				margin-top: 0;
				font-size: rem-calc(17);
				margin-bottom: rem-calc(10);
			}

			@include respond-min($xlarge-breakpoint) {
				font-size: rem-calc(24);
				margin-bottom: rem-calc(24);
			}
		}

		.address {
			font-weight: bold;
			color: $text-color-base;
			font-size: rem-calc(18);
			display: block;
			@include respond-min($large-breakpoint) {
				font-family: $font-family-2;
				font-weight: 400;
				font-size: rem-calc(21);
				line-height: rem-calc(30);
				color: white;
				margin-bottom: rem-calc(26);
			}

			@include respond-min($xlarge-breakpoint) {
				font-size: rem-calc(27);
				line-height: rem-calc(36);
				margin-bottom: rem-calc(41);
			}
		}

		.direction-link {
			position: relative;
			margin-top: rem-calc(26);
			margin-left: rem-calc(30);
			a {
				color: $theme-color-1;
				display: block;
				font-weight: bold;
				font-size: rem-calc(18);
				@include respond-min($large-breakpoint) {
					font-size: rem-calc(16);
				}

				@include respond-min($xlarge-breakpoint) {
					font-size: rem-calc(17);
				}
			}

			.indicator {
				position: absolute;
				height: 2px;
				background-color: $theme-color-1;
				width: 34%;
				left: -39%;
				top: rem-calc(10);

				@include respond-min($xlarge-breakpoint) {
					width: 55%;
					left: -63%;
				}
			}
		}

		h2,
		h3,
		.address,
		.direction-link {
			@include respond-min($large-breakpoint) {
				margin-left: auto;
				margin-right: auto;
				width: rem-calc(202);
			}

			@include respond-min($xlarge-breakpoint) {
				margin-left: rem-calc(40);
				margin-right: 0;
				width: auto;
			}
		}


	}
}


.lc__inner-wrap--full-width{
	padding-left:0!important;
	padding-right:0!important;
	@include respond-min(960px){
		padding-left:0!important;
		padding-right:0!important;
	}
}

.footer_container{
	@include respond-max($xlarge-breakpoint) {
		margin-top: rem-calc(-50);
		padding-top:0;
		overflow: visible;

		& .lc__content-offset-top-2{
			margin-top: -12.5rem;
		}
	}

}

.extra__padding-bottom{
	@include respond-max($xlarge-breakpoint){
		padding-bottom: 13rem;
	}

	@include respond-max($medium-breakpoint){
		padding-bottom: 10rem;
	}

}

