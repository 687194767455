.welcome_text {
  &__subheader {
    font-size: 11px;
    line-height: 30px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    letter-spacing: 2px;
    @include respond-min($large-breakpoint) {
      font-size: 12px;
    }
  }

  &__header {
    font-size: 22px;
    line-height: 28px;
    font-weight: 700;
    text-align: center;
    padding:0 10px;
    margin-bottom: 10px;
    letter-spacing: -0.5px;
    @include respond-min($large-breakpoint) {
      font-size: 42px;
      line-height: 48px;
      margin-bottom: 20px;
    }
  }

  &__link {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0;

    a{
      color: var(--theme-color-1);
      font-weight: bold;
      padding-bottom: 2px;
      border-bottom: 2px solid var(--theme-color-1);
    }
  }

  br{
    display: none;
    @include respond-min($medium-breakpoint) {
      display: block;
    }
  }
}
