	// ======================================================================
	// ACCORDION & TABS
	// ======================================================================


.msi-tabs {
	overflow: hidden;

	.scroll-container {
		// this width: 103% is added by Ben to hide the FireFox/IE scroll bar, also, the overflow: hidden; is added to .msi-tabs in order to make the idea work. The idea is from http://stackoverflow.com/questions/20997183/how-to-hide-scrollbar-in-mozilla-firefox
		width: 103%;
		// overflow: auto;
		overflow-y: scroll; /* has to be scroll, not auto */
		-webkit-overflow-scrolling: touch;
		&::-webkit-scrollbar {
			display: none;
		}
		& > .tabs {
			@include reset-ul;
			border-bottom: 3px solid $lighter-gray;
			margin: rem-calc(29) 0;
			margin-bottom: rem-calc(18) !important;
			line-height: rem-calc(32);
			white-space: nowrap;
			position: relative;
			display: inline-block;
			min-width: 100%;
			& > .tab-title {
				white-space: nowrap;
				display: inline-block;
				float: none;
				&:first-child > a { margin-left:0px; }
				&  > a {
					background: transparent;
					color: $theme-color-2;
					margin-bottom: -3px;
					padding: rem-calc(7px) 0;
					margin: 0 rem-calc(15) -3px;
					border-bottom: 3px solid $lighter-gray;
					text-decoration: none;
					@include respond-min($large-breakpoint) {
						margin: {
							left: rem-calc(20);
							right: rem-calc(10);
						}
					}
					&:hover,
					&:focus {
						background-color: transparent;
						border-bottom: 3px solid $theme-color-1;
						color: $theme-color-1;
					}
					&:focus {
						// outline: none;
						//@todo ron - we may want to remove this outline globally, but there may be accessibility ramificaitons
					}
				}
				&.active {
					color: $theme-color-2;
					> 	a {
						border-bottom: 3px solid $theme-color-1;
						color: $theme-color-2;
						font-weight: 700;

					}
				}
			}
		}
	}
}
