// ======================================================================
// Basic CE Styling
// ======================================================================


.text-and-images {

	@extend %clearfix;
}

.tai__ {

	&image-group-wrap {
		@extend %clearfix;
		margin: 0 auto 40px;
		width: 100%;

		@include respond-min($small-breakpoint) {
			&.medium { width: 40%; }
			&.small { width: 25%; }
			&.x-small { width: 15%; }
		}
		@include respond-min($large-breakpoint) {
			margin-bottom: 48px;
		}
	}


	&image-wrap-no-margin {
		.tai__image-group-wrap {
			margin-bottom: 0;
		}
	}

	&fixed-image-width {
		margin-left: 0.625em;
		.tai__image-group {
			// margin: 0;
			&.single-image {
				max-width: 120px;
				@include respond-min($small-breakpoint) {
					max-width: 170px;
				}
			}
			@include block-grid(2);
			> li img{
				max-width: 120px;
				@include respond-min($large-breakpoint) {
					max-width: 150px;
				}
			}
			@include respond-min($small-breakpoint) {
				@include block-grid(3);
			}
			@include respond-min($medium-breakpoint) {
				@include block-grid(4,50px);
			}
		}
	}

	@include respond-min($small-breakpoint) {
		&image-above, &image-below {
			&.tai__image-right {
				.tai__image-group-wrap {
					margin-right: 0;
				}
			}

			&.tai__image-left {
				.tai__image-group-wrap {
					margin-left: 0;
				}
			}
		}
	}

	&image-in-text {

		.tai__image-group-wrap {
			width: 30%;
			float: left;
			margin: rem-calc(4) rem-calc(20) rem-calc(10) 0;
			@include respond-min($large-breakpoint) {
				margin: rem-calc(4) rem-calc(40) rem-calc(25) 0;
			}
			@include respond-min($small-breakpoint) {
				&.large { width: 52.173913%; }
				&.medium { width: 40%; }
				&.small { width: 25%; }
				&.x-small { width: 15%; }
			}

			// @todo-ron margin not right fix it
			// @include respond-min($medium-breakpoint) {
			// 	margin: 4px 45px 25px 0;
			// 	margin: rem-calc(4) rem-calc(45) rem-calc(25) 0;
			// }
		}

		&.tai__image-right {
			.tai__image-group-wrap {
				float: right;
				margin: rem-calc(4) 0 rem-calc(10) rem-calc(20);
				@include respond-min($large-breakpoint) {
					margin: rem-calc(4) 0 rem-calc(25) rem-calc(35);
				}
			}

			// @todo-ron margin not right fix it
			// @include respond-min($medium-breakpoint) {
			// 	margin: rem-calc(4) 0 rem-calc(25) rem-calc(45);
			// }

		}
	}

	&image-beside-text {
		.tai__image-group-wrap {

			&.medium {
				width: 100%;

				@include respond-min($large-breakpoint) {
					width: 40%
				}
			}

			@include respond-min($large-breakpoint) {
				float: left;
				margin-top: rem-calc(4);
				&.large {
					width: 57.4468085%;
					margin-right: 9%;

					@include respond-min($xlarge-breakpoint) {
						margin-right: 11.6382979%;
					}
				}
				&.medium, &.small, &.x-small {
					margin-right: 7.4468085%;
				}
			}
		}

		&.tai__image-right {
			.tai__image-group-wrap {
				@include respond-min($large-breakpoint) {
					float: right;
					&.large {

						margin-left: 9%;
						margin-right: 0;

						@include respond-min($xlarge-breakpoint) {
							margin-left: 10.5744681%;
						}

					}
					&.medium, &.small, &.x-small {
						margin-left: 9.5744681%;
						margin-right: 0;
					}
				}
			}
		}

		.tai__image-group.single-image {
			img {
				width: 100%;
			}
		}
	}

	&image-beside-text {

		.ce-content {
			@include respond-min($large-breakpoint) {
				display: table;
			}
		}
	}

	&hang-both {
		.single-image {
			margin-left: rem-calc(-15);
			margin-right: rem-calc(-15);

			@include respond-min($xsmall-breakpoint) {
				margin-left: rem-calc(-30);
				margin-right: rem-calc(-30);
			}

			.photo-caption {
				margin-left: rem-calc(15);
				@include respond-min($xsmall-breakpoint) {
					margin-left: rem-calc(30);
				}
			}
		}

		@include respond-min($xlarge-breakpoint) {
			.tai__image-group {
				margin-left: 0;
				margin-right: 0;
			}

			.photo-caption {
				margin-left: 0;
			}
		}
	}

	@include respond-min($large-breakpoint) {
		&hang-both .tai__image-group {
			margin-left: -30px;
			margin-right: -30px;
		}
		&hang-right .tai__image-group { margin-right: -30px; }
		&hang-left .tai__image-group { margin-left: -30px; }
	}

	@include respond-min($xlarge-breakpoint) {
		&hang-both .tai__image-group {
			margin-left: -40px;
			margin-right: -40px;
		}
		&hang-right .tai__image-group { margin-right: -40px; }
		&hang-left .tai__image-group { margin-left: -40px; }
	}

	@include respond-min(1100px) {
		&hang-both .tai__image-group {
			margin-left: -70px;
			margin-right: -70px;
		}
		&hang-right .tai__image-group { margin-right: -70px; }
		&hang-left .tai__image-group { //margin-left: -70px;
			margin-left: -100px; }
	}

	&image-group figcaption, .photo-caption {
		position: relative;
		font-size: rem-calc(15);
		margin: rem-calc(20) 0 0;
		line-height: 1.2;

		.indicator {
			visibility: hidden;
		}

		.small {
			font-size: rem-calc(13);
			font-style: italic;
			font-family: $font-family-2;
		}
		br,
		.break {
			display: none;
			@include respond-min($large-breakpoint) {
				display: block;
			}
		}
	}

	@include respond-min(1100px) {
		&image-left, &hang-both, &hang-left {
			.photo-caption {
				.indicator {
					position: absolute;
					height: 1px;
					background-color: $gray-20;
					width: 63px;
					left: -82px;
					top: 8px;
					visibility: visible;
				}
			}
		}
	}
}

@media only screen and (min-width:0) and (max-width: rem-calc(767))  {

	.tai__ {
		&image-above, &image-below {

			&.tai__hang-left, &.tai__hang-right {
				.single-image {
					margin-left: rem-calc(-15);
					margin-right: rem-calc(-15);

					@include respond-min($xsmall-breakpoint) {
						margin-left: rem-calc(-30);
						margin-right: rem-calc(-30);
					}
				}
			}

			// @include respond-min(1100px) {
			// 	&hang-right .tai__image-group.single-image {
			// 		margin-left: 0;
			// 		margin-right: -70px;
			// 	}
			// 	&hang-left .tai__image-group.single-image {
			// 		margin-left: -70px;
			// 		margin-right: 0;
			// 	}
			// }
		}
	}
}




@include respond-min($xlarge-breakpoint) {

	// @todo-ron doesn't look like this is valid anymore 1/19/16
	// .layout-container .text-and-images.image-beside-text {
	// 	.tai__image-group-wrap.large {
	// 		width: 57.4468085106%;
	// 		margin-right: 100px;
	// 	}
	//
	// 	&.tai__image-right {
	// 		.tai__image-group-wrap.large {
	// 			margin-left: 100px;
	// 			margin-right: 0;
	// 		}
	// 	}
	// }

	#f1a.exhibit.home .tai__image-beside-text {
		.tai__image-group-wrap {
			margin-right: 6.3829787%;
			&.large {
				width: 51.0638298%;
			}
			&.medium {
				width: 46.8085106%;
			}
		}

		&.tai__image-right {
			.tai__image-group-wrap {
				margin-left: 6.3829787%;
				margin-right: 0;
			}
		}
	}
}
