	// ======================================================================
	// Special Headline Group
	// // @todo-ron MOVE TO TYPOGRAPHY LATER
	// ======================================================================

.headline-group {
	position: relative;

	&.with-rule {
		margin-bottom: rem-calc(35);
		@extend .clearfix;

		.title {
			margin-bottom: rem-calc(0);
			padding-bottom: rem-calc(28);
		}
		/*
		h1 {
			margin-bottom: 0;
			padding-bottom: rem-calc(28);
		}
		*/
	}

	.rule {
		position: absolute;
		height: 2px;
		background-color: $theme-color-1;
		width: rem-calc(150);
		bottom: 0;
		left: rem-calc(-120);
        .lc__theme-color-2 &, .lc__theme-color-3 & {
            background-color: $theme-color-1-alt;
        }
	}

	.title + .subtitle {
		margin-top: rem-calc(-16);
	}

	h1 + .subtitle {
		margin-bottom: rem-calc(24);
	}

	h2 + .subtitle,
	h3 + .subtitle,
	h4 + .subtitle {
		margin-bottom: rem-calc(18);
	}

	h5 + .subtitle {
		margin-bottom: rem-calc(14);
	}

	.subtitle {
		margin-bottom:  rem-calc(5);
		font-size: rem-calc(12);
		text-transform: uppercase;
		font-weight: 400;
		letter-spacing: rem-calc(2);
		line-height: 0.9rem;
		color: $text-color-base;
	}


	// right aligned
	&.align-right {
		.title,
		.subtitle {
			text-align: right;
		}
		.rule {
			left: auto;
			right: rem-calc(-120);
		}
	}

	// center aligned
	&.align-center {
		.title,
		.subtitle {
			text-align: center;
		}
		.rule {
			left: auto;
			right: auto;
			position: relative;
			display: block;
   			margin: 0 auto;
		}
	}

	&.invert {
		.title,
		.subtitle {
			color: white;
		}
	}

	.tai__image-left & h2.title, .tai__image-right & h2.title {
		margin-bottom: 20px;
	}
}



/// BEN CODE

// .special-headline-group {
//
//
// 	.title {
// 		font-size: rem-calc(48);
// 		padding-top: rem-calc(15);
// 		padding-bottom: rem-calc(40);
// 		line-height: 1;
// 		color: white;
// 		position: relative;
//
// 		.indicator {
// 			position: absolute;
// 			height: 2px;
// 			background-color: $theme-color-1;;
// 			width: 20%;
// 			bottom: 0;
// 		}
// 	}
//
// 	.subtitle {
// 		font-size: rem-calc(10);
// 		text-transform: uppercase;
// 		font-weight: 400;
// 		letter-spacing: rem-calc(3);
// 		line-height: 0.9rem;
// 		color: white;
// 		@include opacity(.5);
//
// 	}
//
//
// 	&.special-headline-group-left {
// 		.title {
// 			.indicator {
// 				left: -15%;
// 			}
// 		}
// 	}
//
// 	&.special-headline-group-right {
// 		.title {
// 			text-align: right;
// 			.indicator {
// 				right: -15%;;
// 			}
// 		}
//
// 		.subtitle {
// 			text-align: right;
// 		}
// 	}
//
// 	&.special-headline-group-invert {
// 		.title {
// 			color: $theme-color-2;
// 		}
//
// 		.subtitle {
// 			color: $theme-color-2;
// 		}
// 	}
// }
