// ======================================================================
// Box Teaser
// ======================================================================
//
// .box-teaser governs the side-by-side state of the box teaser
// .horizontal and .teaser-foo-rejig wrappers govern when and how they convert to image-above-text box teasers when they pass $layout-breakpoint
//
// Explore (landing) :: http://msi.dev/explore/
//     web/typo3conf/ext/activities/Resources/Private/Templates/Activity/ListByType.html
//     web/typo3conf/ext/experiences/Resources/Private/Templates/Experience/ListByType.html
//
// Explore Whats Here :: http://msi.dev/explore/whats-here/
//     web/typo3conf/ext/experiences/Resources/Private/Templates/Experience/ListByFilters.html
//
// Plan Your Visit :: http://msi.dev/visit/plan-your-visit/
//     web/typo3conf/ext/experiences/Resources/Private/Templates/Experience/PlanYourVisit.html
//
// Games Page :: http://msi.dev/index.php?id=116
//    web/typo3conf/ext/activities/Resources/Private/Templates/Activity/List.html
//
// ?
//     web/typo3conf/ext/experiences/Resources/Private/Templates/Experience/List.html
//
// Experience - More to Explore :: http://msi.dev/explore/whats-here/exhibits/u-505-submarine/
//     web/typo3conf/ext/experiences/Resources/Private/Templates/Experience/FeaturedOrRelated.html
//
// My Visit Drawer (must add items)
//     web/typo3conf/ext/experiences/Resources/Private/Partials/Visit/FeaturedVisitItem.html
//     web/typo3conf/ext/experiences/Resources/Private/Partials/Visit/VisitItem.html
//
// Search Results :: must search title, e.g. 'brick by brick'
//     web/typo3conf/ext/experiences/Resources/Private/Templates/Experience/SearchResults.html
//
// Museum Map :: http://msi.dev/index.php?id=26&no_cache=1
//     web/typo3conf/ext/map/Resources/Private/Partials/Map/InfoWindow.html
//     web/typo3conf/ext/map/Resources/Private/Partials/Map/ListItem.html
//
// Ticketing Summary ::
//     web/typo3conf/ext/msi_ticketing/Resources/Public/App/app/templates/summary.hbs
//
@use "sass:math";

$type-range-1: em-calc(370);
$type-range-2: $small-breakpoint - em-calc(1);
$type-breakpoint-10: em-calc(370);
$layout-breakpoint: em-calc(540);
$type-breakpoint-20: $xlarge-breakpoint;
.lc__content-inner-wrap > div{
    display:block;
    width:100%;
}
.box-teaser {
    overflow: hidden;
    position: relative;
   // @include box-shadow(rgba(0,0,0,0.1) 0px 4px 6px);
    @include box-shadow(rgba(0,0,0,0.1) 0px 7px 15px);
    background-color: rgba(0,0,0,0.1); //simulate transparent border
    padding: 1px;
    max-width: 600px;
    margin: 0 auto;
    br,
    .break {
        display: none;
    }
    .box-teaser-inner-wrap {
        display: table; // table-cell width is calculated off this width
        table-layout: fixed;
        width: 100%;
        background: white;
    }
    .box-teaser-image-wrap {
        width: math.div(158, 518) * 100%; // desired width divided by parent width times 100% gives you desired percent width
        display: table-cell;
        background-color: #525861;
    }
    .box-teaser-image {
        width: 100%;
        margin-bottom: 100%;
        &[src*="/"] {
            margin-bottom: 0;
        }
    }
    .box-teaser-content {
        display: table-cell;
        vertical-align: middle;
        width: math.div(360, 518) * 100%;
        padding: rem-calc(5) 10%;
        color: $text-color-base;
        background-color: white;
        height: math.div(232, 518) * 100%;
    }
    .icon-circle {
        display: block;
        font-size: rem-calc(12);
        margin: 0;
        position: absolute;
        left: math.div(158, 518) * 100%;
        top: 50%;
        margin: -1em;

        @include respond-min($large-breakpoint){
            width:70px;
            height: 70px;
            left: 46%;
        }

        .icon {
            color: white;
            font-size: 1.4em;
            line-height:1.4em;
            width:1.4em;
            height: 1.4em;
            @include respond-min($large-breakpoint){
                width:70px;
                height: 70px;
                font-size: 47px;
                line-height: 47px;
            }
        }
    }
    .title {
        font-size: rem-calc(15);
        line-height: rem-calc(15);
        margin-top: 0;
        margin-bottom: 0;
        text-align: left;
        color: $theme-color-3 !important;
    }
    .subtitle {
        font-size: rem-calc(9);
        line-height: rem-calc(9);
        text-transform: uppercase;
        letter-spacing: rem-calc(3);
        font-weight: 400;
        margin-top: rem-calc(6);
        margin-right: rem-calc(-4);
        color: $theme-color-1;
        font-weight: 600;
    }
    .description {
        font-size: rem-calc(16);
        line-height: rem-calc(20);
        margin-bottom: rem-calc(23);
        display: none;
        padding-top: 10px;
        margin-bottom: 0;
    }
    .link-underline {
        font-size: rem-calc(13);
        line-height: rem-calc(13);
        font-weight: bold;
        padding-bottom: rem-calc(8);
        margin-bottom: rem-calc(30);
        opacity: 0;
        transition: opacity 0.3s ease;
        display: none;

        @include respond-min($xxlarge-breakpoint){
            margin-bottom: rem-calc(50);
        }
    }
    &:hover, &--focused {
        color: $text-color-base;
        .icon {
            color: white;
        }
        .link-underline {
            opacity: 1;
            &:hover {
                color: $link-color-hover;
                border-color: $link-color-hover;
            }
        }
    }
    .corner-wrap {
        position: absolute;
        overflow: hidden;
        height: rem-calc(72);
        width: rem-calc(72);
        transform: translate3d(0px, 0px, 0px);
        &.top {
            top: 1px;
        }
        &.right {
            right: 1px;
            float: none !important;
        }
        &.left {
            left: 1px;
            float: none !important;
        }
        &.bottom {
            bottom: 1px;
        }
    }
    .corner {
        position: absolute;
        top: -53px;
        right: -16px;
        background: $theme-color-1;
        padding: rem-calc(40 4);
        @include rotate(-45deg);
        transition: opacity 0.6s cubic-bezier(0.165, 0.84, 0.44, 1), color 0.2s ease;
        opacity: 0;
        display: block;
        width: auto;

        @include respond-min($large-breakpoint){
            top: -50px;
            right: -5px;
        }

        .icon {
            @include rotate(45deg);
            color: white;
            font-size: 12px;
            padding: 10px;
            font-size: 12px;
            margin-right: 20px;
            @include respond-min($large-breakpoint){
                font-size: 1em;
                padding: rem-calc(20 20 14 14);
                margin-right: 0.25em;
            }
        }
        &:hover, &:focus {
            background: white;
            .icon {
                color: $link-color-hover;
            }
        }
        &.bottom-right {
            top: inherit;
            bottom: -45px;
            right: -18px;
            padding: rem-calc(40 17 40 5);
            @include rotate(45deg);
            .icon {
                padding: 0 21px 0 0;
                @include rotate(-45deg);
            }
        }
        &.box-teaser-remove {
            background-color: rgba(0,0,0,0.05);
            .icon {
                color: #999999;
            }
            &:hover {
                background-color: $theme-color-1;
                .icon {
                    color: white;
                }
            }
        }
    }
}
.box-teaser.horizontal {
    // My Visit Drawer (must add items)
    //      web/typo3conf/ext/experiences/Resources/Private/Partials/Visit/FeaturedVisitItem.html
    //      web/typo3conf/ext/experiences/Resources/Private/Partials/Visit/VisitItem.html
    // Search Results :: must search title, e.g. 'brick by brick'
    //      web/typo3conf/ext/experiences/Resources/Private/Templates/Experience/SearchResults.html
    // Museum Map :: http://msi.dev/index.php?id=26&no_cache=1
    //      web/typo3conf/ext/map/Resources/Private/Partials/Map/ListItem.html
    // Ticketing Summary ::
    //      web/typo3conf/ext/msi_ticketing/Resources/Public/App/app/templates/summary.hbs
    $type-breakpoint-10: $medium-breakpoint;
    $type-breakpoint-20: $xlarge-breakpoint;
    @include box-shadow(rgba(0,0,0,0.1) 0px 4px 6px);
    max-width: 600px;
    margin: 0 auto rem-calc(20);
    border-radius: 10px;
    @include respond-min($xlarge-breakpoint) {
        min-height: 0;
    }
    .box-teaser-inner-wrap {
        display: table;
        border-radius: 10px;
    }
    .box-teaser-image-wrap {
        display: table-cell;
        width: math.div(158, 518) * 100%;
        margin-bottom: 0;
        z-index: 1;
    }
    .icon-circle {
        z-index: 2;
        font-size: rem-calc(13);
        position: absolute;
        left: math.div(158, 518) * 100%;
        top: 50%;
        margin: -1em;
        width:2em;
        height:2em;
        @include respond-min($type-breakpoint-20) {
            font-size: rem-calc(18);
        }

        .icon{
            font-size: 1.4em;
            line-height: 1.4em;
            width: 1.4em;
            height: 1.4em;
        }
    }
    .box-teaser-content {
        display: table-cell;
        padding: rem-calc(5) 13% rem-calc(5) 8%;
        min-height: 0;
        max-height: auto;
        width: math.div(360, 518) * 100%;
        transform: inherit !important;

        @include respond-min($medium-breakpoint){
            padding: rem-calc(5) 10%;
        }
    }
    .title {
        font-size: rem-calc(15);
        line-height: 1;
        min-height: 0;
        padding-bottom: 0;
        text-align: left;
        @include respond-min($medium-breakpoint) {
            font-size: rem-calc(20);
            line-height: 1;
        }
    }
    .subtitle {
        margin-top: rem-calc(6);
        min-height: 0;
        padding-bottom: 0;
        text-align: left;
        @include respond-min($medium-breakpoint) {
            margin-top: rem-calc(10);
        }
    }
    .description {
        padding-top: rem-calc(10);
        margin-bottom: 0;
        @include respond-min($large-breakpoint) {
            display: block;
        }
    }
    .box-teaser-remove-wrap {
        position: absolute;
        bottom: 1px;
        right: 1px;
        width: 40px;
        height: 55px;
        transform: translateZ(0);
        overflow: hidden;
    }

    &:hover .corner {
        display: block;
        opacity: 1;
    }
    &.map {
        .description {
            display: none;
        }
        .link-underline {
            display: none;
        }
    }
}
.teaser-medium-rejig {
    // Explore (landing) :: http://msi.dev/explore/
    //    web/typo3conf/ext/activities/Resources/Private/Templates/Activity/ListByType.html
    //    web/typo3conf/ext/experiences/Resources/Private/Templates/Experience/ListByType.html
    // Explore Whats Here :: http://msi.dev/explore/whats-here/
    //    web/typo3conf/ext/experiences/Resources/Private/Templates/Experience/ListByFilters.html
    // Plan Your Visit :: http://msi.dev/visit/plan-your-visit/
    //    web/typo3conf/ext/experiences/Resources/Private/Templates/Experience/PlanYourVisit.html
    // Games Page :: http://msi.dev/index.php?id=116
    //    web/typo3conf/ext/activities/Resources/Private/Templates/Activity/List.html
    // ?
    //    web/typo3conf/ext/experiences/Resources/Private/Templates/Experience/List.html
    $type-breakpoint-10: em-calc(370);
    $layout-breakpoint: em-calc(540);
    $type-breakpoint-20: $xlarge-breakpoint;
    .box-teaser {
        @include respond-min(600px) {
             //@include box-shadow(rgba(0,0,0,0.1) 0px 3px 4px);
             @include box-shadow(rgba(0,0,0,0.1) 0px 7px 15px);
            //max-width: rem-calc(300);
            max-width: rem-calc(360);
            margin-bottom: rem-calc(20);
        }

        @include respond-min(600px) {
            margin: rem-calc(0 -0.016 20 0);
        }

        @include respond-min($xlarge-2-breakpoint){
            height: 520px;
        }

        #plan-visit-outer-wrap & {
            @include respond-min($layout-breakpoint) {
                margin: rem-calc(0 -0.35 20 0);
            }
        }
        br,
        .break {
            @include respond-min($layout-breakpoint) {
                display: block;
            }
        }
        .box-teaser-inner-wrap {
            @include respond-min(600px) {
                display: block;
                overflow: hidden;
                position: relative;
            }
        }
        .box-teaser-image-wrap {
            @include respond-min(600px) {
                width: auto;
                //margin-bottom: (518 - 298) / 298 * 100%;
                margin-bottom: math.div(520 - 363, 298) * 100%;
                //margin-bottom: 43%;
                display: block;
            }
        }
        .box-teaser-image {
            @include respond-min(600px) {
                float: none;
            }
        }
        .icon-circle {
            @media only screen and (min-width:$type-range-1) and (max-width: $type-range-2) {
                font-size: rem-calc(16);
            }
            @include respond-min($type-breakpoint-10) {
                font-size: rem-calc(16);
            }
            @include respond-min(600px) {
                font-size: rem-calc(20);
                margin: -1em;
                left: 50%;
                top: 0;
                width:2em;
                height: 2em;
            }
            @include respond-min($type-breakpoint-20) {
                font-size: 1.4em;
                width:70px;
                height: 70px;
                left: 46%;
                top: -8px;
            }

            .icon{
                width: 1.4em;
                height: 1.4em;
                font-size: 1.4em;
                line-height: 1.4em;

                @include respond-min($type-breakpoint-20) {
                    width:70px;
                    height: 70px;
                    font-size: 47px;
                    line-height: 47px;
                }
            }
        }
        .box-teaser-content {
            @media only screen and (min-width:1px) and (max-width: $layout-breakpoint) {
                transform: inherit !important;
            }
            @media only screen and (min-width:$type-range-1) and (max-width: $type-range-2) {
                padding: rem-calc(5) 8.3333333333%;
            }
            @include respond-min($type-breakpoint-10) {
                padding: rem-calc(5) 8.3333333333%;
            }
            @include respond-min(600px) {
                transition-property: transform;
                transform: translate3d(0px, 0px, 0px);
                transition-duration: 0.6s;
                transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
                display: block;
                padding: 0;
                position: absolute;
                bottom: 15px;
                //margin-top: 232 / 298 * -100%;
                //margin-top: -55%;
                margin-top: math.div(252, 363) * -100%;
                left: 0;
                right: 0;
                padding-bottom: rem-calc(40);
                width: auto;
                &:after {
                    content: "";
                    display: table;
                    clear: both;
                }
            }
        }
        .description,
        .subtitle,
        .title {
            @include respond-min(600px) {
                padding: 0 12%;
            }
            @include respond-min(em-calc(680)) {
                padding: 0 6%;
            }
            @include respond-min(em-calc(970)) {
                padding: 0 12%;
            }
        }
        .title {
            @media only screen and (min-width:$type-range-1) and (max-width: $type-range-2) {
                font-size: rem-calc(20);
                line-height: rem-calc(20);
            }
            @include respond-min($type-breakpoint-10) {
                font-size: rem-calc(20);
                line-height: rem-calc(20);
            }
            @include respond-min(600px) {
                display: table;
                text-align: center;
                width: 100%;
                margin-top: rem-calc(20);
                font-size: rem-calc(22);
                line-height: rem-calc(24);
                //height: rem-calc(81);
                margin-bottom: 10px;
                height: 50px;

            }
            @include respond-min(em-calc(970)) {
                font-size: rem-calc(24);
                line-height: rem-calc(28);
                height: 70px;
            }
            @include respond-min($type-breakpoint-20) {
                margin-top: rem-calc(42);
            }
        }
        .title-inner-wrap {
            @include respond-min(600px) {
                display: table-cell;
                text-align: center;
                vertical-align: middle;
            }
        }
        .subtitle {
            @media only screen and (min-width:$type-range-1) and (max-width: $type-range-2) {
                margin-right: 0;
                margin-top: rem-calc(10);
                font-size: rem-calc(9);
                line-height: rem-calc(11);
            }
            @include respond-min($type-breakpoint-10) {
                margin-right: 0;
                margin-top: rem-calc(10);
                font-size: rem-calc(12);
                line-height: rem-calc(14);
            }
            @include respond-min(600px) {
                margin: 0;
                min-height: rem-calc(9);
                padding-bottom: math.div(20, 298) * 100%;
                text-align: center;
                @include respond-min(em-calc(680)) {
                    padding-bottom: math.div(20, 298) * 100%;
                }
                @include respond-min(em-calc(970)) {
                    padding-bottom: math.div(20, 298) * 100%;
                }
                @include respond-min($type-breakpoint-20) {
                    //padding-bottom: 20 / 298 * 100%;
                    padding-bottom: 13px;
                }
            }
        }
        .description {
            /*@include respond-min(em-calc(486)) {
                display: block;
                padding-top: rem-calc(6);
                margin-bottom: rem-calc(0);
            }

             */
            @include respond-min(600px) {
                display: block;
                padding-top: 0;
                margin-bottom: rem-calc(22);
                font-size: rem-calc(16);
                line-height:rem-calc(24);
            }
        }
        .link-underline {
            @include respond-min(600px) {
                display: inline-block;
                margin-left: 13%;
            }
            @include respond-min(em-calc(680)) {
                margin-left: 6%;
            }
            @include respond-min(em-calc(970)) {
                margin-left: 13%;
            }
        }
        &:hover {
                .corner {
                    display: block;
                    opacity: 1;
                }

        }


        .corner {
                display: block;
                overflow: visible;
                opacity: 0;
                &--focused {
                    opacity: 1;
                }
            }
        }

    .fade-in {
        display: none;
        @include respond-min(600px) {
            display: block;
            position: absolute;
            left: 1px;
            right: 1px;
            bottom: 0;
            height: rem-calc(20);
            background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
        }
    }
}
.teaser-large-rejig {
    // Ticketing Summary ::
    //    web/typo3conf/ext/msi_ticketing/Resources/Public/App/app/templates/summary.hbs
    // Museum Map :: http://msi.dev/index.php?id=26&no_cache=1
    //     web/typo3conf/ext/map/Resources/Private/Partials/Map/InfoWindow.html
    $type-breakpoint-10: em-calc(370);
    $layout-breakpoint: $large-breakpoint;
    $type-breakpoint-20: $xlarge-breakpoint;
    .box-teaser {
        @include respond-min($layout-breakpoint) {
             @include box-shadow(rgba(0,0,0,0.1) 0px 7px 15px);
            max-width: rem-calc(360);
            margin-bottom: rem-calc(20);
        }
        @include respond-min($large-breakpoint) {
            margin: rem-calc(0 -0.016 20 0);
        }

        @include respond-min($xlarge-2-breakpoint){
            height: 520px;
            .experience-timeline & {
                height: rem-calc(460);
            }
        }

        br,
        .break {
            @include respond-min($layout-breakpoint) {
                display: block;
            }
        }
        .box-teaser-inner-wrap {
            @include respond-min($layout-breakpoint) {
                display: block;
                overflow: hidden;
                position: relative;
            }
        }
        .box-teaser-image-wrap {
            @include respond-min($layout-breakpoint) {
                width: auto;
                margin-bottom: (520 - 282) / 282 * 100%;
                margin-bottom: (520 - 312) / 312 * 100%;
                //margin-bottom: 43%;
                // margin-bottom: math.div(520 - 363, 298) * 100%;
                display: block;
            }
        }
        .box-teaser-image {
            @include respond-min($layout-breakpoint) {
                float: none;
            }
        }
        .icon-circle {
            @media only screen and (min-width:$type-range-1) and (max-width: $type-range-2) {
                font-size: rem-calc(16);
            }
            @include respond-min($type-breakpoint-10) {
                font-size: rem-calc(16);
            }
            @include respond-min($layout-breakpoint) {
                font-size: rem-calc(20);
                margin: -1em;
                left: 50%;
                top: 0;
                .experience-timeline & {
                    left: 49%;
                    top: rem-calc(-5);
                    width:rem-calc(46);
                    height: rem-calc(46);
                    & > .icon {
                        width:rem-calc(36) !important;
                        height: rem-calc(36) !important;
                        padding-left: rem-calc(7);
                        font-size: rem-calc(33);
                        line-height: 1.35;
                    }
                }
            }
            @include respond-min($type-breakpoint-20) {
                font-size: 1.4446em;
                .experience-timeline & {
                    left: 50%;
                    top: 0;
                    width:rem-calc(46);
                    height: rem-calc(46);
                    & > .icon {
                        width:rem-calc(36) !important;
                        height: rem-calc(36) !important;
                        padding-left: rem-calc(7);
                        font-size: rem-calc(33);
                        line-height: 1.35;
                    }
                }
            }
        }
        .box-teaser-content {
            @media only screen and (min-width:1px) and (max-width: $layout-breakpoint) {
                transform: inherit !important;
            }
            @media only screen and (min-width:$type-range-1) and (max-width: $type-range-2) {
                padding: rem-calc(5) 8.3333333333%;
            }
            @include respond-min($type-breakpoint-10) {
                padding: rem-calc(5) 8.3333333333%;
            }
            @include respond-min($layout-breakpoint) {
                transition-property: transform;
                transform: translate3d(0px, 0px, 0px);
                transition-duration: 0.6s;
                transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
                display: block;
                padding: 0;
                position: absolute;
                //margin-top: 232 / 363 * -100%;
                //margin-top:-55%;
                margin-top: math.div(252, 363) * -100%;
                left: 0;
                right: 0;
                width: auto;
                &:after {
                    content: "";
                    display: table;
                    clear: both;
                }
            }
        }
        // WORKING
        .description,
        .subtitle,
        .title {
            #interactive-map & {
                font-size: rem-calc(15);
                line-height: 1;
                height: auto;
            }
            #interactive-map & {
                font-size: rem-calc(20);
            }
            // #interactive-map & {
            //     font-size: 20px;
            //     line-height: 20px;
            //     height: auto;
            // }
            @media screen and (min-width: 48em) {
                #interactive-map & {
                    font-size: 23px;
                    line-height: 25px;
                    height: auto;
                }
            }
            
            @include respond-min($layout-breakpoint) {
                padding: 0 13%;
            }
            @include respond-min(em-calc(680)) {
                padding: 0 6%;
            }
            @include respond-min(em-calc(970)) {
                padding: 0 13%;
            }
        }
        .title {
            @media only screen and (min-width:$type-range-1) and (max-width: $type-range-2) {
                font-size: rem-calc(20);
                line-height: rem-calc(20);
            }
            @include respond-min($type-breakpoint-10) {
                font-size: rem-calc(20);
                line-height: rem-calc(20);
            }
            @include respond-min($layout-breakpoint) {
                display: table;
                width: 100%;
                margin-top: rem-calc(26);
                font-size: rem-calc(24);
                line-height: rem-calc(28);
                height: rem-calc(107);
            }
        }
        .title-inner-wrap {
            @include respond-min($layout-breakpoint) {
                display: table-cell;
                text-align: center;
                vertical-align: middle;
            }
        }
        .subtitle {
            font-size: rem-calc(10);
            @media only screen and (min-width:$type-range-1) and (max-width: $type-range-2) {
                margin-right: 0;
                margin-top: rem-calc(10);
                font-size: rem-calc(12);
            }
            @include respond-min($type-breakpoint-10) {
                margin-right: 0;
                margin-top: rem-calc(10);
                font-size: rem-calc(12);
            }
            @include respond-min($layout-breakpoint) {
                margin: 0;
                min-height: rem-calc(12);
                padding-bottom: rem-calc(20);
                text-align: center;
            }
            @include respond-min($type-breakpoint-20) {
                padding-bottom: rem-calc(20);
            }
        }
        .description {
            @include respond-min($layout-breakpoint) {
                display: block;
                padding-top: 0;
                margin-bottom: rem-calc(22);
            }
        }
        .link-underline {
            @include respond-min($layout-breakpoint) {
                display: inline-block;
            }
        }
        &:hover {
            @include respond-min($layout-breakpoint) {
                .corner {
                    display: block;
                    opacity: 1;
                }
            }
        }
        .corner {
            @include respond-min($layout-breakpoint) {
                display: block;
                overflow: visible;
            }
        }
        #interactive-map &.box-teaser-image-wrap {
            @include respond-min(600px) {
                width: auto;
                // margin-bottom: (518 - 298) / 298 * 100%;
                margin-bottom: math.div(520 - 363, 298) * 100%;
                //margin-bottom: 43%;
                display: block;
            }
        }
    }
    .fade-in {
        display: none;
        @include respond-min($layout-breakpoint) {
            display: block;
            position: absolute;
            left: 1px;
            right: 1px;
            bottom: 1px;
            height: rem-calc(30);
            background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
        }
    }

    // WORKING
    #interactive-map & .icon-circle, #interactive-map & .text-circle {
        width: rem-calc(32);
        height: rem-calc(32);
        display: none;
        @include respond-min($small-breakpoint) {
            display: block;
        }
        @include respond-min($large-breakpoint) {
            margin: -3px 0 0 -2px;
            font-size: 28px;
            width: 38px;
            height: 38px;
        }
        // @include respond-min($medium-breakpoint) {
        //     width: rem-calc(40);
        //     height: rem-calc(40);
        //     font-size: 28px;
        //     line-height: 39px;
        // }
    }
    #interactive-map & .icon-circle .icon,  #interactive-map & .text-circle .icon {
        @include respond-min($large-breakpoint) {
            width: rem-calc(38);
            height: rem-calc(38);
            margin: -2px 0 0 -1px;
            font-size: 28px !important;
            line-height: 39px !important;
        }
    }
    

    // #interactive-map & .icon-circle, #interactive-map & .text-circle {
    //     width: 40px;
    //     height: 40px;
    // }
    // #interactive-map & .icon-circle .icon,  #interactive-map & .text-circle .icon {
    //     width: rem-calc(20);
    //     height: rem-calc(20);
    //     font-size: 28px;
    //     line-height: 39px;
    // }
    
    #interactive-map & .description {
        font-size: rem-calc(13) !important;
        @include respond-min($medium-breakpoint) {
            line-height: rem-calc(20) !important;
        }
    }
    #interactive-map & .box-teaser.map {
        @include respond-min($xlarge-2-breakpoint) {
            height: rem-calc(425) !important;
        }
    }

    #interactive-map &.box-teaser-image-wrap {
        @include respond-min(600px) {
            width: auto;
            // margin-bottom: (518 - 298) / 298 * 100%;
            margin-bottom: math.div(520 - 363, 298) * 100%;
            //margin-bottom: 43%;
            display: block;
        }
    }
    #interactive-map &.box-teaser-image-wrap {
        @include respond-min(600px) {
            width: auto;
            // margin-bottom: (518 - 298) / 298 * 100%;
            margin-bottom: math.div(520 - 363, 298) * 100%;
            //margin-bottom: 43%;
            display: block;
        }
    }

}
.teaser-xlarge-rejig {
    // Experience - More to Explore :: http://msi.dev/explore/whats-here/exhibits/u-505-submarine/
    //    web/typo3conf/ext/experiences/Resources/Private/Templates/Experience/FeaturedOrRelated.html
    $type-breakpoint-10: $small-breakpoint;
    $layout-breakpoint: $xlarge-breakpoint;
    $type-breakpoint-20: $xlarge-breakpoint;
    .box-teaser {
        @include respond-min($layout-breakpoint) {
            // @include box-shadow(rgba(0,0,0,0.1) 0px 3px 4px);
            @include box-shadow(rgba(0,0,0,0.1) 0px 7px 15px);
            //max-width: rem-calc(360);
            margin-bottom: rem-calc(20);
        }
        @include respond-min($large-breakpoint) {
            margin: rem-calc(0 -0.016 20 0);
        }

        @include respond-min($xlarge-2-breakpoint){
            height: 520px;
        }

        br,
        .break {
            @include respond-min($layout-breakpoint) {
                display: block;
            }
        }
        .box-teaser-inner-wrap {
            @include respond-min($layout-breakpoint) {
                display: block;
                overflow: hidden;
                position: relative;
            }
        }
        .box-teaser-image-wrap {
            width: 25%;
            @include respond-min($layout-breakpoint) {
                width: auto;
                //margin-bottom: (518 - 298) / 298 * 100%;
                margin-bottom: math.div(520 - 363, 298) * 100%;
                display: block;
            }
        }
        .box-teaser-image {
            @include respond-min($layout-breakpoint) {
                float: none;
            }
        }

        /*
        .icon-circle {
            //left: 25%;
            left: 30%;
            @include respond-min($type-breakpoint-10) {
                font-size: rem-calc(16);
            }
            @include respond-min($layout-breakpoint) {
                font-size: rem-calc(20);
                //margin: -1em;
                margin: -35px;
                left: 50%;
                top: 0;
            }
            @include respond-min($type-breakpoint-20) {
                font-size: 1.4446em;
            }

            & .icon{
                width:70px;
                height: 70px;
            }
        }

         */

        .icon-circle {
            left: 25%;

            @media only screen and (min-width:$type-range-1) and (max-width: $type-range-2) {
                font-size: rem-calc(16);
            }
            @include respond-min($type-breakpoint-10) {
                font-size: rem-calc(16);
                width:2em;
                height: 2em;
            }

            @include respond-min($xlarge-breakpoint) {
                font-size: rem-calc(20);
                margin: -1em;
                left: 50%;
                top: 0;
                width:2em;
                height: 2em;
            }
            @include respond-min($type-breakpoint-20) {
                font-size: 1.4em;
                width:70px;
                height: 70px;
                left: 46%;
                top: -8px;
            }

            .icon{
                width: 1.4em;
                height: 1.4em;
                font-size: 1.4em;
                line-height: 1.4em;

                @include respond-min($type-breakpoint-20) {
                    width:70px;
                    height: 70px;
                    font-size: 47px;
                    line-height: 47px;
                }
            }
        }


        .box-teaser-content {
            width: 75%;
            @media only screen and (min-width:1px) and (max-width: $layout-breakpoint) {
                transform: inherit !important;
            }
            @include respond-min($type-breakpoint-10) {
                padding: rem-calc(5) 8.3333333333%;
            }
            @include respond-min($layout-breakpoint) {
                transition-property: transform;
                transform: translate3d(0px, 0px, 0px);
                transition-duration: 0.6s;
                transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
                padding: 0 13%;
                display: block;
                position: absolute;
                //margin-top: 232 / 298 * -100%;
                margin-top: math.div(252, 363) * -100%;
                left: 0;
                right: 0;
                width: auto;
                //padding-bottom: rem-calc(40);
            }
            @include respond-min(em-calc(680)) {
                padding: 0 6% rem-calc(40);
            }
            @include respond-min(em-calc(970)) {
                padding: 0 13% rem-calc(40);
                bottom: 15px;
            }
        }
        .title {
            @include respond-min($type-breakpoint-10) {
                font-size: rem-calc(20);
                line-height: rem-calc(20);
            }
            @include respond-min($layout-breakpoint) {
                display: table;
                width: 100%;
                margin-top: rem-calc(30);
                font-size: rem-calc(24);
                line-height: rem-calc(26);
                height: 95px;
                margin-bottom: 10px;
            }
        }
        .title-inner-wrap {
            @include respond-min($layout-breakpoint) {
                display: table-cell;
                text-align: center;
                vertical-align: middle;
            }
        }
        .subtitle {
            @include respond-min($type-breakpoint-10) {
                margin-right: 0;
                margin-top: rem-calc(10);
                font-size: rem-calc(12);
            }
            @include respond-min($layout-breakpoint) {
                margin: 0;
                min-height: rem-calc(9);
                padding-bottom: rem-calc(20);
                text-align: center;
            }
            @include respond-min($type-breakpoint-20) {
                padding-bottom: rem-calc(20);
            }
        }
        .description {
            @include respond-min($medium-breakpoint) {
                display: block;
                padding-top: rem-calc(6);
                margin-bottom: rem-calc(0);
            }
            @include respond-min($layout-breakpoint) {
                display: block;
                padding-top: 0;
                margin-bottom: rem-calc(22);
            }
            @include respond-min($type-breakpoint-20) {
                margin-bottom: rem-calc(23);
            }
        }
        .link-underline {
            @include respond-min($layout-breakpoint) {
                display: inline-block;
            }
        }
        &:hover {
            @include respond-min($layout-breakpoint) {
                .corner {
                    display: block;
                    opacity: 1;
                }
            }
        }
        .corner {
            @include respond-min($layout-breakpoint) {
                display: block;
                overflow: visible;
            }
        }
        .fade-in {
            display: none;
            @include respond-min($layout-breakpoint) {
                display: block;
                position: absolute;
                left: 1px;
                right: 1px;
                bottom: 0px;
                height: rem-calc(55);
                background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
    
            }
        }
        &:hover .fade-in {
            display: none;
        }
    }
    .more-to-explore & {
        // http://msi.dev/explore/whats-here/exhibits/u-505-submarine/
       margin: 0 rem-calc(-10);

        @include respond-min($xlarge-breakpoint){
            margin: 0;
        }

        @include respond-min($xxlarge-breakpoint){
            margin: 0 -20px;
        }

        @include clearfix;
        li {
            margin: 0 auto;
            display: block;
            margin: 0 auto rem-calc(20);
            max-width: rem-calc(600);
            height: auto;
            list-style: none;
            @include respond-min($xlarge-breakpoint) {
                float: left;
                height: auto;
                margin: 0;
                padding: 0 0.625rem;
                width: 33.333333333333336%;
                .box-teaser {
                    margin-bottom: 0;
                    max-width: auto;
                }
            }
            @include respond-min($xxlarge-breakpoint){
                padding: 0 20px;
            }
        }
    }
}
#search-modal .box-teaser {
    // Search Results :: must search title, e.g. 'brick by brick'
    //    web/typo3conf/ext/experiences/Resources/Private/Templates/Experience/SearchResults.html
    margin: 0;
}
.experience-landing {
    // Explore (landing) :: http://msi.dev/explore/
    //     web/typo3conf/ext/activities/Resources/Private/Templates/Activity/ListByType.html
    //     web/typo3conf/ext/experiences/Resources/Private/Templates/Experience/ListByType.html
    &-teasers {

        @include respond-min($large-breakpoint) {
            float: right;
            width: math.div(640, 940) * 100%;

            .lc__experience-landing-right & {
                float: left;
            }
        }
        .box-teaser {
            @include respond-min($layout-breakpoint) {
                margin-bottom: rem-calc(40);
            }
        }
    }
    &-intro {
        @include respond-min($large-breakpoint) {
            float: left;
            width: math.div(264, 940) * 100%;

            .lc__experience-landing-right & {
                float: right;
            }
        }
        .title {
            margin-bottom: rem-calc(24);
            @include respond-min($large-breakpoint) {
                font-size: 24px;
            }
            @include respond-min($xlarge-breakpoint) {
                font-size: 24px;
            }
        }
        p {
            font-family: $font-family-2;
            font-size: rem-calc(16);
            line-height: rem-calc(26);
        }
        .see-all {
            margin-bottom: rem-calc(30);
        }
    }
}

#interactive-map .gm-style-iw-d {
    overflow: visible;
}
#interactive-map .info-window {
    // Museum Map :: http://msi.dev/index.php?id=26&no_cache=1
    //    web/typo3conf/ext/map/Resources/Private/Partials/Map/InfoWindow.html
    //    web/typo3conf/ext/map/Resources/Private/Partials/Map/ListItem.html
    // $type-range-1: em-calc(370);
    // $type-range-2: $small-breakpoint - em-calc(1);
    $type-breakpoint-10: em-calc(370);
    $type-breakpoint-15: $medium-breakpoint;
    $layout-breakpoint: $large-breakpoint;
    $type-breakpoint-20: $xlarge-breakpoint;
    @include respond-min($layout-breakpoint) {
        height: 469px !important;
    }
    .box-teaser {
        margin-bottom: rem-calc(14);
       // @include box-shadow(rgba(0,0,0,0.2) 0px 4px 6px);
        @include box-shadow(rgba(0,0,0,0.1) 0px 7px 15px);
        background-color: rgba(0,0,0,0.2);
        .box-teaser-inner-wrap {
            @include respond-min($layout-breakpoint) {
                position: relative;
            }
        }
        .box-teaser-image-wrap {
            width: math.div(48, 280) * 100%;
            @include respond-min($type-breakpoint-10) {
                width: math.div(158, 518) * 100%;
            }
            @include respond-min($layout-breakpoint) {
                width: auto;
            }
        }
        .icon-circle {
            left: math.div(48, 280) * 100%;
            font-size: rem-calc(10);
            left: 30%;
            @include respond-min($type-breakpoint-10) {
                left: math.div(158, 518) * 100%;
                font-size: rem-calc(16);
            }
            @include respond-min($layout-breakpoint) {
                font-size: rem-calc(20);
                margin: -1em;
                left: 50%;
                top: 0;
            }
        }
        .box-teaser-content {
            width: math.div(232, 280) * 100%;
            padding: rem-calc(3) 7%;
            @media only screen and (min-width:1px) and (max-width: $layout-breakpoint) {
                transform: inherit !important;
            }
            @include respond-min($type-breakpoint-10) {
                width: math.div(360, 518) * 100%;
                padding: rem-calc(3) 10%;
            }
            @include respond-min($type-breakpoint-15) {
                padding: rem-calc(3) 8%;
            }
            @include respond-min($layout-breakpoint) {
                transition-property: transform;
                transform: translate3d(0px, 0px, 0px);
                transition-duration: 0.6s;
                transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
                width: auto;
                margin: 0;
                padding: 0 13%;
                display: block;
                position: absolute;
                margin-top: math.div(232, 298) * -100%;
                left: 0;
                right: 0;
                width: auto;
            }
        }
        .subtitle,
        .title {
            min-height: 0;
            text-align: left;
            @include respond-min($layout-breakpoint) {
                text-align: center;
            }
        }
        .description {
            margin-top: 0;
            text-align: left;
            display: none;
            @include respond-min($medium-breakpoint) {
                display: block;
            }
        }
    }
}
.experience- {
    // global colors inside experience box teasers
    &event {
        .icon-circle {
            background-color: $color-event;
            border-color: $color-event;
        }
        .last-icon-cell .icon,
        .subtitle,
        .text-cell {
            color: $color-event;
        }
    }
    &exhibit {
        .icon-circle {
            background-color: $color-exhibit;
            border-color: $color-exhibit;
        }
        .last-icon-cell .icon,
        .subtitle,
        .text-cell {
            color: $color-exhibit;
        }
    }
    &film {
        .icon-circle {
            background-color: $color-film;
            border-color: $color-film;
        }
        .last-icon-cell .icon,
        .subtitle,
        .text-cell {
            color: $color-film;
        }
    }
    &tour {
        .icon-circle {
            background-color: $color-tours;
            border-color: $color-tours;
        }
        .last-icon-cell .icon,
        .subtitle,
        .text-cell {
            color: $color-tours;
        }
    }
}
.activity- {
    // global colors inside activity box teasers
    &video {
        .icon-circle {
            background-color: $color-video;
            border-color: $color-video;
        }
        .last-icon-cell .icon,
        .subtitle,
        .text-cell {
            color: $color-video;
        }
    }
    &game {
        .icon-circle {
            background-color: $color-games;
            border-color: $color-games;
        }
        .last-icon-cell .icon,
        .subtitle,
        .text-cell {
            color: $color-games;
        }
    }
    &hands-on {
        .icon-circle {
            background-color: $color-hands-on;
            border-color: $color-hands-on;
        }
        .last-icon-cell .icon,
        .subtitle,
        .text-cell {
            color: $color-hands-on;
        }
    }
}


@include respond-min($layout-breakpoint) {
    // global fade to text to white in box-teasers unless unset elsewhere
    .fade-in {
        position: absolute;
        left: 1px;
        right: 1px;
        bottom: 1px;
        height: rem-calc(30);
        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
    }
}
