// ======================================================================
// Responsive Image Grid
// ======================================================================

@use "sass:math";

.gallery-grid {
	@include grid-row();
	@include single-transition(all, 0.5s, ease-in-out);
	.gallery-grid-sizer {
		@include grid-column(math.div($total-columns, 6));
	}
  .gallery-grid-item {
		@include grid-column($total-columns);
    margin: 10px 0;
    @include respond-min($small-breakpoint) {
			@include grid-column($total-columns*0.5);
			// &.half { @include grid-column($total-columns); }
    }
    @include respond-min($medium-breakpoint) {
			@include grid-column(math.div($total-columns, 3));
			&.half { @include grid-column($total-columns*0.5); }
    }
  }
}
