	// ======================================================================
	// PAGINATION Previous Next
	// ======================================================================


.pagination-prev-next {
	font-size: rem-calc(9);
	padding-top: rem-calc(20);
	padding-top: rem-calc(40);
	margin: rem-calc(64 0 32);
	border-top: 2px solid $lighter-gray;
	@extend .clearfix;


	.arrow {
		display: block;
		text-decoration: none;
		float: left;
		width: 50%;
		//width: 100%;
		margin-top: rem-calc(4);
		//margin-bottom:rem-calc(35);
		color: $gray;
		letter-spacing: rem-calc(3);

		.text-wrap{
			//display: block;
			text-align: right;
		}

		.title {
			display: block;
			color: $theme-color-2;
			font-weight: 600;
			font-size: rem-calc(15);
			letter-spacing: 0;
			margin-top: rem-calc(-5);
			transition: color 0.5s ease-out;
			font-size: rem-calc(15);
			//font-size: rem-calc(22);
			margin-left: rem-calc(53);

			@include respond-min($medium-breakpoint) {
				font-size: rem-calc(22);
			}
		}

		&:hover,
		&:focus {

			.title {
				color: $theme-color-1;
			}

			.icon-circle.light {
				border-color: $theme-color-1;
				background-color: $theme-color-1;
				.icon {
					color: white;
				}
			}
		}

		&.right {
			float: right;
			text-align: right;
			//margin-bottom:rem-calc(0);

			.title {
				margin-left: 0;
				margin-right: rem-calc(53);
			}

			/*.text-wrap{
				display: block;
				text-align: left;
			}

			 */
		}

	}

	.subtitle {
		text-transform: uppercase;
		font-size: rem-calc(9);

		@include respond-min($medium-breakpoint) {
			font-size: rem-calc(12);
		}
	}

	.icon-circle {
		display: block;
		font-size: rem-calc(17.5);
		margin-top: rem-calc(4.5);
		margin-right: rem-calc(18);
		margin-bottom: 0;
		transition: background-color 0.3s ease-out, border-color 0.3s ease-out;

		.icon {
			transition: color 0.3s ease-out 0.2s;
		}

		&.right {
			margin-left: rem-calc(18);
			margin-right: 0;
		}
	}

}
