@import '~pikaday/scss/pikaday.scss';

.pika-single {
	&.is-bound {
		@include respond-min($medium-breakpoint) {
			margin-top: rem-calc(20);
		}
		@include respond-max($small-breakpoint) {
			padding-right: rem-calc(22);
		}
	}

	.pika-lendar {
		font-family: $font-family-1;
		text-transform: uppercase;
		color: $theme-color-2;
		width: rem-calc(383);

		abbr {
			text-transform: uppercase;
			color: $theme-color-2;

			&[title] {
				border: none;
				cursor: default;
			}
		}

		tr td,
		thead tr th {
			border: none;
			width: rem-calc(50);
			text-align: center;
		}

		.pika-title {
			border-bottom: $border-1;
			margin-bottom: rem-calc(30);
			padding: rem-calc(14 0 10);
		}

		.pika-label {
			font-weight: 600;
			color: $theme-color-2;
			font-size: rem-calc(12);
			letter-spacing: rem-calc(2);
		}

		.is-selected {
			.pika-button {
				background-color: $theme-color-1;
				box-shadow: none;
				transition: background-color 0.4s linear,
				color 0.4s linear;
				@include respond-min($medium-breakpoint) {
					font-weight: 700;
				}
				&.promotional-code-valid {
					background-color: $success-color;
					color: white;
					transition: background-color 0.4s linear,
					color 0.4s linear;
				}
			}

		}

		.is-today .pika-button {
			@include respond-min($medium-breakpoint) {

				&::after {
					content: '\AToday';
					font-weight: normal;
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					text-align: center;
				}
			}
		}
		.pika-button {
			background-color: white;
			border-radius: 0;
			font-weight: 600;
			text-align: center;
			transition: background-color 0.4s linear,
			color 0.4s linear;
			@include respond-min($medium-breakpoint) {
				padding: rem-calc(19 5 16);
			}
			&.promotional-code-valid {
				color: $success-color;
				transition: background-color 0.4s linear,
				color 0.4s linear;
				&:hover {
					background-color: $success-color;
					color: white;
				}
			}
			&:hover,
			&:focus {
				background-color: $theme-color-2;
				transition: background-color 0.4s linear;
			}
		}
	}
}