	// ======================================================================
	// QUOTE CONTENT ELEMENT
	// ======================================================================

.feature-quote {
	@include reset-blockquote;
	margin-bottom: rem-calc(20);
	margin: rem-calc(32 40 52);
	padding-bottom: 20px;

	overflow: hidden;
	//padding: 5.125rem 3.125rem 7.5rem;

	.icon {
		animation-duration: 1s;
		animation-timing-function: ceaser($easeInOutQuad);

		font-size: rem-calc(40);
		display: block;
		margin: rem-calc(25) auto rem-calc(15);

		@include respond-min($medium-breakpoint) {
			font-size: rem-calc(50);
		}
	}


	cite, .icon {
		color: $theme-color-1;
	}

	cite {
		animation-delay: .2s;
		animation-duration: 1s;
		animation-timing-function: ceaser($easeInOutQuad);

		margin-top: rem-calc(0);
		line-height: 1.2;
		font-style: italic;

		&:before {
			content: none;
		}

		@include respond-min($small-breakpoint){
			margin-top: rem-calc(15);
		}
	}

	.person {
		font-size: rem-calc(16);
		line-height:rem-calc(18);
		font-weight: 700;
		display: inline-block;
	}

	.organization {
		font-size: rem-calc(16);
		line-height: rem-calc(18);
		display: inline-block;
		font-weight: 400;
	}

	.quote-content {
		animation-delay: .1s;
		animation-duration: 1s;
	  	animation-timing-function: ceaser($easeInOutQuad);

		font-size: rem-calc(24);
		line-height: rem-calc(32);
		display: block;

		br,
		.break {
			display: none;
		}

		@include respond-min($xlarge-breakpoint) {
			br,
			.break {
				display: block;
			}
		}

		@include respond-min($small-breakpoint) {
			font-size: rem-calc(24);
			line-height: rem-calc(32);
		}

		&.large {
			font-size: rem-calc(24);
			line-height: rem-calc(30);
			margin-top: rem-calc(30);
			margin-bottom: rem-calc(21);
			font-family: "Times new roman";
			letter-spacing: 0px;
			@include respond-min($large-breakpoint) {
				font-size: rem-calc(33);
				line-height: rem-calc(37);
			}
		}

		&:before {
			content:"\201C";
			font-family: Georgia, serif;
		}
		&:after {
			content:"\201D";
			font-family: Georgia, serif;
		}
	}

	&.inset {
		margin-right: rem-calc(72);
		margin-left: rem-calc(72);
	}
}

.layout-container .feature-quote {
	margin: 0;
}


.text-light, .lc__theme-color-1, .layout-container.text-light {

	.feature-quote {
		cite, .icon {
			color: $theme-color-1-alt;
		}

		color: $theme-color-1-text-light;
	}

}

.feature-quote.text-dark {

	cite, .icon {
		color: $theme-color-2 !important;
	}

	color: $theme-color-2 !important;

}

.feature-quote.text-light {

	cite, .icon {
		color: $theme-color-1-text-light !important;
	}

	color: $theme-color-1-text-light !important;

}



//  blockquote.feature-quote.text-dark
// .text-light .feature-quote, .feature-quote.text-light {
//
// 	cite, .icon {
// 		color: mix($theme-color-2, $theme-color-1, 40%);
// 	}
//
// 	color: $text-color-invert;
//
// }
