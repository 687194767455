// ======================================================================
// TYPOGRAPHY
// ======================================================================


// ======================================================================
// Typekit Loading
// ======================================================================

// @todo-ron need to make sure I know what this is for and what position should it be in if it matters.
/*
.wf-loading {
	h1, h2, h3, h4, h5, li, p  {
		opacity: 0;
	}
}

h1, h2, h3, h4, h5, li, p  {
	transition: opacity 0.1s ease-in-out;
}
*/


// ======================================================================
// Paragraphs
// ======================================================================


// @todo-ron This should be handled below with new code
// p, ul, ol {
//
// 	&.small,
// 	&.small-spaced {
// 		font-size: rem-calc(14);
// 		line-height: rem-calc(22);
// 	}
// }
.break {
  display: block;
  line-height: 0;
  font-size: .1px;
  margin-bottom: 0;
}

h1, h2, h3, h4, h5, h6, .subtitle {

  br {
    display: none;
    @include respond-min($xlarge-breakpoint) {
      display: inline;
    }
  }

  .break {
    display: none;
    @include respond-min($xlarge-breakpoint) {
      display: block;
    }
  }
}

.rte-content {

  font-size: rem-calc(18);
  line-height: rem-calc(32);

  > h1, > h2 {
    margin-top: rem-calc(70);
  }

  > h3 {
    line-height: 30px;
  }

  > h3, h4, > h5, > h6 {
    margin-top: 50px
  }

  p, ul, ol, dl {
    font-size: inherit;
    line-height: inherit;
    margin-bottom: 1.666666667em;
  }

  &.rte-text-large, .large, .large-spaced {
    font-size: rem-calc(22);
    line-height: rem-calc(32);
  }

  &.rte-text-medium, .medium, .medium-spaced {
    font-size: rem-calc(16);
    line-height: rem-calc(24);
    //margin-bottom:rem-calc(17);

    @include respond-min($medium-breakpoint){
      font-size: rem-calc(18);
      line-height: rem-calc(28);
    }
  }

  &.rte-text-small, .small, .small-spaced {
    font-size: rem-calc(14);
    line-height: rem-calc(22);
    //margin-bottom: rem-calc(20);
    @include respond-min($medium-breakpoint){
      font-size: rem-calc(16);
      line-height: rem-calc(24);
    }
  }

  &.rte-text-smaller, .smaller, .smaller-spaced {
    font-size: rem-calc(14);
    line-height: rem-calc(20);
    margin-bottom: rem-calc(20)
  }

  p.small-note {
    font-size: rem-calc(13);
    font-style: italic;
    font-family: $font-family-2;
    line-height: rem-calc(23);
    margin-bottom: rem-calc(15);
  }

  p.feature-text {
    font-size: rem-calc(25);
    line-height: 1.6;
    font-family: $font-family-2;
    color: $theme-color-2;
    margin-bottom: rem-calc(37);
  }

  p.no-margin-bottom {
    margin-bottom: 0;
  }

  p.supporters {
    font-size: rem-calc(24);
    line-height: rem-calc(35);
    font-family: $font-family-1;
    text-align: center;
    width: 85%;
    // This causes odd overlapping. Not for sure why the negative top and bottom margin. is there in the first place.
    // margin: -50px auto!important;
    margin: 0 auto!important;
    margin-bottom: rem-calc(20);
    font-style: italic;
    letter-spacing: -0.3px;

    @include respond-min($large-breakpoint){
      width: 65%;
      margin: 0 auto!important;

    }

    @include respond-min($xlarge-breakpoint){
      width: 49%;
    }
  }

  .background--light-gray{
    background: $light-gray;
    border-radius: 3px;
    padding:20px;
  }

  a{
    line-height:rem-calc(22) ;
  }

  .title-gray{
    color:$title-gray;
  }
}

// @todo-ron will need to work on this.

.text-light, .lc__theme-color-2, .lc__theme-color-2.text-light {

  color: $theme-color-2-text-light;

  p, ul, ol, .subtitle {

    color: $theme-color-2-text-light;

    // color: $theme-color-1-text-light;
    //
    // @include respond-min($medium-breakpoint) {
    // 	color: $theme-color-2-text-light;
    // }
  }

  h1, h2, h3, h4, h5, h6, p.feature-text, p.supporters {
    color: $theme-color-1-text-light;
  }
}

.lc__theme-color-1, .lc__text-light {

  color: $theme-color-1-text-light;

  p, ul, ol, .subtitle {
    color: $theme-color-1-text-light;
  }

  h1, h2, h3, h4, h5, h6, p.feature-text, p.supporters {
    color: $theme-color-1-text-light;
  }
}

.lc__theme-color-3, .lc__theme-color-3.text-light {

  color: $theme-color-3-text-light;

  p, ul, ol, .subtitle {

    color: $theme-color-3-text-light;


    // color: $theme-color-1-text-light;
    //
    // @include respond-min($medium-breakpoint) {
    // 	color: $theme-color-3-text-light;
    // }
  }

  h1, h2, h3, h4, h5, h6, p.feature-text, p.supporters {
    color: $theme-color-1-text-light;
  }
}

.text-dark, .layout-container.text-dark {

  color: $body-font-color;

  p, ul, ol, .subtitle {
    color: $body-font-color;
  }

  h1, h2, h3, h4, h5, h6, p.feature-text {
    color: $header-font-color;
  }
}

.layout-container, .lc__theme-color-2, .lc__theme-color-3{

  &.text-transparent {

    p, .subtitle {

      color: $theme-color-1-text-light;
      opacity: .5;

      // color: $theme-color-1-text-light;
      // opacity: 1;
      //
      // @include respond-min($medium-breakpoint) {
      // 	opacity: .5;
      // }
    }

    h1, h2, h3, h4, h5, h6, p.feature-text, p.supporters {
      color: $theme-color-1-text-light;
    }

  }
}

// ======================================================================
// Headlines
// ======================================================================

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: rem-calc(25);
  // line-height: 1.2; adjust header-line-height in variables.scss

  a {
    color: inherit;

    &:hover,
    &:focus {
      color: $theme-color-1;
      text-decoration: none;
    }
  }
}

h1 {
  margin-bottom: rem-calc(50);
  @include respond-min($large-breakpoint) {
    line-height: rem-calc(50); // https://basecamp.com/2171988/projects/9771101/todos/212316754
  }
}

h2 {
  line-height: rem-calc(40);
  @include respond-min($large-breakpoint) {
    margin-bottom: rem-calc(45);
  }
}

h3 {
  line-height: rem-calc(30);
  //margin-bottom: rem-calc(30);
  margin-bottom: rem-calc(15);
}

h4, h5, h6 {
  line-height: rem-calc(25);
  //margin-bottom: rem-calc(25);
  margin-bottom: rem-calc(15);
}

.h1-match {
  @extend h1;
}

.h1-match-container {
  h2 {
    @extend h1;
  }
}

.bn__page-title {
  font-size: rem-calc(37);
  line-height: rem-calc(37);
  @include respond-min($large-breakpoint) {
    font-size: rem-calc(65);
    line-height: rem-calc(60);
    letter-spacing: rem-calc(-2);
  }
}
.base-title{
  font-size: rem-calc(36);
  line-height: rem-calc(36);
  //text-align: center;
  letter-spacing: -0.5px;
}

.base-subtitle{
  font-size: 20px;
  line-height: 24px;
  margin-bottom:7px;
}

.base-headline {
  font-size: 30px;
}


.base-section-title{
  font-size: rem-calc(22)!important;
  line-height: rem-calc(32)!important;
  margin-bottom: 10px!important;
  color:$title-gray;
  @include respond-min($medium-breakpoint){
    font-size: rem-calc(26)!important;
  }
}


.base-subhead {
  font-size: 16px;
  line-height: 1.1;
  margin-bottom: 0;
}

.base-subhead-tag {
  font-size: 16px;
  line-height: 1.15;
}

.intro-headline{
  font-size: rem-calc(32);
  line-height: rem-calc(38);
  margin-bottom: rem-calc(30);
 // margin-top: rem-calc(15);
  letter-spacing: -0.5px;

  .text-and-images & {
    margin-bottom: 20px;
    letter-spacing: -1px;
    line-height: rem-calc(32);
    font-size: rem-calc(28);

    @include respond-min($large-breakpoint){
      line-height: rem-calc(38);
      font-size: rem-calc(32);
    }
  }
}

span.old-price {
  text-decoration: line-through;
  color: $text-gray;
}

span.new-price {
  color: $title-gray;
  font-weight: bold;
}

.title-gray-color{
  color:$title-gray;
}

hr.ce-div{
  background-color: $lightest-blue-gray;
  height: 1px;
  margin:0;
  margin-bottom:25px;
  @include respond-min($small-breakpoint){
    margin-bottom:38px;

  }
}

// ======================================================================
// Lists
// ======================================================================

dl, ol, ul,
.accordion .rte-content ul {

  margin-bottom: rem-calc(20);
}


// @todo-ron will need to rework for new RTE styles
ol, ul {

  &.spaced li {
    margin-bottom: rem-calc(20);
  }

  &.small-spaced li {
    margin-bottom: rem-calc(15);
  }
}

%msi-common-list {
  list-style-type: none;
  margin-left: 0;

  > li {
    text-indent: rem-calc(-40);
    position: relative;
    left: rem-calc(40);
    padding-right: rem-calc(40);

    &:before {
      font-family: "icons";
      float: left;
      color: $theme-color-1;
      width: rem-calc(40);
    }
  }
}

.rte-content ul,
.msi-bullet-list {
  @extend %msi-common-list;

  > li {

    &:before {
      @extend %icon-list-bullet;
      position: relative;
      left: rem-calc(-3);
      top: .05em;
    }
  }
}

ul.checked-list,
.rte-content .checked-list {
  @extend %msi-common-list;

  > li {
    text-indent: rem-calc(-28);
    left: rem-calc(28);
    padding-right: rem-calc(28);
    font-size: rem-calc(14);
    line-height: rem-calc(20);
    font-weight: 600;
    margin-bottom: rem-calc(20);

    &:before {
      @extend %icon-check;
      font-size: rem-calc(16);
      color: $color-2;
      width: rem-calc(36);
      position: relative;
      top: -.1em;
    }

    &.ellipsis:before {
      @extend %icon-ellipsis;
      font-size: rem-calc(22);
      top: .1em;
      left: rem-calc(-6);
    }
  }
}

// ul.checked-ellipsis-list,
// .rte-content .checked-ellipsis-list {
// 	@extend %msi-common-list;
//
// 	> li {
// 		text-indent: rem-calc(-28);
// 		left: rem-calc(28);
// 		padding-right: rem-calc(28);
// 		font-size: rem-calc(14);
// 		line-height: rem-calc(20);
// 		font-weight: 600;
// 		margin-bottom: rem-calc(20);
//
// 		&:before {
// 			@extend %icon-check;
// 			font-size: rem-calc(16);
// 			color: $color-2;
// 			width: rem-calc(36);
// 			position: relative;
// 			top: -.1em;
// 		}
//
// 		&:last-child:before {
// 			@extend %icon-ellipsis;
// 			font-size: rem-calc(22);
// 			top: .1em;
// 			left: rem-calc(-6);
// 		}
// 	}
// }

ul.checked-list,
.rte-content .checked-list {
  @extend %msi-common-list;

  > li {
    text-indent: rem-calc(-28);
    left: rem-calc(28);
    padding-right: rem-calc(28);
    font-size: rem-calc(14);
    line-height: rem-calc(20);
    font-weight: 600;
    margin-bottom: rem-calc(20);

    &:before {
      @extend %icon-check;
      font-size: rem-calc(16);
      color: $color-2;
      width: rem-calc(36);
      position: relative;
      top: -.1em;
    }
  }
}

ul.last-item-ellipsis li:last-child:before {
  @extend %icon-ellipsis;
  font-size: rem-calc(22);
  top: .1em;
  left: rem-calc(-6);
}

ul.link-list, ul.arrow-list,
.rte-content .arrow-list {
  @extend %msi-common-list;

  > li {
    text-indent: rem-calc(-28);
    left: rem-calc(28);
    padding-right: rem-calc(28);
    margin-bottom: rem-calc(7);

    a {
      font-weight: 700;

      &:hover,
      &:focus {
        color: $theme-color-2;
      }
    }

    &:before {
      @extend %icon-anglebracket-right;
      width: 0;
      margin-right: rem-calc(28);
      position: relative;
      margin-top: -.05em;
      transition: text-indent 0.3s ceaser($easeInOutQuad);
    }

    &:hover,
    &:focus {
      &:before {
        text-indent: rem-calc(-22);
        color: $theme-color-2;
      }
    }
  }
}

a.arrow-link {
  display: block;
  position: relative;
  padding-left: rem-calc(20);
  font-weight: 700;
  .lc__theme-color-2 &, .lc__theme-color-3 & {
    color: $theme-color-1-alt;
    &:hover {
        color: white;
    }

}

  &:before {
    @extend %icon-anglebracket-right;
    position: absolute;
    left: rem-calc(-4);
    font-family: "icons";
    width: rem-calc(24);
    transition: left 0.3s ceaser($easeInOutQuad);;
  }

  &:hover:before,
  &:focus:before {
    left: rem-calc(2);
  }

  &.back {

    &:before {
      @extend %icon-anglebracket-left;
      position: absolute;
      left: rem-calc(-4);
      font-family: "icons";
      width: rem-calc(24);
      // top: .1em;
    }
  }

}
.underline{
  text-decoration:underline;
}

.link-underline {
  color: $link-color;
  display: inline-block;
  font-weight: bold;
  font-size: rem-calc(13);
  padding-bottom: rem-calc(8);
  border-bottom: 2px solid $theme-color-1;

  &:hover,
  &:focus {
    color: $theme-color-2;
    border-color: $theme-color-2;
  }
}

.link-with-bottom-border, .link-underline {
  @extend .link-underline;
  font-size: rem-calc(16);
  line-height: rem-calc(20);
  padding-bottom: rem-calc(3);

  &:hover,
  &:focus {
    color: $theme-color-2!important;
    border-color: $theme-color-2!important;
  }

}


.link-tickets{
  display: block;
  text-align: center;
  font-size:18px;
  @include respond-min($medium-breakpoint){
    display: inline-block;
    margin-left:30px;
    text-align: left;
    margin-top: 6px;
  }

  @include respond-min($xlarge-2-breakpoint){
    margin-left:60px;
  }
}


ul.link-list-underline {
  @include reset-ul;

  li {
    margin-bottom: rem-calc(25);
  }

  a {
    @extend .link-underline;
  }
}

// @todo-ron review this. will need to rework
// ul.link-list-small,
// .rte-content .link-list-small {
// 	@extend ul.small;
// 	@extend ul.link-list;
//
// }

.rte-content ol,
.msi-numbered-list {
  @extend %msi-common-list;

  &:first-child {
    counter-reset: customlistcounter;
  }

  & > li {
    counter-increment: customlistcounter;

    &:before {
      content: counter(customlistcounter) " ";
      font-weight: bold;
      font-family: $font-family-1;
    }
  }
}


// ======================================================================
// other
// ======================================================================

sup {
  font-size: 60%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  top: -0.5em;
}

// ======================================================================
// rte generated
// ======================================================================

.align-right {
  text-align: right;

  &.indent {
    @include respond-min($xlarge-breakpoint) {
      margin-left: 0;
    }
  }
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

.indent {
  @include respond-min($xlarge-breakpoint) {
    margin-left: 1.875rem;
  }
}

a.blue-link{
  color:$theme-color-1;
  font-weight: bold;
  font-size:18px;
}


// ======================================================================
// Multi-column Layout Module
// ======================================================================

@include respond-min($small-breakpoint) {
  .rte-content-two-column {
    @include column-count(2);
    @include column-gap(rem-calc(20));
    @include column-fill(balance);

  }
}
