$icon-width: 2.5rem;
$icon-height: 24px;
$nav-drop-shadow: 0 -.2rem .4rem 0 rgba(0, 0, 0, .2);
[x-cloak] {
  display: none;
}

.hidden .overlay-clickable {
  display: none;
}
.overlay-clickable {
  position: fixed; top:0px; bottom:0px; width: 100%; left: 0px;
}
.box-teaser .corner {
  &:hover, &:focus {
    opacity: 1;
  }

  @include respond-max($large-breakpoint) {
    /*opacity: 1;
    background-color: rgba(0, 0, 0, 0.05);
    color: $gray-40;
    .icon {
      color: $gray-40;
    }

     */

    &:hover, &:focus {
      opacity: 1;
      background: $theme-color-1;

      .icon {
        color: $white;
      }

    }
  }
}
.sticky-footer-wrapper{
  @include respond-min($xlarge-breakpoint) {
    display: none;
  }
}
.sticky-footer {

  align-items: center;
  background: $white;
  bottom: 0;
  box-shadow: $nav-drop-shadow;
  display: flex;
  justify-content: space-evenly;
  padding: 0 0 .5rem 0;
  // When a user is on a iPhone X device, we can use this user agent styling to
  // ensure that the right amount of space is given to prevent phone UI form
  // interfering with our element
  padding-bottom: calc(.5rem + env(safe-area-inset-bottom));
  position: fixed;
  width: 100%;
  z-index: 1009;
  min-height: 70px;
  border-radius: 10px 10px 0 0;
  transition: all .5s, visibility 0s;

  @include respond-min($xlarge-breakpoint) {
    display: none;
  }

  &.hidden{
    opacity: 0;
    height: 0;
  }
  &.active{
    opacity: 1;
  }
  &__item {
    flex:auto;
    align-items: center;
    color: $gray-60;
    display: flex;
    flex-direction: column;
    letter-spacing: 0;
    position: relative;

    &:hover {

      & .sticky-footer__item-label{
        color:$theme-color-1-alt !important;
      }

      svg g, svg g #Colors\/Black{
        fill:$theme-color-1;
        color:$theme-color-1;
        opacity: 1;
      }
    }

    &--active {
      color: $theme-color-1;


      svg g, svg g #Colors\/Black{
        fill:$theme-color-1;
        color:$theme-color-1;
        opacity: 1;
      }

      &:hover, &:focus {
        color: $theme-color-1 !important;
        & > .sticky-footer__item-label {
            color: $theme-color-1 !important;
        }
      }

    }

    &--home {
      svg g, svg g #Colors\/Black{
        fill:white;
        color:white;
        opacity: 1;
      }
      &:hover, &:focus {
        svg g, svg g #Colors\/Black{
            fill:white;
            color:white !important;
            opacity: 1;
        }
      }
    }
  }

  &__panel {
    border-radius: 10px;
    background: $white;
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 35px 30px 70px;
    margin-bottom: 45px;
    //max-height: 80%;
    max-height: 86%;
    transition: all .7s, visibility 0s;
    &.hidden{
      opacity: 0;
      height: 0;
      bottom: -100%;
      visibility: hidden;
    }
    &.active{
      opacity: 1;
      height: auto;
      visibility: visible;

    }
    &-interior {
      //max-height: 100%;
      max-height: 60vh;
      overflow-y: scroll;
      &.hidden{
        opacity: 0;
        height: 0;
      }
      &.to-restore-height {
        //padding-bottom: 70px;
      }

      .button--full-width {
        background-color: var(--theme-color-1-alt);
        font-size: 1rem;
        padding: 16px 12px;
        &:hover .icon {
            color: white !important;
        }
      }
    }

    [data-mobile-cart-items-list] {
      margin-bottom: 3em;
      margin-top: 1.5em;
    }

    &.visit {
      margin-bottom:30px;
      .sticky-footer__submenu__item {
        display: none;
      }

    }

     &.visit-tickets{
       padding: 35px 0 70px;

       .sticky-footer__title{
         padding: 0 30px;
       }
     }

  }

  &__title {
    font-size: rem-calc(24);
    line-height: rem-calc(29);
    color: $title-gray !important;
    letter-spacing: -0.5px;
    margin-bottom: 5px;
    text-align: left;
    font-weight: bold;

    .sticky-footer__menu-item & {
        &:hover {
            color: black !important;
        }
    }

    &--white {
      font-size: rem-calc(22);
      line-height: rem-calc(28);
      color: white;
    }

    &--search {
      margin-top: 80px;
      margin-bottom: 20px;
      border-bottom: none;
    }

    .icon{
      margin-right:15px;
    }
  }

  &__menu-item {
    list-style: none;
    padding: 15px 0;
    border-bottom: 2px solid $light-gray;
    cursor: pointer;

    &:last-of-type {
      border-bottom: none;
    }

    .icon {
      position: absolute;
      right: 30px;
      margin-top: 2px;
      font-size: rem-calc(25px);
      width: rem-calc(25px);
      height: rem-calc(25px);
      color: $light-gray;
    }

    a {
      width: 100%;
    }
  }

  &__clear {
    font-weight: bold;
    font-size: rem-calc(16);
    line-height: rem-calc(20);
    position: absolute;
    top: 45px;
    right: 35px;

    & > a {
      color: $text-gray;
    }
  }

  &__text {
    font-size: rem-calc(15);
    line-height: rem-calc(24);
    color: $text-gray;
    padding: 0 10px;
    @include respond-min($xsmall-breakpoint) {
        font-size: rem-calc(18);
    }
    @include respond-min($small-breakpoint) {
        font-size: rem-calc(20);
    }
  }

  &__item-icon {
    margin-bottom: 0;
    position: relative;

    .svg svg{
      margin-top: -10px;
      width: 60px;
      height: 50px;

      &--close svg{
        margin-top: -10px;
      }
    }

    &--hamburger {
      margin-bottom: 0;
      background: $theme-color-1;
      border-radius: 100%;
      height: 44px;
      width: 44px;
      margin-top: 10px;

      &:hover {
        background: $theme-color-2;
        svg g, svg g #Colors\/Black{
            fill:white !important;
        }
      }

      .svg svg{
        margin-left: -8px;
      }
    }
  }

  &__item-label {
    font-family: $font-family-1;
    font-size: rem-calc(11);
    line-height: rem-calc(12);
    margin-top: -13px;
  }

  &__button {
    width: 200px;
  }

  &__link {
    font-size: rem-calc(16);
    line-height: rem-calc(20);
    padding-bottom: 3px;
    margin: 20px 0 0;
    a:hover {
        color: $theme-color-2 !important;
    }
  }

  .glide {
    .box-teaser.horizontal .corner {
      display: block;
      opacity: 1;
    }

    .box-teaser .corner.box-teaser-remove {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  &__panel .box-teaser.horizontal .box-teaser-remove {
    &-wrap {
        top: 50%;
        bottom: auto;
        height: auto;
        translate: 0 -50%;
    }
    &-cross {
        font-size: rem-calc(40);
        color: $gray-30;
        &:hover {
            color: $gray-60;
        }
    }
  }

  //submenu
  &__submenu {

    &__title {
      display: block;


      display: block;
      width: auto;
      display: block;
      margin-top: -35px;
      margin-left: -30px;
      margin-right: -30px;
      margin-bottom: 30px;

      background: $light-gray;
      padding: 18px 30px;
      border-radius: 10px 10px 0 0;
      .sticky-footer__panel &:hover {
        color: black !important;
      }

      &.icon, .icon {
        color: $theme-color-1;

        &:hover {
          color: $theme-color-1;
        }
      }
    }

    &__item {
      margin-bottom: 30px;

      & ul {
        margin: 0;
      }

      & li {
        list-style: none;
        padding: 10px 0;
        border-bottom: 2px solid $light-gray;

        &:last-of-type {
          border-bottom: none;
        }

        a {
          font-size: rem-calc(18);
          line-height: rem-calc(22);
          color: $theme-color-2 !important;
          font-weight: bold;
          width: 100%;
          &:hover {
            color: black !important;
          }
        }

        &:before {
          font-family: icons;
          @extend %icon-anglebracket-right;
          color: $theme-color-1;
        }


        .visit & {
          border-bottom: 0px solid $light-gray;

          &:before {
            content: '';
            display: none;
          }
        }
      }
    }

    &__content {
      margin-bottom: 20px;

      h3 {
        font-size: rem-calc(22);
        line-height: rem-calc(30);
        color: $theme-color-3;
        margin-bottom: 0;
      }

      p {
        font-size: rem-calc(16);
        line-height: rem-calc(26);
        color: $text-gray;
        margin-bottom: 0;
      }

      .arrow-link:hover, a.link-with-bottom-border:hover {
        color: black !important;
      }

      & ul {
        margin: 0;

        & li {
          list-style: none;
          padding: 10px 0;
          border-bottom: 2px solid $light-gray;

          &:last-of-type {
            border-bottom: none;
          }

          a {
            font-size: rem-calc(18);
            line-height: rem-calc(22);
            color: $theme-color-2 !important;
            font-weight: bold;
            width: 100%;
            &:hover {
                color: black !important;
            }
          }

          &:before {
            font-family: icons;
            @extend %icon-anglebracket-right;
            color: $theme-color-1;
          }


        }


      }

      .visit-menu{

        li {
            border-bottom:none;
            display: block;
            padding: 6px;

          &:before {
            content: '';
            display: none;
          }

          .visit-svg-wrap {
            display: inline-block;
            display: none;
            width: 60px;
            height: 60px;
            background: $light-gray;
            border-radius: 50%;
            text-align: center;

            .visit-svg{
              //padding: 33px;
              width:32px;
              height: 32px;
              margin-top:13px;
            }
          }

          a{
            margin-left: 20px;
          }
        }
      }
    }

  }


}

  .icon-wrap {
    border-radius: 50%;
    background: $light-gray;
    display: inline-block;
  }

  .sticky-footer-wrapper {
    .box-teaser .corner.box-teaser-remove {
      opacity: 1;
    }
  }

  .mobile-nav-global {
    margin: 0;
    //margin-bottom: 30px;
  }


  .sticky-footer-container {
    background: $theme-color-3;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1008;
    transition: all 0.5s, height 0.01s;
    &.hidden{
      opacity: 0;
      height: 0;
    }
    .glide__bullet {
      touch-action: none;
      background-color: $gray-highlight;
      border-color:$gray-50;


      &--active{
        background-color: $theme-color-3;
        border-color:$theme-color-3;
      }
    }
  }

  // Search

  .search.content .search-form {
    background: white;
    border-radius: 27.5px;

    > .columns {
      border-bottom: none;
    }
  }

  .search.content .search-submit {
    color: $theme-color-1;
    margin: 5px 0 5px;
    width: 2.5rem;
    height: 2.5rem;

    &:before {
      font-size: 30px;
    }
  }

  .search.content .search-input {
    padding: 25px 0px;
    margin-bottom: 0;
  }

  .number-items {
    position: absolute;
    top: 12px;
    //right: 8px;
    right: 0;
    border-radius: 100%;
    background: red;
    color: white;
    font-size: 0.8em;
    width: 17px;
    height: 17px;
    text-align: center;
    line-height: 17px;
    /* font-weight: bold; */
  }




