// ======================================================================
// Plan Your Visit Tool
// ======================================================================

@use "sass:math";

.click-intercept {
  position: absolute;
  top: -10px;
  left: 30px;
  right: 30px;
  height: 50px;
}

#plan-visit-outer-wrap {
  @include grid-row();
  // margin: rem-calc(0 0 0 -40);
  // width: auto;

  .column {
    // @todo-jeremyg Look at why transitions are broken
    // @include transition(all .5s ease-in-out);
  }

  margin-top:rem-calc(0);

  @include respond-min($medium-breakpoint) {
    margin-left: auto;

    width: 100%;
    // .column {
    // 	@include grid-column($total-columns/2);
    // }
    // &.three-column-group .column {
    // 	@include grid-column($total-columns/3);
    // }
  }


  @include respond-min($large-breakpoint) {
    margin: rem-calc(0 0 0 -40);
    width: auto;
    margin: rem-calc(0 -10);
    margin-top:rem-calc(55);

    .column {
      &.half {
        @include grid-column($total-columns*0.5);
      }

      &.third {
        width:40%;
      }

      &.two-third {
        width:60%;
        padding-left: 1.625rem;
        padding-right: 1.625rem;
      }
    }
  }
  @include respond-min($xlarge-breakpoint) {
    // margin-left: auto;
    // width: 100%;

    .column {
      &.half {
        @include grid-column($total-columns*0.5);
      }

      &.third {
        @include grid-column(math.div($total-columns, 3));
        padding-left: 0;
      }

      &.two-third {
        @include grid-column(math.div($total-columns, 3)*2);
        padding-left: 1.625rem;
        padding-right: 1.625rem;
      }
    }

  }
}

.mobile-handler{
  text-align: center;
  border: none;
  border-radius: 5px;
  margin: 15px -18px 40px;
  padding: 1.25rem 2rem;
  background: $gray-05;

  @include respond-min($large-breakpoint) {
    display: none;
  }

  #filterActivities{
    max-width: 475px;
    margin: 0 auto;
    outline-color: $theme-color-2;
    .text-cell{
      text-align: center;
      display: inline-block;
      font-size: 18px;
    }
  }
}

#plan-your-visit-results{
  padding-left: 0;
  padding-right: 0;
  @include respond-min($large-breakpoint){
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }

  .bm__content-wrap a {

  }

  hr{
    margin-bottom: rem-calc(20);

    @include respond-min($large-breakpoint){
      margin-bottom: rem-calc(35);

    }
  }
}



.tabs-plan-visit-tool-wrap {
  //margin-top: rem-calc(-60);
  border: none;
  overflow: hidden;
  display: block;
  //margin: -20px -50px 25px;

  .button.button-table.button-hollow {
    color: $theme-color-1 !important;
    &:hover, &:focus, &:active {
        color: #ffffff !important;
    }
    }
  &.active{
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    padding: rem-calc(50 33 2);
    overflow: scroll;
    background: $gray-05;
    left: 0;
   // padding: 30px;
    z-index: 9999;
    opacity: 1;
    margin-bottom: 1.25rem;

    -webkit-transition: height 0.2s ease-in-out, padding 0.2s ease-in-out, opacity 0.5s ease-in;
    -moz-transition:  height 0.2s ease-in-out, padding 0.2s ease-in-out, opacity 0.5s ease-in;
    -ms-transition:  height 0.2s ease-in-out, padding 0.2s ease-in-out, opacity 0.5s ease-in;
    -o-transition:  height 0.2s ease-in-out, padding 0.2s ease-in-out, opacity 0.5s ease-in;

    @include respond-min($small-breakpoint) {
      padding: rem-calc(50 33 0);
      margin: 0;
    }

  }

  @include respond-min($large-breakpoint) {
    padding: rem-calc(50 20 0);
    display: block;
    border-radius: 5px;
  }

  @include respond-min(rem-calc(970)) {
    padding: rem-calc(50 50 0);
  }


  .results-button-wrap{
    display: block;

    @include respond-min($large-breakpoint){
      display: none;
    }

    #show-results{
      margin: 0 auto;
      float: none;
      margin-bottom: 35px;

      & span.text-cell{
        font-size:18px;
      }

      @include respond-min($small-breakpoint){
        width: 310px;
      }
    }
  }

  .tabs-plan-visit-tool {
    a {
      &:hover,
      &:focus {
        text-decoration: none;
      }
    }

    a.previous-link {
      //float: left;
      float: none;
      display: block;
      text-align: center;
      margin: auto;
      display: none;

      & > span.text-cell {
        margin-left: 4px;
      }
    }

    a.next-link {
      display: block;
      margin: auto;
      margin-top: rem-calc(40);
      margin-bottom: rem-calc(10);

      & > span.text-cell {
        margin-right: 4px;
        font-weight: bold;
      }
    }

    a.next-link, a.previous-link {
      font-weight: bold;

      .icon-circle {
        width: 12px;
        height: 12px;
        margin: 0;
        top: 1px;
        position: relative;

        & .icon {
          margin: 0;
          font-size: 12px;
          line-height: 11px;
          width: 10px;
          height: 10px;
          color: white;
          position: absolute;
          right: -1px;
          top: -1px;
        }
      }

      &:hover {

        .icon-circle {
          background-color: $theme-color-2;
          border-color: $theme-color-2;
          color: $theme-color-2;
        }

        .icon {
          color: white;
        }
      }
    }
  }

  .button-table .text-cell {
    padding: 0 1.125rem 0 1.5rem;
    text-align: center;
  }

  .button-table .last-icon-cell {
    width: 30px;
  }

  .button-table .icon {
    margin: -.5625rem 5px -.75rem -15px;
  }

  .previous-link {
    .icon {
      position: relative;
      top: 0px;
      left: -1px;
    }

    @include respond-min($small-breakpoint) {
      width: 48%;
      margin-top: rem-calc(38);
      float: left;
      text-align: left;
    }
    @include respond-min($medium-breakpoint) {
      margin-top: rem-calc(38);
    }
    @include respond-min($large-breakpoint) {
      width: 100%;
      float: none;
      margin-top: 0;
      //margin-bottom: rem-calc(20);
    }
  }

  .button-table {
    margin-top: rem-calc(30);
    clear: both;
  }

  .button-table {
    max-width: 475px;
    float: none;
    clear: both;
    width: 100%;
    margin: 0 auto;
    @include respond-min($small-breakpoint) {
      width: 48%;
      margin-top: 1.875rem;
    }
    @include respond-min($large-breakpoint) {
      width: 100%;
      margin-top: 0;
    }
  }

  // @todo This is copied out of ticketing.  Needs some cleanup and should possibly be common code with ticketing
  .button-table.secondary {
    padding: 0;
    cursor: pointer;
    margin-bottom: 25px;

    @include respond-min($small-breakpoint) {
      float: none;
    }

    &:hover,
    &:focus {
      background: white;
      color: $theme-color-2;

      .icon {
        color: $theme-color-2;
      }

      input[type=text] {
        color: $theme-color-2;
      }
    }

    input[type=text] {
      font-size: rem-calc(22);
      font-weight: 600;
      color: $theme-color-1;
    }

    .text-cell {
      padding: 0 rem-calc(22);
      font-size: rem-calc(17);
    }

    .last-icon-cell {
      padding-right: rem-calc(7);
      padding-left: rem-calc(12);
    }

    .icon {
      font-size: rem-calc(30);
      margin-top: rem-calc(-10);
    }
  }
}

.panel{
  position: fixed;
  top: 0;
  left:0;
  width: 100%;
  opacity: 0;
  height: 0;
  padding: 0;
  display: block;
  -webkit-transition: height 0.7s ease-out, padding 0.7s ease-out, opacity 0.5s ease-out;
  -moz-transition: height 0.7s ease-out, padding 0.7s ease-out, opacity 0.5s ease-out;
  -ms-transition: height 0.7s ease-out, padding 0.7s ease-out, opacity 0.5s ease-out;
  -o-transition: height 0.7s ease-out, padding 0.7s ease-out, opacity 0.5s ease-out;
  margin-bottom: 0;

  @include respond-min($large-breakpoint){
    display: block;
    position: relative;
    height:100%;
    padding: rem-calc(50 33 2);
    overflow: scroll;
    opacity: 1;
    margin-bottom: 1.25rem;

  }
}

.button.datepicker-button {
  padding: 0;
  cursor: pointer;
  table-layout: fixed;

  .input-cell {
    display: table-cell;
  }

  #datepicker,
  #datepicker-value {
    border: none;
    box-shadow: none;
    margin: 0;
    height: auto;
    display: block;
    float: left;
    vertical-align: middle;
    line-height: 1.375rem;
    font-size: rem-calc(18);
    background: white;

    &::-ms-clear {
      display: none;
    }
  }

  #datepicker {
    text-align: center;
    // @todo What should this measure be?
    padding: rem-calc(12 5 8);
    font-weight: 600;
    color: $theme-color-1;
    width: 99%;
  }

  #datepicker-value {
    /* iOS needs a 16px font to not zoom on the input field */
    // font-size: 16px !important;
    width: 1%;
    margin-left: -1%;
    padding: rem-calc(12 0 8);
    opacity: 0;
    text-indent: -9999em;
  }

  .icon {
    font-size: rem-calc(30);
    margin-top: rem-calc(-10);
    margin: -.5625rem 5px -.75rem -15px;
  }

  &:hover,
  &:focus {
    background-color: white;
    color: $theme-color-2;

    .icon {
      color: $theme-color-2;
    }

    #datepicker {
      color: $theme-color-2;
    }
  }

  .text-cell {
    padding: 0 rem-calc(22);
    font-size: rem-calc(17);
  }

  .last-icon-cell {
    padding-right: rem-calc(7);
    width: rem-calc(45);
  }

  .icon {
    font-size: rem-calc(30);
    margin-top: rem-calc(-10);
  }
}


.tabs-plan-visit-tool-page {
  @include reset-ul;
  text-align: center;
  background: image-url("plan-visit-tool-tab-title-bg.jpg") center no-repeat;
  margin-bottom: rem-calc(12);
  background-size: 120px 2px;

  .tab-title {
    border-radius: 1000px;
    display: inline-block;
    margin: 0 rem-calc(4);
   // position: relative;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;

     /* &:after{
        content:none;
      }

      */
    }

    /*&:after{
      content:" ";
      display: block;
      width: 15px;
      height: 2px;
      position: absolute;
      top: 15px;
      right: -15px;
      background: white;
    }

     */



    a {
      border: 3px solid white;
      background-color: white;
      height: rem-calc(30);
      width: rem-calc(30);
      display: block;
      text-align: center;
      border-radius: 1000px;
      line-height: 1.9;
      font-size: rem-calc(14);
      font-weight: 700;
      cursor: default;


      &:hover,
      &:focus {
        //background-color: $theme-color-1;
        //color: white;
        color: $theme-color-1;
      }

      &:focus {
        //outline: 0;
      }

    }

    &.active {
      border-color: $theme-color-1;

      a {
        background-color: $theme-color-1;
        border-color: $theme-color-1;
        color: white;
      }
    }
  }
}

.tabs-plan-visit-tool-content {

  @include respond-min($medium-breakpoint) {
    margin-bottom: 0;
  }

  @include respond-min($large-breakpoint) {
    margin-bottom: 1.5rem;
  }

  .content {
    text-align: center;

    h3 {
      margin-bottom: rem-calc(30);
      line-height: rem-calc(30);
      font-size: rem-calc(26);
      color: $theme-color-3;
      padding:0 20px;

      @include respond-min($small-breakpoint){
        line-height: rem-calc(20);
        font-size: rem-calc(20);
      }

      @include respond-min($large-breakpoint){
        line-height: rem-calc(31.2);
        font-size: rem-calc(26);
        margin-bottom: rem-calc(20);
        padding: 0;
      }
    }

    p {
      font-size: rem-calc(16);
      line-height: 1.4;
      margin-bottom: rem-calc(25);
      color:$text-gray;
    }

    label {
      color: $theme-color-3;
      font-weight: bold;
      font-size: rem-calc(15);
      line-height: 1.4;
      text-align: left;
      clear: left;
      padding-bottom: rem-calc(8);
      max-width: rem-calc(340);
      margin: 0 auto;

      @include respond-min($medium-breakpoint) {
        border-top: $border-1;
        padding: rem-calc(15 7);
      }

      @include respond-min($large-breakpoint) {
        padding: rem-calc(8 4);
      }

      @include respond-min($xlarge-breakpoint) {
        padding: rem-calc(15 7);
      }

      input {
        float: left;
        margin: rem-calc(4) 1em 0 0;

        @include respond-min($large-breakpoint) {
          float: right;
          margin: rem-calc(4) 0 0 1em;
        }
      }
    }


  }
}

.range-slider-wrap {
  position: relative;
  // min-width: rem-calc(220);
  // max-width: rem-calc(230);
  width: rem-calc(128);
  margin: -10px auto 0px;

  .range-slider {
    width: 6px;
    background-color: $theme-color-1;

    .range-slider-handle {
      width: 20px;
      height: 20px;
      margin-left: -10px;
      border: 5px solid $theme-color-1;
      border-radius: 1000px;
      background-color: white;
      transition: transform .3s ceaser($easeInOutQuad);
    }

    .range-slider-active-segment {
      width: 4px;
      margin-left: -2px;
      background-color: $theme-color-2;
      transition: height .3s ceaser($easeInOutQuad);
    }
  }

  .duration {
    position: absolute;
    width: rem-calc(59);

    &:hover,
    &:focus {
      cursor: pointer;
    }

    .time {
      background: white;
      height: rem-calc(32);
      width: rem-calc(32);
      display: block;
      text-align: center;
      border-radius: 1000px;
      line-height: 1.9;
      font-size: rem-calc(17);
      font-weight: 700;
      color: $gray-30;

      sup {
        font-size: rem-calc(9);
        margin-left: 2px;
      }
    }

    .indicator {
      position: absolute;
      height: 2px;
      width: 16px;
      top: 15px;
    }

    &.duration-left {
      right: 50%;

      .indicator {
        right: 0;
      }
    }

    &.duration-right {
      left: 50%;

      .indicator {
        left: 0;
      }

      .time {
        float: right;
      }
    }

    &.active {
      .time {
        background: $theme-color-1;
        font-size: rem-calc(18);
        color: white;
      }

      .indicator {
        background-color: $theme-color-1;
        width: 28px;

        transition: width .1s linear .2s;
        transition: background-color .1s linear .3s;
      }
    }

    &.above {
      .indicator {
        background-color: $theme-color-1;
      }
    }

    &.below {
      .indicator {
        background-color: $theme-color-2;
      }
    }

    &:last-child {
      .time {
        background: none;
        color: $theme-color-2;
      }

      &.active {
        .time {
          background: none;
          font-size: rem-calc(17);
          color: $theme-color-1;
        }

        .indicator {
          background-color: $theme-color-1;
          width: 20px;
        }
      }
    }
  }

  #duration-1 {
    top: 12px;
  }

  #duration-2 {
    top: 37px;
  }

  #duration-3 {
    top: 63px;
  }

  #duration-4 {
    top: 90px;
  }

  #duration-5 {
    top: 115px;
  }

  #duration-6 {
    top: 140px;
  }

  #duration-7 {
    top: 167px;
  }

  #duration-8 {
    top: 192px;
  }

  label {
    border-top: 1px solid $gray-10;
    text-align: left;
    padding: rem-calc(15) 0 rem-calc(5);
    @extend .clearfix;
    font-size: rem-calc(17);
    color: $theme-color-3;
    max-width: 400px;
    margin: 0 auto;

    .title {
      display: block;
      width: 85%;
      float: left;
      font-size: rem-calc(17);
      color: $theme-color-3;

      .subtitle {
        font-size: rem-calc(10);
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: rem-calc(3);
        display: block;
        color: $gray-40;
      }
    }

    input {
      float: right;
      font-size: 30px;
      margin-top: 5px;
    }
  }
}

.things-to-do {
  padding-bottom: rem-calc(35);
  border-bottom: 1px solid $gray-10;
  font-size: rem-calc(27);

  .number {
    color: $theme-color-1;
  }

  small {
    display: block;
    font-size: rem-calc(11);
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: rem-calc(3);
    margin-top: rem-calc(20);
    color: $gray-30;
  }
}


.pyv-count {
  margin-bottom: 25px;
  letter-spacing: -0.5px;
  font-size: 26px;

  @include respond-min($small-breakpoint){
    margin-top:25px;
  }

  @include respond-min($large-breakpoint){
    margin-top:0;
  }

  &-text {
    font-weight: normal;
  }
}

@media only screen and (min-width: 0) and (max-width: $small-breakpoint) {
  .pika-single.is-bound {
    left: 0px !important;
    right: 0px !important;

    .pika-lendar {
      width: 100%;
    }
  }
}
#step1, #step2{
  .button{
    margin-top: 10px;
  }
}


#step3 {
  & > input {
    visibility: hidden;
    height: 0;
    width: 0;
    position: absolute;
    left: 0;


    &:focus + label::before {
      outline: rgb(59, 153, 252) auto 5px;
    }
  }

  label {
    max-width: rem-calc(408);
    //margin: 0 0 9px;


    &:after {
      content: '\2713\0020';
      color: transparent;
      font-size: 20px;
      font-weight: bold;
      position: absolute;
      right: 15px;
      top: 30%;
    }
  }

  input[type="checkbox"]:checked + label {
    border-color: $theme-color-1;
    background-color: $theme-color-1;
    color: white !important;

    &:after {
      color: white;
    }
  }

}



#step4 {

  .topic__label {
    display: block;
    width: 100%;
    text-align: left;
    max-width: 450px;
    margin: auto;

    @include respond-min(500px){
        display: inline-block;
        width: 49%;
        text-align: left;
        max-width: 350px;
    }

    @include respond-min($large-breakpoint){
      display: block;
      width: 100%;
      text-align: left;
    }

    &.--all{
      border-bottom: 2px solid $gray-10;
      padding-bottom: 17px;

      @include respond-min(500px){
        border-bottom: none;
        padding-bottom: 0;

      }

      @include respond-min($large-breakpoint){
        border-bottom: 2px solid $gray-10;
        padding-bottom: 17px;
      }

      label{
        font-weight: bold;
      }
    }
    &:nth-of-type(2) {
      padding-top: 17px;
    }

    &:last-of-type {
      padding-bottom: 30px;
    }

    input {

      /* Base for label styling */
      &[type="checkbox"]:not(:checked),
      &[type="checkbox"]:checked {
        position: absolute;
        left: -9999px;
      }

      &[type="checkbox"]:not(:checked) + label,
      &[type="checkbox"]:checked + label {
        position: relative;
        padding-left: 2.95em;
        cursor: pointer;
      }

      /* checkbox aspect */
      &[type="checkbox"]:not(:checked) + label:before,
      &[type="checkbox"]:checked + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 5px;
        width: 30px;
        height: 30px;
        border: 2px solid $gray-highlight;
        background: transparent;
        border-radius: 3px;
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, .1);
      }

      &[type="checkbox"]:checked + label:before {
        background: $theme-color-1;
        border-color: $theme-color-1;
      }

      /* checked mark aspect */
      &[type="checkbox"]:not(:checked) + label:after,
      &[type="checkbox"]:checked + label:after {
        content: '\2713\0020';
        position: absolute;
        top: 13px;
        left: 8px;
        font-size: 1.3em;
        line-height: 0.8;
        color: white;
        transition: all .2s;
        font-family: $font-family-2, Arial;
      }

      /* checked mark aspect changes */
      &[type="checkbox"]:not(:checked) + label:after {
        opacity: 0;
        transform: scale(0);
      }

      &[type="checkbox"]:checked + label:after {
        opacity: 1;
        transform: scale(1);
      }

      /* accessibility */
      &[type="checkbox"]:checked:focus + label:before {
        border: 2px solid $theme-color-1;
      }

      &[type="checkbox"]:not(:checked):focus + label:before {
        border: 2px solid $theme-color-1;
      }
    }

    /* hover style just for information */
    label:hover:before {
      border: 2px solid $theme-color-1;
    }
  }

  label {
    max-width: rem-calc(408);
    margin: 0 auto;
    border: none;
    font-weight: normal;
    font-size: rem-calc(14);
    padding-top: 12px;
    padding-bottom: 12px;
  }

  /*

    @include respond-min($medium-breakpoint) {
      width: 43%;
      float: left;
      clear: left;
      margin-left: 4%;

      &:nth-child(2n+1) {
        margin: 0 0 0 0;
        clear: right;
        float: right;
        margin-right: 4%;
      }
    }

    @include respond-min($large-breakpoint) {
      width: auto;
      float: none;
      clear: left;
      margin-left: 0;

      &:nth-child(2n+1) {
        margin: 0 0 0 0;
        clear: left;
        float: none;
        margin-right: 0;
      }
    }

  }

   */

  @include respond-min($large-breakpoint) {

    .button-table {
      display: none;
    }

    /*

        .previous-link {
            margin-top: 1.25rem;
            font-weight: 700;
        }
     */
  }
}


.tabs-content {
  position: relative;

  > .content {
    opacity: 0;
    position: absolute;
    top: 0;

    &.slideInRight,
    &.slideInLeft,
    &.slideOutRight,
    &.slideOutLeft {
      animation-duration: 0.5s !important;
    }

    &.active {
      //animation: slideInRight 1s;
      opacity: 1;
      display: block;
      position: static;

      &.reverse {
        //animation: slideInLeft 1s;
      }
    }

    &.last-active {
      //animation: slideOutRight 1s;
      display: block !important;

      &.reverse {
        //animation: slideOutLeft 1s;
      }
    }
  }
}

.pav {

  &__preview-add-visit {
    width: 100%;
    height: 100%;
    max-width: 600px;
    @include respond-min($large-breakpoint){
      min-width: 730px;
      max-width: 730px;
     // padding:1em;
    }

    @include respond-min($xlarge-breakpoint){
      min-width: 965px;
      max-width: 965px;
    }
  }

  &__column-group{
      min-height: 100%;

    @include respond-min($large-breakpoint){
      display: flex;
      justify-content: space-between;
      align-items: stretch;
    }
  }

  &__column {

    h3{
      padding-top:0;
      @include respond-min($large-breakpoint){
        padding-right: 2em;
        padding-top: 1em;
      }
      @include respond-min($xlarge-breakpoint){
        padding-top:2em;
        padding-right:0;
      }
    }

    &-one{
      padding:0!important;
      width: 100%!important;

      /*@include respond-min($medium-breakpoint){
        padding:1.5em 0.5em 0 1.5em !important;
      }

       */

      @include respond-min($large-breakpoint){
        width:37.333333%!important;
        padding:1.5em 0.5em 0 1.5em !important;
      }

      @include respond-min($xlarge-breakpoint){
        padding:2.5em 0.5em 0 2.5em !important;
        width:33.333333%!important;

      }

      @include respond-min($xxlarge-breakpoint){
      }

      & img{
        width:100%;
      }
    }

    &-two{
      padding:2.5em!important;
      border-top: 1px solid rgba($title-gray, 0.2)!important;

      @include respond-max($large-breakpoint){
        width: 100%!important;
      }

     /* @include respond-min($medium-breakpoint){
        padding:1.5em 1.2em!important;
        border-top:none!important;
      }

      */

      @include respond-min($large-breakpoint){
        padding: 0 3em 0 1.5em!important;
        border-top:none!important;
      }

      @include respond-min($xlarge-breakpoint){
        border-left: 2px solid rgba($title-gray, 0.2)!important;
        padding-left:3.5em!important;
        margin-left: 35px;
      }

    }
  }

  h2 {
    margin-top: rem-calc(20);
    font-size: rem-calc(24);
    letter-spacing: -0.5px;
    font-weight: 600;
    margin-bottom:0;
    padding:0 35px;

    @include respond-min($large-breakpoint){
      padding: 0;
    }
  }

  & .subtitle{
    text-transform: uppercase;
    margin: 0;
    font-size: 12px;
    letter-spacing: 1.8px;
    font-weight: 600;
    color:$text-gray!important;
    padding: 0 35px;
    margin-bottom: 11px;
    display: block;
    @include respond-min($large-breakpoint){
      padding: 0;
    }

    @include respond-min($xlarge-breakpoint){
      margin-bottom: 0;
    }
  }

  &__add-button {
    float: none !important;
    height: 55px !important;
    width: 80%;
    padding:0!important;
    padding-left:1.125em!important;
    margin:1.5em auto 0;
    @include respond-min(400px){
      margin: 0.5em 30px;
      min-width: 204px;
      max-width:260px;
      width: 100%;
    }

    @include respond-min($small-breakpoint){
    }


    @include respond-min($large-breakpoint){
      margin: 0;
    }

    & .text-cell {
      padding: 0!important;
      font-size: 16px;
    }


    &:hover, &:active,&:focus{
      background:$theme-color-2!important;
      & .text-cell, .icon {
        color: white !important;
      }

    }
  }

  #all-essentials.pav__list{
    margin: rem-calc(5 0 20);
    padding: 1em 2em;
    @include respond-min($large-breakpoint){
      padding: 0;
      margin: rem-calc(20 0);
    }

    & > li.pav__list-item{
      padding-bottom: rem-calc(15)!important;
      width:100%!important;
    }

    & .essentials-icon{
      width:44px!important;
    }

    & .title, & .item{
      font-size: 14px;
      font-weight: 600;
      float:none!important;
      width:auto!important;
    }

    & .description{
      color:$text-gray!important;
      margin-left:0;
      font-size: 14px;
    }


  }

  &__description-link{
    font-weight: bold;
  }

  & .icon-circle, & #all-essentials .icon-circle{
    background-color: $lightest-blue-gray;
    border-color: $lightest-blue-gray;
    width: 14px;
    height: 14px;
    margin: 0;
    top: 2px;
    position: relative;

    & .icon {
      margin: 0;
      font-size: 14px;
      line-height: 14px;
      position: absolute;
      top: -1px;
      right: -2px;
      color: $theme-color-1;
      width: 14px;
      height: 14px;
    }
  }

}

.pyv-add-button {
    min-width: rem-calc(220);
    .text-cell {
        padding-bottom: rem-calc(2);
    }
}

.fancybox-overlay{
  background:rgba(#030D26,0.9)!important;
  //background:$theme-color-2!important;
}

.fancybox-skin, .fancybox-inner{
  padding: 0!important;
  border-radius:0!important;

  @include respond-min($large-breakpoint){
    border-radius:8px!important;
  }
}

.fancybox-skin .fancybox-close {
  top: 15px!important;
  right: 20px!important;
  background-image: unset!important;
  background: white!important;
  display: block!important;
  width:50px!important;;
  height: 50px!important;
  border-radius: 50%!important;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.1);

  @include respond-min($xxlarge-breakpoint){
    position: fixed;
    top: 30px !important;
    right: 30px !important;
  }

  &:after{
    content:"\00d7";
    color:$theme-color-1;
    text-align:center;
    display: block;
    margin-top: 15px;
    font-size: 40px;
  }
}

.select-options{
  padding: rem-calc(15);
  width: 100%;
  text-align:center;

  @include respond-min($small-breakpoint) {
    padding: rem-calc(30);

  }
  & .title {
    margin-bottom:20px;
    font-size: rem-calc(22);

    @include respond-min($medium-breakpoint){
      font-size: rem-calc(28);
    }
  }

  & .time-slots{
    @include reset-ul;

    & > li{
      display: inline-block;
      margin: 10px 5px;

      & > .button{
        min-width:137px;
        margin-bottom: 0;
      }

    }
  }

}

.exhibit .fancybox-skin .fancybox-close {
  top: 15px !important;
  position: fixed;
  right: 20px !important;
}

.next-link.small{
  position: absolute !important;
  width: 60px !important;
  display: block;
  right: 0 !important;
  margin: 13px 0px 0px 0px !important;
  .text-cell{
    display: inline !important;
    padding: 0px !important;
  }
  .icon{
    right: 0 !important;
  }
}

[data-datepicker]{
  margin-top: 0px !important;
}

.button.datepicker-button .input-cell{
  display:block !important;
}

.button.datepicker-button #datepicker{
  width: 100% !important;
}



.pulse {
  animation: pulse 3s infinite;
  .icon-circle, .text-circle {
    animation: pulse-icon 3s infinite
  };
}

@keyframes pulse {
  0% {
    color: var(--theme-color-1);
    text-decoration: none;
  }
  100% {
    color: var(--theme-color-2);
    text-decoration: underline;
  }
}

@keyframes pulse-icon {
  0% {
    background: var(--theme-color-1);
    border: 1px solid var(--theme-color-1);
  }
  100% {
    background: var(--theme-color-2);
    border: 1px solid var(--theme-color-2);
  }
}

