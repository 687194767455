	// ======================================================================
	// Teaser Module
	// This was origianlly connected with the Box module ce, but was split out
	// when that ce was rewritten. May revisit the code later.
	// ======================================================================

@use "sass:math";

.teaser-module.vertical-box {
	margin-bottom: rem-calc(40);
	box-shadow: 0px 2px 4px 1px rgba(0,0,0,0.15);

	.teaser-module-image {
		width: 100%;
		display: block;
	}

	.teaser-module-content-wrap {
		//border: $border-1;
		padding: rem-calc(40 45 40);
		background-color: white;
	}

	&.teaser-module-blue {
		background-color: $theme-color-2;
		color: white;

		.teaser-module-content {
			border: none;
		}
	}

	&.teaser-module-no-bottom-collapse {
		margin-bottom: 0;
	}

	footer {
		text-align: center;

		&.align-left {
			text-align: left;;
		}
		.button {
			margin-bottom: 0;
		}
	}

	.title {
		margin-bottom: .5em;
	}

	.subtitle {
		margin-bottom: 20px;
	}

	h3.title {
		margin-bottom: 1em;
	}
}


.teaser-module.inset-text {
	border: 1px solid $gray-10;
	position: relative;

	.teaser-module-content-wrap {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 44%;
		background-color: $theme-color-3;
		padding: rem-calc(30 40 40);

		.title {
			margin-bottom: rem-calc(25);
		}

		p {
			margin-bottom: rem-calc(20);
			line-height: 1.5rem;
		}
	}
}

.teaser-module.horizontal.image-large {

	.teaser-module-image-wrap {

		@include respond-min($small-breakpoint) {
			width: 54.7%;
			max-width: 310px;
		}
	}

	// .teaser-module-content-wrap {
	// 	width: 45.3%;
	// }

	.title {
		font-size: rem-calc(24);
		line-height: rem-calc(26);
	}

	.info + hr {
		display: block;
	}

}

.teaser-module.horizontal.image-thumbnail {

	.teaser-module-image-wrap {
		width: 21.19%;
		padding: rem-calc(32 76 32 0);
		padding-right: 13%;

		img {
			max-height:rem-calc(64);
		}
	}

	.teaser-module-content-wrap {
		width: 78.81%;
	}

	p {
		padding-right: 7.8%;
	}

}

.teaser-module.horizontal.calendar {
	border-top: $border-1;
	border-bottom: none;

	.member-event-title + &{
		border-top:none;
	}

	.teaser-module-image-wrap{
		display: block;
		float: left;
		width: 26%;
		max-width: 188px;
		padding: 2rem 1.7rem 2rem 0;
	}

	.teaser-module-content-wrap{
		width: 74%;

		.title {
			margin-bottom: 0;
			font-size: rem-calc(18);
			line-height: rem-calc(20);
			color:$title-gray;
			font-weight: 700;
			margin-bottom: 5px;
		}

		.info {
			margin-bottom: rem-calc(14);

			& .title, & .subtitle {
				font-size: rem-calc(12);
				line-height: rem-calc(16);
				color:$gray-highlight;
				font-weight: 600;
				letter-spacing: 2px;
			}

			& .subtitle {
				color: $green-highlight;
			}

			& .separator {
				color: $gray-highlight;
				margin: 0 5px;
			}
		}

		.description{
			font-size:16px;
			line-height: 26px;
			color:$text-gray;
			font-weight: 400;
			letter-spacing: 0;
		}
	}

}



.teaser-module.horizontal {
	@extend .clearfix;
	margin-bottom: 0;
	display: block;
	clear: both;
	width: 100%;
	border-bottom: $border-1;

	&.bottom-border-none {
		@extend .bottom-border-none;
	}

	.teaser-module-image-wrap {
		width: 100%;

		@include respond-min($small-breakpoint) {
			display: block;
			float: left;
			width: 41%;
			max-width: 240px;
			padding: rem-calc(32 40 32 0);
		}

		img {
			width: 100%;
		}
	}

	.teaser-module-content-wrap {
		padding: rem-calc(32 0 32);
		display: table;
		width: 59%;
		vertical-align: top;
	}

	.teaser-module-content {
		margin-bottom: rem-calc(16)
	}

	.icon-circle,
	.label {
		float: right;
		margin-right: 0;
		font-size: rem-calc(17);
	}

	.icon-circle.light {
		background-color: transparent;
		border: 1px solid $gray-20;

		&:hover,
		&:focus {
			background-color: $theme-color-1;
			border-color: $theme-color-1;
			cursor: pointer;

			.icon {
				color: white;
			}
		}
	}

	.icon-circle .icon {
		top: 52.5%;
		$size: .75;
		font-size: $size + em;
		line-height: $size + em;
		width: math.div(2, $size) + em;
		height: math.div(2, $size) + em;
		@media screen\0 {
			line-height: math.div($size, 1.02489019) + em;
		}
		// &:before {
		// 	font-size: em-calc(12);
		// }
	}

	.label.secondary {
		color: $theme-color-1;
		font-weight: bold;
		text-transform: uppercase;
		font-size: rem-calc(10);
		letter-spacing: rem-calc(2.5);
		padding: rem-calc(10);
	}

	.title {
		font-size: rem-calc(18);
		line-height: rem-calc(24);
		margin-bottom: rem-calc(16);

		+ .subtitle {
			margin-top: rem-calc(-6);
			margin-bottom: rem-calc(5);
		}
	}

	.subtitle {
		font-size: rem-calc(10);
		line-height: rem-calc(17);
		font-weight: bold;
		text-transform: uppercase;
		color: inherit;
		letter-spacing: rem-calc(2);
		margin-bottom: rem-calc(2);
	}

	.rte-content {
		padding-right: 0;
		margin-bottom: rem-calc(10);

		&.rte-text-large {
			p, ul, ol, dl {
				font-size: rem-calc(14);
				line-height: rem-calc(24);
				margin-bottom: rem-calc(16);
			}
		}
	}

	p, ul, ol, dl {
		font-size: rem-calc(12);
		line-height: rem-calc(21);
		margin-bottom: rem-calc(12);
	}

	.info {
		font-size: rem-calc(9);
		line-height: rem-calc(13);
		text-transform: uppercase;
		display: block;
		margin-bottom: rem-calc(8);
		letter-spacing: rem-calc(2.5);
		font-weight: 600;

		.title {
			font-size: inherit;
			line-height: inherit;
			margin-bottom: 0;
		}
	}

	.info + hr {
		clear: none;
		border-top: 2px dotted $lightest-blue-gray;
		display: none;
		margin: 0 0 rem-calc(3);
	}
}


// @todo-ron-jeremy Ask Jeremy what this is for.

.experience {

	.teaser-module-content .subtitle {
		letter-spacing: rem-calc(4);
		font-weight: 600;

		.date {
			color: $text-color-base;
			letter-spacing: rem-calc(2);
		}
	}

	&.event {
		.icon-circle {
			background-color: $color-event;
			border-color: $color-event;
		}
		.subtitle {
			color: $color-event;
		}
	}

	&.tours, &.tour {
		.icon-circle {
			background-color: $color-tours;
			border-color: $color-tours;
		}
		.subtitle {
			color: $color-tours;
		}
	}

	&.film {
		.icon-circle {
			background-color: $color-film;
			border-color: $color-film;
		}
		.subtitle {
			color: $color-film;
		}
	}

	&.exhibit {
		.icon-circle {
			background-color: $color-exhibit;
			border-color: $color-exhibit;
		}
		.subtitle {
			color: $color-exhibit;
		}
	}
}
