// ======================================================================
// What's Here Filter
// ======================================================================
#filterable-list-plugin {
    z-index: 102;
    top: rem-calc(-300);
    position: relative;
    //margin-bottom: rem-calc(-230);
    @include respond-min($small-breakpoint) {
        //top: rem-calc(-230);
        top: rem-calc(-300);
        position: relative;
		margin-bottom: rem-calc(-230);
    }

    @include respond-min($medium-breakpoint) {
        top: rem-calc(-244);
		margin-bottom: rem-calc(-180);
    }

    @include respond-min($large-breakpoint) {
       /* top: rem-calc(-235);
		margin-bottom: rem-calc(-235);

        */
        top: rem-calc(-235);
        margin-bottom: rem-calc(-368);
    }


    @include respond-min($xlarge-breakpoint) {
        /* top: rem-calc(-235);
         margin-bottom: rem-calc(-235);

         */
        //top: rem-calc(-323);
        top: rem-calc(-315);
        margin-bottom: rem-calc(-315);
    }

    .filter-container {
        font-family: $font-family-2;
        font-style: italic;
        font-size: rem-calc(21);
        text-align: center;
        color: $gray-20;
        padding: rem-calc(20);
        //background: $theme-color-2;
        background: none;
        margin: rem-calc(-20) rem-calc(-30) rem-calc(30);
        padding: rem-calc(20);

        @include respond-min($small-breakpoint) {

            margin: auto;
            padding: 0 0 rem-calc(50);
        }

        @include respond-min($large-breakpoint) {
            font-size: rem-calc(25);
        }

        .filter {
            display: inline-block;
            position: relative;
            font-family: $font-family-1;
            width: 100%;

            @include respond-min($medium-breakpoint) {
                width: auto;

				&:nth-child(2) {
					margin-left: rem-calc(-28);
				}

				&:last-child {
					margin-right: rem-calc(-28);
				}
            }

            a {
                font-size: rem-calc(23);
                font-style: normal;
                font-weight: 700;
                color: $theme-color-1-alt;
                display: inline-block;
                padding: 0 0 rem-calc(12);
                margin: rem-calc(15) 0;
                background: image-url("whats-here-filter-dots.png") left bottom repeat-x;

                @include respond-min($medium-breakpoint) {
                    font-size: rem-calc(20);
                    margin: {
                        left: rem-calc(10);
                        right: 0rem-calc(10);
                    }
                }

                @include respond-min($large-breakpoint) {
                    font-size: rem-calc(25);
                    padding: 0 0 rem-calc(12);
                    margin: {
                        left: rem-calc(25);
                        right: rem-calc(25);
                    }
                }

                @include respond-min($xlarge-breakpoint) {
                    padding: 0 0 rem-calc(12);
                    margin: {
                        left: rem-calc(25);
                        right: rem-calc(25);
                    }
                }

                &.button[data-filterable-list-close] {
                    transition: background-color 0.3s ceaser($easeInOutQuad);
                    display: inline-block;
                    color: white;
                    background-image: none;
                    background-color: $theme-color-1;
                    padding: rem-calc(15 30 16);

                    &:focus {
                        color: $theme-color-1;
                        background-color: white;
                    }

                    @include respond-min($medium-breakpoint) {
                        font-size: rem-calc(15);
                        margin: 0;
                    }
                }
            }

            .dropdown-checklist {
                visibility: hidden;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                font-style: normal;
                padding: rem-calc(5) rem-calc(10);
                background-color: $theme-color-3;
                border: none;
                text-align: left;
                z-index: 1000;
                max-height: 0;
                overflow: hidden;
                transition: max-height 0.3s ceaser($easeInOutQuad);

                label {
                    border-bottom: 1px solid #363c52;
                    padding-top: rem-calc(10);
                    padding-bottom: rem-calc(10);
                    color: $theme-color-1-alt;
                    font-size: rem-calc(23);
                    font-weight: 700;

                    @include respond-min($medium-breakpoint) {
                        font-size: rem-calc(15);
                    }

                    @include respond-min($large-breakpoint) {
                        padding-top: rem-calc(13);
                        padding-bottom: rem-calc(13);
                    }

                    &:hover, &:focus {
                        color: white;
                    }

                    &.filter-all-label {
                        border-bottom: none;
                        background: image-url("whats-here-filter-dots.png") left bottom repeat-x;
                    }

                    &.filter-close-label {
                        border-bottom: none;
                        background: image-url("whats-here-filter-dots.png") left top repeat-x;
                    }
                }
            }
        }
    }
}

// @todo-upgrade  Was html.no-touch before.  Previously set by modernizer but no longer working.  Need further investigation.
.filter-container .filter:hover .dropdown-checklist,
.filter-container .filter--focused .dropdown-checklist {
    visibility: visible !important;
    max-height: rem-calc(4000) !important;

    label.filter-close-label {
        display: none;
    }
}
// @todo-upgrade  Was html.no-touch before.  Previously set by modernizer but no longer working.  Need further investigation.
.filter-container .filter--focused .dropdown-checklist,
.filter-container .filter--focused:hover .dropdown-checklist {
    label.filter-close-label {
        display: block;
    }
}

html.touch .filter-container .filter.hover .dropdown-checklist,
html.touch .filter-container a:focus + .dropdown-checklist {
    visibility: visible !important;
    max-height: 100vh !important;

    .scroll-container {
        overflow: scroll;
        display: block;
        max-height: 70vh;
    }

    @include respond-min($medium-breakpoint) {
        .scroll-container {
            max-height: rem-calc(4000);
        }
    }
}

#filterable-list-plugin {
    .no-results {
        margin-top: rem-calc(20);

        @include respond-min($xlarge-breakpoint) {
            margin-top: rem-calc(120);
        }

        span {
            font-size: rem-calc(18);
            font-style: italic;
            font-family: $font-family-2;
            line-height: 3;
            color: $theme-color-3;
            margin-bottom: rem-calc(10);
            display: block;
        }

        h2 {
            font-size: rem-calc(30);
            line-height: 1.1;
            color: $lighter-gray;
            margin-bottom: rem-calc(30);

            @include respond-min($xlarge-breakpoint) {
                font-size: rem-calc(60);
                margin-bottom: rem-calc(80);
            }
        }
    }
}
/* iCheck plugin Minimal skin, green
----------------------------------- */
.icheckbox_minimal-green,
.iradio_minimal-green {
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 18px;
    height: 18px;
    background: image-url("icheck.png") no-repeat;
    border: none;
    cursor: pointer;
}

.icheckbox_minimal-green {
    background-position: 0 0;
}

// .icheckbox_minimal-green.focus {
//     border: $theme-color-1 auto 5px;
// }

.icheckbox_minimal-green.hover, .icheckbox_minimal-green.focus {
    background-position: -20px 0;
}

.icheckbox_minimal-green.focus {
    outline: $theme-color-1 auto 5px;
}

.icheckbox_minimal-green.checked {
    background-position: -40px 0;
}

.icheckbox_minimal-green.disabled {
    background-position: -60px 0;
    cursor: default;
}

.icheckbox_minimal-green.checked.disabled {
    background-position: -80px 0;
}

.iradio_minimal-green {
    background-position: -100px 0;
}

.iradio_minimal-green.hover, .iradio_minimal-green.focus {
    background-position: -120px 0;
}

.iradio_minimal-green.checked {
    background-position: -140px 0;
}

.iradio_minimal-green.disabled {
    background-position: -160px 0;
    cursor: default;
}

.iradio_minimal-green.checked.disabled {
    background-position: -180px 0;
}
/* HiDPI support */
@media (-o-min-device-pixel-ratio: 5/4),
(-webkit-min-device-pixel-ratio: 1.25),
(min-resolution: 120dpi),
(min-resolution: 1.25dppx) {
    /*
	@todo Doesn't look like this file exists.
    .icheckbox_minimal-green,
    .iradio_minimal-green {
        background-image: image-url("green@2x.png");
        -webkit-background-size: 200px 20px;
        background-size: 200px 20px;
    }
	*/
}

body.no-scroll {
    overflow: hidden;
}
