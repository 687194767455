.benefits_ce {
  //background: white;
  text-align: center;

  &__title {
    padding:0 43px;
    font-size: rem-calc(26);
    line-height: rem-calc(28);
    letter-spacing: -0.5px;
    color: #030D26 !important;
    margin-bottom:10px;
    @include respond-min($medium-breakpoint){
      font-size: rem-calc(36);
      line-height: rem-calc(36);
      padding:0;
    }
  }

  &__subtitle {
    padding: 0 30px;
    font-size: rem-calc(18);
    line-height: rem-calc(28);
    letter-spacing: 0;
    color: rgba(#030D26, 0.6) !important;;
    font-weight: 400;
    margin-bottom:10px;

    @include respond-min($large-breakpoint){
      margin-bottom: 22px;
      padding: 0;

    }
  }

  &__endNote {
    font-size: rem-calc(15);
    line-height: rem-calc(20);
    letter-spacing: 0;
    color: rgba(#030D26, 0.6) !important;
    margin-top: 45px;
  }

  &__list-items {
    display: block;
    max-height: 200px;
    height: auto;
    overflow:hidden;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    width: 100%;
    margin-top: 17px;
    transition: all ease-in-out 1s;
    -webkit-transition: all ease-in-out 1s;
    position: relative;
    &::-webkit-scrollbar {
      display: none;
    }

    &--open{
      max-height: 800px;
      transition: all ease-in-out 1s;
      -webkit-transition: all ease-in-out 1s;
    }



    @include respond-min($medium-breakpoint) {
      max-height:100%;
      margin: auto;
      display: flex;
      justify-content: flex-start;
      align-content: flex-start;
      flex-flow: row wrap;
      align-items: stretch;
      width: 100%;
    }

    @include respond-min($xlarge-2-breakpoint){
      justify-content: space-between;
    }

    &--7-up, &--8-up,&--9-up,&--10-up{
      @include respond-min($small-breakpoint){
        .benefits_ce__list-item--1{
          border-top:none;
        }
      }

      @include respond-min($medium-breakpoint){
        .benefits_ce__list-item--2{
          border-top:none;
        }
      }

      @include respond-min($large-breakpoint){
        .benefits_ce__list-item--3{
          border-top:none;
        }
      }

      @include respond-min(1167px){
        .benefits_ce__list-item--4{
          border-top:none;
        }
      }

    }
    &--5-up, &--6-up{
      justify-content: center;

      .benefits_ce__list-item{
        margin: 0 10px;
        @include respond-min($large-breakpoint){
          margin: 0 20px;
        }
      }

      @include respond-min($small-breakpoint){
        .benefits_ce__list-item--1{
          border-top:none;
        }
      }

      @include respond-min($medium-breakpoint){
        .benefits_ce__list-item--2{
          border-top:none;
        }
      }

      @include respond-min($xlarge-2-breakpoint){
        .benefits_ce__list-item--3{
          border-top:none;
        }
      }
    }
    &--1-up,&--2-up, &--3-up, &--4-up{

      @include respond-min($small-breakpoint){
        .benefits_ce__list-item--1{
          border-top:none;
        }
      }

      @include respond-min($medium-breakpoint){
        .benefits_ce__list-item--2{
          border-top:none;
        }
      }

      @include respond-min($large-breakpoint){
        .benefits_ce__list-item--3{
          border-top:none;
        }
      }

      @include respond-min($xlarge-2-breakpoint){
        .benefits_ce__list-item--4{
          border-top:none;
        }
      }
    }

  }


  .fade-in {
    display: block;
    //bottom: 0;
    position: absolute;
    bottom:0;
    left: 0px;
    height: 170px;
    background: linear-gradient(to top, rgba($light-gray, 1) 0%,rgba($light-gray, 0) 100%);
    opacity: 1;
    width: 100%;
    transition: opacity ease-in-out 1s;


    @include respond-min($medium-breakpoint) {
      display:none;
    }
  }


  .benefits_ce__list-items--open .fade-in{
    opacity:0;
    transition: opacity ease-in-out 0.5s;
  }



  &__list-item {
    flex-basis:80%;
    display: flex;
    flex-flow: row nowrap;
    align-content: flex-start;
    align-items: center;
    justify-content: flex-start;
    padding: 12px 0;


    @include respond-min($medium-breakpoint) {
      flex-basis:46%;
      justify-content: space-between;
      border-top: 2px solid $lighter-gray;
      margin: 0 12px 0 0;
    }

    @include respond-min($large-breakpoint) {
      flex-basis:30%;
      padding: 15px 0;
      margin:0 10px;
    }

    @include respond-min($xlarge-breakpoint) {
      margin:0 15px;

    }

    @include respond-min($xlarge-2-breakpoint) {
      flex-basis: 23%;
      padding: 20px 0;
      margin:0;
    }

    @include respond-min($xxlarge-breakpoint) {
      margin:0;
    }


    &__check {
      display: table;
      max-width: rem-calc(40);
      width: rem-calc(40);
      height: rem-calc(40);
      border-radius: 50%;
      background: $check-green;
      margin-right: 18px;
      flex-basis: 20%;

      @include respond-min($large-breakpoint) {
        margin-right: 10px;
      }

        svg {
        display: table-cell;
        vertical-align: middle;
        margin: 0 auto;
        height: 40px;
      }
    }

    &__text,&__text > p {
      font-size: rem-calc(16);
      line-height: rem-calc(20);
      letter-spacing: 0;
      color: #030D26;
      font-weight: bold;
      text-align: left;
      margin-bottom: 0;

    }

    &__text{
      display: table-cell;
      vertical-align: middle;
      @include respond-min($medium-breakpoint) {
        flex-basis: 80%;
      }
    }

    &__show-more-link{
      padding-bottom: 4px;
      margin-top: 14px;
      font-size: rem-calc(16);
      line-height: rem-calc(20);
      font-weight: bold;
      cursor: pointer;
      position: relative;
      z-index: 999;
      opacity:1;

      &:active,&:focus,&:visited{
        color: var(--theme-color-1);
        border-color: var(--theme-color-1);
      }
    }

  }
  #benefits_ce__list-item__show-more{
    display: block;
    background: $light-gray;
  }
}
