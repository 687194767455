// ======================================================================
// Placeholders
// ======================================================================

// Contain Floats (this mixin inside of placeholder can fix the problem where @extend is not alllowed for mediaquery), e.g. @include respond-min($large-breakpoint) { @include clearfix;}

@mixin clearfix {
	&:before, &:after {
		content: " ";
		display: table;
	}
	&:after { clear: both; }
	*zoom : 1;
}

%clearfix {
	@include clearfix;
}

	// Graphic Text
@mixin graphic-text {
	font-size: 1px;
	overflow: hidden;
	text-indent: -3000px;
}

%graphic-text {
	@include graphic-text
}

	// Graphic Text
@mixin icon {
	font-family:"icons";
	width: 1em;
}

%icon {
	font-family:"icons";
	width: 1em;
}

// ======================================================================
// Utility Classes
// ======================================================================

.bottom-border-none {
	border-bottom: 0;
}





/* Typography =================================================== */



// %hyphens {
// 	// -ms-word-break: break-all;
// 	word-break: break-all;
// 	word-break: break-word;
//
// 	-webkit-hyphens	: auto;
// 	-moz-hyphens		: auto;
// 	-ms-hyphens			: auto;
// 	hyphens					: auto;
// }
//
// %text-overflow {
// 	overflow: hidden;
// 	text-overflow: ellipsis;
// 	white-space: nowrap;
// }

/* Colour =================================================== */

// Chrome Frame
// %chromeframe {
// 	margin : .2em 0;
// 	background : $light-grey;
// 	color : $black;
// 	padding : .2em 0;
// }

/* Layout =================================================== */


// %content-margins {
// 	margin: 0 $gutter-small;
// 	@include respond-min($bp-large) { margin: 0 $gutter-large; }
// 	@include respond-min($bp-xl) { margin: 0 $gutter-xl; }
// 	@include respond-min($bp-xxl) {
// 		max-width: $xxl-width;
// 		margin: 0 auto;
// 	}
// }

// For image replacement
// %hide-text {
// 		text-indent: 100%;
// 		white-space: nowrap;
// 		overflow: hidden;
// }

// Hide but maintain layout
// %invisible {
// 		visibility: hidden;
// }

// Hide from visual and speaking browsers
// %hidden {
// 		display: none !important;
// 		visibility: hidden;
// }

// Hidden but available to speaking browsers
// %visually-hidden {
// 	overflow: hidden;
// 	position: absolute;
// 	clip: rect(0 0 0 0);
// 	height: 1px;
// 	width: 1px;
// 	margin: -1px;
// 	padding: 0;
// 	border: 0;
// 	// Make visuallyhidden focusable with a keyboard
// 	&.focusable {
// 		&:active, &:focus {
// 			position: static;
// 			clip: auto;
// 			height: auto;
// 			width: auto;
// 			margin: 0;
// 			overflow: visible;
// 		}
// 	}
// }
