.ticket_experience_teaser{
  margin: 0 auto 10px;
  position: relative;
  width: 100%;
  background: none;
  max-width: 310px;
  //min-width:310px ;
  height: auto;
  min-height: 80px;
  box-shadow: 0 1px 3px 0 rgba(82,98,105,0.16), 0 7px 15px 0 rgba(0,0,0,0.12);
  border-radius:10px;

  @include respond-min($medium-breakpoint){
    margin: 0 auto 20px;
  }

  @include respond-min($xlarge-breakpoint){
    max-width: 360px;
    min-width:320px;
  }

  &:hover{

    .ticket_experience_teaser__title
    .icon-anglebracket-right {
      opacity: 1;
      //padding-left:5px;
      right: -5px;
      font-weight: 700;
      transition: all 0.3s ease-in-out;
    }

  }

  > a {
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 100%;
  }

  .ticket_experience_teaser__image-wrap {
    // desired width divided by parent width times 100% gives you desired percent width
    //width: 150 / 600 * 100%; comment out by Ben, because if we compare with the mobile mockup, 25% is narrower.
    //width: 185 / 600 * 100%;
    width:35%;
    // table-cell on the image gives the content something to lock up to
    display: table-cell;
    //flex-basis: 30%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    @include respond-min($small-breakpoint){
      //width:30%;
      width:36%;
    }
  }

  .ticket_experience_teaser__content {
    width: 65%;
    padding: 15px 10px 15px 28px;;
    display: table-cell;
    vertical-align: top;
    background: rgba(white,0.05);
    overflow: hidden;
    position: relative;

    @include respond-min($small-breakpoint){
      width:68%;
    }
  }

  .ticket_experience_teaser__title {
    color: $title-gray;
    line-height: rem-calc(20);
    margin-bottom:0;
      display: table;
      width: 100%;
      height: rem-calc(50);

    &-inner-wrap {
        display: table-cell;
        vertical-align: middle;
    }

    .icon-anglebracket-right {
      //position: absolute;
      //margin-top: 1px;
      color: $gray-highlight;
      opacity: 0.5;
      padding-left:0;
      font-weight: normal;
      transition: all 0.3s ease-in-out;
      display: table-cell;
      vertical-align: middle;
      width:22px;
      height: 22px;
      font-size:rem-calc(25);
      position: relative;
      right: 0;
    }
  }
}



.tickets-experiences-wrapper{
  max-width: 760px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: stretch;
  align-content: center;
  margin-bottom: 35px;

  @include respond-min($medium-breakpoint){
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: space-between;
    margin-bottom:10px;
  }


  @include respond-min($large-breakpoint){
    flex-flow: column wrap;
    justify-content: center;
    align-content: center;
  }


  @include respond-min(975px){
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: space-between;
  }



  & > div {
    //width: 50%;
    flex-basis: 90%;
    @include respond-min($medium-breakpoint){
      flex-basis: 48%;
    }
    @include respond-min($large-breakpoint){
      flex-basis: 90%;
    }

    @include respond-min(975px){
      flex-basis: 48%;
    }
  }
}

hr{
  border:none;
  margin: rem-calc(20 0 25);
  height:2px;
  background-color: $light-gray;
}
