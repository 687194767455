// ===================================================================
// Note that some CSS for the home and exhibit home is in containers partial
// ===================================================================

#skip-feature:before {
	display: block;
 	content: " ";
	height: $fixed-header-height;
  margin-top: -$fixed-header-height;
  visibility: hidden;
}

#content-wrap {
	max-width: rem-calc(1220);
	> .columns {
		padding-right: 30px;
		padding-left: 30px;
	}

	@include respond-min($xlarge-breakpoint) {
		min-height: rem-calc(700);
	}
}


#content-block-1, #sidebar {
	padding-bottom: 20px;
	@include respond-min($large-breakpoint) {
		padding-bottom: 90px;
	}
}

#sidebar {
	// padding: rem-calc(30) rem-calc(30);
	padding: 0 rem-calc(30) rem-calc(30);
	margin: 0 rem-calc(-30);
	> :nth-child(2):not(.button-table) {
		padding-top: rem-calc(30);
	}
	> :last-child:not(.button-table) {
		padding-bottom: rem-calc(30);
	}

	@include respond-min($large-breakpoint) {
		background-color: transparent;
		padding: 0;
		padding-bottom: 90px;
		margin: 0;
		min-width: 230px;
		max-width: 230px;
		> :nth-child(2):not(.button-table) {
			padding-top: 0;
		}
		> :last-child:not(.button-table) {
			padding-bottom: 0;
		}

		.nav-secondary {
			margin-bottom: rem-calc(20);
		}
	}

	@include respond-min($xlarge-breakpoint) {
		min-width: 260px;
	}
}

#f1a {
	&.exhibit.home {
		//background-color: $theme-color-2;
		//background: linear-gradient( $theme-color-3, $theme-color-2); ;

		& #page-inner-wrap{
			background: linear-gradient( $theme-color-3, $theme-color-2)!important;
		}

		.bh__height-size-4{
			.bh__content-wrap {
				height: rem-calc(200);
				@include respond-min($large-breakpoint){
					height: rem-calc(355);
				}
			}

			.bh__content {
				padding: 160px 30px 70px 30px;

				@include respond-min($large-breakpoint){
					padding: 210px 30px 45px 30px;
				}

				@include respond-min($xlarge-breakpoint){
					padding: 70px 30px 135px 30px;
				}
			}
		}
	}

	&.interior.bleed, &.home.bleed {
		#content-wrap {
			> .columns {
				padding-right: 0;
				padding-left: 0;
			}
		}
		#content-block-1 {
			padding-bottom: 0;
		}
	}

	&.interior.wide {
		.nav-secondary {
			margin-bottom: rem-calc(30);

			@include respond-min($large-breakpoint) {
				margin-bottom: 0;
			}
		}
		#content-block-1 {
			margin-left: 0;
			margin-right: 0;
		}

		.menu-container {

			@include respond-min($large-breakpoint) {
				margin-bottom: 60px;
			}
		}

		& .responsive-block-grid-3{
			//margin: 0 -.625rem;
			margin: 0 -20px!important;

			& > li{
				@include respond-min($medium-breakpoint){
					padding-left: 20px!important;
					padding-right: 20px!important;
				}

			}
		}

	}


	&.home.main {
		#content-block-1 {
			margin-top: 0;
		}
		#feature > .inner-wrap {
			max-height: rem-calc(700);
			overflow: hidden;
		}
	}
}

#f2a, #f2b {
	#content-inner-wrap {
		@include respond-min($large-breakpoint) {
			display: flex;
			justify-content: space-between;
		}
	}
	
	#content-block-1 {
		margin-top: rem-calc(45);
		@include respond-min($large-breakpoint) {
			margin-right: 40px;
		}

		@include respond-min(1220px) {
			//margin-right: 40px;
			margin-right: 140px;
			padding-left: 100px;
			margin-top: 85px;
		}
	}

}

.nav-secondary {
	// @include single-transition(all, $duration-short, ease-in-out);
	margin-top: rem-calc(30);
	margin-bottom: 20px;
	overflow: hidden;

	@include respond-min($large-breakpoint) {
		margin-bottom: 0;
	}

	.button-wrap {
		display: table-cell;
		width: 280px;
	}


	.button-table.add-button {
		float: right;
		display: none;
		margin: 0;
		width: 100%;
	}
	.container {
		margin-left: rem-calc(-15);
		margin-right: rem-calc(-15);
		margin-bottom: -1px;
		padding-bottom: 2px;
		@include respond-min($xsmall-breakpoint) {
			margin-left: rem-calc(-30);
			margin-right: rem-calc(-30);
		}
		@include respond-min($xlarge-breakpoint) {
			margin-left: 0;
			margin-right: 0;
		}

		// this width: 110% is added by Ben to hide the FireFox scroll bar, also, the overflow: hidden; is added to .nav-secondary in order to make the idea work. The idea is from http://stackoverflow.com/questions/20997183/how-to-hide-scrollbar-in-mozilla-firefox
		width: 110%;

		// overflow: auto;
		overflow-y: scroll; /* has to be scroll, not auto */
		-webkit-overflow-scrolling: touch;
 		&::-webkit-scrollbar {
			display: none;
		}
	}
	ul {
		margin: 0;
		padding: 0;
		padding-left: rem-calc(15);
		white-space: nowrap;
		position: relative;
		display: inline-block;

		@include respond-min($xsmall-breakpoint) {
			padding-left: rem-calc(30);
		}
		@include respond-min($large-breakpoint) {
            width: 100%;
		}
		@include respond-min($xlarge-breakpoint) {
			padding-left: 0;
		}

		li {
			list-style: none;
			padding-left: 0;
			text-indent: 0;
			white-space: nowrap;
			display: inline-block;
			&:before { content: none; }
			a {
				display: block;
				margin-right: rem-calc(35);
				margin-bottom: rem-calc(5);
				color: $theme-color-3;
				font-size: rem-calc(16);
				font-weight: normal;
				border-bottom: 2px solid rgba(255,255,255,0);
				padding: rem-calc(10 0 0);
				&:hover,
				&:focus {
					text-decoration: none;
					color: $theme-color-1;
				}
			}

			&:last-child a {
				margin-right: 0;
			}

			&.active, &.activeactive, &.current {
				a, a.active {
					color: $theme-color-3;
					font-weight: bold;
					padding-bottom: 4px;
					border-bottom: $border-link;
					margin-bottom: -2px;

					@include respond-min($large-breakpoint) {
						padding-bottom: 25px;
					}
					position: relative;
					display: inline-block;

					&:after{
						content: " ";
						width:100%;
						height:2px;
						background-color:$theme-color-1;
						position: absolute;
						bottom: 0;
						left: 0;
						box-shadow: 0px 5px 5px $theme-color-1;
						//box-shadow: 10px 5px 5px $theme-color-1;
					}
				}
			}
		}
		@include respond-min($large-breakpoint) {
			white-space: normal;
		}
	}

	& + #nav-secondary-placeholder {
		display: none;
	}

	@include respond-min($medium-breakpoint) {
		margin: rem-calc(54) 0 0;
	}
	@include respond-min($large-breakpoint) {

		&.stuck + #nav-secondary-placeholder {
			display: block;
		}


		&.stuck {
			@include transition(background-color $duration-short ceaser($easeInOutQuad), transform $duration-short ceaser($easeInOutQuad));
			background-color: $theme-color-3;
			position: fixed;
			min-width: 100%;
			margin-top: 0;
			z-index: 1500;
			top: 0;
			left: 0;
			animation-fill-mode: none;
			.container {
				display: table;
				width: 100%;
				max-width: rem-calc(1220);
				margin: 0 auto;
				min-height: rem-calc(80);
				padding:0 30px;
			}
			ul {
				display: table-cell;
				vertical-align: middle;
				width: (100% - 31.914893617%);
				padding-left: rem-calc(0);

				@include respond-min($xsmall-breakpoint) {
					padding-left: rem-calc(0);
				}

				@include respond-min($xlarge-breakpoint) {
					padding-left: 0;
				}

				a {
					color: $white;
					padding: rem-calc(5) 0 0 0;
					margin-bottom: 0;
					&:after{
						content: unset!important;
					}
				}


				li.active a, li.active a.current, li a.curren{
					padding-bottom: 0!important;
					border-bottom: 2px solid $theme-color-1;
					color: $white!important;
				}
			}

			.add-button, .button {
				display: table;
				width:280px;
				&:hover,
				&:focus {
					background-color: white;
					color: $theme-color-1 !important;
					.last-icon-cell {
					    border-left: 2px solid rgba(0,0,0,.1);
					    .icon {
						    color: $theme-color-1;
						}
					}
				}
			}

			.feature-footer__button {
				display: table-cell;
				vertical-align: middle;

				a.button{
					&:hover,
					&:focus {
						background-color: white;
						color: $theme-color-1 !important;
						span{
							color: $theme-color-1 !important;
						}
						.last-icon-cell {
							border-left: 2px solid rgba(0,0,0,.1);
							.icon {
								color: $theme-color-1;
							}
						}
					}
				}

			}
		}
		&.scrolling {
			// top: $fixed-header-height;
			transform: translateY($fixed-header-height-px + px);
		}
	}
}


#f1a.exhibit.home .nav-secondary {

	margin: 0 rem-calc(-15) rem-calc(40) 0;
	margin-bottom: rem-calc(0);


	@media only screen and (min-width:0) and (max-width: rem-calc(767))  {

        margin-top: rem-calc(-15);
        //margin-bottom: rem-calc(30);
        margin-bottom: 20px;

    }

	@include respond-min($xsmall-breakpoint) {
		margin-right: rem-calc(-30);
	}

	@include respond-min($large-breakpoint) {
		// overflow: visible;
		margin-right: 0;
		margin-bottom: 0;
		flex-basis: 78%;
	}

	@include respond-min($xlarge-breakpoint) {
		//margin-bottom: rem-calc(50);
		//width: rem-calc(600);
	}

	ul{

		li {
			a {
				color: white;
				font-weight: normal;
				margin-bottom: -2px;
				display:inline-block;
				&:hover,
				&:focus { color: $theme-color-1 !important; }
			}
			&.active a {
				//color: $theme-color-1;
				padding-bottom: 4px;
				font-weight: bold;

				@include respond-min($large-breakpoint) {
					padding-bottom: 25px;
				}

				&:after{
					border-bottom: $border-link;
					z-index: 1004;
				}
			}
		}
	}

	&.stuck {
		margin-top: 0!important;
	}
}

#f1a.exhibit.home{

	.lc__intro-content{
		.feature-text{
			font-size: rem-calc(25);
			line-height: rem-calc(32);
			margin-bottom: rem-calc(20);
			// margin-top: rem-calc(15);
			letter-spacing: -0.5px;
			font-family:$font-family-1;
			font-weight:bold;

			@include respond-min($large-breakpoint){
				font-size: rem-calc(32);
				line-height: rem-calc(38);
				margin-bottom: rem-calc(30);
			}
		}

		.rte-text-medium, .rte-text-large{
			font-size: rem-calc(18);
			line-height: rem-calc(24);

			@include respond-min($large-breakpoint){
				font-size: rem-calc(22);
				line-height: rem-calc(32);
			}
		}
	}

	.text-and-images{

		& .headline-group{
			margin-top:50px;

			@include respond-min($medium-breakpoint){
				margin-top:30px;
			}

			@include respond-min($large-breakpoint){
				margin-top:unset;
			}

		}

		.tai__image-group-wrap{
			margin:0 auto;
			@include respond-min($large-breakpoint){
				margin:0 auto 40px;
			}
		}

		& .rte-text-small p {
			font-size: 16px;
		}

	}

	.tai__image-left{

		.tai__image-group-wrap + .ce-content{
			@include respond-min($large-breakpoint){
				//padding-left: 25px;
				padding-left: 70px;
				width: 38%;
			}

		}
	}

	.feature-footer__button{
		&>a:hover,&>a:focus{
			background-color: white;
			color: $theme-color-1;

			.last-icon-cell {
				border-color: $theme-color-1-light;
			}

			.icon, .text-cell {
				color: $theme-color-1;
			}
		}
	}

}

#f2b.interior .nav-secondary {
	margin: 0 0 rem-calc(40);
}


#content-block-1 {
	margin-top: rem-calc(20);
}

#f1a.interior.bleed #content-block-1 {
	margin-top: 0;
}


#feature {
	min-height: rem-calc(90);
	background-color: $theme-color-2;
}

@include respond-min($large-breakpoint) {

	#feature {
		max-width: 100%;

		& > .inner-wrap {
			// margin-top: -180px;
			min-height: rem-calc(230);

		}
	}

	#content-block-1 {
		margin-top: rem-calc(60);
	}

	.style-guide #content-block-1 {
		margin-top: 0;
	}

	.inner-content-block-1 {
		width: 61.1702128%;
		float: left;
	}

	.inner-sidebar {
		width: 31.9148936%;
		float: right;
	}

	#f1a {
		#content-block-1 {
			// this fixes the IE padding issues
			margin-top: 0;

			.content-block-1-inner-wrap {
				padding: rem-calc(40 0 90);

				@include respond-min($xlarge-breakpoint) {
					padding: rem-calc(60 0 90);
				}
			}
		}
	}

	#f1a.bleed {
		#content-wrap {
			max-width: 100%;
		}

		#content-block-1 {
			margin: 0;
		}

		#content-wrap > .columns {
			padding-left: 0;
			padding-right: 0;
		}

		#footer {
			margin: 0;
		}
	}

	// @todo-ron For development only
	#f1a.dev-bare {

		#header, #footer {
			display: none;
		}
	}

	#f1a.interior.bleed {
		.nav-secondary {
			margin-left: auto;
			margin-right: auto;
			width: rem-calc(940);
		}
	}

	#f2b {
		background: image-url("f2b-content-wrap-bg.png") center top repeat-y;

		#content-wrap {
			&>div {
				display: table;
				table-layout: fixed;
			}
		}

		#content-block-1 {
			padding-right: 5.85106383%;
			display: table-cell;
			vertical-align: top;
			padding-top: rem-calc(60);
		}

		#sidebar {
			width: 31.914893617%;

            @include respond-min($large-breakpoint) {
				background-color: #F0F0F0;
			}

			@include respond-min($large-breakpoint) {
				width: 31.4%;
			}
			@include respond-min($xlarge-breakpoint) {
				width: 31.914893617%;
			}

			display: table-cell;
			vertical-align: top;
			padding-left: 20px;
			padding-top: rem-calc(60);
		}
	}
}


@include respond-min($xlarge-breakpoint) {

	#f2b {
		#sidebar {
			padding-left: 60px;
		}
	}

}



