@import '~plyr';

.plyr button {
  margin-bottom: 0;
}

//https://github.com/sampotts/plyr/issues/976
.plyr iframe {
  transition: 0.2s filter linear;
}

.plyr.plyr--paused iframe {
  filter: blur(1.5rem);
}


:root{
  --plyr-color-main: var(--theme-color-1);
}


.plyr__controls{
  display:none;
  .plyr--playing & {
    display: flex;
  }
}