	// ======================================================================
	// DEVELOPMENT
	// Styles that are used in development but not used in production
	// ======================================================================

.dev-ron-test {
	@include reset-ul;
}

.dev-info-text {
	color: red;
}

.development-header {
	margin-bottom: rem-calc(50);
	background-color: $theme-color-3;
	color: white;

	h1,h2, h3, h4, h5, h6 {
		color: white;
	}

	a {
		color:	white;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}
}

.dev-basecamp {
	display: block;
	font-style: italic;
	font-size: 1em;
}

.dev-menu {
	line-height: 1.2;
 	margin: 0;
	padding: 0;

	li {
		margin-bottom: rem-calc(10);

		&:before {
			content: none;
		}

	}

	a {

		&:hover,
		&:focus {
			color: $theme-color-2;
			text-decoration: none;
		}
	}

	.title {
		font-weight:  bold;
	}
}

.swatch-box {
	height: rem-calc(80);
	margin-bottom: rem-calc(30);
}

.swatch-box-1a {
	background-color: $theme-color-1;
}

.swatch-box-1b {
	background-color: $theme-color-2;
}

.swatch-box-1b-lighter {
	background-color: $blue-gray;
}

.swatch-box-1c {
	background-color: $theme-color-3;
}

.swatch-box-light-gray {
	background-color: $light-gray;
}

.swatch-box-2 {
	background-color: $color-2;
}
