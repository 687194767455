// ======================================================================
// Responsive Block Grid
// ======================================================================

@use "sass:math";

[class*="responsive-block-grid-"] {
	// @todo-upgrade Changed original code for sass deprecation.  Needs testing. http://bit.ly/ExtendCompound
	// @extend ul.no-bullets;
	@extend .no-bullets;

	> li {
		width: 100%;
	}
	@include respond-min($small-breakpoint) {
		&.responsive-block-grid-1 {
			@include block-grid(1);
		}
		@include block-grid(2);
	}
	@include respond-min($medium-breakpoint) {
		&.responsive-block-grid-3 {
			@include block-grid(3);
		}
		&.responsive-block-grid-4, &.responsive-block-grid-5, &.responsive-block-grid-6 {
			@include block-grid(4);
		}
	}
	@include respond-min($large-breakpoint) {
		&.responsive-block-grid-5 {
			@include block-grid(5);
		}
		&.responsive-block-grid-6 {
			@include block-grid(6);
		}
	}
}

.responsive-block-grid-3.feature-block-grid {
	@include respond-min($small-breakpoint) {
		@include grid-row();
		> li {
			@include grid-column($total-columns*0.5);
			&:first-of-type {
				@include grid-column($total-columns);
			}
			&:nth-of-type(2n+1) {
				clear: none;
			}
		}
	}
	// @include respond-min($medium-breakpoint) {
	// 	> li {
	// 		@include grid-column($total-columns/3);
	// 		&:first-of-type {
	// 			@include grid-column(($total-columns/3)*2);
	// 			width: 100%;
	// 		}
	// 		&:nth-of-type(0n+5) {
	// 			clear: left;
	// 		}
	// 		&:nth-of-type(3n+1) {
	// 			clear: none;
	// 		}
	// 	}
	// }
	@include respond-min($large-breakpoint) {
		> li {
			@include grid-column(math.div($total-columns, 3));
			&:first-of-type {
				@include grid-column(math.div($total-columns, 3)*2);
			}
			&:nth-of-type(0n+5) {
				clear: none;
			}
			&:nth-of-type(0n+3) {
				clear: left;
			}
			&:nth-of-type(3n+1) {
				clear: none;
			}
		}
	}
}

[class*="responsive-block-grid-"].teaser-block-grid {

	// @todo-upgrade Changed original code for sass deprecation.  Needs testing. http://bit.ly/ExtendCompound
	// @extend ul.no-bullets;
	@extend .no-bullets;

	> li {
		width: 100%;
	}
	@include respond-min($small-breakpoint) {
		&.responsive-block-grid-1 {
			@include block-grid(1);
		}
		@include block-grid(1);
	}
	@include respond-min(em-calc(540)) {
		&.responsive-block-grid-2 {
			@include block-grid(2);
			margin:0 -20px;

			&>li{
				padding-left: 20px;
				padding-right:20px;
			}
		}
		/*&.responsive-block-grid-3 {
			@include block-grid(2);
		}

		 */
		&.responsive-block-grid-4, &.responsive-block-grid-5, &.responsive-block-grid-6 {
			@include block-grid(4);
		}
	}

	@include respond-min(em-calc(600)) {
		&.responsive-block-grid-3 {
			@include block-grid(2);
			margin:0;

			&>li{
				padding-left: 20px;
				padding-right:20px;
			}
		}
	}

	@include respond-min(em-calc(900)) {
		&.responsive-block-grid-3 {
			@include block-grid(3);
			//margin:0 -30px;
			margin:0 -20px;

			&>li{
				padding-left: 20px;
				padding-right:20px;
			}
		}
	}

	@include respond-min($large-breakpoint) {
		&.responsive-block-grid-5 {
			@include block-grid(5);
		}
		&.responsive-block-grid-6 {
			@include block-grid(6);
		}
	}
}

.responsive-block-grid-3.teaser-block-grid.feature-block-grid {
	@include respond-min(em-calc(540)) {
		@include grid-row();
		> li {
			@include grid-column($total-columns*0.5);
			&:first-of-type {
				@include grid-column($total-columns);
			}
			&:nth-of-type(2n+1) {
				clear: none;
			}
		}
	}
	@include respond-min($large-breakpoint) {
		> li {
			@include grid-column(math.div($total-columns, 3));
			&:first-of-type {
				@include grid-column(math.div($total-columns, 3)*2);
			}
			&:nth-of-type(0n+5) {
				clear: none;
			}
			&:nth-of-type(0n+3) {
				clear: left;
			}
			&:nth-of-type(3n+1) {
				clear: none;
			}
		}
	}
}
