/* Header =================================================== */
@use "sass:math";
@include respond-min($large-breakpoint) {
  .move-left > .inner-wrap {
    transform: none;
  }

}

#header-outer-wrap {
  // @include transition(top $duration-short ease);
  @include transition(transform $duration-short ceaser($easeInOutQuart));
  position: absolute;
  width: 100%;

  @include respond-min($large-breakpoint) {
    &.offscreen {
      position: fixed;
      top: -($fixed-header-height-px + px);
      z-index: 1700;
      height: $fixed-header-height-px + px;
      overflow: hidden;
      background-color: $theme-color-2;

      #header {
        .site-id {
          margin-top: 6px;
          width: 170px;
          height: 49px;
        }

        .nav-utility {
          display: none;
        }

        .nav-global__list {
          margin-top: rem-calc(12);
        }
      }
    }
    &.stuck {
      transform: translateY($fixed-header-height-px + px);
      box-shadow: 0 5px 2px rgba($black, 0.4);

      .keyboard-open-menu,.icon-anglebracket-down{
        display: none;
      }
    }

  }
}

body.translation-bar-on {
  #header-outer-wrap.stuck {
    padding-top: rem-calc(40);
    height: ($fixed-header-height-px + 40) + px;
  }
}

#header {
  z-index: 300;
  position: relative;
  padding-top: rem-calc(20);
  max-width: 1220px;

  > .columns {
    padding-right: 30px;
    padding-left: 30px;
  }

  // Mega Menu Columns general styling
  .column-wrapper {
    display: flex;
    flex-flow: row-reverse wrap;
    justify-content: space-between;
    background: white;
    padding: 40px 40px 0 45px;
    border-radius: 5px;

    &__column {
      flex-basis: 49%;

      h3 {
        margin-bottom: 5px;
        margin-top: 4px;
        letter-spacing: -0.5px;
        font-size: rem-calc(22px);
      }

      p {
        font-size: rem-calc(16px);
        line-height: rem-calc(25px);
        margin-bottom: 5px;
        color: $text-gray;
      }
    }

    & .sticky-footer__submenu__item {
      margin-bottom: 15px;
    }
  }

  a {
    color: white;

    &:hover {
      text-decoration: underline;
    }

    &:focus {
      text-decoration: underline;

      .site-id {
        text-decoration: none;
      }
    }
  }

  a[href="/"]:hover,
  a[href="/"]:focus {
    text-decoration: none;
  }

  li {
    &:before {
      content: none;
    }

    padding-left: 0;
    text-indent: 0;
  }

  .site-id {
    margin: 0 0 10px;
    width: 170px;
    height: 49px;
    float: left;
    background: transparent image-url("logos/griffin_msi_horizontal_logo_white_rgb.svg") no-repeat center;
    font-size: 19px;
    line-height: 1em;
    font-weight: 400;
    color: transparent;
    text-indent: 0px;
  }

  .mobile-menu {
    float: right;
    background: transparent image-url("mobile-menu.png") no-repeat center;
    background-size: 32px auto;
    width: 32px;
    height: 25px;
    margin-top: 5px;
  }

  .nav-utility,
  .nav-global {
    display: none;

    .content {
      border-radius: 5px;
    }
  }

  #my-visit-opener.button {
    float: right;
    display: block;
    width: rem-calc(75);
    opacity: 0;
    margin-left: rem-calc(7);
    padding: rem-calc(12 10);
    background: $theme-color-1;
    color: white;
    transition: color 0.3s ease-out, background-color .3s ease-out;

    &:hover,
    &:focus {
      cursor: pointer;
      background-color: white;
      color: $theme-color-1;
    }

    .icon-add-to-visit {
      font-size: rem-calc(23);
      display: block;
      margin: rem-calc(-2 6 -7 0);
    }

    .first-icon-cell {
      float: left;
    }

    .text-circle {
      margin: rem-calc(-3 0 -9);
      font-size: rem-calc(13);
      background: $theme-color-2-theme-color-1--mix-15;
      border-color: $theme-color-2-theme-color-1--mix-15;
      transition: background-color .3s ease-out, border-color .3s ease-out;

      .text-wrap {
        $size: 1.4;
        font-size: $size + em;
        line-height: $size + em;
        width: (2 / $size) + em;
        height: (2 / $size) + em;
        @media screen \0
        {
          line-height: 1em;
        }
      }

      .text-inner-wrap {
        font-size: 1.1em;
        font-weight: 700;
        color: white;
        font-size: rem-calc(13);
        line-height: rem-calc(13);
        vertical-align: middle;
      }
    }
  }

  .nav-global__button {
    border-radius: 0 0 5px 5px;
  }

  // Responsive
  @include respond-min($large-breakpoint) {
    padding-left: 0;
    padding-right: 0;

    .nav-utility,
    .breadcrumb-share-wrap {
      display: block;
    }

    #header {
      padding-top: 15px;
    }

    .site-id {
      margin: 10px 0 0;
    }

    .nav-utility {
      @include reset-ul;
      float: right;
      overflow: visible;
      font-size: rem-calc(12);
      line-height: rem-calc(12);
      margin-bottom: rem-calc(29);

      > li {
        margin-left: 15px;
        border-right: 1px solid rgba(255, 255, 255, 0.5);
        padding-right: 15px;

        &:nth-last-child(2) {
          border-right: none;
          padding-right: 0;
        }

        &:last-child {
          margin-left: 30px;
          border-right: none;
          padding-right: 0;
          @include opacity(1);

          a {
            width: 20px;
            height: 20px;
            border-radius: 20px;
            padding: 2px;
            margin: -2px;

            &:focus {
              box-shadow: 0px 0px 10px $white;
            }

            .hidden {
              @include graphic-text;
              display: block;
            }

            &:before {
              @include icon-search;
              font-family: 'icons';
              font-weight: 400;
              float: left;
              width: rem-calc(16);
              font-size: rem-calc(23);
              line-height: 1;
              display: block;
              padding: 1px;
            }
          }
        }
      }

      a {
        color: rgba(white, .8);
        font-weight: 600;
      }
    }

    .nav-global {
      clear: right;

      &__list {
        @include reset-ul;
        float: right;
        font-size: rem-calc(16);
        line-height: rem-calc(20);
        margin-bottom: rem-calc(14);
        position: relative;
        margin-top: rem-calc(29);
      }

      &__list li {
        float: left;
        margin-left: rem-calc(35);
        margin-top: rem-calc(11);
        font-weight: 700;
        padding-right: 20px;

        a {
          text-decoration: none;
          padding-bottom: rem-calc(9);
        }

        a.keyboard-open-menu {
          margin-top: -27px;
          margin-right: -25px;

          &:hover .icon, &:focus.icon {
            color: white;
          }

          .icon-anglebracket-down {
            width: rem-calc(21);

            &:before {
              font-size: rem-calc(21);
            }
          }
        }

        &.nav-global__item--search {
          margin-left: 30px;
          border-right: none;
          padding-right: 0;
          @include opacity(1);

          a {
            width: 20px;
            height: 20px;
            border-radius: 20px;
            padding: 2px;
            margin: -6px 0 -6px -6px;

            &:focus {
              box-shadow: 0px 0px 10px $white;
            }

            .hidden {
              @include graphic-text;
              display: block;
            }

            &:before {
              @include icon-search;
              font-family: 'icons';
              font-weight: 400;
              float: left;
              width: rem-calc(23);
              font-size: rem-calc(23);
              line-height: 1;
              display: block;
              padding: 1px;
            }
          }
        }

      }

      #magic-line {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100px;
        height: 3px;
        background: $white;
      }

      .button-group {
        transition: width 1s ease;
        margin-top: 0;
        margin-left: rem-calc(40);
        padding-right: 0;

        .tickets {
          float: right;
          border-bottom: none;
          transition: padding 0.3s ease-out,
          background-color .3s ease-out;
          padding: {
            top: 11px;
            left: 28px;
            right: 28px;
            bottom: 11px;
          }
        }

        &.my-visit-button-appear {
          .tickets {
            float: left;

            .first-icon-cell {
              display: none;
            }

            .text-cell {
              display: block;
              padding-right: 0;
              text-align: center;
            }

          }
        }

        #my-visit-opener.button {
          opacity: 1;
        }
      }

      .button {
        background-color: white;
        background-color: rgba(white, 0.15);
        color: $theme-color-1--darken-15;
        padding: 10px 27px;
        margin-bottom: 0;
        text-shadow: none;
        font-weight: 700;
        border-radius: 5px;
        color: #ffffff;

        &:hover,
        &:focus {
          background-color: $theme-color-1;
          color: white;
          text-decoration: none;
          border-bottom: none;
        }
      }

      .nav-global__button {
        display: block;
        width: 100%;
        font-weight: bold;
        padding: 20px;
        text-align: center;
        margin: auto;

        & .icon-arrow-right {
          font-size: rem-calc(20);
          margin-right: 0;
          font-weight: 200;
          padding-left: 5px;
          position: relative;
          top: 4px;
        }

        &:hover {
          color: $theme-color-2;

          .icon-arrow-right {
            color: $theme-color-2;
          }
        }
      }

      ////////////////////////////
      ////////// mega menu ///////
      ////////////////////////////
      .content {
        position: absolute;
        top: 90px;
        display: none;
        background-color: #ffffff;

        h3.title {
          color: $theme-color-2;
        }

        a {
          color: $theme-color-1;

          &:hover,
          &:focus {
            text-decoration: none;
            color: $theme-color-2 !important;
            fill:$theme-color-2;

            svg.visit-svg g rect,.svg-visit-label{
              fill:$theme-color-2;
              color:$theme-color-2;
            }
          }

          .svg-visit-label{
            margin-top: 10px;
            display: inline-block;
          }
        }

        .dropdown-arrow-list {
          float: left;
          min-height: 100%;

          ul {
            @include reset-ul;

            li {
              border-bottom: none !important;
              display: table;

              &:before {
                font-family: icons;
                @include icon-anglebracket-right;
                color: $theme-color-1;
                display: table-cell;
                vertical-align: middle;
                font-weight: bold;
              }

              &:hover {

                &:before, a, img.visit-svg {
                  color: $theme-color-2 !important;
                }

                &:before {
                  text-indent: 5px;
                }
              }

              a {
                color: $theme-color-1;
                font-size: rem-calc(18);
                line-height: rem-calc(22);
                font-weight: bold;
                padding: rem-calc(8 0);
                display: table-cell;

                &:hover,
                &:focus {
                  color: $theme-color-1 !important;
                  text-decoration: none;
                }

              }
            }
          }
        }

        .dropdown-arrow-list-sub {
          float: left;
          width: 49%;
          padding: rem-calc(30) 0;

          ul {
            display: none;

            &.open {
              display: block;
            }
          }

          li {
            a {
              font-size: rem-calc(16);
              font-weight: 700;
              color: $theme-color-1 !important;
              text-decoration: none;

              &:hover,
              &:focus {
                color: $theme-color-2 !important;
                text-decoration: none;
                fill:$theme-color-2;

                svg.visit-svg g rect,.svg-visit-label{
                  fill:$theme-color-2;
                  color:$theme-color-2;
                }
              }

              .svg-visit-label{
                margin-top: 10px;
                display: inline-block;
              }
            }
          }

          ul {
            list-style-type: none;
            margin-left: rem-calc(40);

            & > li {
              text-indent: rem-calc(-40);
              padding: 0 rem-calc(40) rem-calc(10);

              &:before {
                @include icon-anglebracket-right;
                @include icon;
                float: left;
                color: $theme-color-1 !important;
                width: rem-calc(40);
              }

              &:hover,
              &:focus {
                &:before {
                  text-indent: rem-calc(-35);
                }
              }
            }
          }

          // end of todo-ben
        }

        &.explore {
          //the width is supposed to be 705, yet that brings a bug in IE9/10/11, adding one more pixel fixes the probelm
          width: rem-calc(560);
          right: 250px;

          @include respond-min($xlarge-breakpoint) {
            right: rem-calc(30);
          }

          .arrow-up {
            @include triangle(20px, $light-gray, up);
            margin: {
              top: -10px;
              right: rem-calc(490);
            }
            float: right;

            body.my-visit-button-shown & {
              margin-right: rem-calc(318 + 82);
            }
          }
        }

        &.visit {
          width: 654px;
          right: 20px;
          background-color: white;
          @include respond-min($xlarge-breakpoint) {
            right: rem-calc(30);
          }

          .arrow-up {
            @include triangle(20px, $white, up);
            margin: {
              top: -10px;
              right: rem-calc(390);
            }
            float: right;

            body.my-visit-button-shown & {
              margin-right: rem-calc(243 + 82);
            }
          }

          .column-wrapper {
            padding: 40px 40px 25px 20px;
          }

          .column-1 {
            flex-basis: 31%;
          }

          .column-2 {

            flex-basis: 65%;
          }

          .visit-menu__link-label {
            color: $theme-color-1 !important;
            &:hover {
                color: $theme-color-2 !important;
            }
          }

          .visit-svg-wrap {
            display: inline-block;
            width: 84px;
            height: 84px;
            background: $light-gray;
            border-radius: 50%;
            text-align: center;

            .visit-svg {
              display: inline-block;
              width: 32px;
              height: 32px;
              margin-top: 25px;

              .primary-color {
                fill: var(--theme-color-1, $theme-color-1);
              }

              &:hover .primary-color {
                fill: var(--theme-color-2, $theme-color-2);
              }
            }
          }

          ul {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            align-content: center;
            align-items: center;

            & li {
              display: block;
              flex-basis: 30%;
              text-align: center;

              &:before {
                content: "";
              }

              a {
                font-size: rem-calc(16);
                line-height: rem-calc(20);
                display: inline-block;
                padding: 8px 5px;
              }
            }
          }
        }

        // @todo-upgrade Support class was original.  Just did a quick hack to add educate.
        &.educate,
        &.support {

          width: 560px;
          right: 20px;

          @include respond-min($xlarge-breakpoint) {
            right: rem-calc(30);
          }

          .arrow-up {
            @include triangle(20px, $white, up);
            float: right;
            margin: {
              top: -10px;
              right: rem-calc(275);
            }

            body.my-visit-button-shown & {
              margin-right: rem-calc(166 + 82);
            }
          }
        }

        &.educate {
          .column-wrapper {
            padding: 40px 20px 25px 45px;

            .column-1 {
              flex-basis: 55%;
            }

            .column-2 {
              flex-basis: 45%;
            }
          }
        }


        // @todo-upgrade Only defined support here to shift it farther to right.
        &.support {
          padding-bottom: 25px;
          @include respond-min($xlarge-breakpoint) {
            right: rem-calc(30);
          }
        }
      }
    }
    ////////////////////////////
    //////end of mega menu ///////
    ////////////////////////////
    .breadcrumb-share-wrap {
      clear: both;
      @include clearfix;
      border-top: 2px solid rgba(255, 255, 255, 0.2);
      padding-top: rem-calc(15);
      font-size: rem-calc(13);
      color: white;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: rem-calc(1);
      @include single-text-shadow(0, 1px, 0px, 0px, rgba(0, 0, 0, 0.7));

      @include respond-min($xlarge-breakpoint){
        padding-top: rem-calc(25);
        font-size: rem-calc(16);

      }
      .icon {
        font-size: rem-calc(16);
        margin-right: 0;
        vertical-align: middle;
        width: rem-calc(20);
        height: rem-calc(20);
        color: $theme-color-1;
        &:hover,
        &:focus {
          text-decoration: none;
          border-bottom: none !important;
          color: #ffffff;
        }
      }

      .breadcrumb__link {
        display: inline;
        margin-right: rem-calc(8);
        text-transform: capitalize;
        font-size: rem-calc(13);
        line-height: rem-calc(20);
        color: #D8D8D8;

        @include respond-min($large-breakpoint){
          font-size: rem-calc(16);
        }

        &:hover,
        &:focus {
          text-decoration: none;
          border-bottom: none !important;
          padding-bottom: rem-calc(4);
          color: #ffffff;
        }
      }
    }

    .breadcrumb {
      //New redesign shows breadcrumbs on mobile display:none;
      display:inline-block;
      float: left;

      @include respond-min($xlarge-breakpoint){
        display:inline-block;
        float: left;
      }
    }

    .share {
      float: right;
      font-size: rem-calc(8);

      a {
        .icon {
          font-size: 12px;
          vertical-align: middle;
          margin-left: 25px;
          margin-right: 8px;
        }

        &:hover,
        &:focus {
          text-decoration: none;

          span {
            border-bottom: 1px solid white;
            padding-bottom: rem-calc(4);
          }

          .icon {
            color: $theme-color-1-text-light;
          }
        }
      }
    }
  }
  @include respond-min($xlarge-breakpoint) {
    .nav-global {
      display: block;
    }

    .site-id {
        margin: 10px 0 27px;
    }

    .mobile-menu {
      display: none;
    }

  }

  // Breadcrumbs + share
  .breadcrumb-share-wrap {
    clear: both;
    @include clearfix;
    padding-top: rem-calc(25);
    font-size: rem-calc(16);
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: rem-calc(1);
    @include single-text-shadow(0, 1px, 0px, 0px, rgba(0, 0, 0, 0.7));
    border: none;

    @include respond-min($xlarge-breakpoint) {
      border-top: 2px solid rgba(255, 255, 255, 0.2);
    }

    .icon {
        position: relative;
        top: rem-calc(1);
      font-size: rem-calc(16);
      margin-right: 0;
      vertical-align: middle;
      width: rem-calc(20);
      height: rem-calc(20);
      color: $theme-color-1-alt;
    }

    .breadcrumb__link {
      //New redesign shows breadcrumbs on mobile display:none;
      display: inline;
      margin-right: rem-calc(8);
      text-transform: capitalize;
      font-size: rem-calc(13);
      line-height: rem-calc(16);
      color: #D8D8D8;
      @include respond-min($xlarge-breakpoint){
        display: inline;

      }

      @include respond-min($xlarge-breakpoint) {
        font-size: rem-calc(16);
        line-height: rem-calc(20);
      }

      &:hover,
      &:focus {
        text-decoration: none;
        // border-bottom: 1px solid white;
        // padding-bottom: rem-calc(4);
        .icon {
            color: #ffffff;
        }
      }
    }
  }

  .breadcrumb {
    float: left;
  }

  .share {
    float: right;
    font-size: rem-calc(8);

    a {
      .icon {
        font-size: 12px;
        vertical-align: middle;
        margin-left: 25px;
        margin-right: 8px;
      }

      &:hover,
      &:focus {
        text-decoration: none;

        span {
          border-bottom: 1px solid white;
          padding-bottom: rem-calc(4);
        }

        .icon {
          color: $theme-color-1-text-light;
        }
      }
    }
  }

  // Top Right Menu
  #topright-menu .topright-menu {
    &__list {

      &__item {
        font-size: rem-calc(14) !important;
        line-height: rem-calc(20) !important;
        font-weight: normal !important;
        letter-spacing: 0;

        &:hover {
          border-bottom: none;
          padding-bottom: 0;

          a {
            color: rgba(255, 255, 255, 0.5);
          }
        }

        &.current {
          a {
            color: rgba(255, 255, 255, 0.5);
          }
        }

        &--read-more {

          .read-more {
            padding-right: 20px;
            position: relative;
            display: inline-block;
            margin-right: 0;
            cursor: pointer;
          }

          .read-more--etc {
            font-weight: bold;
            position: absolute;
            bottom: 5px;
            right: -5px;
            font-size: 27px;
            letter-spacing: 0;
          }

          &.open {
            border: none;
            padding-bottom: 0;


            .arrow-up {
              border-bottom: 10px solid white;
              border-left: 10px solid transparent;
              border-right: 10px solid transparent;
              margin-top: 0;
              margin-right: 0;
              margin-left: 6px;
              position: absolute;
              bottom: -1px;
              opacity: 1;
              transition: opacity .4s ease-in;
            }

            .topright-menu__list--read-more__list {
              display: block;
              margin-top: 18px;
              margin-left: 0;
              position: absolute;
              right: 30px;
              background: white;
              border-radius: 10px;
              height: auto;
              width: auto;
              min-width: 153px;
              padding: 20px;
              opacity: 1;
              transition: height .35s ease-in, width .35s ease-in, opacity .4s ease-in;


              .topright-menu__list__item {
                display: block;
                padding: 4px;

                a {
                  color: $theme-color-1;
                  font-weight: bold;
                  padding: 0.5rem 0;
                  font-size: 16px;
                }

                &:hover {
                  a {
                    color: $theme-color-2;
                  }
                }
              }
            }
          }
        }
      }

      .arrow-up {
        opacity: 0;
        transition: opacity .4s ease-out;
      }

      &--read-more__list {
        display: block;
        height: 0;
        padding: 0;
        width: 0;
        min-width: 0;
        opacity: 0;
        transition: height .35s ease-out, width .35s ease-out, opacity .2s ease-out;
      }


      @include respond-max($xlarge-breakpoint) {
        display: none;
      }
    }

  }

}

.reveal-modal-bg {
  background: $reveal-overlay-bg;
}

// new feature-footer
.feature-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding-top: 25px;
  z-index: 100;
//   background: $theme-color-3;
  &.no-hours {
    padding-top: 0;
  }

  @include respond-min($large-breakpoint) {
    background: transparent;
    padding-bottom:25px;
    min-height: 100px;
  }

  .inner-wrap {
    position: relative;
    max-width: rem-calc(1220);
    margin: 0 auto;
    display: block;

    @include respond-min($large-breakpoint) {
      border: none;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__info {
    flex-basis: 65%;
    margin: 0 30px;
    padding-bottom: 30px;
    border-color: rgba(#C7D3D8, 0.3);
    // border-bottom: 1px solid;
    @include respond-min($large-breakpoint) {
      padding-bottom: 0;
      border: none;
    }
    // @include respond-min($xlarge-2-breakpoint) {
    //     margin-left: 0;
    // }
    .feature-footer.no-hours & {
        padding-bottom: 0;
    }

  }

  &__button {
    flex-basis: 100%;
    @include respond-min($large-breakpoint) {
      flex-basis: 33%;
    }

    @include respond-min(1176px) {
      flex-basis: 31.914893617%;
    }

    & > a.button{
      margin-top:0!important;
      max-width: 100%;
      width: 100%;
      border-radius:0!important;

      @include respond-min($large-breakpoint) {
        border-radius: 5px!important;
        width: 260px;
        max-height:55px;
        padding:rem-calc(0 0 0 18)!important;
        margin-right: 1em;
      }

      @include respond-min(1200px) {
        margin-right: 0;

      }

      & .text-cell{

        font-size:rem-calc(22);

        @include respond-min($large-breakpoint) {
          font-size:rem-calc(18);
        }
      }

    }
  }


  &__open-hours {
    color: white;
    font-size: rem-calc(16);
    line-height: rem-calc(20);
    padding-left: 0;
    // text-align: center;
    // margin: 0 30px;


    @include respond-min($large-breakpoint) {
    //   padding-left: 1em;
      display: inline-block;
    //   text-align: left;
      margin-top: 0;
    //   margin-left: 15px;
      margin-right: rem-calc(0);
      display:block;
    }

    @include respond-min($xlarge-breakpoint) {
        display: inline-block;
    }

    // @include respond-min(915px) {
    //   display:inline-block;
    //   width: auto;
    // }


    // @include respond-min(1160px) {
    //   margin-left: 0;
    //   padding-left: 25px;
    //   margin-right: rem-calc(20);
    // }


    // @include respond-min(1180px) {
    //   padding-left: 20px;
    // }


    // @include respond-min(1200px) {
    //   padding-left: 0;
    // }


    .hours-description {
    //   color: var(--theme-color-3-text-light);
    //    @include opacity(.65);
      font-size: rem-calc(16);
      line-height: rem-calc(20);
      font-weight: 700;
      margin-top: rem-calc(5);
      text-align: left;

      @include respond-min($large-breakpoint) {
        color: white;
        text-align: left;
      }

      &__timetable {
        display: block;
        font-size: rem-calc(14);
        font-weight: normal;
        @include opacity(.65);
        @include respond-min($small-breakpoint) {
            display: inline;
            font-size: rem-calc(16);
        }
        @include respond-min($large-breakpoint) {
          color: white;
        }
      }
    }


    .icon {
      color: $theme-color-1;
      margin-left: -1.5em;
      margin-right: 0.5em;
      position: relative;
      top: rem-calc(1);
    }

  }

  &__link {
    @include respond-min($large-breakpoint) {
      display: inline-block;
      font-size: rem-calc(16);
      line-height: rem-calc(20);
      font-weight: 700;
      text-align: center;
    //   margin: 0 15px 0 0;
    //   padding-right: 1em;
        margin-right: 30px;
    }

    @include respond-min(915px) {
    //   margin-left: 15px;
    //   margin-top:0;
    //   padding-left: 0;
    }

    // @include respond-min(1050px) {
    //   margin: 0 20px;
    // }

    a {
      color: #ffffff;
      font-weight: bold;
      padding-bottom: 5px;
      border-bottom: 2px solid transparent;
      transition: color 0.4s ease-out, border-color 0.4s ease-out;

      &:hover, &:focus {
        border-bottom-color: white;
      }

    }

    &-wrap{
      display: none;
      @include respond-min($large-breakpoint){
        display:block;
        margin-top: rem-calc(10);
        // margin-left: 15px;
        // padding-left: 1em;
      }

      @include respond-min($xlarge-breakpoint){
        display:inline-block;
        margin-top: 0;
        padding-left: rem-calc(30);
      }
    }

  }
}


  .carousel-container--with-arrows ~ .feature-footer {
    .inner-wrap {
      @include respond-min($large-breakpoint) {
        justify-content: flex-start;
      }
    }


  }

  #feature .inner-wrap {
    // background: $theme-color-3;

    @include respond-min($large-breakpoint) {
      background: transparent;
    }
  }

  .first-theme-class {

    #header .nav-global .button-group #my-visit-opener.button,
    #header .nav-global .button-group #my-visit-opener.button .text-circle {
      transition-duration: 0s;
    }
  }

