// ======================================================================
// My Visit
// ======================================================================
.delay-children {
    $delay: 0.33;
    $delay-curve: 1.15;
    $delay-min: 0.0;
    > :nth-child(1) {
        transition-duration: $delay + $delay-min+ s;
    }
    $delay: $delay * $delay-curve;
    > :nth-child(2) {
        transition-duration: $delay + $delay-min+ s;
    }
    $delay: $delay * $delay-curve;
    > :nth-child(3) {
        transition-duration: $delay + $delay-min+ s;
    }
    $delay: $delay * $delay-curve;
    > :nth-child(4) {
        transition-duration: $delay + $delay-min+ s;
    }
    $delay: $delay * $delay-curve;
    > :nth-child(5) {
        transition-duration: $delay + $delay-min+ s;
    }
    $delay: $delay * $delay-curve;
    > :nth-child(6) {
        transition-duration: $delay + $delay-min+ s;
    }
    $delay: $delay * $delay-curve;
    > :nth-child(7) {
        transition-duration: $delay + $delay-min+ s;
    }
    $delay: $delay * $delay-curve;
    > :nth-child(8) {
        transition-duration: $delay + $delay-min+ s;
    }
    $delay: $delay * $delay-curve;
    > :nth-child(9) {
        transition-duration: $delay + $delay-min+ s;
    }
    $delay: $delay * $delay-curve;
    > :nth-child(10) {
        transition-duration: $delay + $delay-min+ s;
    }
    $delay: $delay * $delay-curve;
    > :nth-child(11) {
        transition-duration: $delay + $delay-min+ s;
    }
    $delay: $delay * $delay-curve;
    > :nth-child(12) {
        transition-duration: $delay + $delay-min+ s;
    }
}
.closed-container {
    > * {
        // transform: translateX(175px);
        // left: 175px;
        transition-property: transform;
        // transition-duration: 1s;
        transition-timing-function: ease-out;
    }
    &.opening-container,
    &.open-container {
        > * {
            //transform: translateX(0px);
        }
    }
}

#my-visit-container {
    .overlay-exit {
        z-index: 999;
        -webkit-backface-visibility: hidden;
        bottom: 0;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        transition: opacity 0.3s ease;
        -webkit-tap-highlight-color: transparent;
        display: block;
        cursor: pointer;
        background: black;
        opacity: 0;
    }
    &.open .overlay-exit {
        opacity: 0.33 !important;
        transition: opacity 0.3s ease;
        &:hover,
        &:focus {
            opacity: 0.33 !important;
        }
    }
}

html.no-csstransforms3d {
    #my-visit-wrap {
        right: -500px;

        &.open {
            right: 0;
        }
        &.open-more {
            right: 0;
        }
    }

}

#my-visit-wrap,.my-visit-wrap {
    position: fixed;
    height: 100%;
    background-color: #f0f0f0;
    z-index: 1800;
    top: 0;
    right: 0;
    transform: translateX(434px);
    cursor: default;
    transition: transform .5s ease, width .5s ease;

    width: 80%;
    @include respond-min($small-breakpoint) {
        width: 400px;
    }
    &.open {
        transform: translateX(0px);
    }
    &.open-more {
        width: 800px;
        transform: translateX(0px);
    }
    .button {
        // transition: box-shadow 0.3s ease-out, color 0.3s ease-out;
        // box-shadow: inset rem-calc(0 0 1 -1) $theme-color-2;
        font-size: rem-calc(17);
        padding-left: rem-calc(14);
        padding-right: rem-calc(6);
        // &:hover {
        //     box-shadow: inset rem-calc(0 -128 1 -1) $theme-color-2;
        //     background: $theme-color-1;
        // }
        @include respond-min($large-breakpoint) {
            font-size: rem-calc(20);
            padding-left: rem-calc(20);
            padding-right: rem-calc(10);
            .last-icon-cell {
                padding: rem-calc(0 0 0 10);
            }
        }
        .icon-ticket {
            margin-top: rem-calc(-15);
            font-size: rem-calc(42);
        }

        .icon-anglebracket-right {
            font-size: rem-calc(32);
            margin-left: rem-calc(-8);
            @include respond-min($large-breakpoint) {
                margin-left: 0;
            }
        }
    }
    a:hover,
    a:focus {
        text-decoration: none;
    }
    #my-visit .experience-wrap,
    .my-visit .experience-wrap,
    #more-visit {
        &.open-container .box-teaser {
            transition: transform 0.4s ease, margin 0.4s ease, opacity 0.2s linear;
            z-index: 1100;
            border-radius: 10px;
            &.item-removed {
                transform: translateY(-98px);
                //margin-bottom: -98px;
                margin-bottom: -77px;
                opacity: 0;
                z-index: 1050 !important;
            }
            &.new-item {
                transform: translateY(-98px);
                margin-bottom: -98px;
                opacity: 0;
            }
            &.incoming-item {
                transform: translateY(0);
                margin-bottom: rem-calc(3);
                opacity: 1;
                &.item-removed {
                    transform: translateY(-98px);
                    margin-bottom: -98px;
                    opacity: 0;
                    z-index: 1050 !important;
                }
            }
        }
    }
    #my-visit,.my-visit {
        .experience-wrap {
            -ms-overflow-style: -ms-autohiding-scrollbar;
            -ms-overflow-x: hidden;
            overflow: auto;
            position: absolute;
            top: rem-calc(100);
            right: 0;
            left: 0;
            padding-right: rem-calc(30);
            padding-left: rem-calc(30);
            bottom: rem-calc(140);
            @extend .closed-container;
            margin:0 0 1.25rem;
            @include respond-min($xlarge-breakpoint) {
                //top: rem-calc(150);
                top: rem-calc(130);
                //bottom: rem-calc(185);
                bottom: rem-calc(219);
            }

            & > .box-teaser.horizontal{
                border-radius:10px;
                max-width:325px;
                & .box-teaser-content {
                    //padding: 0.3125rem 5%;
                    padding: 0 5%;
                    padding-right: 45px;
                    max-height: 81px;
                }

                &-inner-wrap{
                    border-radius:10px;
                }
                .title {
                    @include respond-min($medium-breakpoint) {
                        font-size: rem-calc(17);
                    }
                }
            }
        }

        h2 {
            //border-bottom: $border-1;
            border-color: white;
            // padding-bottom: rem-calc(20);
            transition: border-color .33s ease, margin .33s ease, padding .33s ease, transform .33s ease-out;
            margin: {
                top: rem-calc(15);
                bottom: rem-calc(18);
                left: rem-calc(0);
                right: rem-calc(30);
            }
            @include respond-min($xlarge-breakpoint) {
                //margin-top: rem-calc(70);
                margin-top: rem-calc(20);
                margin-left: 0;
                margin-right: 0;
                left: 0;
                right: 0;
            }
        }
    }
    h4 {
        font-size: rem-calc(21);
        margin-bottom: rem-calc(15);
    }
    p {
        font-size: rem-calc(14);
        line-height: 1.2rem;
        color:$gray-35;

        &.my-visit-duration{
            color:$gray-35;
        }
    }

    .box-teaser {
        margin-bottom: rem-calc(15);
    }
    .box-teaser.horizontal .corner.bottom-right {
        display: block;
        opacity: 1;
    }
    .box-teaser-remove {
        z-index: 1200;
        display: table;
        width: 100%;
        height: 100%;

        &-cross{
            font-size: 40px;
            color: $gray-highlight;
            display: table-cell;
            vertical-align: top;
            text-align: center;
            //content:"\00d7";

            @include respond-min($medium-breakpoint){
                vertical-align: middle;
            }
        }

        &-wrap{
            height:100%;
        }
    }
    .my-visit-teaser {
        border: $border-1;
        background-color: white;
        @include box-shadow(rgba(0,0,0,0.1) 0px 4px 6px);
        @extend .clearfix;
        margin-bottom: rem-calc(3);
        position: relative;
        .my-visit-teaser-image {
            width: rem-calc(103);
            height: rem-calc(103);
            float: left;
        }
        .icon-circle {
            font-size: 1.2em;
            margin-right: 0;
            display: block;
            float: left;

        }

        .my-visit-teaser-content-wrap {
            padding: 0 rem-calc(20);
            width: rem-calc(215);
            height: rem-calc(103);
            display: table;
            .my-visit-teaser-content {
                display: table-cell;
                vertical-align: middle;
                .title {
                    font-size: rem-calc(18);
                    margin-top: 0;
                    line-height: 1.1;
                    margin-bottom: 6px;
                }
                .duration {
                    font-size: rem-calc(10);
                    text-transform: uppercase;
                    font-weight: 400;
                    letter-spacing: rem-calc(3);
                    line-height: 0.9rem;
                    color: $gray-30;
                    margin: 0;
                }
            }
        }
        .my-visit-teaser-remove {
            position: absolute;
            bottom: 0;
            right: 0;
            display: block;
            height: 41px;
            width: 41px;
            .icon {
                margin-top: 1.2em;
                margin-left: 1.2em;
                color: $gray-30;
            }
            &:hover,
            &:focus {
                background: image-url("my-visit-remove-bg.png") right bottom no-repeat;
                .icon {
                    color: white;
                }
            }
        }
    }
    &.open-more #my-visit {
        #visit-more-wrap {
            transform: translateY(-80px);
        }
        h2 {
            margin-top: rem-calc(35);
            //border-bottom: $border-1;
            //padding-bottom: rem-calc(20);
        }
    }
    #my-visit {
        @extend .closed-container;
        background-color: white;
        width: 100%;
        height: 100%;
        position: relative;
        float: right;
        z-index: 1002;
        padding-right: rem-calc(30);
        padding-left: rem-calc(30);
        @include box-shadow(rgba(0,0,0,0.1) -15px 0px 15px);
        @include respond-min($small-breakpoint) {
            width: 400px;
        }
        @include respond-min($xlarge-breakpoint) {
            padding: rem-calc(20) rem-calc(39);
        }

        #visit-more-wrap {
            //border-bottom: $border-1;
            height: rem-calc(40);
            margin-bottom: rem-calc(25);
            padding-bottom: rem-calc(20);
            //position: absolute;
            transform: translateY(0px);
            /*top: rem-calc(20);
            left: rem-calc(30);
            right: rem-calc(30);

             */
            //bottom: rem-calc(290);
            //left:0;
            text-align: center;
            width: 100%;
            margin-top:30px;

            transition: transform 0.25s ease, transform .5s ease-out;
            display: none;
            @include respond-min($xlarge-breakpoint) {
                display: block;
            }
        }

        .my-visit-more {
            font-weight: 700;
            color: $theme-color-2;

            .icon-circle {
                font-size: rem-calc(10);
                margin-left: 2px;
                margin-bottom: 5px;
                color: white;
                width: 1.4em;
                height: 1.4em;
                top: 7px;

                & .icon{
                    font-size: 1.4em;
                    line-height: 1.2em;
                    width: 1em;
                    height: 1em;
                }
            }

            &:hover,
            &:focus {
                color: $theme-color-1 !important;

                .icon {
                    color: white;
                }
            }
        }

        .my-visit-footer {
            position: absolute;
            bottom: rem-calc(15);
            right: rem-calc(30);
            left: rem-calc(30);
            text-align: center;
            //box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
            @include respond-min($xlarge-breakpoint) {
                //bottom: rem-calc(60);
                bottom: rem-calc(0);
                left: rem-calc(0);
                //right: rem-calc(30);
                //padding: 0;
                padding: 22px 40px;
                width: 100%;
                box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 0 2px 4px 0 rgba(0, 0, 0, 0.5);
            }


            & .button {
                height: 55px;
                padding-top: 0;
                padding-bottom: 0;
            }

            & .text-cell {
                text-align: center;
            }
        }

        .my-visit-footer-continue {
            font-weight: bold;
            margin-top: 1.5em;
            display: block;

            &:hover,
            &:focus {
                color: $theme-color-2 !important;
            }

            & > .icon{
                font-size:22px;
            }
        }
    }

    #more-visit {
        @extend .closed-container;
        background:white;
        width: 400px;
        padding: rem-calc(56) rem-calc(20) rem-calc(30) rem-calc(40);
        height: 100%;
        position: absolute;
        left: 0;
        z-index: 1001;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        -ms-overflow-x: hidden;
        overflow: auto;
        p {
            color: $gray-35;
        }
        a.add-visit {
            display: block;
            &:hover,
            &:focus {
                .icon-circle {
                    background-color: $theme-color-1;
                    border-color: $theme-color-1;
                    .icon {
                        color: white;
                        -webkit-font-smoothing: none;
                        $size: 1;
                        font-size: $size + em!important;
                        line-height: $size + em!important;
                        //width: (2 / $size) + em;
                        width: 2.1em!important;
                        height: 2.1em!important;
                        //height: (2 / $size) + em;
                        @media screen\0 {
                            // line-height: ($size / 1.02489019) + em;
                            line-height: 1.8em;
                            width: 34px;
                            height: 34px;
                        }
                        &:before {
                            @extend %icon-add;
                        }

                        // 	margin: 0;
                        // 	$size: 0.9;
                        // 	font-size: $size + em;
                        // 	line-height: $size + em;
                        // 	width: rem-calc(46);
                        // 	height: rem-calc(46);
                        // 	@media screen\0 {
                        // 		line-height: 1.77em;
                        // 		width: 46px;
                        // 		height:46px;
                    }
                }
                h3 {
                    color: $theme-color-1;
                }
            }
        }

        & .box-teaser{
            border-radius: 10px;
            &-inner-wrap{
                border-radius:10px;
            }
        }
    }

    #close {
        @include rotate(-90deg);
        position: absolute;
        top: 50%;
        left: -50px;
        height: 70px;
        width: 110px;
        background-color: white;
        padding: rem-calc(5 15 5 15);
        color: $theme-color-1;
        transition: box-shadow 0.3s ease-out, color 0.3s ease-out, background-color 0s linear 0.3s;
        box-shadow: inset rem-calc(0 0 1 -1) $theme-color-1;
        font-weight: $button-font-weight;
        font-size: 19px;
        line-height: 40px;
        text-align: center;
        border-radius: 7px 7px 0 0;
        z-index: 9999;
        /*clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);*/


        &:hover,
        &:focus {
           // box-shadow: inset rem-calc(0 -36 1 -1) $theme-color-1;
           // background-color: $theme-color-1;
            color: $theme-color-2;
            cursor: pointer;
        }

        /*&:before,&:after{
            width:30px;
            height:80px;
            content:" ";
            border-radius: 0 0 5px 5px;
        }

        &:after{
            position: relative;
            right: -42px;
            top: -45px;
            background: rgba(0,0,0, 0.33);
            display: block;
            border-radius: 10px;        }

        &:before{
            position: relative;
            left: -42px;
            top: -45px;
            background: rgba(0,0,0, 0.33);
            display: block;
            border-radius: 10px;
        }

         */


        .bar {
            // border-left: $border-1;
            border-left: 1px solid rgba($gray-10,0.4);
            display: block;
            float: right;
            border-width: 2px;
            font-weight: $button-font-weight;
            margin-left: 10px;
            padding-left: 8px;
            font-size: rem-calc(24);
            line-height: rem-calc(28);
            height: rem-calc(24);
            display: none;
        }
    }
}

.text-light {
    .overlay-exit:hover,
    .overlay-exit:focus {
        opacity: 0.33 !important;
    }
    #my-visit,
    #more-visit {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $theme-color-2;
        }
    }
}

.experience-date-selection {
    h2.title {
        color: $gray-70 !important;
    }
}


#my-visit-wrap.open #close{
    left: -61px;
    transition: left 0.2s ease-in-out;
}
