	// ======================================================================
	// ICON BUTTON
	// ======================================================================

@use "sass:math";

.icon-link-list {
	list-style: none;
	.icon {
		color: $theme-color-1;
	}
}

.hang-icon {
	margin-left: 1.25em;

	.icon {
		margin-left: -1.25em;
	}
}

// Most of the .icon class is defined in the font build files
// .icon { included in icon.scss
// 	&.tiny {
// 		font-size: rem-calc(16);
// 	}
// 	&.small {
// 		font-size: rem-calc(24);
// 	}
// 	&.medium {
// 		font-size: rem-calc(36);
// 	}
// 	&.large {
// 		font-size: rem-calc(60);
// 	}
// }

%basic-circle {
	width: 2em;
	height: 2em;
	font-size: rem-calc(25);
	border-radius: 2em;
	background: $theme-color-1;
	color: white;
	display: inline-block;
	margin-bottom: rem-calc(20);
	margin-right: rem-calc(20);
	text-align: center;
	border: 1px solid $theme-color-1;
	position: relative;

	&.light {
		background: none;
		color: $theme-color-1;
		border: 1px solid $gray-20;
	}

	&.right {
		margin-left: rem-calc(20);
		margin-right: 0;
	}
}

.icon-circle {
	@extend %basic-circle;

	&.light {
		background: transparent;
		border: 1px solid $gray-20;

		.icon {
			color: $theme-color-1;
		}
	}

	&.right {
		margin-left: rem-calc(20);
		margin-right: 0;
	}

	.icon {
		color: white;
		display: table;
		margin: -1px 0 0 -1px;
		$size: 1.4;
		font-size: $size + em;
		line-height: $size + em;
		width: math.div(2, $size) + em;
		height: math.div(2, $size) + em;
		@media screen\0 {
			line-height: math.div($size, 1.02489019) + em;
		}

		&.icon-anglebracket-left {
			margin-left: -4%;
		}

		&.icon-anglebracket-right {
			margin-left: 4%;
		}

		&:before {
			display: table-cell;
			vertical-align: middle;
			@media screen\0 {
				display: inline-block;
			}
		}
	}

	// .icon {
	//		font-size: 1.4em;
	//		line-height: 1.6;
	//		margin-right: 0;
	//		margin-top: -.1em;
	//
	// 	&.icon-anglebracket-left {
	// 		 margin-left: -.1em;
	// 		 margin-top: -.14em;
	// 	}
	//
	// 	&.icon-anglebracket-right {
	// 		 margin-right: -.1em;
	// 		 margin-top: -.14em;
	// 	}
	//
	// 	&.icon-add-bold {
	// 		 font-size: .8em;
	// 		 margin-top: .1em;
	// 		 margin-left: .1em;
	// 	}
	//
	// 	&.icon-minus-bold {
	// 		 font-size: .8em;
	// 		 margin-top: .1em;
	// 		 margin-left: .1em;
	// 	}
	// }
}

// @todo ron - this should probably go somewhere else

.text-circle {
	@extend .icon-circle;
	// line-height: 2em;
	// min-width: 2em;
	// width: auto;

	.text-wrap {
		color: white;
		display: table;
		margin: 0px 0 0 -1px;
		$size: 1.4;
		font-size: $size + em;
		line-height: $size + em;
		width: math.div(2, $size) + em;
		height: math.div(2, $size) + em;
		@media screen\0 {
			line-height: math.div($size, 1.02489019) + em;
		}
	}

	.text-inner-wrap {
		display: table-cell;
		vertical-align: middle;
		// width: 3.3em;
	    // height: 3.3em;
		// padding: 0 .84em;
		font-size: rem-calc(14);
		line-height: rem-calc(14);
		text-align: center;
		font-weight: 400;
		color: $light-blue-gray;
		@media screen\0 {
			display: inline-block;
		}

		&:before,
		&:after {
			display: none;
		}

		span {
			font-weight: 600;
			margin-left: .2em;
			color: $theme-color-2;
			font-size: rem-calc(15);
		}
	}

	&.light {
		color: $lighter-gray;

		.inner-wrap span {
			color: $theme-color-2;
		}
	}
}


/* Icons */
