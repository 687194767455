// Required Core Stylesheet
@import "~@glidejs/glide/src/assets/sass/glide.core";

// Optional Theme Stylesheet
@import "~@glidejs/glide/src/assets/sass/glide.theme";

ul.glide__slides{
  margin: 0;
}
.glide__bullets {
  bottom: -1em;
}
.glide__bullet {
  width:7px;
  height:7px;
}


li.glide__slide:nth-child(1) {
  animation-delay: 200ms;
}

li.glide__slide:nth-child(2) {
  animation-delay: 300ms;
}

li.glide__slide:nth-child(3) {
  animation-delay: 400ms;
}

.glide-container, .glide .glide--slider, .glide__track, .glide__slides{
  visibility: visible;

  @include respond-min($xlarge-2-breakpoint){
    overflow: visible;
    visibility: visible;
  }
}


