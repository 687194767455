	// ======================================================================
	// exprerince
	// ======================================================================

// this is for member calendar list view
.member-event-title {
	&:first-child {
		margin-top: 0;
	}
	//margin-top: rem-calc(55);
	margin-top: 0;
	//margin-bottom: rem-calc(35);
	margin-bottom: 0;

	//new
	background:$light-gray;
	font-size:rem-calc(16);
	line-height: rem-calc(20);
	color:$title-gray;
	padding: 20px 25px;
	border-radius: 3px;
}
	/*todo member calendar: height 159px;
	width: 760px;

		image{
			160px
			height:130px;
		}
	}

	 */
