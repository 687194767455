/* Faq =================================================== */
 #faqs {
     //margin: 100px 0;

     h2.category-title {
          margin-top: 40px;
     }

     form {
          input {
               height: rem-calc(46);
               border: 2px solid #d9dde6;
          }

          select {
               height: rem-calc(46);
               border: 2px solid #d9dde6;
          }

          button {
               height: rem-calc(46);
               padding-top: rem-calc(12);

               .icon {
                    margin-right: 0;
               }
          }
     }

     .faq-question {
          font-weight: bold;
          font-size: 1.125rem;
     }
 }
