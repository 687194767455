.box_teaser {
  margin: 0 auto 20px;
  position: relative;
  width: 100%;
  max-width: 310px;
  background: none;
  min-height: 100%;
  height: 398px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.1);

  @include respond-min($xlarge-2-breakpoint) {
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.1);
    margin: 0;
    max-width: 360px;
    min-height: 100%;
    //height: 440px;
    height: 445px;
  }

  br,
  .break {
    display: none;
    @include respond-min($xlarge-breakpoint) {
      display: block;
    }
  }
  > a {
    // this is the direct parent of what we want to be table-cells
    // table-cell width is calculated off this width
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 100%;
  }
  .fade-in {
    display: block;
    // 	position: absolute;
    // 	left: 0;
    // 	right: 0;
    bottom: 0;
    height: rem-calc(30);
    background: linear-gradient(to bottom, rgba(255,255,255,0) 3%,rgba(255,255,255,1) 33%);
    @include respond-min($medium-breakpoint) {
      display: block;
      background: linear-gradient(to bottom, rgba(255,255,255,0) 3%,rgba(255,255,255,1) 33%);
    }
  }
  &__image-wrap {
    height: 290px;
    width:100%;
    background-size: cover;
   // background-position: center center;
    background-position: center bottom;
    transition: background-position 0.3s ease-out;
  }

  /*&__image {
    // setting margin-bottom as a percentage allows the width to dictate the height of the box-module
    //margin-bottom: 160px;
    display: block;
    width: 100%;
    height: 290px;
  }

   */

  &__content {
    //padding: 1.5rem 8.333333% 1.875rem;
    padding: 1.5rem 8.333333% 0;
    @include respond-min($medium-breakpoint){
      //padding: 20px 15px;
      padding: 20px 15px 0;
      max-height: 175px;
      min-height: 158px;
    }
    @include respond-min($xlarge-breakpoint){
      //padding: rem-calc(34) 13.3333333334% rem-calc(30);
      padding: rem-calc(34) 13.3333333334% 0;
    }
    color: $text-color-base;
    overflow: hidden;
    display: block;
    width: auto;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    max-height: 150px;
    min-height: 150px;
    height: auto;
    background: white;
    transition: all 0.2s ease-out;
    z-index: 200;
  }
  &__subtitle {
    font-size: rem-calc(12);
    text-transform: uppercase;
    letter-spacing: rem-calc(3);
    font-weight: 400;
    line-height: rem-calc(15);
    margin-bottom: rem-calc(2);
    color: $theme-color-1 !important;
    @include respond-min($large-breakpoint) {
      margin-bottom: rem-calc(10);
    }
    @include respond-min($xlarge-breakpoint) {
      min-height: rem-calc(20);
    }
  }
  &__title {
    font-size: rem-calc(24);
    line-height: rem-calc(30);
    margin-top: 0;
    margin-bottom: 0;
    color: $theme-color-3!important;;
    @include respond-min($medium-breakpoint) {
      font-size: rem-calc(20);
      line-height: rem-calc(26);
    }

    @include respond-min($xxlarge-breakpoint) {
      min-height: rem-calc(40);
      padding-bottom: 10px;
      font-size: rem-calc(24);
      line-height: rem-calc(30);
    }
  }
  &__description {
    font-size: rem-calc(16);
    line-height: rem-calc(24);
    //moving the padding and margin to the top of the description instead of the bottom of the element above it allows perfect vertical centering when the description is not present
    padding-top: 10px;
    margin-bottom: 0;
    @include respond-min($medium-breakpoint) {
      display: block;
    }
    @include respond-min($xlarge-breakpoint) {
      padding-top: 0;
      //margin-bottom: rem-calc(15);
    }
  }
  &__link-underline {
    display: inline-block;
    line-height: rem-calc(20);
    font-size: rem-calc(16);
    font-weight: bold;
    color: $link-color;
    margin-top: rem-calc(0);
    height: 0;
    opacity: 0;
    transition: margin-top 0.1s ease-out, height 0.1s ease-out, opacity 0.15s ease-out;
    //transition: opacity 0.2s ease-out;
  }
}
.box_teaser:hover, .box_teaser:focus {
  //color: $text-color-base;

  .box_teaser__image-wrap {
    //background-position: center bottom;
    background-position: center bottom 20px;
    transition: background-position 0.2s ease-in;
  }

  .box_teaser__content {
    min-height: 210px;
    max-height: 100%;
    height: auto;
    transition: all 0.15s ease-in;
  }


  .box_teaser__link-underline {
    height: auto;
    display:inline-block;
    //margin-bottom: 2px;
    margin-top: rem-calc(15);
    padding-bottom: 8px;
    border-bottom: 2px solid $theme-color-1;
    opacity: 1;
    transition: padding-bottom 0.1s ease-in, margin-top 0.1s ease-in,height 0.1s ease-in, opacity 0.2s ease-in;

    &:hover,
    &:focus {
      color: $link-color-hover;
      border-color: $link-color-hover;
    }
  }

}


