	// ======================================================================
	// Activity Feature
	// ======================================================================

@use "sass:math";

.experience-feature {
	background-color: $theme-color-2;
	background-position-x: top;
	background-position-y: left;
	background-repeat: no-repeat;
	// padding: 10.4838709677% 9.6774193548%;
	padding: (math.div(55, 539.98) * 100%) (math.div(50, 539.98) * 100%);
	margin-right: rem-calc(-.02);
	background-size: cover;

	// @todo-ron may want to put in and variable or placeholder
	@include box-shadow(rgba(0,0,0,0.1) 0px 4px 6px);

	// @include respond-min($small-breakpoint) {
	// 	padding: 10.4838709677% 9.6774193548% 0;
	//
	// }
	@include respond-min($medium-breakpoint) {
		// height: rem-calc(384);
	}
	@include respond-min($large-breakpoint) {
		// min-height: rem-calc(498);
		padding: (math.div(55, 539.98) * 100%) (math.div(50, 539.98) * 100%);
	}
	@include respond-min($xlarge-breakpoint) {
		// min-height: rem-calc(498);
		padding: (math.div(65, 619.984) * 100%) (math.div(60, 619.984) * 100%);
	}

	> .content {
		width: 100%;
		height: 0;
		// desired width divided by parent width times 100% gives you desired percent width
		margin-bottom: math.div(368, 499.984) * 100%;
		padding-right: 0;
		@include respond-min($medium-breakpoint) {
			margin-bottom: math.div(248, 344.64) * 100%;
			padding-right: 15%;
		}
		@include respond-min($large-breakpoint) {
			margin-bottom: math.div(340.47, 440) * 100%;
			padding-right: 25%;
		}
		@include respond-min($xlarge-breakpoint) {
			margin-bottom: math.div(390, 500) * 100%;
			padding-right: 40%;
		}
	}

	.title {
		font-family: $font-family-1;
		font-size: rem-calc(20);
		margin-top: rem-calc(10);
		line-height: 1.2;
		margin-bottom: rem-calc(16);
		color: white;
		@include single-text-shadow(0, 1px, 1px, 1px, rgba(0,0,0,0.5));

		@include respond-min($medium-breakpoint) {
			font-size: rem-calc(30);
		}
		@include respond-min($xlarge-breakpoint) {
			font-size: rem-calc(44);
			margin-top: rem-calc(20);
		}
	}

	.subtitle {
		font-size: rem-calc(10);
		margin-bottom:  rem-calc(10);
		text-transform: uppercase;
		font-weight: 400;
		letter-spacing: rem-calc(3);
		line-height: 0.9rem;
		color: $theme-color-1;

		@include respond-min($small-breakpoint) {
			font-size: rem-calc(11);
			margin-bottom:  rem-calc(25);
		}
	}

	.description {
		display: none;
		color: white;
		opacity: .5;
		font-family: $font-family-1;
		font-size: rem-calc(13);
		line-height: rem-calc(16);
		margin-bottom: rem-calc(5);

		@include respond-min($small-breakpoint) {
			display: block;
			font-size: rem-calc(16);
			line-height: rem-calc(27);
			margin-bottom: rem-calc(13);
		}
	}

	.link-underline {
		font-size: rem-calc(13);

		@include respond-min($small-breakpoint) {
			font-size: rem-calc(15);
		}
	}
}
