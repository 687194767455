// ======================================================================
// search
// ======================================================================

.search-open {
    position: fixed;
    width: 100%;
}

.search.content {
    // @todo Jeff temporarily disabled z-index
    // z-index: 500;
    background-color: transparent;
    border: none;
    box-shadow: none;
    color: white;

    overflow-y: scroll; /* has to be scroll, not auto */
     -webkit-overflow-scrolling: touch;

    padding: 0;

    @include respond-min($large-breakpoint) {
        padding-top: rem-calc(55);
    }

    > .row {
        max-width: rem-calc(600);
        margin-top: rem-calc(40);

        @include respond-min($xlarge-breakpoint) {
            min-width: rem-calc(960);
            margin-top: 0;
        }
    }

    > .row,
    > .row > .columns {
        height: 100%;
    }

    .close-button {
        background: transparent;
        position: absolute;
        top: 0;
        right: 0;
        font-size: rem-calc(32);
        font-weight: 700;
        color: white;
        padding: rem-calc(7 25);

        @include respond-min($large-breakpoint) {
            padding: 0.9375rem 1.875rem 1rem;
        }

        &:hover,
		&:focus {
            background: transparent;
            color: $theme-color-1;
        }
    }

    .search-form {
        clear: both;
        margin: rem-calc(40 0 0);

        @include respond-min($large-breakpoint) {
            margin-top: 0;
        }

        > .columns {
            border-bottom: 1px solid white;
            // margin-bottom: rem-calc(40);
            // &:first-child {
            // 	padding-left: 0;
            // 	margin-left: .625rem;
            // }
        }
    }

    .search-input {
        font-size: rem-calc(18);
        background-color: transparent;
        border: none;
        box-shadow: none;
        color: $theme-color-3;
        font-weight: 600;
        display: block;
        height: rem-calc(38);
        margin: 0 0 1rem;
        padding: 0.5rem 0;
        &::-ms-clear {
            display: none;
        }

        @include input-placeholder {
            color: #5b606c;
        }

        @include respond-min($large-breakpoint) {
            font-size: rem-calc(24);
            height: rem-calc(45);
        }

      /*  @include respond-min($xlarge-breakpoint) {
            font-size: rem-calc(49);
            height: rem-calc(75);
        }*/
    }

    .search-spinner {
        width: rem-calc(40);
        height: rem-calc(32);
        display: block;
        position: relative;
        color: #fff;

        @include respond-min($large-breakpoint) {
            width: rem-calc(53);
            height: rem-calc(34);
        }

       /* @include respond-min($xlarge-breakpoint) {
            width: rem-calc(43);
            height: rem-calc(60);
        }*/
    }

    .search-submit {
        @include button;
        background: none;
        line-height: rem-calc(18);

        @include respond-min($large-breakpoint) {
            line-height: rem-calc(24);
        }

        &:before {
            @include icon-search;
            font-family: 'icons';
            font-weight: 400;
            float: left;
            width: rem-calc(40);
            font-size: rem-calc(25);
            display: block;
        }

        &:hover,
		&:focus {
            background: transparent;
            color: $theme-color-1;
        }

        .hidden {
            @include graphic-text;
            display: block;
        }
    }

    .search-spinner,
    .search-submit {
        margin: rem-calc(5 0 0);  // Was 17 for margin-bottom
        width: rem-calc(40);
        height: rem-calc(32);
        padding: rem-calc(7 0);
        float: right;

        @include respond-min($large-breakpoint) {
            // margin-bottom: rem-calc(22);
            padding: rem-calc(5);
            height: rem-calc(34);
        }

        /*@include respond-min($xlarge-breakpoint) {
            margin-bottom: rem-calc(26);
            padding: rem-calc(18 0);
            height: rem-calc(60);
        }*/
    }

    .search-quantity {
        margin-bottom: rem-calc(75);
        font-size: rem-calc(14);

        i {
            font-family: $font-family-2;
        }
    }

    .search-results-wrapper {
        position: absolute;
        top: 195px;
        bottom: 0;
        margin-right: rem-calc(10);

        @include respond-min($large-breakpoint) {
            top: 192px;
            margin-right: 0;
        }

        @include respond-min($xlarge-breakpoint) {
            top: 192px;
        }

        > div > div {

            > h3 {
                font-size: 1.5rem;
                line-height: 1.875rem;
                margin-bottom: 1.875rem;
                font-style: normal;
                font-weight: 700;
                text-rendering: auto;
                color: white;
                margin-top: rem-calc(50);

                @include respond-min($large-breakpoint) {
                    margin-top: rem-calc(50);
                }

           }
        }
    }

    .results-list {
        @include reset-ul;

        li {
            margin-bottom: rem-calc(20);

            @include respond-min($large-breakpoint) {
                margin-bottom: rem-calc(50);
            }
        }

        h2.results-topic {
            margin-bottom: rem-calc(0);
            margin-top: rem-calc(40);
            font-size: rem-calc(26);

            @include respond-min($large-breakpoint) {
                margin-bottom: rem-calc(10);
                margin-top: rem-calc(35);
            }
        }

        a {
            color: $theme-color-1;

            &:hover,
    		&:focus {
                color: white;
            }
        }

        h3 {
            margin-bottom: 0;

            @include respond-min($large-breakpoint) {
                margin-bottom: rem-calc(8);
            }
        }

        .breadcrumb {
            font-size: rem-calc(9);
            text-transform: uppercase;
            margin-bottom: rem-calc(6);

            .icon {
                vertical-align: middle;
            }

            a {
                color: white;

                &:hover,
        		&:focus {
                    color: $theme-color-1;
                }
            }
        }

        p {
            margin-bottom: rem-calc(6);
            opacity: 0.5;
        }

        .details {
            @extend .subtitle;
        }

        > p,
        > div {
            padding-bottom: 75px;
        }
    }

    .box-teaser {

        &.horizontal {
            margin: 0;
        }
        .link-underline,
        .fade-in {
            display: none;
        }
    }
}
.search.content .search-form[data-search-form]{
    margin: 0 5%;
    @include respond-min($large-breakpoint) {
        margin: 0 auto;
    }
}
