.membership-card-glide__wrap {
  overflow: hidden;
  .glide__wrap {
    max-width: 2100px;
    margin: 0 auto 0;
  }

  @media screen and (min-width: 1960px) {
    .glide__wrap {
      margin-left: auto;
    }
  }

  .glide__track {
    overflow: visible;
  }

  .membership-card__wrap {
    /* margin-bottom: 60px; */

    max-width: 1850px;
    margin-right: auto;
    /* margin-left: 20px; */
    /* margin-bottom: 100px; */

  }

  .membership-card__container {

    max-width: 360px;
    overflow: visible;

    /* margin: 0 auto;*/
    /* flex-wrap: space-between; */
  }

  .membership-card__list {

  }

  .membership-card {
    /* flex-basis: 31%; */
  }

  .membership-card:last-child {
    /*/ margin-right: 0;*/
  }

  .membership-card__disclaimer {
    text-align: center;
    margin: 15px 15px;
  }

  /* @media screen and (min-width:1024px) and (max-width:1299px){
  .membership-card:nth-child(even) {
  margin-right: 0;
  }
  }
  @media screen and (min-width:1300px){
  .membership-card {
  flex-basis: 31%;
  }
  .membership-card:nth-child(3n+0) {
  margin-right: 0;
  }
  } */

  .membership-card__wrap {
    /* max-width: 360px; */
    display: flex;
    padding: 10px 0;
    margin-bottom: 10px
  }

  .membership-card {
    margin: 0 10px;
    position: relative;
    overflow: hidden;

    /* max-width: 360px; */
    /* min-width: 360px; */

    /*width: 375px;
    min-width: 360px;
    min-width: 360px;*/
    /* min-width: 300px; */
    /* margin-right: 30px; /* except last child */
    /* margin: 0 20px 40px; */
    width:100%;
    padding: 30px 30px 155px;
    /* border: 1px solid black; */

    background: #FFFFFF;
    box-shadow: 0 1px 3px 0 rgba(82, 98, 105, 0.16), 0 7px 15px 0 rgba(0, 0, 0, 0.12);
    border-radius: 10px;
    /* background: $theme-color-1; */

    &__ribbon{
      display: block;
      width: 80px;
      height: 80px;
      position: absolute;
      top: 0;
      right: 0;
      border-left: 80px solid white;
      border-top: 80px solid var(--theme-color-1);
      border-top-right-radius: 5px;

      &-text{
        font-size: 12px;
        line-height: 20px;
        font-weight: bold;
        color: #FFF;
        text-transform: capitalize;
        text-align: center;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        display: block;
        width: 80px;
        text-shadow: 0 2px 4px rgba(0,0,0, .5);
        /*position: absolute;
        top: -60px;
        right: -10px;

         */
        position: relative;
        top: -60px;
        left: -70px;
      }
    }
  }

  .membership-card__footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    padding: 0 30px 30px;
  }

  .base-headline {
    font-size: 30px;
  }

  .base-subhead {
    font-size: 16px;
    line-height: 1.1;
    margin-bottom: 0;
  }

  .base-subhead-tag {
    font-size: 16px;
    line-height: 1.15;
  }

  .base-more-link {
    /* font-family: ProximaNova-Bold; */
    font-size: 16px;
    font-weight: bold;
    color: $theme-color-1;
    letter-spacing: 0;
    text-align: center;
    border-bottom: 2px solid $theme-color-1;
  }

  .membership-card__item {
    margin-bottom: 10px;
    padding-left: 30px;
    list-style-type: none;
    background: transparent image-url("check.svg") no-repeat left top;
    /* FOR SINGLE COLUMN */
    -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
    page-break-inside: avoid; /* Firefox */
    break-inside: avoid; /* IE 10+ */

  }

  /* Inline #10 | https://msi.test/index.php?id=4252 */

  .membership-card__price-wrap {
    padding: 20px 20px;
    margin-top: 20px;
    background: #EEF1F3;
    border-radius: 10px;
    text-align: center;
  }

  .membership-card__price {
    font-size: 32px;
    font-weight: 700;
    color: $theme-color-1;
    letter-spacing: 0;
    line-height: 32px;
  }

  .membership-card__price:before {
    content: "$";
    opacity: 0.5;
    margin-right: 5px;
    font-size: 18px;
    font-weight: bold;
    color: #030D26;
    letter-spacing: 0;
    line-height: 32px;
  }

  .membership-card__deduct {
    margin-right: 5px;
    opacity: 0.6;
    /* font-family: ProximaNova-Semibold; */
    font-size: 14px;
    font-weight: 600;
    color: #030D26;
    letter-spacing: 0;
    line-height: 4px;
  }

  /* corporate-membership-development.css | https://msi.test/fileadmin/__RON_SCRATCH/corporate-membership-development.css */

  .glide__slide {
    height: auto;
  }

  .glide__bullets {
    bottom: 0;
  }

  @media screen and (min-width: 1920px) {
    /*.glide__bullets {
      display: none;
    }*/
  }


}

.glide__arrow {

  color: $theme-color-3;
}

.glide__bullet {
  border: 1px solid $theme-color-3;
  &:focus,&:hover{
    border: 1px solid $theme-color-3;
  }

  &--active {
    background-color: white;

    &:focus, &:hover {
      background-color: white;

    }

    .membership-card-glide__wrap &{
      background-color: $theme-color-3;
    }
  }
}
