	// ======================================================================
	// ICON BUTTON
	// ======================================================================

a:hover,
a:focus {
	.icon {
		text-decoration: none;
		color: $theme-color-2;
	}
}

.icon-link-list {
	list-style: none;
	.icon {
		color: $theme-color-1;
	}
}

.hang-icon {
	margin-left: 1.25em;

	.icon {
		margin-left: -1.25em;
	}
}

.icon {

	&.tiny {
		font-size: rem-calc(16);
	}

	&.small {
		font-size: rem-calc(24);
	}

	&.medium {
		font-size: rem-calc(36);
	}

	&.large {
		font-size: rem-calc(60);
	}
}

// .icon-circle { included in icon_extended.scss
// 	width: 2em;
// 	height: 2em;
// 	font-size: rem-calc(25);
// 	border-radius: 2em;
// 	background: $theme-color-1;
// 	color: white;
// 	display: inline-block;
// 	margin-bottom: rem-calc(20);
// 	margin-right: rem-calc(20);
// 	text-align: center;
// 	border: 1px solid $theme-color-1;
// 	position: relative;
//
// 	&.light {
// 		background: transparent;
// 		border: 1px solid $gray-20;
//
// 		.icon {
// 			color: $theme-color-1;
// 		}
// 	}
//
// 	&.right {
// 		margin-left: rem-calc(20);
// 		margin-right: 0;
// 	}
//
// 	.icon {
// 		color: white;
// 		display: table;
// 		margin: -1px 0 0 -1px;
// 		$size: 1.4;
// 		font-size: $size + em;
// 		line-height: $size + em;
// 		width: (2 / $size) + em;
// 		height: (2 / $size) + em;
// 		@media screen\0 {
// 			line-height: ($size / 1.02489019) + em;
// 		}
//
// 		&.icon-anglebracket-left {
// 			margin-left: -4%;
// 		}
//
// 		&.icon-anglebracket-right {
// 			margin-left: 4%;
// 		}
//
// 		&:before {
// 			display: table-cell;
// 			vertical-align: middle;
// 			@media screen\0 {
// 				display: inline-block;
// 			}
// 		}
// 	}
// }

.icon-event {
	color: $color-event;
}

.icon-exhibit {
	color: $color-exhibit;
}

.icon-film, .icon-Film {
	color: $color-film;
}

.icon-tour {
	color: $color-tours;
}

.icon-video {
	color: $color-video;
}

.icon-game {
	color: $color-games;
}

.icon-hands-on {
	color: $color-hands-on;
}


/* Icons */
