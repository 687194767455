body {
	color: $text-color-base;
	// @todo-ron see if this should be globally or not
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	// Fix for very specific behavior on iPhone when rotation changes
	@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
		-webkit-text-size-adjust: 100%;
	}
}

// @todo-ron where is this nav style used?
// nav ul {
//     list-style: none;
// }

html[data-whatintent="touch"],
html[data-whatintent="mouse"] {
    h1,
    h2.h1-match,
    main,
	nav,
    [role=main],
    [role=navigation],
    [role=search] {
        outline: 0;
    }
}

a {
	&:hover,
	&:focus {
		color: $theme-color-2;
	}
	&:focus {
		color: $theme-color-2;
		// background-color: $theme-color-1-light;
		// outline: 3px auto $theme-color-1-light;
		// outline-offset: -1px;
		// transition: outline 500ms cubic-bezier(0.4, 0, 0.2, 1),
		// background-color 500ms cubic-bezier(0.4, 0, 0.2, 1);
		outline: 0;
	}
}

.text-light,
.text-transparent {
	a, ul a, ol a {
		opacity: 1;

		&:hover,
		&:focus {
			color: white !important;
			opacity: 1 !important;
		}
		&:focus {
			// background-color: $theme-color-3;
			// outline-color: $theme-color-3;
		}
	}

	.arrow-list li:hover:before,
	.arrow-list li:focus:before {
		color: white !important;
	}

	ul.bh__links.link-list {
		> li:hover:before,
		> li:focus:before {
			color: white !important;
		}
	}
}

#footer ul.arrow-list {
	> li:hover:before,
	> li a:focus:before {
		color: white !important;
	}

	a:hover,
	a:focus {
		color: white !important;
	}
	a:focus {
		// background-color: $theme-color-2;
		// outline-color: $theme-color-2;
	}
}


figure {
	margin: 0;
	padding: 0;
}

.ce-margin, .teaser-module.vertical-box.ce-margin, .teaser-module.horizontal.ce-margin, .feature-quote.ce-margin, .msi-accordion.ce-margin, .gallery-grid.ce-margin, .column-group.ce-margin, .layout-container.ce-margin {

	&-top- {
		&1025 {margin-top: rem-calc(25);}
		&1050 {margin-top: rem-calc(50);}
		&1075 {margin-top: rem-calc(75);}
		&1 {margin-top: rem-calc(100);}
		&125 {margin-top: rem-calc(125);}
		&15 {margin-top: rem-calc(150);}
		&175 {margin-top: rem-calc(175);}
		&2 {margin-top: rem-calc(200);}
		&25 {margin-top: rem-calc(250);}
		&3 {margin-top: rem-calc(300);}
		&1000 {margin-top: rem-calc(0);}
	}

	&-bottom- {
		&1025 {margin-bottom: rem-calc(25);}
		&1050 {margin-bottom: rem-calc(50);}
		&1075 {margin-bottom: rem-calc(75);}
		&1 {margin-bottom: rem-calc(100);}
		&125 {margin-bottom: rem-calc(125);}
		&15 {margin-bottom: rem-calc(150);}
		&175 {margin-bottom: rem-calc(175);}
		&2 {margin-bottom: rem-calc(200);}
		&25 {margin-bottom: rem-calc(250);}
		&3 {margin-bottom: rem-calc(300);}
		&1000 {margin-bottom: rem-calc(0);}
	}
}




blockquote {

	@include reset-blockquote;

	font-family: $font-family-2;
	font-size: rem-calc(32);
	line-height: rem-calc(43);
	font-style: italic;
	text-align: center;
	margin: rem-calc(64 40 52);

	cite {
		margin-top: rem-calc(15);
		font-family: $font-family-1;
		font-style: normal;
		color: $theme-color-1;
	}

	p {
		font-family: $font-family-2;
		font-size: rem-calc(32);
		line-height: rem-calc(43);
		font-style: italic;
		text-align: center;
		margin: rem-calc(64 40 52);
	}
}


img {
	max-width: 100%;
	&[data-sizes="auto"] {
		display: block;
		width: 100%;
	}
}

.alert-box {
	padding: rem-calc(24 32);
	border: none;
	font-size: 14px;
	// text-transform: uppercase;
	font-weight: bold;
	background: #ffdacc;
	color: #ff4800;

	&.success {
		background: #d2fbf0;
		color: #07a778;
	}
	&.alert {
		color: #ff4800;
	}
}

input[type=text],
select {
	border: 2px solid #ced2dd;
	color: #2aa9e0;

	&:focus {
		border-color: #3b4b75;
		// outline: none;
	}

	&.invalid {
		border-color: #ffb699;
		color: #ff4800;
		line-height: 1rem;
	}
}

.hide-visual {
	position: absolute;
	overflow: hidden;
	clip: rect(0 0 0 0);
	height: 1px; width: 1px !important;
	margin: -1px !important;
	padding: 0 !important;
	border: 0 !important;
}

// ul li {

// 	margin-left: 2rem;

// 	&:before {
// 		/* Unicode bullet symbol */
// 		content: '\2022 ';
// 		/* Bullet color */
// 		color: $theme-color-1;
// 		font-size: 2rem;
// 		margin-left: -2rem;
// 		line-height: 1rem;
// 	}
// }



/* Elements =================================================== */

// ::-moz-selection {
// 	background-color: lighten($basecolor, 65%);
// 	color: $basecolor;
// 	text-shadow: none;
// }
//
// ::selection {
// 	background-color: lighten($compcolor, 45%);
// 	color: $textcolor;
// 	text-shadow: none;
// }

// html {
// 	background-color: $bodycolor;
// 	font-size: 62.5%;
// 	-webkit-overflow-scrolling: touch;
// 	-webkit-tap-highlight-color: lighten($basecolor, 75%);
// 	-webkit-text-size-adjust: 100%;
// 	-ms-text-size-adjust: 100%;
// }
//
// body {
// 	@include font-size();
// 	margin: 0;
// 	padding: 0;
// 	width: 100%;
// 	background-color: transparent;
// 	font-family: $basefont;
// 	line-height: $baselineheight;
// 	color: $textcolor;
// }
//
// nav {
// 	clear: both;
// 	margin: 0;
// 	text-align: left;
// }
//
// hr {
// 	display: block;
// 	margin: $vertical-gutter 0;
// 	padding: 0;
// 	height: 2px;
// 	border: 0;
// 	background-color: $dividercolor;
// 	@include respond-min($bp-xl) {
// 		margin: {
// 			top: $vertical-gutter-xl;
// 			bottom: $vertical-gutter-xl;
// 		}
// 	}
// 	&.no-space { margin: 0; }
// 	&.no-space-above { margin-top: 0; }
// 	&.no-space-below { margin-bottom: 0; }
// 	&.graphic-separator {
// 			background: transparent url('./img/graphic_separator.svg') center 0 no-repeat;
// 			background-size: cover;
// 			height: 27px;
// 	}
// }
//
// iframe { max-width: 100%; }
//
// img {
// 	// width: 100%;
// 	max-width : 100%;
// 	height: auto;
// 	border-width: 0;
// 	vertical-align: middle;
// 	-ms-interpolation-mode: bicubic;
// }
//
// svg:not(:root) { overflow: hidden; }
//
// figure {
// 	margin-bottom: $baselineheight;
// 	img { margin-bottom: $baselineheight / 4; }
// 	figcaption {
// 		@include font-size(14);
// 		display: block;
// 		font-weight: normal;
// 		color: $grey;
// 	}
// }
//
// input[type="submit"] { @include button(); }
//
// .inline-form {
// 	margin-bottom: 2em;
// 	input { display: inline; }
// 	input[type="text"], input[type="email"] {
// 		font-size: 110%;
// 		padding: .3em .5em;
// 		height: 21px;
// 		line-height: 1.5;
// 		margin: 0;
// 		border: $inputborderwidth $inputborderstyle $inputborder;
// 	}
// 	input[type="submit"] {
// 		margin: 0;
// 		height: 34px;
// 		padding: {
// 			top: 0;
// 			bottom: 0;
// 		}
// 	}
// }

/* Special Elements =================================================== */

// .clear {
// 	@extend %clearfix;
// }
//
// .container {
// 	@extend %clearfix;
// 	min-height: 100%;
// 	height: 100%;
// 	position: relative;
// }
//
// .visually-hidden {
// 	@extend %visually-hidden;
// }

// #header-page {
//     background: hsla(260, 100%, 50%, .05);
//
//     #{elements-of-type(block)} {
//         background: hsla(260, 100%, 50%, .05);
//         // border: 2px solid hsla(180, 100%, 50%, .05);
//         // margin: -2px;
//     }
// }
//
// #content-wrap {
//     background: hsla(240, 100%, 50%, .05);
//
//     #{elements-of-type(block)} {
//         background: hsla(240, 100%, 50%, .05);
//     }
// }
//
// #feature-wrap {
//     background: hsla(190, 100%, 50%, .05);
//
//     #{elements-of-type(block)} {
//         background: hsla(190, 100%, 50%, .05);
//     }
// }
//
// #footer-page {
//     background: hsla(120, 100%, 50%, .05);
//
//     #{elements-of-type(block)} {
//         background: hsla(120, 100%, 50%, .05);
//     }
// }
