
// Table redesign 2020 Ticket Prices

table.ticket_prices {
  max-width: 760px;
  margin-bottom:30px;

  tr {
    background: $light-gray !important;
    padding:0!important;
    border-bottom: 3px solid white;
    height: 60px;
    @include respond-min($small-breakpoint){
      height:65px;
    }
  }

  tr + th {
    background: transparent;
  }

  th {
    font-size: rem-calc(16);
    line-height: rem-calc(16);
    color: $text-gray;
    background: white;
    text-transform: capitalize;
    padding: 15px 25px;
    letter-spacing: 0;

    &:last-child{
      text-align: right;
    }
  }

  td {
    border-bottom:none;
    color: $title-gray;
    padding:0 0 0 25px;


    &:last-child{
      padding:0 22px;
      text-align: right;
    }

    @include respond-max($small-breakpoint){
      &:empty{
        display: none;
      }

    }


  }

  tr td:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  tr td:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  span.old-price {
    text-decoration: line-through;
    color: $text-gray;
  }

  span.new-price {
    color: $title-gray;
    font-weight: bold;
  }

  span.old-price + span.new-price{
    margin-left:20px;
  }

}


