	// ======================================================================
	// Big Header
	// ======================================================================

.big-header {
	@extend .clearfix;

	.bh__background {
		overflow: hidden;
	}

	.bh__background-color {
		position: absolute;
		width: 100%;
		height: 100%;
		background-color: $theme-color-2;
	}
	.bh__background-image {
		width: 100%;
		height: 100%;
		max-width: 1600px;
		margin: 0 auto;
		background-size: cover;
		background-position-x: 70%;
		background-position-y: top;
		background-repeat: no-repeat;

		@include respond-min($large-breakpoint) {
			background-position-x: center;
			background-size: auto;
			&.lazyload, &.lazyloading, &.lazyloaded {
				background-size: cover;
			}
		}
	}
}

.bh__noise {

	background: $theme-color-2 url('/_assets/16c122cebb6dd10fd754319ee821f8f8/Images/pattern.png') repeat center top;

	.big-header {
		background-color: transparent;

        .bh__background {
            background-color: transparent;
        }
	}
}

.bh__ {

	&theme-color-1 {
		background-color: $theme-color-1;
	}

	&theme-color-2 {
		background-color: $theme-color-2;
	}

	&theme-color-3 {
		background-color: $theme-color-3;
	}

	&theme-color-none {
		background-color: transparent;
	}

	&bg-image-auto, &bg-image-auto.lazyloaded {
		background-size: auto;
	}

	&bg-image-cover, &bg-image-cover.lazyloaded {
		background-size: cover;
	}

	&bg-image-contain, &bg-image-contain.lazyloaded {
		background-size: contain;
	}

	&bg-image-top-center {
          /*
          Skip because its default posiitiion
          background-position: center top !important;
          */
    }

	&bg-image-top-left {
		background-position: left top !important;;
	}

	&bg-image-top-right {
		background-position: right top !important;;
	}

	&bg-image-middle-right {
		background-position: right center !important;;
	}

	&bg-image-middle-left {
		background-position: left center !important;;
	}

	&bg-image-middle-center {
		background-position: center center !important;;
	}

	&bg-image-bottom-right {
		background-position: right bottom !important;;
	}

	&bg-image-bottom-left {
		background-position: left bottom !important;;
	}

	&bg-image-bottom-center {
		background-position: center bottom !important;;
	}

	&gradient {
		position: absolute;
		height: 100%;
		width: 100%;
		max-width: inherit !important;
		z-index: 99;
	}

	&video {
		z-index: 0;
		.bh__background {
			background-color: inherit;
			> div {
				max-width: rem-calc(1600);
				margin: 0 auto;
			}
		}
	}

	&content-wrap {
		width: 100%;
		max-width: rem-calc(1220);
		margin: 0 auto;
		position:relative;
		display: table;
		z-index: 101;

		@include respond-min($xlarge-breakpoint) {
			width: 100%;
		}
	}

	&content {
		display: table-cell;
		vertical-align: middle;
		padding: 130px 30px 130px 30px;
		//background: linear-gradient(260deg, transparent 0%, $title-gray 100%);

		@include respond-min($xlarge-breakpoint) {
			background: transparent;
			padding-top: 70px;
		}


	}

	&title {
		font-size: rem-calc(32);
		line-height: rem-calc(32);
		@include respond-min($large-breakpoint) {
			font-size: rem-calc(62);
			line-height: rem-calc(60);
			//letter-spacing: rem-calc(-2);
			letter-spacing: -0.5px;
			margin-left: rem-calc(-3);
		}
		//margin-bottom: 0;
		margin-bottom: 18px;
		margin-top: 0;
		color: white;
		text-shadow:0 2px 2px rgba(0,0,0,0.4);

		// <br/> tag + line class causes double break.
		br,
		.break {
			display: none;
		}

		// What's here? headline
		#c3169 &{
			text-align: center;
		}
	}

	&title-large {
		@include respond-min($large-breakpoint) {
			font-size: rem-calc(70);
		}
	}

	&subtitle {
		font-family: $font-family-2;
		font-style: italic;
		font-size: rem-calc(18);
		font-weight: normal;
		line-height: 1;
		color: white;
		margin-top: 0;
		margin-bottom: rem-calc(5);
		@include respond-min($large-breakpoint) {
			margin-bottom: rem-calc(15);
			font-size: rem-calc(22);
		}
	}

	&info {
		font-family: $font-family-1;
		font-weight: 700;
		font-style: normal;
	}

	&tagline {
		font-size: rem-calc(10);
		text-transform: uppercase;
		font-weight: 800;
		letter-spacing: rem-calc(3);
		color: white;
		margin-top: rem-calc(30);
		margin-bottom: 0;

	}

	&description {
		margin: rem-calc(10) 0 0;
		 p {
			color: white;
	 		opacity: .5;
			font-size: rem-calc(16);
			font-weight: 400;
			line-height: rem-calc(22);
			margin-bottom: 0;
		 }

		 &--big-text p {
			font-size: 18px;
			line-height: 23px;
			@include respond-min($large-breakpoint) {
				//font-size: 26px;
				font-size: 24px;
				line-height: 22px;
				//line-height: 26px;
			}
		 }

		@include respond-min($medium-breakpoint) {
			width: 60%;
			max-width: 535px;
			//max-width: 460px;
		}
	}

	&bottom-weight- {
		&1 {
			padding-bottom: rem-calc(80);
		}
		&2 {
			padding-bottom: rem-calc(120);
		}
		&none {
			padding-bottom: rem-calc(30);
		}
	}

	&height-size- {

		//Small - Interior
		&1 {
			.bh__content-wrap {
				height: rem-calc(204);

				@include respond-min($large-breakpoint){
					height: rem-calc(175);
					//330px
				}
			}

			.bh__content{
                padding:rem-calc(15 30 15 30);

				@include respond-min($large-breakpoint){
                    padding:rem-calc(25 30 5 30);
				}

				@include respond-min($xlarge-breakpoint){
					padding:15px 30px 0 30px;
				}
			}


			.bh__title {
				letter-spacing: -2px;
			}
		}

		//Medium - Membership

		&2 {
			.bh__content-wrap {
				height: rem-calc(505);
				@include respond-min($large-breakpoint){
					height: rem-calc(497);
					//600px
				}
			}

			.bh__content{
				padding: 100px 30px 70px 30px;
				@include respond-min($large-breakpoint) {
					padding:100px 30px 10px 30px;
				}
				@include respond-min($xlarge-breakpoint) {
					//padding: 45px 30px 70px 30px;
					padding: 20px 30px 55px;
				}
			}

			.bh__title{
				letter-spacing:-0.5px;
				line-height:37px;
				margin-bottom: 5px;
				@include respond-min($large-breakpoint){
					letter-spacing: -1px;
					line-height: 62px;
				}
			}
		}

		//Large - Home

		&3 {

			.bh__content-wrap {
				height: rem-calc(380);
				@include respond-min($large-breakpoint){
					height: rem-calc(455);
					//715px
				}
			}

			.bh__content {
				// background: linear-gradient(transparent 0%, $title-gray 100%);
				padding:197px 30px 0 30px;
                padding: rem-calc(10 30 60 30);

				@include respond-min($large-breakpoint){
					padding:30px 30px 90px 30px;
					//background: linear-gradient(260deg, transparent 0%, $title-gray 100%);
					background: transparent;
				}
			}

			.bh__link-button a{
				width:auto;
			}
		}

		//Exhibit (for Exhibit home styling look in pages.scss)

		&4 {
			.bh__content-wrap {
				height: rem-calc(150);

				@include respond-min($large-breakpoint){
					height: rem-calc(185);
				}
			}

			.bh__content{
				background: linear-gradient(transparent 80%, #030D26 100%);
				padding: 155px 30px 0 30px;

				@include respond-min($large-breakpoint) {
					padding: 200px 30px 10px 30px;
					background: transparent;
				}

				@include respond-min($xlarge-breakpoint) {
					padding:45px 30px 10px 30px;
				}
			}


			.bh__description--big-text p {
				font-size: 18px;
				line-height: 1.1;
				@include respond-min($large-breakpoint) {
					font-size: 24px;
					line-height: 24px;
				}
			}
		}


		&5 {
			.bh__content-wrap {
				height: rem-calc(450);
				@include respond-min($large-breakpoint){
					height: rem-calc(600);
				}
			}
		}
	}


	&links {

		list-style: none;
		margin-top: 22px;
		font-size: rem-calc(18);;
		line-height: rem-calc(32);


		@include respond-min($large-breakpoint) {
			width: 50%;
			margin-top: 25px;
			li {
				margin-left: rem-calc(-5);
			}
		}

		&.link-list {
			margin-bottom: 0;
            li:before {
                color: $theme-color-1-alt;
            }
            a {
                color: $theme-color-1-alt;
            }
		}
	}

	&link-button {
		margin-left: 5px;
		list-style: none;

		a{
			max-width:100%;
            background-color: $theme-color-1-alt !important;
			line-height: rem-calc(52);

			@include respond-min(400px){
				max-width:235px;
			}
		}
	}

	&link-underline {

		@include reset-ul;

		margin-top: rem-calc(8);

		@include respond-min($medium-breakpoint) {
			margin-top: rem-calc(27);
		}

		li {
			margin-bottom: rem-calc(20);
			a {
				@extend .link-underline;
				color: $theme-color-1-alt;
				font-size: rem-calc(18);
				line-height: rem-calc(32);
				padding-bottom: rem-calc(1);
				border-bottom-color: $theme-color-1-alt;

				&:hover,
				&:focus {
					color: white !important;
					border-bottom-color: white !important;
				}
			}
		}
		&.bh__link-alt-color li a {
			color: $white;
			border-bottom-color: $white;
		}
	}
}


// ======================================================================
// For when the Big Header is in #feature
// ======================================================================

#feature {
	.big-header .bh__background-image {
		padding-top: rem-calc(80);
		@include respond-min($large-breakpoint) {
			padding-top: rem-calc(150);
		}
	}

	.bh__content-wrap {
		//height: rem-calc(400);
		//margin-top: -3px;
		margin-top: 125px;
		@include respond-min($xlarge-breakpoint) {
			//margin-top: rem-calc(150);
			margin-top: rem-calc(180);
		}
	}

	@include respond-min($large-breakpoint) {
		.bh__content-wrap {
			//height: rem-calc(430);
		}

	}

	& .bh__{
		&height-size- {

			//Small - Interior
			&1 {
				.bh__content-wrap {
					height: rem-calc(204);

					@include respond-min($large-breakpoint){
						//height: rem-calc(175);
						height: rem-calc(115);
						//330px total
					}
				}

				.bh__content{
                    padding:rem-calc(15 30 15 30);

					@include respond-min($large-breakpoint){
                        padding:rem-calc(50 30 50 30);
					}

					@include respond-min($xlarge-breakpoint){
						//padding:15px 30px 0 30px;
						padding:25px 30PX 47px;
					}
				}


				.bh__title {
					letter-spacing: -2px;
					@include respond-min($large-breakpoint) {
						letter-spacing: -1px;
					}
				}
			}

			//Medium - Membership

			&2 {
				.bh__content-wrap {
					height: rem-calc(505);
					@include respond-min($large-breakpoint){
						height: rem-calc(497);
						//600px
					}
				}

				.bh__content{
					padding: 100px 30px 70px 30px;
					@include respond-min($large-breakpoint) {
						padding:100px 30px 10px 30px;
					}
					@include respond-min($xlarge-breakpoint) {
						padding: 45px 30px 120px 30px;
					}
				}

				.bh__title{
					letter-spacing:-0.5px;
					line-height:37px;
					margin-bottom: 5px;
					@include respond-min($large-breakpoint){
						letter-spacing: -1px;
						line-height: 62px;
						margin-bottom: 20px;

					}
				}
			}

			//Large - Home

			&3 {

				.bh__content-wrap {
					height: rem-calc(380);
					@include respond-min($large-breakpoint){
						height: rem-calc(455);
						//715px
					}
				}

				.bh__content {
					// background: linear-gradient(transparent 0%, $title-gray 100%);
					padding:197px 30px 0 30px;
                    padding: rem-calc(10 30 60 30);

					@include respond-min($large-breakpoint){
						//padding:95px 30px 88px 30px;
						padding:30px 30px 90px 30px;
						//background: linear-gradient(260deg, transparent 0%, $title-gray 100%);
						background: transparent;
					}
				}

				.bh__link-button a{
					width:auto;
				}
			}

			//Exhibit (for Exhibit home styling look in pages.scss)

			&4 {
				.bh__content-wrap {
					height: rem-calc(200);
					@include respond-min($large-breakpoint){
						height: rem-calc(250);
					}
				}

				.bh__content{
					background: linear-gradient(transparent 80%, #030D26 100%);
					padding: 155px 30px 0px 30px;
					@include respond-min($large-breakpoint) {
						padding:95px 30px 10px 30px;
						background: transparent;
					}

					@include respond-min($xlarge-breakpoint) {
						padding:10px 30px 10px 30px;
					}
				}

				.bh__description--big-text p {
					font-size: 18px;
					line-height: 18px;
					@include respond-min($large-breakpoint) {
						font-size: 24px;
						line-height: 24px;
					}
				}
			}

			&5 {
				.bh__content-wrap {
					height: rem-calc(450);
					@include respond-min($large-breakpoint){
						height: rem-calc(600);
					}
				}
			}
		}

	}



	// This is by Jerel. Added by JS to control height of header area.
	&.map {
		overflow: hidden;
		height: rem-calc(90);
		section.big-header {
			padding-top: rem-calc(90);
		}

		@include respond-min($large-breakpoint) {
			overflow: visible;
			height: auto;
			section.big-header {
				padding-top: 0;
			}
		}
	}
}


// ======================================================================
// For when the Big Header is in #feature and the feature footer is present
// ======================================================================

#feature.feature-footer-active {

	> .inner-wrap {

        &:has(.feature-footer__button) {
            padding-bottom: rem-calc(95);
            @include respond-min($large-breakpoint) {
                padding-bottom: 0;
            }
        }

		@include respond-min($large-breakpoint) {
			padding-bottom: 0;
		}

	}

	.big-header {

		@include respond-min($large-breakpoint) {
			padding-bottom: rem-calc(80);
			margin-bottom: 0;
		}
	}

  .bh__{
    &height-size- {
     //Small - Interior
 	&1 {
        .bh__content-wrap {
          height: rem-calc(320);

          @include respond-min($large-breakpoint){
            height: rem-calc(175);
          }
        }

	  	.bh__content{
            padding: rem-calc(10 30 50 30);

			  @include respond-min($large-breakpoint){
                  padding:rem-calc(25 30 5 30);
                
			  }

			  @include respond-min($xlarge-breakpoint){
				  padding:15px 30px 0 30px;
			  }
		  }

	  	.bh__title {
		  letter-spacing: -2px;
	  	}
      }

		// Medium - Membership
		&2 {
			.bh__content-wrap {
				height: rem-calc(505);
				@include respond-min($large-breakpoint){
					height: rem-calc(497);
					//600px
				}
			}

			.bh__content{
				padding: 160px 30px 10px 30px;
				@include respond-min($large-breakpoint) {
					padding:100px 30px 10px 30px;
				}
				@include respond-min($xlarge-breakpoint) {
					padding: 45px 30px 10px 30px;
				}
			}

			.bh__title{
				letter-spacing:-0.5px;
				line-height:37px;
				margin-bottom: 5px;
				@include respond-min($large-breakpoint){
					letter-spacing: -1px;
					line-height: 62px;
				}
			}
		}

		//Large - Home

		&3{

        .bh__content-wrap {
          height: rem-calc(380);
          @include respond-min($large-breakpoint){
            height: rem-calc(455);
			  //715px
          }
        }

		  .bh__content {
			  // background: linear-gradient(transparent 0%, $title-gray 100%);
			  padding:197px 30px 0 30px;
              padding: rem-calc(10 30 60 30);

			  @include respond-min($large-breakpoint){
				  padding:30px 30px 90px 30px;
				  //background: linear-gradient(260deg, transparent 0%, $title-gray 100%);
				  background: transparent;
			  }
		  }

		  .bh__link-button a{
			  width:auto;
		  }
      }

		//Exhibit (for Exhibit home styling look in pages.scss)

		&4 {
		  padding-bottom: 0rem;

		  @include respond-min($large-breakpoint){
			  padding-bottom: 4rem;
		  }

        .bh__content-wrap {
          height: rem-calc(150);

          @include respond-min($large-breakpoint){
            height: rem-calc(185);
          }
        }
		  .bh__content{
			  background: linear-gradient(transparent 80%, #030D26 100%);
			  padding: 155px 30px 0 30px;

			  @include respond-min($large-breakpoint) {
				  padding: 200px 30px 55px 30px;
				  background: transparent;
			  }

			  @include respond-min($xlarge-breakpoint) {
				  padding:45px 30px 55px 30px;
			  }
		  }


		  .bh__description--big-text p {
			  font-size: 18px;
			  line-height: 1.1;
			  @include respond-min($large-breakpoint) {
				  font-size: 24px;
				  line-height: 24px;
			  }
		  }
      }

      &5 {
        .bh__content-wrap {
          height: rem-calc(200);
          @include respond-min($large-breakpoint){
            height: rem-calc(370);
          }
        }
      }
    }
  }

}

.big-header {
	.line-wrap {
		display: block;
	}

	.line {
		display: block;
	}
}

.cycle-slideshow,
.carousel {

	.bh__title-large {
		@include respond-min($large-breakpoint) {
			line-height: normal;
			.line-wrap {
				margin: rem-calc(-10) 0;
			}
		}
	}
}

	// What's here? headline
	#c3169{
		.bh__content{
			padding: 0 30px 130px 30px!important;

			@include respond-min($medium-breakpoint){
				padding: 40px 30px 130px 30px!important;
			}

			@include respond-min($large-breakpoint) {
				padding:220px 30px 270px 30px!important;
			}

			@include respond-min($xlarge-breakpoint) {
				//padding:0 30px 130px 30px;
				padding:30px 10px 295px 30px!important;

			}
		}

		.bh__title{
			font-size: rem-calc(32);
			line-height: rem-calc(32);
			@include respond-min($medium-breakpoint) {
				font-size: rem-calc(62);
				line-height: rem-calc(60);
			}
		}
	}
