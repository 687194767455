// ======================================================================
// Styles that are used for some very rare situation, such as about page
// ======================================================================

// Robot Boy on Home Page

.layout-container + .robot-boy {
  // height: 900px;

  // @todo-ron not for sure why this is there
  // @include respond-min($medium-breakpoint) {
  // 	height: auto;
  // }


  // .lc__bgimage-bottom-left {
  // 	margin-top: 100%;
  // 	height: 750px;
  // 	@include respond-min($medium-breakpoint) {
  // 		margin-top: 0;
  // 		height: 100%;
  // 		// background-size: contain;
  // 		margin-left: rem-calc(-150);
  // 	}
  // 	@include respond-min($large-breakpoint) {
  // 		margin-left: rem-calc(-50);
  // 	}
  // 	@include respond-min($xlarge-breakpoint) {
  // 		margin-left: -38.125rem;
  // 	}
  // }

  .lc__background.lc__left-of-center {
    // background-size: auto rem-calc(400);
    background-position: center top;
    padding-top: rem-calc(275);

    @include respond-min($medium-breakpoint) {
      // background-size: auto rem-calc(400);
      background-position: center top;
      background-size: 602px 728px;
      padding-top: rem-calc(400);
      top: auto;
      bottom: 0;
      height: 0;
      width: 100%;
    }


    @include respond-min($large-breakpoint) {
      background-position: center bottom;
      background-size: auto 100%;
      padding-top: 0;
      top: 0;
      bottom: auto;
      height: 100%;
      width: 50%;
    }

    @include respond-min($xlarge-breakpoint) {
      background-size: 602px 728px;
      background-position: right bottom;
      width: 50%;
    }


    // @include respond-min($large-breakpoint) {
    // 	background-position: right top;
    // }
  }

  & .layout-container .row.lc__content.narrow-right .columns {
    @include respond-min($medium-breakpoint) {
      width: 100%;
      margin-left: 0;
      padding-bottom: rem-calc(400);
    }

    @include respond-min($large-breakpoint) {
      width: 50%;
      margin-left: 50%;
      padding-bottom: rem-calc(0);
    }
  }

  & .lc__mobile-image-bottom .lc__content {
    padding-bottom: rem-calc(275);

    @include respond-min($medium-breakpoint) {
      padding-bottom: 0;
    }
  }

  .indent{
    @include respond-min($medium-breakpoint) {
      margin-bottom:30px!important;
    }

    @include respond-min($large-breakpoint) {
      margin-left:95px;
    }
  }

  p.indent{
      margin-top: -7px;
  }


  .headline-group{
    // @include respond-min($xlarge-breakpoint) {
    //   margin-bottom: 0;
    // }

    .title {
       @include respond-min($xlarge-breakpoint) {
         padding-bottom: 40px;
       }
    }

    .subtitle{
      margin-bottom: 15px;
    }

  }

}

//Robot
#c34251.layout-container .row.lc__content.narrow-right .columns .lc__content-inner-wrap{
  @include respond-min($xlarge-breakpoint) {
    width: auto !important;
  }
}

// styling for support/shell-boy seciond on homepage
@include respond-min($xlarge-breakpoint) {
  .support-container .columns {
    max-width: rem-calc(300);
  }
}


// Home Page teasers for special change to white

#c59798 {
    &.lc__module-3-up .lc__background-2.white {
        background-color: white !important;
    }

    .full_image_box_teaser .full_image_box_teaser__title, .full_image_box_teaser .full_image_box_teaser__description {
        @include respond-max($xlarge-2-breakpoint) {
            color: var(--theme-color-2) !important;
            text-shadow: none;
        }
    }

    .full_image_box_teaser .full_image_box_teaser__content {
        @include respond-max($xlarge-2-breakpoint) {
            @include box-shadow(rgba(0,0,0,0.1) 0px 7px 15px);
            border: rem-calc(1) solid $gray-10;
            border-left: none;
        }
    }
    .full_image_box_teaser a {
        min-height: rem-calc(85);
    }
}


// styling for id=13 page



#c5449 .lc__background {
// background-position-y: 40px;
@include respond-min($xlarge-breakpoint) {
    background-position-y: 120px;
}

}

#c3668 {
  .bn__page-title {
    line-height: rem-calc(48);
    border-bottom: 3px solid $theme-color-1-alt;
    display: inline;

    br,
    .break {
      display: none;
    }

    @include respond-min($large-breakpoint) {
      line-height: rem-calc(78);
      border-bottom-width: 4px;
      font-size: rem-calc(60);
      letter-spacing: rem-calc(-2);

      br,
      .break {
        display: block;
      }
    }
  }

  p {
    margin-top: rem-calc(30);

    @include respond-min($large-breakpoint) {
      margin-top: rem-calc(50);
    }
  }
}

// end of styling for id=13 page


// styling for id=15 & id=16 pages

.small-italic-feature-text {


  .headline-group.with-rule .title {
    @include respond-min($large-breakpoint) {
      padding-bottom: rem-calc(50);
    }
  }

  .rte-content p.feature-text {
    opacity: 0.8;
    font-size: rem-calc(20);
    font-style: italic;


    @include respond-min($large-breakpoint) {
      padding-top: rem-calc(20);
      margin-left: rem-calc(40);
      opacity: 0.5;
      width: rem-calc(310);

    }
  }
}

.gray-small-feature-text {
  .rte-content p.feature-text {
    font-size: rem-calc(20);
    color: $gray-60;
    padding-bottom: rem-calc(20);

    @include respond-min($large-breakpoint) {
      padding-right: rem-calc(50);
    }
  }
}

#c5235.lc__bgimage-middle-left .lc__background {

  background-size: cover;

  @include respond-min($xlarge-breakpoint) {
    background-size: auto;
  }

}

#c5207.lc__bgimage-middle-left .lc__background {

  background-size: cover;

  @include respond-min($small-breakpoint) {
    background-size: contain;
  }

  @include respond-min($xlarge-breakpoint) {
    background-size: auto;
  }

}

// end of styling for id=15 & id=16 pages

// Styling Questions/problems contact file
#c4266{
  text-align: center;
  @include respond-min($large-breakpoint){
    text-align: left;
  }
}

// Styling Contact Us

#c4535{
  background:$gray-05;
  text-align: center;
  border-radius: 10px;

  div.ce-content{
    padding: 30px;
  }

  p{
    margin-bottom: 0;
  }

  p.smaller{
    line-height:rem-calc(19);
    margin-bottom: 15px;
  }

  .rte-button-green{
    width:100%;
    display: block;
  }
}

// Professional development

#c16381{
  div.ce-content.rte-content.rte-text-medium > p:nth-child(3){
    margin-bottom: 3px;
  }

  a.small{
    @include respond-min($xlarge-breakpoint){
      padding-left:30px;
    }
  }
}

// Visiting Chicago page

//logo
#c24387{

  .tai__image-group-wrap{
    margin-bottom:10px;

    img{
      max-width: 180px;
    }
  }
  .rte-content p{
    font-size: 20px;
  }
}

// Chicago city pass

#c9887, #c9889, #c9890{
  border-radius:10px;

  & .bm__image-wrap{
    border-radius: 10px 10px 0 0;
    background-color: black;
    float: none;
    width: 100%;
    max-width:100%;
    padding: 0;

    img {
      border-radius: 10px 10px 0 0;
      max-width:155px;
      margin:0 auto;
    }
  }

  & .bm__content-wrap{
    border-radius: 0 0 10px 10px;
    background-color: $gray-05;
    text-align:center;
    padding:15px 25px 25px;
    border-top: 1px solid rgba(white, 0.2);

    h4{
      color:black;
      margin-top:0;
      margin-bottom:5px;
    }

    p{
      line-height: 20px;
      color:$text-gray;
    }

    .blue-link{
      color:$theme-color-1;
      font-size: 14px;
    }
  }
}

#c4835{

  & .button.button-table.large{
    margin-bottom:8px;
  }

}

// Teachers courses & Corporate support

#c8098, #c833, #c20221, #c4588{
  border-radius:10px;
//   text-align: center;
  border-bottom:0;
  background:$gray-05;


//   .bm__teaser-module-content p{
//     margin-bottom: 16px;
//     line-height: 20px;
//   }

  .bm__content-wrap {
    padding: 25px 25px;
    border-radius: 10px;
    background:$gray-05;
    margin:auto;
    width:100%;

    & .mh__headline-group{
      margin-bottom:0;

      h4{
        margin-bottom: 5px;
      }
    }
  }

//   & .bm__footer a{
//     font-size: 18px;
//     padding: 15px 10px;
//     margin-bottom: 0;
//   }

}

#c833{

  @include respond-max($medium-breakpoint){
    margin-bottom: 10rem!important;
  }

  & .bm__image{
    border-radius: 10px 0 0 10px;

    @include respond-min($large-breakpoint){
      border-radius: 10px 10px 0 0;
    }
  }

  & .bm__image-wrap {
    padding-right: 0;
    padding-bottom:0;
    max-height: 210px;
    overflow: hidden;
    border-radius: 10px 10px 0 0;

    @include respond-min($small-breakpoint){
      //padding-right: 2.5rem;
      border-radius: 10px 0 0 10px;
    }

    @include respond-min($large-breakpoint){
      //padding-right: 2.5rem;
      border-radius: 10px 10px 0 0;
    }

  }

  & .bm__content-wrap{
    border-radius: 0 0 10px 10px;
    width:100%;
    padding:20px 25px 20px;

    @include respond-min($small-breakpoint){
      border-radius: 0 10px 10px 0;
      padding:20px 25px 10px;
      width:50%;
    }

    @include respond-min(513px){
      padding:35px 25px 10px;
    }

    @include respond-min($large-breakpoint){
      border-radius: 0 0 10px 10px;
      width:100%;
      padding:25px 25px;
    }
  }

  & .bm__footer a{
    font-size: 14px;
  }
}

.two-column-courses .lc__content-inner-wrap{
  border-radius: 10px;
  background:$gray-05;

  display: flex;
  align-content: space-between;
  justify-content: space-between;
  align-items: stretch;
  flex-flow:column wrap;
  padding:28px 35px;

  @include respond-min($xlarge-breakpoint){
    flex-flow:row wrap;
  }

  & .text-and-images{
    flex-basis:auto;
    text-align: center;

    @include respond-min($xlarge-breakpoint){
      text-align: left;
    }

    @include respond-min($xlarge-breakpoint){
      flex-basis: 50%;
    }

    .ce-content{
      margin-bottom: 0;
    }

    h3{
      margin-bottom: 7px;
    }

    p{
      margin-bottom: 15px;
      @include respond-min($xlarge-breakpoint){
        margin-bottom: 0;
      }
    }
  }

  & .button.button-table{
    background-color: $color-2;
    align-self: center;
    height:55px;
    font-size: 18px;
    margin-bottom: 0;
    width:100%;
    padding: 0;

    span{
      text-align: center!important;
      padding: 0;
    }

    @include respond-min($xlarge-breakpoint){
      width:228px;
    }
  }
}


#sidebar > #c8098{
  padding-top: 0!important;
  padding-bottom: 0!important;
}

//Accessibility

#c6176{
  .rte-text-medium p{
    margin-bottom:rem-calc(15);
  }
}


//Field trips page
#c3718{
  .circle-icon-links{
    margin-top:10px;

    p{
      margin-bottom:2px;

      a{
        font-weight:bold;
        font-size:16px;
      }
    }

    .icon-circle{
      background-color: $gray-05;
      border-color: $gray-05;
      width: 14px;
      height: 14px;
      margin: 0;
      margin-left: 10px;
      top: 2px;
      position: relative;

      & .icon {
        margin: 0;
        font-size: 14px;
        line-height: 14px;
        position: absolute;
        top: -1px;
        right: -2px;
        color: $theme-color-1;
        width: 14px;
        height: 14px;
      }
    }

  }
}

#c34508{
  #faqs.msi-accordion{
    margin-bottom:20px;
  }
}

#c3719{
  .base-title{
    margin-bottom: 0.4em;
  }
}

#c34524{

  a.button{
    min-width:232px;
  }
}

// Membership page header

#c34356{
  .bh__links{
    width:100%;

    &>li{
      width: 100%;

      @include respond-min(850px){
        min-width: 450px;
      }

      &>a{
        margin-right:27px;
        margin-bottom: 10px;
      }

      &:after{
        content:"Starting from $95 (Tax deductible)";
        font-size: 14px;
        line-height: 16px;
        font-style: italic;
        display:inline-block;
      }
    }
  }
}

// Tickets page
// citypass

#c8901{
  .tai__image-group{

    img{
      border-radius:10px;
    }

  }
}

//Links button below table

.ticketprices_links{
  @include respond-max($medium-breakpoint){
    & .half{
      text-align: center;
    }
  }
}

#c9443{
  @include respond-max($xlarge-breakpoint){
    padding-bottom: 10rem;
  }

  @include respond-max($large-breakpoint){
    padding-bottom:0;
  }

}

.less_padding-top{

  @include respond-min($xlarge-breakpoint){
    padding-top:2.5em;
  }
}


// https://www.msichicago.org/visit/today-at-msi/ Google translate button

#c48081 .translate-btn,
#c56456 .translate-btn {
    position: relative;
    max-width: rem-calc(300);
    padding-left: 0;
}

#c48081 .translate-btn .last-icon-cell,
#c56456 .translate-btn .last-icon-cell {
    right: rem-calc(10);
}


#c48081 .translate-btn select,
#c56456 .translate-btn select {
    max-width: rem-calc(300);
    height: rem-calc(50);
    border-radius: rem-calc(5) !important;
    background-color: $theme-color-1;
    line-height: rem-calc(20);
}

#c48081 .translate-btn select:hover,
#c56456 .translate-btn select:hover {
    background-color: $theme-color-2;
}
