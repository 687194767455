	// ======================================================================
	// TICKETING
	// ======================================================================
@use "sass:math";

.tip- {
	&parent {
		position: relative;
		font-size: rem-calc(13) !important;
		letter-spacing: rem-calc(-0.3);

		.title {
			font-size: rem-calc(14);
			letter-spacing: normal;
		}
	}

	&popup {
		position: absolute;
		top: 0;
		left: 50%;
		@include box-shadow(rgba(0,0,0,0.1) 0px 4px 6px);
	}

	&popup .arrow-down {
		height: 0;
		width: 0;
		border-top: 6px solid #ffffff;
		border-left: 6px solid transparent;
		border-right: 6px solid transparent;
		position: absolute;
		left: 50%;
		margin-left: rem-calc(-6);
		z-index: 510;
	}

	&content {
		position: absolute;
		bottom: 0;
		left: -50%;
		background-color: #fff;
		padding: rem-calc(15 0);
		@include box-shadow(rgba(0,0,0,0.25) 0px 2px 25px -4px);
		z-index: 500;
		text-align: center;
		color: $theme-color-2;
		font-size: rem-calc(12);
		font-weight: 700;

		.card-image {
			margin-bottom: rem-calc(-15);
		}

		.icon {
			font-size: rem-calc(37);
			display: block;
			margin: 0 auto;

			&.icon-child {
				margin-top: rem-calc(7);
				font-size: rem-calc(30);
			}
		}

		&.two-column {
			width: rem-calc(192);
			margin-left: rem-calc(-96);
		}

		&.one-column {
			width: rem-calc(92);
			margin-left: rem-calc(-48);
		}

		.columns:last-of-type {
			border-left: 1px solid #e6e6e6;
		}
	}

	.postfix-radius &popup {
		top: rem-calc(-22);
	}

	.payment-info &popup {
		top: rem-calc(-30);
	}

	.postfix-radius &content,
	.payment-info &content {
		width: rem-calc(335);
		padding: rem-calc(15);
	}

	.payment-info &content {
		margin-left: rem-calc(-215);
	}

	.postfix-radius &content {
		margin-left: rem-calc(-279);
	}

	&trigger {
		cursor: pointer;
	}
}

#f1a.interior.bleed.ticketing,
#f1a.interior.bleed.tickets {
	background-color: $theme-color-3;

	#content-wrap {
		background-color: white;

		@include respond-max($xlarge-breakpoint - 1) {
		   > .columns {
			   padding-right: rem-calc(20);
			   padding-left: rem-calc(20);
		   }
		}
	}
}

.ticketing {
	#feature {
		padding: rem-calc(71 0 36) !important;
	}

	#header {
		padding: rem-calc(10 0 0 0);
		// @include respond-min($large-breakpoint) {
		// 	padding-top: rem-calc(20);
		// }

		>.columns {
			padding: 0;
			@include respond-min($large-breakpoint) {
				padding-right: 20px;
				padding-left: 20px;
			}
		}

		a[href="/"]:hover,
		a[href="/"]:focus {
			text-decoration: none;
		}

		.site-id {
			float: none;
			// background-size: 157px auto;
			margin: 0 0 10px 20px;
			width: 170px;
					height: 49px;
			@include respond-min($large-breakpoint) {
				// width: rem-calc(170);
				// height: rem-calc(49);
				// margin: rem-calc(0 0 10);
				// background-size: 170px auto;
				// margin-top: 6px;
			}
		}

		.breadcrumb-share-wrap {
			display: block;
			border-top: 2px solid rgba(255,255,255,.2);
			padding: 0;
		}

		.share {
			display: none;
			position: absolute;
			right: 20px;
			top: 10px;
			text-transform: uppercase;
			font-size: rem-calc(8);
			font-weight: 700;
			letter-spacing: .0625rem;
			@include respond-min($small-breakpoint) {
				display: block;
			}
			// @include respond-min($large-breakpoint) {
			// 	top: 25px;
			// }
		}
	}
}

.ticketing,
.tickets {
	.reveal-wrap {
		// display: none;
		min-height: rem-calc(48);
		margin: rem-calc(-48 0 0);
		transition: margin 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
		.alert-box {
			// min-height: rem-calc(72);
			opacity: 0;
			// margin-bottom: rem-calc(-72);
			transform: scale(0,0);
			transition: transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
		}
		&.view {
			margin: rem-calc(-16 0 0);
			@include respond-min($medium-breakpoint) {
				// margin: 0;
			}
			.alert-box {
				// min-height: rem-calc(0);
				opacity: 1;
				transform: scale(1,1);
				margin-bottom: rem-calc(0);
			}
		}
	}

	.alert-box {
		// opacity: 1;
		padding: rem-calc(12 16);
		// margin-top: rem-calc(-10);
		margin-bottom: rem-calc(2);
		// transform: scale(1,1);
		transition: transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1),
			margin 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
		background-color: $light-gray;
		color: $text-color-base;
		@include respond-min($large-breakpoint) {
			max-width: rem-calc(540);
		}
		&.hidden-alert-box {
			min-height: rem-calc(72);
			// max-height: 0;
			// height: 0;
			opacity: 0;
			// padding: 0;
			margin-bottom: rem-calc(-72);
			transform: scale(0.01,0.01);
			& + .visitor-table {
				// margin-top: rem-calc(-72);
				transition: margin 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
			}
		}
		& + .visitor-table {
			// margin-top: rem-calc(0);
			transition: margin 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
		}
		.icon-circle {
			float: left;
			font-size: rem-calc(12);
			// margin-top: rem-calc(-5);
			margin-bottom: 0;
			margin-right: rem-calc(16);

			.icon {
				$size: 1.2;
				font-size: $size + em;
				line-height: $size + em;
				width: math.div(2, $size) + em;
				height: math.div(2, $size) + em;
				@media screen\0 {
					line-height: 1.9em;
				}
			}
		}

		&.success {
			background-color: lighten($success-color, 45%);
			color: $success-color;
			.icon-circle {
				background: $success-color;
				border-color: $success-color;
			}

		}
		&.alert {
			background-color: $alert-color;
			color: $alert-font-color;
		}
		&.alert .text-circle,
		.alert.text-circle {
			background-color: $alert-font-color;
			border-color: $alert-font-color;
			.text-inner-wrap {
				color: white;
				font-weight: 700;
				font-size: rem-calc(24);
				line-height: rem-calc(24);
			}
		}
	}

	.alert-box-inner {
		display: table;
		width: 100%;
		.icon-wrap,
		.description {
			display: table-cell;
			vertical-align: middle;
		}
		.icon-wrap {
			width: rem-calc(48);
		}
	}

	.add-experience {
		margin: 0;
		@include respond-min(rem-calc(980)) {
			width: rem-calc(960);
		}
	}
	.pika-single.is-bound {
		@include respond-min($medium-breakpoint) {
			margin: rem-calc(20 0 0 51);
		}
	}

	.rmm__left {
		width: 66%;
		@include respond-min($large-breakpoint) {
			width: 40%;
		}

		ul.arrow-list {
			margin: 0;

			&>li {
				line-height: 1.4;
			}
		}
	}

	@include respond-min($large-breakpoint) {
		.primary-sidebar-button.stuck {
			position: fixed;
			z-index: 1700;
			overflow: hidden;
			// background-color: #0a1f54;
			transform: translateY(81px);
			box-shadow: 0 5px 2px rgba(0,0,0,.4);
			height: 81px;
			top: -81px;
			width: rem-calc(300);
		}
	}


	#content-wrap {
		min-height: rem-calc(400);
		@include respond-min($xsmall-breakpoint) {
			> .columns {
				padding-right: rem-calc(30);
				padding-left: rem-calc(30);
			}
		}
	}

	.column-group.inset,
	.timeline-inner-wrap {
		margin: 0 auto;
		max-width: 60rem;
		width: 100%;

		> .column,
		> .row > .columns {
			padding: 0;
			@include respond-min($medium-breakpoint) {
				padding-left: .625rem;
				padding-right: .625rem;
			}
		}
	}

	.columns.spread {
		padding: 0;
	}

	.ticketing-footer {
		max-width: 100%;
		background-color: white;

		&.summary {
			background-color: $theme-color-2;
		}

		.footer-extend {
			background-color: $theme-color-3;
			height: rem-calc(90);
			margin-top: rem-calc(-90);
			@include respond-min($large-breakpoint) {
				height: rem-calc(148);
				margin-top: rem-calc(-148);
			}
		}


	}

	#footer .footer-end {
		margin-top: 0;
	}

	.timeline-inner-wrap {
		max-width: 52rem;
	}

	#warning {
		background: $alert-color;
		color: $alert-font-color;
		padding: rem-calc(40);
	}

	.timeoutWarning {
		// @extend .row;
		position: fixed;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 9999;

		.errorImage {
			display: none;
		}

		.timeoutText {
			text-align: center;
		}
	}

	.inner-wrap > .slideBase {
		display: none !important;
	}

	.fancybox-overlay {
		background: $theme-color-3--alpha-95;
	}

	.fancybox-skin {
		padding: 0 !important;
		position: relative;

		// .row {
		// 	@include respond-min($large-breakpoint) {
		// 		width: rem-calc(768 - 40);
		// 	}
		// 	@include respond-min($xlarge-breakpoint) {
		// 		width: rem-calc(940);
		// 	}
		// }

		.button {
			float: right;
		}
	}

	.fancybox-inner {
		-webkit-overflow-scrolling: touch;
	}

	.fancybox-inner > .ember-view > .row {
		@extend .clearfix;
	}

	@media only screen and (min-width:rem-calc(1)) and (max-width: $medium-breakpoint) {
		.fancybox-wrap {
			width: 100% !important;
			top: 0 !important;
			left: 0 !important;
		}

		.fancybox-overlay {
			background: white;
		}

		.fancybox-inner {
			height: 100vh !important;
			width: auto !important;
		}
	}

	#feature {
		padding: rem-calc(0 0 36);

		@include respond-min($large-breakpoint) {
			padding-bottom: rem-calc(0);
		}

		> .inner-wrap {
			min-height: rem-calc(81);
		}

		.breadcrumb-wrap {
			// margin: rem-calc(0 -30 0 40);
			// padding-left: rem-calc(15);
			max-width: rem-calc(960);
			width: 100%;
			@include respond-min($large-breakpoint) {
				margin: 0 auto;
			}
		}

		.container {
			margin-left: rem-calc(-15);
			margin-right: rem-calc(-15);
			padding: 0;
			overflow: auto;
			width: auto;
			float: none;
			@include respond-min($xsmall-breakpoint) {
				margin-left: rem-calc(-30);
				margin-right: rem-calc(-30);
			}
			@include respond-min($large-breakpoint) {
				overflow: hidden;
				margin-left: auto;
				margin-right: auto;
			}
			// @include respond-min($large-breakpoint) {
			// 	padding-left: 0;
			// 	padding-right: 0;
			// 	float: left;
			// }
			&::-webkit-scrollbar {
				display: none;
			}
		}

		.breadcrumbs {
			@include clearfix;
			background: none;
			border: none;
			border-radius: 0;
			padding: rem-calc(42 0 0 15);
			white-space: nowrap;
			position: relative;
			display: inline-block;
			min-width: rem-calc(720);
			margin: rem-calc(0 0 0 15);
			@include respond-min($xsmall-breakpoint) {
				margin-left: rem-calc(30);
			}
			@include respond-min($large-breakpoint) {
				display: table;
				min-width: 0;
				// margin: rem-calc(60) auto rem-calc(39);
				margin: rem-calc(0) auto rem-calc(15);
				// padding: rem-calc(64 20 0);
				padding: rem-calc(24 20 0 0);
			}

			li:hover ~ li.current .crumb,
			li:focus ~ li.current .crumb {
				border-color: transparent;
				color: white;
			}

			&:hover li.current,
			&:focus li.current {
				// .crumb {
				// 	border-color: transparent;
				// 	color: white;
				// }
				&:hover .crumb,
				&:focus .crumb {
					color: $theme-color-1;
					border-bottom-color: $theme-color-1;
				}
			}

			li {
				display: inline-block;
				float: none;
				padding: rem-calc(0 16 0 0);
				font-size: rem-calc(18);
				@include respond-min($large-breakpoint) {
					// padding: rem-calc(6) 0 rem-calc(12);
					padding: 0;
				}

				&:first-of-type {

					&:before {
						display: none;
					}
				}

				&:before {
					@extend %icon-anglebracket-right;
					display: inline-block;
					top: 3px;
					font-family:"icons";
					color: #546388;
					// width: 1em;
					font-size: rem-calc(22);
					margin: 0 -.3em;
					padding-right: rem-calc(24);
					@include respond-min($large-breakpoint) {
						padding-right: 0;
						margin: 0 rem-calc(22);
					}
					@include respond-min($xlarge-breakpoint) {
						margin: 0 rem-calc(41);
					}
				}

				&:last-of-type {
					padding-right: 0;
				}

				.crumb {
					display: inline-block;
					// float: right;
					text-decoration: none;
					color: #787e94;
					font-weight: bold;
					padding: rem-calc(0 0 10);
					border-bottom: 2px solid transparent;
				}

				&.success .crumb {
					color: white;
				}

				&.success .crumb:hover,
				&.success .crumb:focus
				&.current .crumb {
					color: $theme-color-1;
					border-bottom-color: $theme-color-1;
				}
			}
		}

		&.success-feature {
			background-image: image-url("feature-purchase-validation.jpg");
			background-position: center;
			background-repeat: no-repeat;
			padding: 0;
		}

		.success-message {
			margin: rem-calc(60 0 30);
			padding: rem-calc(75 20 45);

			// border-top: 2px solid #546388;
			position: relative;
			@include respond-min($large-breakpoint) {
				padding: rem-calc(135 192 28);
			}

			.title {
				text-align: center;
				color: white;
				margin-bottom: 0;
				@include respond-min($large-breakpoint) {
					font-size: rem-calc(60);
					line-height: rem-calc(60);
					margin-bottom: rem-calc(50);
				}
			}

			.icon-circle {
				background: $success-color;
				border-color: $success-color;
				margin: 0 0 rem-calc(-64 -36);
				position: absolute;
				bottom: 0;
				left: 50%;
				font-size: rem-calc(36);

				.icon {
					$size: 1;
					font-size: $size + em;
					line-height: $size + em;
					width: math.div(2, $size) + em;
					height: math.div(2, $size) + em;
					@media screen\0 {
						line-height: 1.9em;
					}
				}
			}
		}
	}

	.inner-content-block-1 {
		margin: rem-calc(40 5 0);
		@include respond-min($xsmall-breakpoint) {
			margin: rem-calc(40 26 0);
		}
		@include respond-min($large-breakpoint) {
			margin: rem-calc(80 0 0);
		}
	}

	.inner-content-block-1 > .title {
		font-size: rem-calc(28);
		line-height: rem-calc(30);
		margin-top: rem-calc(7);
		margin-bottom: rem-calc(34);
		@include respond-min($medium-breakpoint) {
			font-size: rem-calc(38);
			line-height: rem-calc(40);
		}

		+ p {
			display: none;
			@include respond-min($medium-breakpoint) {
				display: block;
			}
		}
	}

	.title .subtitle {
		display: block;
		font-size: rem-calc(28);
		line-height: rem-calc(35);
		@include respond-min($medium-breakpoint) {
			font-size: rem-calc(24);
			line-height: rem-calc(30);
		}
	}

	.un-bold {
		@include respond-min($medium-breakpoint) {
			font-weight: normal;
		}
	}

	.inner-content-block-1 > p {
		font-size: rem-calc(16);
		line-height: rem-calc(28);
	}

	.postfix-radius input {
		&[type=text] {
			color: $text-color-base;
			border: none;
			box-shadow: none;
			margin: 0;
			background: white;
			padding: rem-calc(32 25);
			font-size: rem-calc(16);
			line-height: rem-calc(16);
			padding: rem-calc(34 10 28);
			@include respond-min(rem-calc(769)) {
				padding: rem-calc(0 8);
				font-size: rem-calc(14);
				height: rem-calc(40);
			}
			@include respond-min($xlarge-breakpoint) {
				padding: rem-calc(0 10);
				height: rem-calc(42);
			}
			&::-ms-clear {
				display: none;
			}
		}

		@include input-placeholder {
			color: $gray;
			font-weight: normal;
		}
	}

	// @todo ron this is not being used now for ticketing membership

	// 00

	#content-block-1 .membership-options-row {
		max-width: 920px;
		margin: 0 auto;
	}

	.resend-confirmation-text a {
		display: block;
		@include respond-min($small-breakpoint) {
			display: inline;
		}
	}

	form {
		&#resend-confirmation .columns {
			padding: 0;
		}

		.columns {
			padding: rem-calc(0 11 0 0);
			&:nth-child(2) {
				padding-left: rem-calc(11);
				@include respond-max($small-breakpoint) {
					padding-left: 0;
				}
			}
		}
	}

	.membership,
	.not-member,
	.membership-perks {
		margin-top: rem-calc(48);
		@include respond-min(rem-calc(800)) {
			margin-top: rem-calc(85 0 0);
		}
		@include respond-min(rem-calc(769)) {
			height: rem-calc(286);
		}
	}

	.membership {
		background-color: $light-gray;
		padding: rem-calc(38) 12% rem-calc(60);

		margin-left: -0.625rem;
		margin-right: -0.625rem;
		@include respond-min($xsmall-breakpoint) {
			margin-left: auto;
			margin-right: auto;
		}
		@include respond-min($medium-breakpoint) {
			padding: rem-calc(38) 8%;
			width: 100%;
			float: right;
		}
		@include respond-min(rem-calc(769)) {
			padding: rem-calc(38) (math.div(70, 480) * 100%) 0;
			min-height: rem-calc(286);
		}

		&.not-member {
			background: $theme-color-2;
			transition: .3s background-color ease-in-out;

			.title {
				color: white;

				@include respond-min(rem-calc(769)) {
					margin-bottom: rem-calc(99)
				}
			}

			p {
				color: rgba(255,255,255,0.5);
				text-align: center;
			}

			.button-table.ladda-loading,
			.button-table:hover,
			.button-table:focus {
				background: white;
				color: $theme-color-1;
				text-shadow: none;

				.last-icon-cell {
					border-left-color: $theme-color-1-light;
					.icon {
						color: $theme-color-1;
						text-shadow: none;
					}
				}
			}
		}

		&.membership-perks {
			background: white;
			outline: 1px solid $lighter-gray;
			outline-offset: -1px;

			.title {
				text-align: left;
			}
		}

		.icon-member {
			top: 48%;
			left: 54%;
		}

		.title {
			text-align: center;
			font-size: rem-calc(28);
			line-height: rem-calc(30);
			margin-bottom: rem-calc(18);
			letter-spacing: rem-calc(-1);
			@include respond-min(rem-calc(769)) {
				margin-bottom: rem-calc(15);
			}

			br,
			.break {
				display: none;
				@include respond-min(rem-calc(769)) {
					display: block;
				}
			}
		}

		small.error {
			margin-bottom: 0;
		}

		p,
		ul {
			font-size: rem-calc(16);
			line-height: rem-calc(26);
			margin-top: rem-calc(-4);
			margin-bottom: rem-calc(36);
		}

		ul {
			margin-bottom: rem-calc(17);
		}

		a {
			font-weight: 600;
		}

		.button-table {
			margin-bottom: 0;
			margin-top: rem-calc(32);
			font-size: rem-calc(22);
			height: rem-calc(52);
			@include respond-min(rem-calc(769)) {
				font-size: rem-calc(16);
			}
			@include respond-min(rem-calc(920)) {
				font-size: rem-calc(22);
			}

			&.expand {
				padding: rem-calc(12 7 13 29);

				&.large {
					padding: rem-calc(12 7 13 29);
					@include respond-min(rem-calc(769)) {
						padding-left: rem-calc(22);
					}
					@include respond-min(rem-calc(920)) {
						padding-left: rem-calc(29);
					}
				}
			}

			.text-cell {
				text-align: center;
			}

			.icon {
				font-size: rem-calc(30);
				@include respond-min(rem-calc(769)) {
					font-size: rem-calc(24);
				}
				@include respond-min(rem-calc(920)) {
					font-size: rem-calc(30);
				}
			}
		}
	}

	.layout-container {
		margin-top: rem-calc(48);
		margin-left: rem-calc(36);
		@include respond-min(rem-calc(769)) {
			margin-top: rem-calc(120);
		}

		&.ticket-confirmation {
			margin: rem-calc(120) 0 0;
			text-align: center;
		}
	}

	.postfix-radius {
		margin: 0;
		max-width: none;
		width: auto;
		border: 2px solid #B5B9C7;
		background: white;
		margin-top: rem-calc(18);
		margin-bottom: rem-calc(30);
		position: relative;
		@include respond-min(rem-calc(769)) {
			margin-top: rem-calc(20);
			margin-bottom: rem-calc(17);
		}

		.columns {
			position: static;

			@include respond-min(rem-calc(769)) {
				padding-left: 0;
				padding-right: 0;
			}

			@include respond-min(rem-calc(920)) {
				padding-left: rem-calc(10);
				padding-right: rem-calc(10);
			}
		}

		.error {
			position: absolute;
			z-index: 500;
			min-height: rem-calc(64);
			bottom: rem-calc(64);
			right: -2px;
			left: -2px;
			display: block;
			color: $alert-font-color;
			font-style: normal;
			font-weight: 600;
			font-size: rem-calc(16);
		}
	}

	.icon-i-circle,
	.icon-refresh {
		font-size: rem-calc(20);
		color: #B5B9C7;
		float: right;
	}

	.postfix {
		border: none;
		background: none;
		padding-top: rem-calc(24);
		@include respond-min(rem-calc(769)) {
			padding-top: rem-calc(10);
		}
		@include respond-min($xlarge-breakpoint) {
			padding-top: rem-calc(11);
		}

		.icon {
			cursor: pointer;
		}
	}

	// end not being used

	// 01


	.msi-table {
		width: 100%;
		background: transparent;
		border: none;
		margin-bottom: rem-calc(40);
		table-layout: auto;
		border-collapse: collapse;
		@include respond-min($large-breakpoint) {
			max-width: rem-calc(540);
		}

		td {
			border-bottom: 1px solid $lightest-blue-gray;
			vertical-align: middle;
			padding: rem-calc(20) 0 rem-calc(26);
		}

		&.visitor-table tr {
			height: rem-calc(103);
		}

		.header-cell {
			// width: rem-calc(295);
			@include respond-min($large-breakpoint) {
				padding-top: rem-calc(34);
			}

			.title,
			.subtitle {
				display: block;
				font-weight: bold;
			}

			.title {
				font-size: rem-calc(22);
				line-height: rem-calc(25);
				color: $theme-color-2;
			}

			.subtitle {
				font-size: rem-calc(10);
				color: $light-blue-gray;
				text-transform: uppercase;
				letter-spacing: rem-calc(1.8);
			}
		}

		.price-cell {
			font-size: rem-calc(18);
			font-weight: 400;
			color: $light-blue-gray;
			width: 22%;
			@include respond-min($large-breakpoint) {
				padding-top: rem-calc(18);
				width: math.div(103, 540) * 100%;
			}

			.active-promo {

				.digits {
					color: $success-color;
				}
			}

			s,
			.digits {
				display: block;
				@include respond-min($small-breakpoint) {
					display: inline;
				}
			}

			s {
				color: $light-blue-gray;
				margin-right: 1ex;
				letter-spacing: .1125rem;
				width: rem-calc(37);
				font-size: rem-calc(10);
				@include respond-min($small-breakpoint) {
					font-size: rem-calc(15);
					letter-spacing: 0;
				}
			}

			.digits {
				margin-left: rem-calc(3);
				margin-right: 1ex;
				display: inline-block;
				line-height: rem-calc(25);
				color: $theme-color-1;
				font-weight: bold;
				@include respond-min($small-breakpoint) {
					font-size: rem-calc(25);
				}
			}
		}

		.quantity-cell {
			width: rem-calc(124);
			@include respond-min($small-breakpoint) {
				width: rem-calc(132);
			}
			@include respond-min($medium-breakpoint) {
				width: rem-calc(142);
			}

			.icon-circle {
				font-size: rem-calc(11);
				margin-bottom: 0;
				margin-top: rem-calc(12);
				cursor: pointer;
				margin-right: rem-calc(12);
				@include respond-min($small-breakpoint) {
					margin-right: rem-calc(20);
				}

				.icon {
					$size: 1.0;
					font-size: $size + em;
					line-height: $size + em;
					width: math.div(2, $size) + em;
					height: math.div(2, $size) + em;
					@media screen\0 {
						line-height: 1.9em;
					}
				}

				+ .icon-circle {
					float: right;
					margin-right: 0;
					@include respond-min($small-breakpoint) {
						margin-right: rem-calc(3);
					}
				}

				&:hover,
				&:focus {
					background: $theme-color-2;
					border-color: $theme-color-2;
				}
			}
		}

		.quantity-container {
			height: 45px;
			width: 52px;
			border: 1px solid $lightest-blue-gray;
			display: table-cell;
			text-align: center;
			vertical-align: middle;
			font-weight: bold;
			font-size: rem-calc(18);
			color: $theme-color-1;
		}

		.icon-circle {
			float: left;
		}
	}

	.arrow-link {
		font-weight: bold;
	}

	.arrow-link.back {
		color: $text-color-base;
		margin-top: rem-calc(40);
		margin-left: rem-calc(20);
		@include respond-min($large-breakpoint) {
			margin-left: 0;
		}

		&:before {
			font-size: rem-calc(22);
			top: 0;
		}
	}

	.time-picker-wrap {
		position: relative;

		.time-picker-label {
			color: $gray-40;
		}

		.select {
			cursor: pointer;
			background: image-url("whats-here-filter-dots.png") left bottom repeat-x;
			padding-bottom: rem-calc(7);
			font-weight: 700;
			background-size: rem-calc(22);
			margin: rem-calc(0 2);
			// width: rem-calc(73);
		}

		.icon {
			margin-right: rem-calc(-5);
		}
	}

	.time-picker {
		cursor: pointer;
		list-style: none;
		margin: 0;
		position: absolute;
		width: rem-calc(90);
		position: absolute;
		text-align: right;
		top: -19px;
		font-size: rem-calc(16);
		line-height: rem-calc(24);
		font-style: normal;
		padding: rem-calc(5 14);
		background-color: $title-gray;
		border: none;
		text-align: left;
		z-index: 1000;
		max-height: 0;
		overflow: hidden;
		transition: max-height .3s;
		display: table;

		&.from {
			left: 16px;
		}

		&.to {
			left: 119px;
		}

		li {
			font-size: rem-calc(15);
			padding:rem-calc(13 7);
			text-align: right;
			border-bottom: 1px solid #363c52;
			color: #2aa9e0;
			font-weight: 700;
		}
	}

	.inner-sidebar {
		margin-top: rem-calc(68);
		transform: translate3d(0, 0, 0);

		header {

			> span {
				color: #787e94;
				letter-spacing: rem-calc(1.2);
			}

			.select {
				color: white;
				display: inline-block;

				+ .select {
					margin-top: rem-calc(10);
					@include respond-min(rem-calc(920)) {
						margin-top: 0;
					}
				}
			}
		}


		.padding-wrap {
			padding: rem-calc(60 50 55);
			background-color: $lighter-gray;

			@include respond-min($large-breakpoint) {
				padding: rem-calc(40 38 76);
			}

			&.personal-information {
				padding: rem-calc(30 50 30);
				@include respond-min($large-breakpoint) {
					padding: rem-calc(40 38 16);
				}
			}

			.datepicker-button input {
				@include respond-min($large-breakpoint) {
					font-size: rem-calc(19);
				}
				@include respond-min($xlarge-breakpoint) {
					font-size: rem-calc(22);
				}
			}

			> .title {
				text-align: center;
				font-size: rem-calc(28);
				line-height: rem-calc(28);
				margin: rem-calc(0 12 24);
				@include respond-min($large-breakpoint) {
					font-size: rem-calc(19);
				}
			}
		}

		.padding-wrap,
		header {
			@include respond-min(rem-calc(768)) {
				margin-right: rem-calc(-30);
			}

			@include respond-min(rem-calc(790)) {
				margin-right: rem-calc(0);
			}
		}

		> .button-table {
			@include respond-min(rem-calc(768)) {
				width: 114%;
			}

			@include respond-min(rem-calc(790)) {
				width: 100%;
			}
		}

		+ .arrow-link,
		+ p + .arrow-link {
			clear: left;
			width: rem-calc(64);
			margin: rem-calc(40) auto;
			@include respond-min($large-breakpoint) {
				float: left;
			}
		}

		+ p {
			padding: rem-calc(20);
			float: left;

		}
		@include respond-min($large-breakpoint) {
			+ p {
				padding: 0;
			}
		}
	}

	.primary-sidebar-button {
		margin-bottom: 0;

		.text-cell	{
			text-align: center;
		}
	}


	// 03

	.inner-sidebar {

		h3 {
			font-size: rem-calc(28);
			line-height: rem-calc(28);
			@include respond-min($large-breakpoint) {
				font-size: rem-calc(19);
				line-height: rem-calc(30);
			}
		}

		header {
			background-color: $theme-color-2;
			padding: rem-calc(37 25 47);
			color: white;
			@include respond-min($small-breakpoint) {
				padding-left: rem-calc(38);
			}

			.title {
				color: inherit;
				text-align: left;
				margin-bottom: rem-calc(13);
				@include respond-min($large-breakpoint) {
					margin-bottom: rem-calc(5)
				}
				@include respond-min(rem-calc(920)) {
					margin-bottom: rem-calc(13)
				}
			}

			.new-line {
				display: inline;
				@include respond-min($large-breakpoint) {
					display: block;
					margin-top: rem-calc(10);
				}
				@include respond-min(rem-calc(920)) {
					display: inline;
					margin: 0;
				}
			}

			.icon {
				color: $theme-color-1;
			}

			+ .padding-wrap {
				padding: rem-calc(22 36 15);
			}
		}

		table {
			margin-bottom: 0;

			&.subtotal-table tr:first-of-type td {
				padding-top: 0;
			}

			&.total-table td {
				padding-top: rem-calc(26);
			}

			td {
				border-bottom: 1px solid $gray-20;
				color: $theme-color-1;
				font-weight: 600;
				padding: rem-calc(15 0);
				vertical-align: middle;
				font-size: rem-calc(14);
			}

			tr:last-child td {
				border-bottom: none;
				font-size: rem-calc(18);
				color: $gray-40;
				font-weight: 400;
				text-align: right;

				.title {
					text-align: left;
					font-size: rem-calc(24);
				}

				span {
					color: $theme-color-1;
					text-align: right;
					font-weight: bold;
					font-size: rem-calc(24);
					padding-left: rem-calc(4);
				}
			}

			.subtotal {
				text-align: right;
			}

			.title {
				display: block;
				color: $theme-color-2;
				margin-bottom: rem-calc(2);
			}

			.icon-circle {
				background-color: white;
				border-color: white;
				margin-right: 0;
			}

			.discount .icon-circle {
				background-color: lighten($success-color, 45%);
				background-color: transparent;
				border-color: $success-color;
				display: block;
				margin: 0;
				float: right;
				.icon {
					color: $success-color;
				}
			}

			.text-circle {
				display: block;
				margin: 0;
				float: right;
			}

			.start-time,
			.promo {
				color: $gray-40;
				font-weight: 400;
			}

			.promo {
				display: block;
			}
		}
	}

	// 04

	.experience-summary {
		padding: 0;
		// position: absolute;
		// float: none;
		// top: 0px;
		// bottom: 0px;
		// margin-right: 640 / 940 * 100%;
		width: 100%;
		@include respond-min($large-breakpoint) {
			width: math.div(300, 940) * 100%;
		}

		@media only screen and (min-width:$small-breakpoint) and (max-width: $large-breakpoint - em-calc(1)) {
			padding: rem-calc(40) math.div(60, 940) * 100%;
		}

		img,
		.flowplayer {
			@media only screen and (min-width:$small-breakpoint) and (max-width: $large-breakpoint - em-calc(1)) {
				&[style] {
					width: 50% !important;
				}
			}

			&.is-poster {
				background-size: cover;
			}
		}

		.padding-wrap {
			padding: rem-calc(40) math.div(60, 940) * 100%;
			@media only screen and (min-width:$small-breakpoint) and (max-width: $large-breakpoint - em-calc(1)) {
				padding: 0 0 0 math.div(60, 940) * 100%;
				float: right;
				width: 50%;
			}
			@include respond-min($large-breakpoint) {
				padding: rem-calc(40) math.div(30, 300) * 100%;
			}
			@include respond-min($xlarge-breakpoint) {
				padding: rem-calc(40) math.div(40, 300) * 100%;
			}
		}

		header .title {
			font-size: rem-calc(24);
			line-height: rem-calc(28);
			margin-bottom: rem-calc(2);
		}

		.subtitle {
			text-transform: uppercase;
			font-size: rem-calc(10);
			letter-spacing: rem-calc(4);
		}

		p {
			font-size: rem-calc(12);
			line-height: rem-calc(21);
			margin-bottom: rem-calc(10);
			// @include respond-min($medium-breakpoint) {
			// 	margin-right: rem-calc(36);
			// }
		}

		.info {
			font-size: rem-calc(9);
			line-height: rem-calc(13);
			text-transform: uppercase;
			display: block;
			margin-bottom: rem-calc(8);
			letter-spacing: rem-calc(2.5);
			font-weight: 600;
		}

		hr {
			clear: none;
			border-top: 2px dotted $lightest-blue-gray;
			display: none;
			margin: 0 0 rem-calc(3);
		}
	}
}

.ticketing-options {
	padding: rem-calc(52) math.div(60, 940) * 100%;
	@include respond-max($small-breakpoint) {
		padding: rem-calc(52) 0;
	}
	// margin-left: 300 / 940 * 100%;
	// float: none;
	width: 100%;
	@include respond-min($large-breakpoint) {
		width: math.div(640, 940) * 100%;
	}

	.title {
		font-size: rem-calc(28);
		clear: left;
		margin-bottom: rem-calc(28);
	}

	.arrow-link.back {
		float: left;
		margin-top: rem-calc(11);

		~ .button {
			clear: both;
			width: 100%;
			margin-top: rem-calc(20);

			@include respond-min($small-breakpoint) {
				clear: none;
				width: auto;
				margin-top: 0;
			}
		}
	}

	.time-slots,
	.attendees,
	.conflict-items {
		@include reset-ul;
		@extend .clearfix;
		margin-right: (math.div(-20, 520) * 100%);
		line-height: rem-calc(24);

		li {
			float: left;
			margin: 0 math.div(20, 540) * 100% rem-calc(20) 0;
			// width: rem-calc(115);
			min-width: rem-calc(72);
			line-height: 1;
			text-align: center;
			width: math.div(99, 335.19) * 100%;
			width: 46%;
			@include respond-min($small-breakpoint) {
				width: math.div(115, 540) * 100%;
			}
			@include respond-min($medium-breakpoint) {
				width: math.div(88, 543.53) * 100%;
			}
			@include respond-min($large-breakpoint) {
				width: math.div(115, 540) * 100%;
			}
			@include respond-min($xlarge-breakpoint) {
				width: math.div(88, 543.53) * 100%;
			}
		}
	}

	.time-slots,
	.conflict-items {
		margin-bottom: rem-calc(18);

		.ember-view {
			display: inline;
		}

		& ~ h5 {
			margin-bottom: rem-calc(10);
		}
	}

	.time-slots li {
		background: $light-gray;
		color: $theme-color-1;
		font-size: rem-calc(16);
		font-weight: 600;
		padding: rem-calc(16) math.div(10, 540) * 100% rem-calc(16) math.div(22, 540) * 100%;
		transition: background-color .3s ease-out;

		&:hover,
		&:focus {
			background: $theme-color-2;
			color: white;
			cursor: pointer;
		}

		&.selected {
			background: $theme-color-1;
			color: white;
		}

		&.disabled,
		&.disabled:hover,
		&.disabled:focus {
			color: $gray;
			background: $light-gray;
			cursor: default;
		}
	}

	&.experience-date-selection {
		padding: rem-calc(52 - 15) rem-calc(60 - 15);
		max-width: rem-calc(675);

		.time-slots {
			margin: 0;

			li {
				padding: 0;
				width: auto;
				min-width: rem-calc(112);

				.button {
					padding: rem-calc(16 10);
					color: $theme-color-1;
					background: $light-gray;
					font-size: rem-calc(16);
					font-weight: 600;
					padding: rem-calc(16);
					text-shadow: none;
					margin: 0;

					&:hover,
					&:focus {
						background: $theme-color-2;
						color: white;
					}
				}
			}
		}

		.conflict-items {
			margin: 0;

			li {
				background: #FFD1BF;
				color: #FF3000;
				font-size: rem-calc(16);
				font-weight: 600;
				padding: rem-calc(16);
				width: auto;
			}
		}
	}

	.attendees {
		margin-right: 0;

		li {
			background: $light-gray;
			color: $theme-color-1;
			transition: background-color .3s ease-out;
			// padding: rem-calc(30) rem-calc(16) rem-calc(35);
			font-size: rem-calc(16);
			line-height: rem-calc(18);
			position: relative;
			width: 100%;
			@include respond-min($medium-breakpoint) {
				width: math.div(88, 543.53) * 100%;
			}
			@include respond-min($large-breakpoint) {
				width: math.div(115, 540) * 100%;
			}
			@include respond-min($xlarge-breakpoint) {
				width: math.div(88, 543.53) * 100%;
			}


			.info {
				padding: rem-calc(24);
				transition: background-color .3s ease, color .1s ease-out;
				width: 100%;
				display: table;
				@include respond-min($medium-breakpoint) {
					// width: 46%;
					padding: (math.div(20, 89.28) * 100%) (math.div(16, 89.28) * 100%) (math.div(28, 89.28) * 100%);
				}

				&:hover,
				&:focus {
					background: $theme-color-2;
					color: white;
					cursor: pointer;

					input {
						color: white;
						background: transparent;
					}

					.edit {
						display: block;
					}
				}
			}

			.icon {
				font-size: rem-calc(41);
				display: block;
				float: left;
				@include respond-min($medium-breakpoint) {
					float: none;
					margin: 0 auto rem-calc(10);
				}
			}

			.edit,
			.save {
				font-weight: 700;
				cursor: pointer;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				font-size: rem-calc(12);
				padding: rem-calc(4);
				display: none;
				@include respond-min($medium-breakpoint) {
					display: block;
				}

				&:hover,
				&:focus {
					display: block;
					background: $theme-color-2;
					color: white;
					cursor: pointer;
				}
			}

			@include respond-min($large-breakpoint) {
				.edit {
					display: none;
				}
			}

			.name,
			.price {
				display: inline-block;
				float: left;
				margin-top: rem-calc(12);
				font-weight: bold;
				@include respond-min($medium-breakpoint) {
					display: block;
					float: none;
					margin-top: 0;
					font-weight: normal;
				}
			}

			.price {
				float: right;
				@include respond-min($medium-breakpoint) {
					float: none;
				}
			}

			input {
				color: $theme-color-1;
				background: transparent;
				padding: 0;
				border: none;
				box-shadow: none;
				margin: 0;
				font-size: rem-calc(16);
				line-height: rem-calc(18);
				height: auto;
				font-weight: bold;
				text-align: center;
			}

			&.selected {
				background: $theme-color-1;
				color: white;

				input {
					color: white;
					background: transparent;
				}
			}

			&:hover,
			&:focus {

				// input {
				// 	color: white;
				// 	background: transparent;
				// }

				.edit,
				.save {
					color: white;
					@include respond-min($medium-breakpoint) {
						display: block;
					}
				}
			}

			&.disabled,
			&.disabled:hover,
			&.disabled:focus {
				color: $gray;
				background: $light-gray;
				cursor: default;
			}
		}
	}

	.select-options {
		padding: 0 0 rem-calc(32) 0;
		@include respond-max($small-breakpoint) {
			padding: 0 (math.div(60, 940) * 100%) rem-calc(32);
		}
		border-bottom: 1px solid $lightest-blue-gray;
		margin-bottom: rem-calc(41);

		a {
			display:block;
			margin-bottom: 1.75rem;
		}

		.unavailable-time {
			&:after {
				content: ",";
			}

			&:last-of-type:after {
				content: none;
			}
		}
	}

	&.experience-date-selection .select-options {
		padding-bottom: 0;
		border-bottom: none;
		margin-bottom: 0;
	}

	.ladda-button {
		padding: rem-calc(15 30 16);
		margin-top: 0;
		width: auto;

		@include respond-max($small-breakpoint) {
			width: 100%;
			padding: rem-calc(35);
			margin-top: rem-calc(40);
		}
	}
}

	//04-2

.ticketing,
.tickets {

	.enhance-visit {

		> .title {
			font-size: rem-calc(38);
			text-align: center;
			color: white;
			margin-bottom: rem-calc(64);
		}

		header{
			background: $theme-color-2;
			color: white;
			padding: rem-calc(56) rem-calc(40) rem-calc(26);

			.title {
				color: inherit;
				text-align: center;
				font-size: rem-calc(28);
				margin-bottom: rem-calc(34);
			}

			.subtitle {
				margin-top: rem-calc(8);
				font-size: rem-calc(24);
				color: $theme-color-1;
				display: block;
				font-weight: normal;
			}

			small {
				text-align: center;
				display: block;
				font-size: rem-calc(14);
				font-style: italic;
			}
		}

		table {
			margin-bottom: 0;


			td {
				padding: 22px 0;
			}

			td + td {
				text-align: right;
				font-size: rem-calc(18);
				color: $gray-35;

				.title {
					font-size: rem-calc(25);
					color: $theme-color-1;
					margin: 0 rem-calc(5);
				}
			}

			.title {
				font-size: rem-calc(22);
				font-weight: bold;
			}

			tr:last-child td {
				border-bottom: none;
			}
		}

		.padding-wrap {
			background: white;
			padding: rem-calc(12) rem-calc(35) rem-calc(13);
			margin-bottom: rem-calc(30);
		}

		.button.center {
			margin: 0 auto;
		}
	}

	// 05

	.order-summary {
		margin-top: rem-calc(-10);
		margin-bottom: rem-calc(32);
		@include respond-min($large-breakpoint) {
			max-width: rem-calc(540);
		}

		td,
		.header-cell {
			vertical-align: top;
			padding: rem-calc(20 0 25);
		}

		.no-data {

			td,
			.price-cell {
				padding: 0;
				background: none;
				border-bottom: none;
			}
		}

		.no-bottom-border td {
			border-bottom: none;

			.subtitle {
				margin-bottom: 0;
			}
		}

		tr:nth-last-child(0n+2) td {
			padding-bottom: 0;
		}

		.no-data + tr td {
			padding-top: rem-calc(4);
			padding-bottom: rem-calc(19);
		}

		.round-top,
		.round-bottom {
			width: 100%;
			height: rem-calc(20);
		}

		.round-top {
			border-radius: rem-calc(20) rem-calc(20) 0 0;
		}

		.round-bottom {
			border-radius: 0 0 rem-calc(20) rem-calc(20);
		}

		.price-cell,
		.round-top,
		.round-bottom {
			background-color: $gray-05;
		}

		// .title {
		// 	color: $theme-color-2;
		// 	font-size: rem-calc(22);
		// 	font-weight: bold;
		// 	display: block;
		// 	margin-bottom: rem-calc(7);
		// }
		//
		// .subtitle {
		// 	display: block;
		// }

		.header-cell {
			width: auto;
			font-size: rem-calc(13);
			padding-right: rem-calc(8);
			@include respond-min($medium-breakpoint) {
				padding-right: 0;
			}

			.title + .subtitle {
				margin-top: rem-calc(5);
			}

			.subtitle {
				text-transform: none;
				font-size: rem-calc(19);
				letter-spacing: 0;
				font-weight: 400;
				// color: inherit;
				margin-bottom: rem-calc(15);
			}
		}

		.pre-discount {
			float: right;
			font-size: rem-calc(22);
			font-weight: normal;
			color: $gray;
			display: none;
			@include respond-min($medium-breakpoint) {
				display: block;
				padding-right: rem-calc(32);
			}
			// text-decoration: underline;
		}

		.price-cell {
			padding-left: rem-calc(16);
			padding-right: rem-calc(16);
			text-align: left;
			white-space: nowrap;
			font-size: rem-calc(18);
			min-width: rem-calc(86);
			@include respond-min($medium-breakpoint) {
				padding-left: rem-calc(32);
				padding-right: rem-calc(40);
				min-width: rem-calc(117);
			}

			.digits {
				font-weight: 600;
				font-size: rem-calc(24);
				color: $theme-color-1;
				font-weight: 600;
			}

			.discount .digits {
				color: $success-color;
			}
		}

		.discount span {
			color: $color-2;
		}
	}

	.discount-code {
		background: $gray-05;
		margin: 0;
		padding: rem-calc(16) rem-calc(18);
		@include respond-min($large-breakpoint) {
			max-width: rem-calc(540);
		}

		input[type=text] {
			margin-left: 0;
		}

		input[type=text],
		label.inline {
			margin-bottom: 0;
			text-transform: none;
			letter-spacing: 0;
			font-size: rem-calc(13);
			font-weight: 600;
			color: $light-blue-gray;
			margin-right: rem-calc(14);
		}

		input[type=text] {
			text-transform: none;
			letter-spacing: 0;
			font-size: rem-calc(12);
			font-weight: normal;
			padding: rem-calc(16);
			height: rem-calc(44);
		}

		.postfix {
			padding-top: rem-calc(13);
			padding-right: 0;
			margin-bottom: rem-calc(7);
		}

		.postfix-radius {
			margin: 0;
		}
	}

	.icon-refresh {
		margin-right: 0;
	}

	.title.total-price {
		padding: rem-calc(29 0 18);
		margin-bottom: 0;
		font-size: rem-calc(28);
		text-align: right;
		border-bottom: 1px solid $lightest-blue-gray;
		@include respond-min($large-breakpoint) {
			max-width: rem-calc(540);
		}

		.price {
			font-weight: normal;
			margin-left: rem-calc(85);
			color: $gray;

			span {
				color: $theme-color-1;
				font-weight: 600;
				margin-left: rem-calc(8);
			}
		}
	}

	#payment-info {
		border-bottom: $border-1;
		padding-bottom: rem-calc(20);
		margin-bottom: rem-calc(10);

		.alt-style {
			text-transform: none;
			color: $text-color-base;
			font-size: rem-calc(14);
			font-weight: normal;
			letter-spacing: 0;
		}

		/*
		.columns {
			@include respond-min($medium-breakpoint) {
				margin-bottom: rem-calc(14);
			}
		}
		*/

		input[type=checkbox] {
			margin: rem-calc(4 10 4 0);
			float: left;

			// + label {
			// 	display: inline;
			// 	margin-left: 0;
			// }
		}
	}

	.payment-info {
		.small-12 {
			padding-left: 0;

			& + .small-12 {
				padding-right: 0;
			}
		}

		.tip-parent {
			display: inline-block;
			margin-top: rem-calc(10);
		}
	}

	input[type=text]:focus {
		color: $theme-color-1;
		font-weight: bold;
	}

	label {
		text-transform: uppercase;
		color: $theme-color-2;
		font-weight: bold;
		font-size: rem-calc(10);
		line-height: rem-calc(25);
		letter-spacing: rem-calc(2);

		input[type=text],
		select {
			// background-color: white;
			// margin-bottom: rem-calc(17);
			// text-transform: none;
			height: rem-calc(44);
			// padding: 0 1em;
			// letter-spacing: 0;
			font-weight: bold;
			font-size: rem-calc(16);
		}

		&.invalid {
			color: #ff4800;
			height: 0px;
			line-height: rem-calc(12);
			position: relative;
			top: -12px;
			text-align: right;
		}

		.exp-mo,
		.exp-yr {
			white-space: nowrap;
		}

		.exp-yr {
			color: transparent;
		}
	}

	.personal-information {
		font-size: rem-calc(14);
		line-height: rem-calc(20);

		section {
			padding: 16px 0 8px;

			&:last-of-type {
				border-top: 1px dotted #bbb;
			}

			.title {
				font-size: rem-calc(16);
				margin-bottom: rem-calc(16);

				a {
					color: $theme-color-1;
					font-size: rem-calc(15);
				}

				+ .title {
					margin-bottom: rem-calc(12);
				}
			}

			.subtitle {
				color: $theme-color-2;
				font-weight: 600;
			}

			div {
				margin-bottom: rem-calc(12);
			}
		}
	}

	.inner-sidebar {

		.alt-style {
			text-transform: none;
			color: $theme-color-1;
			font-size: rem-calc(14);
			font-weight: bold;
			letter-spacing: 0;
		}
	}

	// 07

	.final-options {
		text-align: center;
		padding: rem-calc(100 35 50);
		@include respond-min($medium-breakpoint) {
			padding: rem-calc(100 0 50);
		}
		@include respond-min($large-breakpoint) {
			h2 br {
				display: inline;
			}
			.break {
				display: block;
			}
		}

		.title,
		.intrabutton-spacing {
			font-family: $font-family-2;
			font-size: rem-calc(25);
			line-height: rem-calc(40);
			font-weight: 400;
			text-align: center;
		}

		.title {
			margin-bottom: rem-calc(60);
		}

		.intrabutton-spacing {
			padding: 0 rem-calc(36);
			display: none;
			@include respond-min($medium-breakpoint) {
				display: inline;
			}
		}

		.button {
			width: 100%;
			@include respond-min($medium-breakpoint) {
				width: auto;
			}
		}

	}

	.experience-timeline {
		background: $gray-05;
		text-align: center;
		padding: rem-calc(72 50);
		margin: rem-calc(0 -35);
		@extend .clearfix;

		@include respond-min($large-breakpoint) {
			margin: rem-calc(0 -35);
		}

		@include respond-min($xlarge-breakpoint) {
			margin: 0;
		}

		.timeline-inner-wrap {
			> .subtitle {
				text-transform: uppercase;
				font-size: rem-calc(11);
				letter-spacing: rem-calc(5);
				margin-bottom: rem-calc(10);
				display: block;
			}

			> .title {
				text-align: center;
				font-size: rem-calc(48);
				line-height: rem-calc(48);
				margin-bottom: rem-calc(57);
				text-rendering: optimizeLegibility;
				letter-spacing: rem-calc(-1.3);
			}
		}
	}

	.vertical-timeline {
		@extend .clearfix;
		position: relative;
		z-index: 500;
		padding: rem-calc(32 0 16);
		@include respond-min($large-breakpoint) {
			padding: 0;
		}

		.vertical-line {
			background-color: $lightest-blue-gray;
			width: 4px;
			position: absolute;
			top: 0px;
			bottom: 0px;
			left: 50%;
			margin: 0 0 0 -2px;
		}
	}

	.timeline-item {
		margin-bottom: rem-calc(18);
		position: relative;
		text-align: left;
		z-index: 550;
		@include respond-min($large-breakpoint) {
			width: 50%;
			margin-bottom: rem-calc(50);
		}

		.info {
			font-size: rem-calc(10);
			color: #93929D;
			text-transform: uppercase;
			display: block;
			letter-spacing: rem-calc(1.8);
			font-weight: bold;
			@include respond-min($large-breakpoint) {
				margin-bottom: rem-calc(40);
				text-align: center;
			}
		}

		hr {
			clear: none;
			border-top: 2px dotted $lightest-blue-gray;
			display: none;
			margin: 0 0 rem-calc(3);
			display: none;
		}

		.time {
			display: none;
			position: absolute;
			top: rem-calc(115);
			border-bottom: 2px solid $lightest-blue-gray;
			width: math.div(60, 369) * 100%;
			font-size: rem-calc(12);
			letter-spacing: rem-calc(2);
			padding-bottom: rem-calc(4);

			@include respond-min($large-breakpoint) {
				display: block;
			}
			@include respond-min(855px) {
				width: 22%;
			}
			@include respond-min($xlarge-breakpoint) {
				width: 24%;
				letter-spacing: rem-calc(4);
			}

			.meridian {
				letter-spacing: 1px;
			}

			.title {
				display: none;
			}

			.timeline-text {
				display: none;
				text-transform: none;
			}
		}

		@include respond-min($large-breakpoint) {
			&:nth-of-type(2) {
				margin-top: rem-calc(300);
			}

			&:nth-child(odd) {
				float: right;
				// margin-right: rem-calc(20);

				.box-teaser {
					float: right;
				}

				.time {
					left: 0;
					text-align: right;
				}
			}

			&:nth-child(even) {
				float: left;
				// margin-left: rem-calc(20);

				.box-teaser {
					float: left;
				}

				.time {
					// left: auto;
					right: 0px;
					text-align: left;
				}
			}
		}

		@include respond-min(855px) {
			.time .timeline-text {
				display: block;
			}
		}
	}
	@include respond-min($large-breakpoint) {
		.teaser-large-rejig .box-teaser .box-teaser-image-wrap {
			margin-bottom: 86%;
		}

		.teaser-large-rejig .box-teaser .box-teaser-content {
			margin-top: -86%;
		}

		.teaser-large-rejig .fade-in {
			display: none !important;
		}
	}

	.teaser-module {

		.title + .subtitle {
			margin: rem-calc(-10 0 8);
		}

		p {
			margin-bottom: rem-calc(27);
		}

		&.disabled {
			.title + .subtitle {
				display: none;
				@include respond-min($large-breakpoint) {
					display: block;
				}
			}

			.teaser-module-image {
				@include filter(grayscale(100%));
			}
		}

		.teaser-module-image-wrap {
			padding-right: rem-calc(40.75);
		}
		@include respond-min($large-breakpoint) {
			.teaser-module-image {
				margin-bottom: rem-calc(10);
			}
		}

		.button,
		.button-table {
			font-size: rem-calc(18);
			height: rem-calc(52);

			.text-cell {
				// desired width divided by parent width times 100% gives you desired percent width
		        padding-left: math.div(21, 193) * 100%;
				font-size: rem-calc(16);
				@include respond-min(rem-calc(852)) {
					font-size: rem-calc(18);
				}
			}

			.last-icon-cell {
				width: 28%;
			}

			.icon {
				font-size: 1.0625rem;
				margin: -.5625rem auto -.75rem;
				width: 1.883em;
				padding-left: .4414em;
				padding-right: .4414em;
			}

			&.expand {
				padding: rem-calc(12 7 13 20);
			}

			&.large {
				padding: rem-calc(12 0 13 0);
			}

			&.success {
				background-color: $success-color;
				transition: .3s all ease-out 0s;
				border: 1px solid $success-color;

				.last-icon-cell {
					border-left: 2px solid mix($success-color, #ffffff, 80%);
					transition: .3s border-left-color ease-out 0s;
				}

				&:hover,
				&:focus {
					background-color: #fff;
					color: $success-color;
					border: 1px solid hsl(hue($success-color), saturation($lightest-blue-gray), lightness($lightest-blue-gray));
					// transition: .3s background-color ease-out 0s;

					.text-cell,
					.last-icon-cell .icon {
						color: $success-color;
						text-shadow: none;
					}

					.last-icon-cell {
						border-left-color: $success-color;
					}
				}
			}

			&.remove {
				background: $alert-font-color;
				border: 1px solid $alert-font-color;
				transition: .3s all ease-out 0s;

				.last-icon-cell {
					border-left: 2px solid mix($alert-color, #ffffff, 80%);
				}

				&:hover,
				&:focus {
					background-color: #fff;
					color: $alert-font-color;
					border: 1px solid hsl(hue($alert-font-color), saturation($lightest-blue-gray), lightness($lightest-blue-gray));
					// transition: .3s background-color ease-out 0s;

					.text-cell,
					.last-icon-cell .icon {
						color: $alert-font-color;
						text-shadow: none;
					}

					.last-icon-cell {
						border-left-color: $alert-font-color;
					}
				}
			}

			&.disabled {
				background-color: $gray;
				border-color: $gray;
				padding: rem-calc(18 0);
				font-size: rem-calc(16);
				line-height: rem-calc(16);
				text-transform: none;
				font-weight: normal;
				letter-spacing: normal;
				margin: 0;

				.last-icon-cell,
				&:hover .last-icon-cell,
				&:focus .last-icon-cell {
					border-left-color: desaturate(lighten($gray, 20%), 33%);
				}
			}
		}

		.icon-circle {
			display: none;
			@media screen\0 {
				padding: 2px 1px;
			}

			.icon {
				@media screen\0 {
					line-height: 2.2em;
					width: 32px;
					height: 32px;
				}
			}
		}

		.text-cell,
		.last-icon-cell .icon {
			color: white;
		}

		.status {
			font-size: rem-calc(9);
			line-height: rem-calc(9);
			text-transform: uppercase;
			letter-spacing: rem-calc(2);
			font-weight: 400;
			margin-top: rem-calc(6);
			margin-right: rem-calc(-4);
			margin-bottom: 0;

			@include respond-min($large-breakpoint) {
				display: none;
			}
		}

		@media only screen and (min-width:rem-calc(1)) and (max-width: rem-calc(767)) {
			overflow: hidden;
		    position: relative;
		    @include box-shadow(rgba(0,0,0,0.1) 0px 4px 6px);
		    //simulate transparent border
		    background-color: rgba(0,0,0,0.1);
		    padding: 1px;
		    max-width: 600px;
		    margin: 0 auto rem-calc(20);
		    transform: translateZ(0);

		    br,
			.break {
		        display: none;
		    }

		    .teaser-module-inner-wrap {
		        // this is the direct parent of what we want to be table-cells
		        // table-cell width is calculated off this width
		        display: table;
		        table-layout: fixed;
		        width: 100%;
		        position: relative;
				min-height: rem-calc(82);
		    }

			.teaser-module-image-wrap {
				// desired width divided by parent width times 100% gives you desired percent width
		        width: math.div(158, 518) * 100%;
		        // table-cell on the image gives the content something to lock up to
		        display: table-cell;
		        background-color: #525861;
				padding: 0;
				max-width: 9999px;
				float: none;

				a {
					display: block;
				}

				.icon-circle {
					display: block;
			        // font-size: rem-calc(12);
			        position: absolute;
			        left: math.div(158, 518) * 100%;
			        top: 50%;
			        margin: -1em;
					background: white;

					&.disabled {
						background: $gray;

						.icon {
							color: white;
						}
					}
				}

				.button {
					display: none;
				}
			}

			.teaser-module-content-wrap {
				display: table-cell;
		        vertical-align: middle;
		        width: math.div(360, 518) * 100%;
		        padding: rem-calc(5) 10%;
		        color: $text-color-base;
		        background-color: white;

				p {
					display: none !important;
				}
			}

			.info {
				display: none;
			}

			.title {
		        font-size: rem-calc(15);
		        line-height: rem-calc(15);
		        margin-top: 0;
		        margin-bottom: 0;
		        text-align: left;
		        color: $theme-color-3 !important;
		    }

		    .title + .subtitle {
		        font-size: rem-calc(9);
		        line-height: rem-calc(9);
		        text-transform: uppercase;
		        letter-spacing: rem-calc(3);
		        font-weight: 400;
		        margin-top: rem-calc(6);
		        margin-right: rem-calc(-4);
				margin-bottom: 0;
		        color: $theme-color-1;
		        font-weight: 600;
		    }

			p {
				display: none;
			}

			.label.secondary {
				position: absolute;
				top: 0;
				left: 0;
				right: 100% - 30.501930501930506%;
				margin: 3px;
				padding: 3px;
				white-space: normal;
				letter-spacing: 0;
				font-size: rem-calc(12);

			}
		}

		&.horizontal.experience- {
		    &event .subtitle {
		        color: $color-event;
		    }

		    &exhibit .subtitle {
		        color: $color-exhibit;
		    }

		    &film .subtitle {
		        color: $color-film;
		    }

		    &tour .subtitle {
		        color: $color-tours;
		    }
		}
	}

	.vertical-line {
		background-color: #31436f;
		width: 2px;
		margin: 0 auto rem-calc(45);
		min-height: rem-calc(80);

		.top-circle,
		.bottom-circle {
			background: $lightest-blue-gray;
			position: absolute;
			width: 12px;
			height: 12px;
			border-radius: 12px;
			margin: -6px -4px;
		}

		.bottom-circle {
			bottom: 0px;
		}

	}

	.other-events {
		background: $theme-color-2;
		color: white;
		padding: rem-calc(50 20);
		margin: 0 rem-calc(-20);
		@include respond-min($medium-breakpoint) {
			padding: rem-calc(72 50);
		}
		@include respond-min($xlarge-breakpoint) {
			margin: 0;
		}

		> .title {
			color: white;
			font-size: rem-calc(36);
			line-height: rem-calc(36);
			text-align: center;
			margin-bottom: rem-calc(55);
			text-rendering: optimizeLegibility;
			letter-spacing: -1.3px;
		}

		.teaser-no-rejig {
			max-width: rem-calc(700);
			margin: 0 auto rem-calc(40);

			li {
				padding: 0 5px 10px 5px;
			}

			.box-teaser {
				margin-bottom: 0;

				.icon-circle {
					left: 33.5%;
					font-size: rem-calc(16);
				}

				.title {
					font-size: rem-calc(18);
					line-height: rem-calc(18);
				}

				.subtitle {
					margin-bottom: 0;
				}
			}

			.box-teaser-image-wrap {
				width: 33.5%;
			}

			.icon-circle {
				// left: 33.5%;
			}
		}

		.row {
			@include respond-min($medium-breakpoint) {
				padding: rem-calc(0 75 40);
			}
		}

		.icon-circle.light {
			border: 3px solid $theme-color-1;
			// width: 4.75em;
			// height: 4.75em;
			// border-radius: 4.75em;
			// font-size: rem-calc(16);
			font-size: rem-calc(36);

			.icon {
				color: white;
				// margin-top: .9em;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}

	.add-visit {
		@extend .clearfix;
		display: block;
		margin-bottom: rem-calc(9);
	}

	.social-outer-wrap {
		.title {
			color: white;
			text-align: center;
		}

		.social-wrap {
			margin: 0 auto;
			width: rem-calc(267);

			.icon {
				margin: -3px 0 0 -3px;
				$size: .666667;
				font-size: $size + em;
				line-height: $size + em;
				width: math.div(2, $size) + em;
				height: math.div(2, $size) + em;
				@media screen\0 {
					line-height: 2.9em;
				}
			}
		}
	}

	.my-visit-teaser {  // @todo ron - this was pulled from my_visit.scss, I suggest it live outside any wrapper I did make a few edits for this section
		border: $border-1;
		background-color: white;
		// @include box-shadow(rgba(0,0,0,0.1) 0px 4px 6px);
		@extend .clearfix;
		margin-bottom: rem-calc(3);
		position: relative;

		.my-visit-teaser-image {
			width: rem-calc(103);
			height: rem-calc(103);
			float: left;
		}

		.icon-circle {
			font-size: 1.2em;
			margin-top: 1.5em;
			margin-left: -1em;
			margin-right: 0;
			display: block;
			float: left;
		}

		.my-visit-teaser-content-wrap {
			padding: 0 rem-calc(20);
			height: rem-calc(103);
			display: table;

			.my-visit-teaser-content {
				display: table-cell;
				vertical-align: middle;

				.title {
					font-size: rem-calc(18);
					margin-top: 0;
					line-height: 1.1;
					margin-bottom: 6px;
				}

				.duration {
					font-size: rem-calc(10);
					text-transform: uppercase;
					font-weight: 400;
					letter-spacing: rem-calc(3);
					line-height: 0.9rem;
					color: $gray-30;
					margin: 0;
				}
			}
		}

		.my-visit-teaser-remove {
			position: absolute;
			bottom: 0;
			right: 0;
			display: block;
			height: 41px;
			width: 41px;

			.icon {
				// margin-top: 1.2em;
    			// margin-left: 1.2em;
    			color: $gray-30;
			}

			&:hover,
			&:focus {
				background: image-url("my-visit-remove-bg.png") right bottom no-repeat;
				.icon {
	    			color: white;
				}
			}
		}
	}

	.remove-lightbox,
	#visitModal {
		padding: rem-calc(52 - 15) rem-calc(60 - 15);
		max-width: rem-calc(475);
		text-align: center;
		margin: 0 auto;;
	}

	sup.time-ampm {
		margin-left: rem-calc(2)
	}
}

/***
 * IE9-11 hacks for Ladda button positioning inside a table cell
 **/
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ticketing .ladda-loading .ladda-button,
	.tickets .ladda-loading .ladda-button {
  	padding-top: 19px !important;
  }
}

:root .ticketing .ladda-loading .ladda-button,
:root .tickets .ladda-loading .ladda-button {
	padding-top: 19px !important;
}
