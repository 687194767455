	// ======================================================================
	// fancybox
	// ======================================================================

.fancybox-skin {
	z-index: 10000;
    position: relative;
	border-radius: 0;
	box-shadow: none;

    .fancybox-close {
		//background-image: image-url("fancybox/fancybox-close.png") !important;
		background-size: 80px 30px;
		top: -50px;
		right: 0;
		width: 80px;
		height: 30px;
	}
	.fancybox-nav {
	    bottom: -90px;
	    top: auto;
	    width: 69px;
	    height: 69px;
	    display: inline-block;

	    span {
			visibility: visible;
			width: 69px;
		    height: 69px;
		    margin-top: 0;
			top: 0;
			background-size: auto;
		}

	}

	.fancybox-next {
	    right: 0;

	    span {
		    right: 0;
		    background-image: image-url("fancybox/fancybox-next.png") !important;
		    background-position: 0 0;
		}
		&:hover,
		&:focus {
			span {
			    background-position: -69px 0;
			}
		}
	}

	.fancybox-prev {
	    right: 100px;
	    left: auto;

	    span {
		    left: 0;
		    background-image: image-url("fancybox/fancybox-previous.png") !important;
		    background-position: 0 0;
		}
		&:hover,
		&:focus {
			span {
			    background-position: -69px 0;
			}
		}
	}

	@include respond-min($xlarge-breakpoint) {
		.fancybox-nav {
		    bottom: auto;
		    top: 50%;
		    margin-top: -35px;
		}

		.fancybox-next {
		    right: -120px;
		}

		.fancybox-prev {
		    right: auto;
		    left: -120px;
		}
	}
}

.fancybox-title-outside-wrap {
    margin-top: 25px;
    padding-right: 200px;
    width: 100%;
    line-height: 1.2;


    background: left 10px no-repeat;
    font-size: 20px;
    font-weight: 700;

    @include respond-min($small-breakpoint) {
    	margin-top: 45px;
    	background-image: image-url("fancybox/fancybox-caption-ruler.png") !important;
    	padding-left: 80px;
	}

    .sub-caption{
		margin-top: 10px;
		font-family: times;
		color: #999;
		font-size: 18px;
		font-style: italic;
		display: block;
		font-weight: 400;
	}

}

.fancybox-wrap-flowplayer {
  width: 80% !important;
}

.fancybox-wrap.fancybox-type-inline {
	@include respond-max($medium-breakpoint){
		translate: 0 -25px;
	}
}

.fancybox-wrap-flowplayer .fancybox-skin .fancybox-close {
	@include respond-max($xxlarge-breakpoint){
		display: none !important;
	}
}