// =============================================================
// SASS VARIABLES
// This is for variables for the site. Some placed here will override variables in Foundation
// See foundation_settings_reference.txt for list of all Foundation variables and the default settings.
// =============================================================
// Allows the use of rem-calc() or lower-bound() in your settings
@import '~foundation-sites/scss/foundation/functions';

// Foundation uses this to control whether or not CSS classes come through in the gem files.
$include-html-classes: true;
$include-html-global-classes: $include-html-classes;
$include-xl-html-grid-classes: true;
$include-xl-html-block-grid-classes: true;

// =============================================================
// Grid
// =============================================================

$total-columns: 24;
$row-width: rem-calc(1180);
$column-gutter: rem-calc(20);

// =============================================================
// Utility Color
// =============================================================

// Grayscale

$gray-03: #f7f7f7;
$gray-05: #F2F2F2;
$gray-10: #E5E5E5;
$gray-15: #D9D9D9;
$gray-20: #CCCCCC;
$gray-25: #BFBFBF;
$gray-30: #B3B3B3;
$gray-35: #A6A6A6;
$gray-40: #999999;
$gray-45: #8C8C8C;
$gray-50: #808080;
$gray-55: #737373;
$gray-60: #666666;
$gray-65: #595959;
$gray-70: #4D4D4D;
$gray-75: #404040;
$gray-80: #333333;
$gray-85: #262626;
$gray-90: #1A1A1A;
$gray-95: #0D0D0D;

// Third-party color
$facebook-blue: #3b5998;
$twitter-blue: #00acee;
$pinterest-red: #cd2129;

// =============================================================
// Site Colors
// =============================================================

// Theme Colors !!! IMPORTANT use these for base colors so we can change the color scheme per section
$theme-color-1: var(--theme-color-1) !default; // highlight color used in action buttons and links
$theme-color-1-alt: var(--theme-color-1-alt) !default; // highlight color used in action buttons and links in special contexts (e.g. footer)
$theme-color-2: var(--theme-color-2) !default; // medium color used in headlines and social links in footer
$theme-color-3: var(--theme-color-3) !default; // deep color used in footer background
$theme-color-1-text-light: white !default;
$theme-color-2-text-light:  var(--theme-color-2-text-light) !default;
$theme-color-3-text-light: var(--theme-color-3-text-light) !default;
$theme-color-1--alpha-8: var(--theme-color-1--alpha-8); //rgba(var(--my-color-rgb), 0.5) later
$theme-color-2--alpha-8: var(--theme-color-2--alpha-8);
$theme-color-3--alpha-8: var(--theme-color-3--alpha-8);
$theme-color-3--alpha-95: var(--theme-color-3--alpha-95);
$theme-color-3--alpha-0: var(--theme-color-3--alpha-0);

$theme-color-2-theme-color-1--mix-15: var(--theme-color-2-theme-color-1--mix-15);
$theme-color-2-theme-color-1--mix-40: var(--theme-color-2-theme-color-1--mix-40);
$theme-color-1-white--mix-80: var(--theme-color-1-white--mix-80);

$theme-color-1--lighten-40: var(--theme-color-1--lighten-40);
$theme-color-2--lighten-20: var(--theme-color-2--lighten-20);

$theme-color-1--darken-15: var(--theme-color-1--darken-15);

$theme-color-2--lighten-20-desaturate-33: var(--theme-color-2--lighten-20-desaturate-33);

$theme-color-1--scale-color-minus-14: var(--theme-color-1--scale-color-minus-14);
$theme-color-1--scale-color-minus-12: var(--theme-color-1--scale-color-minus-12);
$theme-color-1--scale-color-94: var(--theme-color-1--scale-color-94);
$theme-color-2--scale-color-35: var(--theme-color-2--scale-color-35);

$theme-color-1-light: $theme-color-1--lighten-40;
$theme-color-4: $theme-color-2-theme-color-1--mix-40; // medium color used in quotes and carousels

// @todo-ron going to hold off on this as it may need to be done differently as color could be changed by theme color changes.
// Ron kill this later probably
$theme-color-dark: $theme-color-2; // this color is to be called by the .dark class

// Site Grays
$gray: $gray-50; // #80
$gray-highlight: $gray-25;
$light-gray: $gray-03; // from MSI stylesheet #f0
$lighter-gray: lighten($gray, 40%); // #e6
$dark-gray: darken($gray, 20%); // #4d
$darker-gray: darken($gray, 35%); // #27
$blue-gray: #848fa9; // from stylesheet labelled 50% blue
$light-blue-gray: #92949c;
$lightest-blue-gray: #ced2dd;
$title-gray:#030D26;
$text-gray:#676D7C;

// Site Greens
$color-2: #85bb37; // light green used in action buttons
$green-highlight:#06B04C;// Green in member calendar
$check-green:#1CAF51; // Checks

// Activity Colors
$color-agenda: #689e1a;
$color-event: #689e1a;
$color-exhibit: #2aa9e0;
$color-film: #e3147d;
$color-video: #e3430f;
$color-games: #721e81;
$color-hands-on: #00993f;
$color-tours: #f39b26;

// Colors from Foundation in Global Section
$primary-color: $theme-color-1;
//$alert-function-factor: -14%;

$alert-border-color: $theme-color-1--scale-color-minus-14; //THIS NEEDS TO BE OVERWRITTEN MANUALLY AS IT IS USED INSIDE A MIXIN, //scale-color($primary-color, $lightness: $alert-function-factor) !default;

$success-color: $color-2;

$anchor-font-color-hover: $theme-color-1--scale-color-minus-14;
$callout-panel-bg: $theme-color-1--scale-color-94; //scale-color($primary-color, $lightness: 94%) !default;
$callout-panel-link-color-hover: $theme-color-1--scale-color-minus-14;
$range-slider-handle-bg-hover-color: $theme-color-1--scale-color-minus-12;

// $secondary-color: #e7e7e7; // We will probably need to reset this at some time, but want to see where it is used
// $alert-color: #f04124;
// $warning-color: #f08a24;
// $info-color: #a0d3e8;

// Other color variables
$text-color-base: #737373;
// $text-color-base: #a9a9a9; // This matches the mockups, not the stylesheet
$body-font-color: $text-color-base; // @todo-ron this is because of foundation. Look at it later.

$link-color: $theme-color-1;
$link-color-light: $link-color;
// @todo-ron need hover color, may not need the invert colors.
$link-color-hover: $theme-color-2;
// $invert-link-color-hover: $color-2;
$border-color: $lighter-gray;
$alert-color: #ffdacc;
$alert-font-color: #ff4800;

// =============================================================
// Typography & General Styling
// =============================================================

// Foundation override
$header-text-rendering: auto;
$paragraph-text-rendering: auto;

// BND

// @TODO-UPGRADE Ron search for values of variables in SASS files and replace with variables where needed.

// $font-family-1: "proxima-nova", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
// $font-family-2: "Playfair Display", Georgia, Times, 'Times New Roman', serif;
// $font-family-3: "Space Grotesk", serif;

$font-family-1: "DM Sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
$font-family-2: "Playfair Display", Georgia, Times, 'Times New Roman', serif;
$font-family-3: $font-family-1;
$body-font-family: $font-family-1;

$border-1: 1px solid $gray-10; // Used around teaser and container boxes
$border-link: 2px solid $theme-color-1; // thick light blue bottom border used under links
// Foundation

$header-font-family: $body-font-family;
$header-font-family: $font-family-3;
$header-font-color: $theme-color-2;
$subheader-font-color: $theme-color-2--scale-color-35; //scale-color($header-font-color, $lightness: 35%) !default;
$small-font-color: $theme-color-2--scale-color-35; //scale-color($header-font-color, $lightness: 35%) !default;

$header-font-weight: bold;
$header-line-height: 1.1;
$h1-font-size: rem-calc(48);
$h2-font-size: rem-calc(38);
$h3-font-size: rem-calc(24);
$h4-font-size: rem-calc(18);
$h5-font-size: rem-calc(16);
$h6-font-size: rem-calc(16);

$paragraph-font-family: $body-font-family;
$paragraph-font-size: rem-calc(18);
$paragraph-line-height: rem-calc(32);
$paragraph-margin-bottom: rem-calc(30);
$paragraph-font-weight-bold: 700;

$blockquote-font-color: $theme-color-2;
$blockquote-padding: rem-calc(8) rem-calc(24);
$blockquote-border: 2px solid $theme-color-1;
$blockquote-border: 2px solid green;
$blockquote-cite-font-size: rem-calc(17);
$blockquote-cite-font-color: $theme-color-2-theme-color-1--mix-40;
$blockquote-cite-link-color: $theme-color-2-theme-color-1--mix-40;

$fixed-header-height-px: 91;
$fixed-header-height: rem-calc($fixed-header-height-px);

// =============================================================
// d. Media Query Ranges
// =============================================================
$xsmall-breakpoint: em-calc(370);
$small-breakpoint: em-calc(480); // 480px = 30em
$medium-breakpoint: em-calc(600); // 600px = 37.5em
$large-breakpoint: em-calc(768); // 768px = 48em
// @todo-ron changing this for now 12/12/15
// $xlarge-breakpoint: em-calc(1080); // 1080px = 67.5em
$xlarge-breakpoint: em-calc(1024);
$xlarge-2-breakpoint: em-calc(1167); // not in foundation
$xxlarge-breakpoint: em-calc(1310); // not in foundation

// @todo-ron We might use these. Ron needs to test
$small-range: (0, $small-breakpoint);
$medium-range: ($small-breakpoint  + em-calc(1), $medium-breakpoint);
$large-range: ($medium-breakpoint + em-calc(1), $large-breakpoint);
$xlarge-range: ($large-breakpoint  + em-calc(1), $xlarge-breakpoint);
$xxlarge-range: ($xlarge-breakpoint + em-calc(1), em-calc(99999999));

// =============================================================
// @todo-ron need to check these out to see if needed.
// =============================================================

// Not foundation
// See how this is used and probably renamed. Gutter not right term.
// $vertical-gutter    : 20px;
// $xlarge-vertical-gutter : 30px;

// @todo-ron Need to understand how this is used. I see is showing up in foundation components but looks like it is just used as a variable inside of mixinx or functions.
// $max-width : $xxlarge-breakpoint;

// @todo-ron This is likely important but need to figure out how it works. Not for sure it matters if it is real big, but maybe it does.
// foundation
// $row-width: $max-width;

// =============================================================
// 05. Buttons
// =============================================================

$button-sml: rem-calc(12.5);
$button-med: rem-calc(15);
$button-lrg: rem-calc(27);
$button-font-tny: rem-calc(16);
$button-font-sml: rem-calc(16);
$button-font-med: rem-calc(16);
$button-font-lrg: rem-calc(22);
$button-font-weight: 700;
$button-bg-hover: $theme-color-2;
$button-disabled-opacity: .5;
$secondary-button-bg-hover: $theme-color-1;
$secondary-button-border-color: $color-2;
$success-button-bg-hover: $theme-color-2;

$include-html-nav-classes: $include-html-classes;

// We use these to set various text styles for breadcrumbs.
$crumb-font-size: rem-calc(16);
$crumb-font-color: white;
$crumb-font-color-current: $theme-color-1;
$crumb-font-transform: capitalize;
$crumb-link-decor: none;

// We use these to control the slash between breadcrumbs
$crumb-slash-color: #546388;

// =============================================================
// 10. Flex-Video
// =============================================================

$flex-video-widescreen-padding-bottom: 56.25%;
$flex-video-padding-top: 0;

// =============================================================
// 25. Reveal Modal
// =============================================================

//$reveal-overlay-bg: $theme-color-3--alpha-95;
$reveal-overlay-bg: $theme-color-3;
$reveal-modal-bg: $theme-color-3--alpha-0;

// =============================================================
//     Animation variables
// =============================================================

$duration-extra-short: 200ms;
$duration-short: 500ms;
$duration-medium: 1000ms;
$duration-long: 3000ms;
$duration-extra-long: 5000ms;

// =============================================================
// 11. Forms
// =============================================================

// $input-focus-bg-color: scale-color(#ffffff, $lightness: -2%);
// $input-focus-border-color: scale-color(#ffffff, $lightness: -40%);
// $input-include-glowing-effect: true;
// $glowing-effect-fade-time: .45s;
// $glowing-effect-color: $theme-color-1;


