// ======================================================================
// Mixins
// ======================================================================


// Resets
// ======================================================================

@mixin reset-ul {
	margin: 0;
	padding: 0;
	list-style: none;
	line-height: 1;

	li {
		margin: 0;
		padding: 0;
		text-indent: 0;

		&:before {
			content: none;
		}
	}
}

@mixin reset-blockquote {
	margin: 0;
	padding: 0;
	border: none;
}




// Responsive Mixin (http://jakearchibald.github.io/sass-ie/) ==============================

$fix-mqs: false !default;
@mixin respond-min($width) {
	@if $fix-mqs {
		@if $fix-mqs >= $width { @content; }
	}
	@else {
		@media screen and (min-width: $width) { @content; }
	}
}
@mixin respond-max($width) {
	@if $fix-mqs {
		@if $fix-mqs <= $width { @content; }
	}
	@else {
		@media screen and (max-width: $width) { @content; }
	}
}
$old-ie: false !default;
@mixin old-ie {
	@if $old-ie { @content; }
}

/* Typography =================================================== */

@mixin font-size($font-size: 16){
	font-size : #{$font-size}px;
	font-size : #{$font-size * 0.1}rem;
}

/* Gradients =================================================== */

// @mixin horizontal($startColor : $white, $endColor : $lightergrey) {
// 	background-color	: $endColor;
// 	background-image	: -webkit-gradient(linear, 0 0, 100% 0, from($startColor), to($endColor));  // Safari 4+, Chrome 2+
// 	background-image	: -webkit-linear-gradient(left, $startColor, $endColor);                    // Safari 5.1+, Chrome 10+
// 	background-image	: -moz-linear-gradient(left, $startColor, $endColor);                       // FF 3.6+
// 	background-image	: -ms-linear-gradient(left, $startColor, $endColor);                        // IE10
// 	background-image	: -o-linear-gradient(left, $startColor, $endColor);                         // Opera 11.10
// 	background-image	: linear-gradient(left, $startColor, $endColor);                            // W3C
// 	background-repeat : repeat-x;
// }
//
// @mixin vertical($startColor : $white, $endColor:	$lightergrey) {
//     background-color	: $endColor;
// 		background-image	: -webkit-gradient(linear, 0 0, 0 100%, from($startColor), to($endColor)); // Safari 4+, Chrome 2+
// 		background-image	: -webkit-linear-gradient(top, $startColor, $endColor);                    // Safari 5.1+, Chrome 10+
// 		background-image	: -moz-linear-gradient(top, $startColor, $endColor);                       // FF 3.6+
// 		background-image	: -ms-linear-gradient(top, $startColor, $endColor);                        // IE10
// 		background-image	: -o-linear-gradient(top, $startColor, $endColor);                         // Opera 11.10
// 		background-image	: linear-gradient(top, $startColor, $endColor);                            // W3C
// 		background-repeat : repeat-x;
// }
//
// @mixin directional($startColor : $white, $endColor : $lightergrey, $deg : 45deg) {
// 		background-color	: $endColor;
// 		background-image	: -moz-linear-gradient($deg, $startColor, $endColor);    // FF 3.6+
// 		background-image	: -ms-linear-gradient($deg, $startColor, $endColor);     // IE10
// 		background-image	: -webkit-linear-gradient($deg, $startColor, $endColor); // Safari 5.1+, Chrome 10+
// 		background-image	: -o-linear-gradient($deg, $startColor, $endColor);      // Opera 11.10
// 		background-image	: linear-gradient($deg, $startColor, $endColor);         // W3C
// 		background-repeat : repeat-x;
// }

/* Borders =================================================== */

// .bordered(COLOR, COLOR, COLOR, COLOR);

// @mixin bordered($top-color: #eee, $right-color: #eee, $bottom-color: #eee, $left-color: #eee) {
// 	border: {
// 		top     : solid 1px $top-color;
// 		left    : solid 1px $left-color;
// 		right   : solid 1px $right-color;
// 		bottom  : solid 1px $bottom-color;
// 	}
// }

// @include rounded(VALUE);

// @mixin rounded($radius:4px) {
// 	-webkit-border-radius  : $radius;
// 	-moz-border-radius     : $radius;
// 	border-radius          : $radius;
// }

// @include border-radius(VALUE,VALUE,VALUE,VALUE);

// @mixin border-radius($topright: 0, $bottomright: 0, $bottomleft: 0, $topleft: 0) {
// 	-webkit-border-top-right-radius    : $topright;
// 	-webkit-border-bottom-right-radius : $bottomright;
// 	-webkit-border-bottom-left-radius  : $bottomleft;
// 	-webkit-border-top-left-radius     : $topleft;
//
// 	-moz-border-radius-topright    : $topright;
// 	-moz-border-radius-bottomright : $bottomright;
// 	-moz-border-radius-bottomleft  : $bottomleft;
// 	-moz-border-radius-topleft     : $topleft;
//
// 	border-top-right-radius    : $topright;
// 	border-bottom-right-radius : $bottomright;
// 	border-bottom-left-radius  : $bottomleft;
// 	border-top-left-radius     : $topleft;
//
// 	-webkit-background-clip  : padding-box;
// 	-moz-background-clip     : padding;
// 	background-clip          : padding-box;
// }

/* Colour =================================================== */

// .drop-shadow(HORIZONTAL, VERTICAL, BLUR, ALPHA);

// @mixin drop-shadow($x-axis: 0, $y-axis: 1px, $blur: 2px, $alpha: 0.1) {
// 	-webkit-box-shadow : $x-axis $y-axis $blur rgba(0, 0, 0, $alpha);
// 	-moz-box-shadow    : $x-axis $y-axis $blur rgba(0, 0, 0, $alpha);
// 	box-shadow         : $x-axis $y-axis $blur rgba(0, 0, 0, $alpha);
// }

// @include opacity(VALUE);

// @mixin opacity($opacity : .5) {
// 	-webkit-opacity  : $opacity;
// 	-moz-opacity     : $opacity;
// 	opacity          : $opacity;
// }

// .rotate(VALUEdeg);

// @mixin rotate($deg) {
// 	-webkit-transform  : rotate($deg);
// 	-moz-transform     : rotate($deg);
// 	-ms-transform      : rotate($deg);
// 	-o-transform       : rotate($deg);
// 	transform          : rotate($deg);
// }

// .scale(VALUE);

// @mixin scale($ratio) {
// 	-webkit-transform  : scale($ratio);
// 	-moz-transform     : scale($ratio);
// 	-ms-transform      : scale($ratio);
// 	-o-transform       : scale($ratio);
// 	transform          : scale($ratio);
// }

// .skew(VALUE, VALUE);

// @mixin skew($x: 0, $y: 0) {
// 	-webkit-transform  : skew($x, $y);
// 	-moz-transform     : skew($x, $y);
// 	-ms-transform      : skew($x, $y);
// 	-o-transform       : skew($x, $y);
// 	transform          : skew($x, $y);
// }

// @include transition(PROPERTY DURATION DELAY(OPTIONAL) TIMING-FINCTION);

// @mixin transition($transition) {
// 	-webkit-transition : $transition;
// 	-moz-transition    : $transition;
// 	-ms-transition     : $transition;
// 	-o-transition      : $transition;
// 	transition         : $transition;
// }

// .translate(VALUE, VALUE)

// @mixin translate($x: 0, $y: 0) {
// 	-webkit-transform  : translate($x, $y);
// 	-moz-transform     : translate($x, $y);
// 	-ms-transform      : translate($x, $y);
// 	-o-transform       : translate($x, $y);
// 	transform          : translate($x, $y);
// }

// @mixin translate3d($x: 0, $y: 0, $z: 0) {
// 	-webkit-transform  : translate($x, $y, $z);
// 	-moz-transform     : translate($x, $y, $z);
// 	-ms-transform      : translate($x, $y, $z);
// 	-o-transform       : translate($x, $y, $z);
// 	transform          : translate($x, $y, $z);
// }

// @include background-alpha(VALUE VALUE);

@mixin background-alpha($color: $white, $alpha: 1) {
	background-color : $color;
	background-color : hsla(hue($color), saturation($color), lightness($color), $alpha);
}

// .background-size(VALUE VALUE);

// @mixin background-size($size) {
// 	-webkit-background-size  : $size;
// 	-moz-background-size     : $size;
// 	-o-background-size       : $size;
// 	background-size          : $size;
// }

@mixin triangle($size, $color, $direction) {
  $width: nth($size, 1);
  $height: nth($size, length($size));
  $foreground-color: nth($color, 1);
  $background-color: if(length($color) == 2, nth($color, 2), transparent);
  height: 0;
  width: 0;

  @if ($direction == up) or ($direction == down) or ($direction == right) or ($direction == left) {
    $width: $width * 0.5;
    $height: if(length($size) > 1, $height, $height*0.5);

    @if $direction == up {
      border-bottom: $height solid $foreground-color;
      border-left: $width solid $background-color;
      border-right: $width solid $background-color;
    } @else if $direction == right {
      border-bottom: $width solid $background-color;
      border-left: $height solid $foreground-color;
      border-top: $width solid $background-color;
    } @else if $direction == down {
      border-left: $width solid $background-color;
      border-right: $width solid $background-color;
      border-top: $height solid $foreground-color;
    } @else if $direction == left {
      border-bottom: $width solid $background-color;
      border-right: $height solid $foreground-color;
      border-top: $width solid $background-color;
    }
  } @else if ($direction == up-right) or ($direction == up-left) {
    border-top: $height solid $foreground-color;

    @if $direction == up-right {
      border-left:  $width solid $background-color;
    } @else if $direction == up-left {
      border-right: $width solid $background-color;
    }
  } @else if ($direction == down-right) or ($direction == down-left) {
    border-bottom: $height solid $foreground-color;

    @if $direction == down-right {
      border-left:  $width solid $background-color;
    } @else if $direction == down-left {
      border-right: $width solid $background-color;
    }
  } @else if ($direction == inset-up) {
    border-color: $background-color $background-color $foreground-color;
    border-style: solid;
    border-width: $height $width;
  } @else if ($direction == inset-down) {
    border-color: $foreground-color $background-color $background-color;
    border-style: solid;
    border-width: $height $width;
  } @else if ($direction == inset-right) {
    border-color: $background-color $background-color $background-color $foreground-color;
    border-style: solid;
    border-width: $width $height;
  } @else if ($direction == inset-left) {
    border-color: $background-color $foreground-color $background-color $background-color;
    border-style: solid;
    border-width: $width $height;
  }
}

// @mixin button($color: $compcolor, $textcolor: $white) {
// 	@if lightness($color) > lightness(#888888) {
// 		$textcolor: $black;
// 	} @else {
// 		$textcolor: $white !global;
// 		// text-shadow: 0 1px 0 darken($textcolor, 80%);
// 	}
// 	background-color: $color;
// 	color: $textcolor;
// 	font-size: 110%;
// 	line-height: $baselineheight;
// 	text-align: center;
// 	overflow: hidden;
// 	padding: .5em 30px;
// 	display: inline-block;
// 	border: none;
// 	font-family: $altfont;
// 	font-weight: $altboldweight;
// 	text-transform: uppercase;
// 	&:hover,
//	&:focus {
// 		background-color: darken($color, 10%);
// 		color: $textcolor;
// 	}
// }

// .background-clip(VALUE); (border-box, padding-box, content-box)

// @mixin background-clip($clip) {
// 	-webkit-background-clip  : $clip;
// 	-moz-background-clip     : $clip;
// 	background-clip          : $clip;
// }

// @include box-sizing(VALUE); (border-box, padding-box, content-box)

// @mixin box-sizing($boxsize: border-box) {
// 	-webkit-box-sizing : $boxsize;
// 	-moz-box-sizing    : $boxsize;
// 	-ms-box-sizing     : $boxsize;
// 	box-sizing         : $boxsize;
// }

// Chrome Frame

// @mixin chromeframe() {
// 	margin : .2em 0;
// 	background : $lightgrey;
// 	color : $black;
// 	padding : .2em 0;
// }

// Contain floats

// @mixin clearfix {
// 	&:before,
// 	&:after {
// 			content: " ";
// 			display: table;
// 	}
// 	&:after { clear: both; }
// 	*zoom : 1;
// }

// For image replacement

// @mixin hide-text() {
// 	text-indent: 100%;
// 	white-space: nowrap;
// 	overflow: hidden;
// }

// Hide from visual and speaking browsers

// @mixin hidden() {
// 	display: none !important;
// 	visibility: hidden;
// }

// Hide but maintain layout

// @mixin invisible() {
// 	visibility: hidden;
// }

// @include resize(VALUE) (none, both, horizontal, vertical, inherit)

// @mixin resize($direction: both) {
// 	resize: $direction;
// 	overflow: auto;
// }

// .userselect(VALUE) (all, element, none, text)

// @mixin user-select($select) {
// 	-webkit-user-select : $select;
// 	-moz-user-select : $select;
// 	-o-user-select : $select;
// 	user-select : $select;
// }

// Hidden but available to speaking browsers

// @mixin visuallyhidden() {
// 	overflow : hidden;
// 	position : absolute;
// 	clip : rect(0 0 0 0);
// 	height : 1px;
// 	width : 1px;
// 	margin : -1px;
// 	padding : 0;
// 	border : 0;
//
// 	// Make visuallyhidden focusable with a keyboard
// 	&.focusable {
// 		&:active, &:focus {
// 			position : static;
// 			clip : auto;
// 			height : auto;
// 			width : auto;
// 			margin : 0;
// 			overflow: visible;
// 		}
// 	}
// }

/* Layout =================================================== */

// .columns(250px, 0, 24px, COLOR, solid, 1px)

// @mixin columns($colwidth: 250px, $colcount: 0, $colgap: 50px, $columnRuleColor: #eee, $columnRuleStyle: solid, $columnRuleWidth: 1px) {
// 	-webkit-column-width : $colwidth;
// 	-webkit-column-count : $colcount;
// 	-webkit-column-gap : $colgap;
// 	-webkit-column-rule-color : $columnRuleColor;
// 	-webkit-column-rule-style : $columnRuleStyle;
// 	-webkit-column-rule-width : $columnRuleWidth;
// 	-moz-column-width : $colwidth;
// 	-moz-column-count : $colcount;
// 	-moz-column-gap : $colgap;
// 	-moz-column-rule-color : $columnRuleColor;
// 	-moz-column-rule-style : $columnRuleStyle;
// 	-moz-column-rule-width : $columnRuleWidth;
// 	column-width : $colwidth;
// 	column-count : $colcount;
// 	column-gap : $colgap;
// 	column-rule-color : $columnRuleColor;
// 	column-rule-style : $columnRuleStyle;
// 	column-rule-width : $columnRuleWidth;
// }

// See current support chart http://caniuse.com/#feat=multicolumn

/* 
Column mixins by Tiffany Brown (@webinista / webinista.com) 
Public domain licensed, because, really who couldn't come up with this on their own?
It's not required, but I'd appreciate you letting me know if you found these useful :-).
*/

@mixin column-count($value) {
	-moz-column-count: $value;
	-webkit-column-count: $value;
	column-count: $value;
}

@mixin column-fill($value) {
	-moz-column-fill: $value;
	-webkit-column-fill: $value;
	column-fill: $value;
}

@mixin column-gap($value) {
	-moz-column-gap: $value;
	-webkit-column-gap: $value;
	column-gap: $value;
}