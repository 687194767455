.animation-wrapper {
  // Loop through the items and add some delay.
  @for $i from 1 through 10 {
    .animated:nth-child(#{$i}) {
      @include animation-delay(.1s * $i);
    }
  }
}

.animatable {
  visibility: hidden;
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
  visibility: visible;
}


@keyframes groupedSlideUpOut {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(0, -350px, 0);
    visibility: hidden;
  }
}

.groupedSlideUpOut {
  animation-name: groupedSlideUpOut;
  animation-timing-function: ceaser($easeInOutQuad);
  animation-duration: .75s !important;
}

@keyframes groupedSlideUpIn {
  from {
    transform: translate3d(0, 350px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.groupedSlideUpIn {
  animation-name: groupedSlideUpIn;
  animation-timing-function: ceaser($easeInOutQuad);
  animation-delay: .125s;
  animation-duration: .75s !important;
}

@keyframes bigHeaderSlideUpOut {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    transform: translate3d(0, -350px, 0);
    // visibility: hidden;
  }
}

.bigHeaderSlideUpOut {
  animation-name: bigHeaderSlideUpOut;
  animation-timing-function: ceaser($easeInOutQuart);
  animation-duration: .75s !important;
}

@keyframes bigHeaderSlideUpIn {
  from {
    transform: translate3d(0, 350px, 0);
    // visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.bigHeaderSlideUpIn {
  animation-name: bigHeaderSlideUpIn;
  animation-timing-function: ceaser($easeInOutQuart);
  animation-delay: .125s;
  animation-duration: .75s !important;
}

.bigHeaderSlideUpInLink {
  @extend .bigHeaderSlideUpIn;
  // animation-delay: .5s;
}

@keyframes fadeInAndSlideInLeft {
  from {
    transform: translate3d(-1rem, 0, 0);
    opacity: 0;
  }

  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1.0;
  }
}

.fadeInAndSlideInLeft {
  animation-duration: 2s !important;
  animation-name: fadeInAndSlideInLeft;
  animation-timing-function: ceaser($easeOutQuad);
}

@keyframes fadeInFastAndSlideInLeft {
  from {
    transform: translate3d(-1rem, 0, 0);
    opacity: 0;
  }

  15% {
    opacity: 1.0;
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

.fadeInFastAndSlideInLeft {
  animation-duration: 1s !important;
  animation-name: fadeInFastAndSlideInLeft;
  animation-timing-function: ceaser($easeOutQuad);
}

@keyframes bigFadeInAndSlideInLeft {
  from {
    transform: translate3d(-3rem, 0, 0);
    opacity: 0;
  }

  75% {
    opacity: 1.0;
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

.bigFadeInAndSlideInLeft {
  animation-duration: 2s !important;
  animation-name: bigFadeInAndSlideInLeft;
  animation-timing-function: ceaser($easeOutQuad);
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}

@keyframes bigSlideInLeft {
  from {
     transform: translate3d(-15%, 0, 0);
     visibility: visible;
   }

   100% {
     transform: translate3d(0, 0, 0);
   }
}

.bigSlideInLeft {
  animation-duration: 2s !important;
  animation-name: bigSlideInLeft;
  animation-timing-function: cubic-bezier(0.145, 0.865, 0.140, 0.875);
}

@keyframes fadeInAndSlideInRight {
  from {
    transform: translate3d(1rem, 0, 0);
    opacity: 0;
  }

  75% {
    opacity: 1.0;
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

.fadeInAndSlideInRight {
  animation-duration: 2s !important;
  animation-name: fadeInAndSlideInRight;
  animation-timing-function: ceaser($easeOutQuad);
}

@keyframes fadeInFastAndSlideInRight {
  from {
    transform: translate3d(1rem, 0, 0);
    opacity: 0;
  }

  25% {
    opacity: 1.0;
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

.fadeInFastAndSlideInRight {
  animation-duration: 1s !important;
  animation-name: fadeInFastAndSlideInRight;
  animation-timing-function: ceaser($easeOutQuad);
}

@keyframes bigFadeInAndSlideInRight {
  from {
    transform: translate3d(1rem, 0, 0);
    opacity: 0;
  }

  75% {
    opacity: 1.0;
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

.bigFadeInAndSlideInRight {
  animation-duration: 2s !important;
  animation-name: bigFadeInAndSlideInRight;
  animation-timing-function: ceaser($easeOutQuad);
}


@keyframes slideInUp {
  from {
    transform: translate3d(0, 20%, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  animation-name: slideInUp;
  animation-duration: 1.5s !important;
  animation-timing-function: ceaser($easeOutQuad);
}

@keyframes slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(0, -20%, 0);
  }
}

.slideOutUp {
  animation-name: slideOutUp;
  animation-duration: 1.5s !important;
  animation-timing-function: ceaser($easeOutQuad);
}

@keyframes slideInUpBig {
  from {
    transform: translate3d(0, 100%, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

.slideInUpBig {
  animation-name: slideInUpBig;
  animation-duration: 1s !important;
  animation-timing-function: ceaser($easeOutQuart);
}

@keyframes slideOutUpBig {
  from {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(0, -100%, 0);
  }
}

.slideOutUpBig {
  animation-name: slideOutUpBig;
  animation-duration: 1s !important;
  animation-timing-function: ceaser($easeInOutQuart);
}


@keyframes slideInUpSmall {
  from {
    transform: translate3d(0, 100px, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}

.slideInUpSmall {
  animation-name: slideInUpSmall;
  animation-duration: 1s !important;
  animation-timing-function: ceaser($easeOutSine);
}

.slideInUpSmallFast {
  animation-name: slideInUpSmall;
  animation-duration: .4s !important;
  animation-timing-function: ceaser($easeInOutQuad);
}

@keyframes slideOutUpSmall {
  from {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(0, -100px, 0);
  }
}

.slideOutUpSmall {
  animation-name: slideOutUpSmall;
  animation-duration: 1.5s !important;
  animation-timing-function: ceaser($easeOutSine);
}

.slideOutUpSmallFast {
  animation-name: slideOutUpSmall;
  animation-duration: .4s !important;
  animation-timing-function: ceaser($easeInOutQuad);
}

@keyframes slideInRight {
  from {
     transform: translate3d(150%, 0, 0);
     visibility: visible;
     opacity: 0;
   }

   100% {
     transform: translate3d(0, 0, 0);
     opacity: 1;
   }
}

.slideInRight {
  animation-duration: 1s !important;
  animation-name: slideInRight;
  animation-timing-function: ceaser($easeOutQuad);
}

@keyframes slideOutRight {
  from {
     transform: translate3d(0, 0, 0);
     opacity: 1;
   }

   100% {
     transform: translate3d(-150%, 0, 0);
     opacity: 0;
     visibility: hidden;
   }
}

.slideOutRight {
  animation-duration: 1s !important;
  animation-name: slideOutRight;
  animation-timing-function: ceaser($easeOutQuad);
}



@keyframes slideInLeft {
  from {
     transform: translate3d(-150%, 0, 0);
     visibility: visible;
     opacity: 0;
   }

   100% {
     transform: translate3d(0, 0, 0);
     opacity: 1;
   }
}

.slideInLeft {
  animation-duration: 1s !important;
  animation-name: slideInLeft;
  animation-timing-function: ceaser($easeOutQuad);
}

@keyframes slideOutLeft {
  from {
     transform: translate3d(0, 0, 0);
     opacity: 1;
   }

   100% {
     transform: translate3d(150%, 0, 0);
     opacity: 0;
     visibility: hidden;
   }
}

.slideOutLeft {
  animation-duration: 1s !important;
  animation-name: slideOutLeft;
  animation-timing-function: ceaser($easeOutQuad);
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
  animation-timing-function: ceaser($easeOutQuad);
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
}

.fadeOutFast {
  animation-name: fadeOut;
  animation-timing-function: ceaser($easeOutQuad);
  animation-duration: .5s !important;
}

.fadeOutFaster {
  animation-name: fadeOut;
  animation-timing-function: ceaser($easeOutQuart);
  animation-duration: .3s !important;
}

@keyframes activate {
  from {
    background-color: white;
    color: $theme-color-1;
  }

  50% {
    background-color: $theme-color-1;
    color: white;
    transform: scale3d(.5, .5, .5);
  }

  to {
    background-color: $theme-color-1;
    color: white;
  }

}

.activate {
  animation-duration: .75s !important;
  animation-name: activate;
}

@keyframes deactivate {
  from {
    background-color: $theme-color-1;
    color: white;
  }

  50% {
    background-color: white;
    color: $theme-color-1;
    transform: scale3d(.5, .5, .5);
  }

  to {
    background-color: white;
    color: $theme-color-1;
  }
}

.deactivate {
  animation-duration: .75s !important;
  animation-name: deactivate;
}

.blur-up {
  filter: blur(10px);
  -webkit-filter: blur(10px);
  transition: filter 400ms, -webkit-filter 400ms;
}

.blur-up.lazyloaded {
  filter: blur(0);
  -webkit-filter: blur(0);
  transition: filter 400ms, -webkit-filter 400ms;
}