/* Video Elements =================================================== */

// Eliminate blur on paused video

.plyr.plyr--paused iframe {
    filter: none !important;
}


iframe { max-width: 100%; }

.fancybox-wrap-flowplayer .flowplayer .fp-progress,
.fancybox-wrap-flowplayer .flowplayer .fp-volumelevel {
	background-color: $theme-color-1 !important;
}

.fancybox-wrap-flowplayer .is-paused.flowplayer .fp-ui {
	background-image: image-url("play-video-alt.svg") !important;
	background-size: 11% !important;
}

.flex-video {
	video {
        height: auto;
	}
}

// video in mason gallary
.video-in-gallery {
	position: relative;
	display: block;
	margin-bottom: 1em;

	&:hover,
	&:focus {
		.vps__play {
			background-position: 0 -50px;
		}
	}

	@include respond-min($medium-breakpoint) {
		&:hover,
		&:focus {
			.vps__play {
				background-position: 0 -75px;
			}
		}
	}
}

.vig__ {
	&play {
		position: absolute;
		top: 50%;
		left: 50%;
		height: 55px;
		width: 166px;
		padding: 14px 10px;
	}
}






.interior.wide {
	.video-preview-simple,
	.video-preview-module {
		@include respond-min($xlarge-breakpoint) {
			margin-left: rem-calc(-50);
			margin-right: rem-calc(-50);
		}
	}
}


// video preview with only play icon
.video-preview-simple {
	position: relative;
	display: block;
	margin-bottom: 1em;

	&:hover,
	&:focus {
		.vps__play {
			background-position: 0 -50px;
		}
	}

	@include respond-min($medium-breakpoint) {
		&:hover,
		&:focus {
			.vps__play {
				background-position: 0 -100px;
			}
		}
	}
}

.vps__ {
	&play {
		position: absolute;
		top: 50%;
		left: 50%;
		height: 55px;
		width: 166px;
		padding: 14px 10px;
	}
}


// video preview with title and tagline
.video-preview-module {
	position: relative;
	display: block;
	margin-bottom: 1em;

	&:hover,
	&:focus {
		.vpm__play {
			.fill {
				opacity:0.33;
			}
			.circle {
				fill:#FFFFFF;
			}
			.triangle {
				fill:#FFFFFF;
			}
		}
	}
}

.vpm__ {

	&overlay {
		position: absolute;
		top: 0;
		height: 100%;
		width: 100%;

		.vpm__play {
			margin: 0 auto;
			height: 55px;
			width: 166px;
			padding: 14px 10px;

			@include respond-max($small-breakpoint) {
				width: 55px;
		    }


		}

		.vpm__wrap {
			position: absolute;
			top: 50%;
			left: 50%;
            translate: -50% -50%;
			text-align: center;
		}
	}

	&has-title {
		.vpm__wrap {
			display: table;
			width: 100%;
			height: 100%;
			margin: 0 auto;
		}

		.vpm__inner-wrap {
			display: table-cell;
			vertical-align: middle;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            translate: -50% -50%;
		}
	}

	&overlay-black {
		background: rgba(0,0,0,0.6);

		.vpm__play {

			.fill {
				opacity:0;

				.video-preview-module:hover &,
				.video-preview-module:focus & {
					opacity:0.33;
				}
			}
			.circle {
				fill:#FFFFFF;
				opacity: 0.33;

				.video-preview-module:hover &,
				.video-preview-module:focus & {
					fill:#FFFFFF;
				}
			}
			.triangle {
				fill:$theme-color-1;

				.video-preview-module:hover &,
				.video-preview-module:focus & {
					fill:#FFFFFF;
				}
			}
		}
	}

	&overlay-theme-1 {
		background: $theme-color-1--alpha-8;

		.vpm__play {

			.fill {
				opacity:0;

				.video-preview-module:hover &,
				.video-preview-module:focus & {
					opacity:0.33;
				}
			}
			.circle {
				fill:#FFFFFF;
				opacity: 0.33;

				.video-preview-module:hover &,
				.video-preview-module:focus & {
					fill:#FFFFFF;
				}
			}
			.triangle {
				fill:$theme-color-2;

				.video-preview-module:hover &,
				.video-preview-module:focus & {
					fill:#FFFFFF;
				}
			}
		}

		.vpm__text {
			color: white;
			text-transform: unset;
		}
	}

	&overlay-theme-2 {
		background: $theme-color-2--alpha-8;

		.vpm__play {

			.fill {
				opacity:0;

				.video-preview-module:hover &,
				.video-preview-module:focus & {
					opacity:0.33;
				}
			}
			.circle {
				fill:#FFFFFF;
				opacity: 0.33;

				.video-preview-module:hover &,
				.video-preview-module:focus & {
					fill:#FFFFFF;
				}
			}
			.triangle {
				fill:$theme-color-1;

				.video-preview-module:hover &,
				.video-preview-module:focus & {
					fill:#FFFFFF;
				}
			}
		}
	}

	&overlay-theme-3 {
		background: $theme-color-3--alpha-8;

		.vpm__play {

			.fill {
				opacity:0;

				.video-preview-module:hover &,
				.video-preview-module:focus & {
					opacity:0.33;
				}
			}
			.circle {
				fill:#FFFFFF;
				opacity: 0.33;

				.video-preview-module:hover &,
				.video-preview-module:focus & {
					fill:#FFFFFF;
				}
			}
			.triangle {
				fill:$theme-color-1;

				.video-preview-module:hover &,
				.video-preview-module:focus & {
					fill:#FFFFFF;
				}
			}
		}
	}



	&play {
		margin: 0 auto;
		height: 55px;
		width: 166px;
		padding: 14px 10px;

		.fill {
			opacity:0;
		}
		.circle {
			fill:#FFFFFF;
			opacity: 0.33;
		}
		.triangle {
			fill:$theme-color-1;
		}

		@include respond-max($small-breakpoint){
			width: 55px;
		}
	}

	&play-svg{
		position: relative;
		top: 5px;

		@include respond-max($small-breakpoint){
			left:2px;
		}
	}

	&title {
		color: white;
		font-size: rem-calc(22);
		margin-top: rem-calc(10);
		margin-bottom: rem-calc(7);
		letter-spacing:-0.5px;
		line-height: 1.05em;
		@include respond-min($medium-breakpoint) {
			font-size: rem-calc(48);
			margin-top: rem-calc(23);
			margin-bottom: rem-calc(28);
	    }
	}

	&text {
		color: white;
		font-size: rem-calc(18);
		//text-transform: uppercase;
		font-weight: 700;
		letter-spacing: rem-calc(0);
		//margin-top: rem-calc(10);
		margin-bottom: 0;
		line-height: 1em;
		display: inline-block;
		margin-left:18px;


		@include respond-max($small-breakpoint){
			display:none;
		}
	}
}
