/* Map =================================================== */
// .nav-secondary {
//     display: none;
// }
#interactive-map {
    @include respond-min($large-breakpoint) {
        padding-right: rem-calc(300);
    }

    @include respond-min($xlarge-breakpoint) {
        padding-right: rem-calc(350);
    }

    @include respond-min($xxlarge-breakpoint) {
        padding-right: rem-calc(400);
    }

    .map-main-content {
        background-color: $theme-color-2;
        padding: 0;
        width: 100%;

        #museum-map {
            margin: 0;
            height: rem-calc(700);

            .map-label {
                margin-left: -30px;
            }
        }

        #map-legend-button {
            width: rem-calc(20);
            margin-top: rem-calc(-81);

            .button {
                margin: 0;
            }
        }

        #map-legend {
            padding: rem-calc(80 160);

            .icon-circle {
                float: left;
            }

            p {
                padding-left: 30px;
                color: $theme-color-1-text-light;
                line-height: rem-calc(50);
            }
        }
    }

    .map-sidebar {
        height: rem-calc(700);
        overflow: scroll;
        padding: rem-calc(20);
        position: absolute;
        top: 0;
        right: 0;
        background-color: white;
        display: none;

        @include respond-min($large-breakpoint) {
            display: block;
            width: rem-calc(300);
        }

        @include respond-min($xlarge-breakpoint) {
            width: rem-calc(350);
        }

        @include respond-min($xxlarge-breakpoint) {
            width: rem-calc(400);
        }

        h2 {
            margin: rem-calc(20 0);
            font-size: rem-calc(36);
        }
    }

    .gm-style-iw {
        width: rem-calc(320) !important;
        background-color: transparent;
        box-shadow: none;
        border: none;

        @include respond-min($xsmall-breakpoint) {
            width: rem-calc(370) !important;
        }

        @include respond-min($small-breakpoint) {
            width: rem-calc(480) !important;
        }

        @include respond-min($medium-breakpoint) {
            width: rem-calc(600) !important;
        }

        @include respond-min($large-breakpoint) {
            width: rem-calc(290) !important;
        }

        @include respond-min($xlarge-breakpoint) {
            width: rem-calc(320) !important;
        }
        //    @include respond-max($large-breakpoint - em-calc(1)) {
        //        width: 280px !important;
        //    }
    }

    .gm-style-iw > div:first-child[style] {
        max-width: 100% !important;
        max-height: 100% !important;
        overflow: auto !important;
        // padding-left: rem-calc(50); @TODO-RON Causes issues on hover 220914-1843
        // @include respond-min($large-breakpoint) {
        //     max-width: 290px !important;
        // }
        // @include respond-max($large-breakpoint - em-calc(1)) {
        //     max-width: 300px !important;
        // }
    }

    .info-window {
        width: auto !important;
        padding: rem-calc(0 6 6) !important;
        //margin: 30px;
        text-align: center;
        z-index: 999;

        img.box-teaser-image {
            //width: 100%;
            //height: 100%;
        }

        .box-teaser {
            // background-color: white;
        }
        // @include respond-min($large-breakpoint) {
        //     width: auto !important;
        // }
        // @include respond-max($large-breakpoint - em-calc(1)) {
        //     width: auto !important;
        // }
    }

    .custom-control {
        padding: 30px 10px 0;
        cursor: pointer;

        .zoom-control-container {
            text-align: center;
            width: 32px;
            height: 70px;
            color: white;
            font-size: 16px;

            div {
                width: 32px;
                height: 32px;
                padding: 5px;
                background-color: rgb(42, 169, 224);
                margin-bottom: 6px;
            }
        }

        .floor-control-container {
            text-align: center;
            width: auto;
            height: 152px;
            color: rgb(42, 169, 224);
            font-size: 14px;

            .floor-button-container {
                clear: both;
                width: auto;

                div {
                    width: 32px;
                    height: 32px;
                    padding: 5px;
                    background-color: white;
                    margin-bottom: 6px;
                    float: left;
                    transition: all ease-in-out 0.4s;
                    transition-delay: 0.4s;

                    &.active {
                        color: white;
                        background-color: rgb(10, 31, 84);
                        transition: all ease-in-out 0.4s;
                        transition-delay: 0.4s;
                    }
                }

                span {
                    display: block;
                    max-width: 0;
                    padding: 0;
                    overflow: hidden;
                    font-size: 12px;
                    height: 32px;
                    color: white;
                    background-color: rgb(10, 31, 84);
                    float: right;
                    border: none;
                    transition: all ease-in-out 0.4s;

                    &.active {
                        padding: 5px 10px;
                        border-left: solid white 2px;
                        max-width: 90vw;
                        transition: all ease-in-out 0.4s;
                        transition-delay: 0.4s;
                    }
                }
            }
        }

        .geolocation-control-container {
            div {
                width: 32px;
                height: 32px;
                padding: 5px;
                background: #ffffff url('/typo3conf/ext/map/Resources/Public/Icons/geolocation.png') no-repeat center center;
                margin-bottom: 5px;
            }

            &.active {
                div {
                    background-color: rgb(10, 31, 84);
                }
            }
        }
    }
}
