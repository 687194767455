	// ======================================================================
	// Exhibit Home Sidebar
	// ======================================================================


.exhibit-home-sidebar {
	margin: rem-calc(10) 0 0 0;

	@include respond-min($xlarge-breakpoint) {
		margin: rem-calc(10) 0 0 rem-calc(30);
	}
}

ul#all-essentials {
	@include reset-ul;
	padding:0;
	@include respond-min($large-breakpoint){
		padding: 1em 2em;
	}

	li {
		color: white;
		font-size: rem-calc(14);
		letter-spacing: rem-calc(0.5);
		left: 0;
		padding-bottom: rem-calc(18);
		//padding-bottom: rem-calc(31);

		@include respond-min($large-breakpoint) {
			width: 195px;
		}
		@include respond-min($xlarge-breakpoint) {
			width: 230px;
		}
		.essentials-info {
			display: table;
		}
		.essentials-icon {
			color: $theme-color-1-alt;
			font-size: rem-calc(30);
			display: table-cell;
			vertical-align: top;
			//width: 50px;
			width: 45px;
		}
		span.item {
			line-height: 1.3em;
			display: table-cell;
			vertical-align: middle;
			font-weight: 700;

		}
		a {
			line-height: 1.3em;
			display: table-cell;
		    vertical-align: middle;
			color: white;
			font-weight: 700;

			span.title {
				float: left;
				width: 85%;


			}
			.icon {
				color: white;
				font-size: 1.6em;
				margin: 0;
				position: relative;
				top: rem-calc(-2);
				float: right;
			}
			&:hover,
			&:focus {
				color: $theme-color-1 !important;
				.icon {
					color: $theme-color-1;
				}
			}
		}
		.description {
			font-size: rem-calc(12);
			// color: $gray-50;
			color: rgba($white, .75);
			margin: rem-calc(0 0 0 50);
			line-height: 1.5;
			font-weight: 400;
		}
		&.hidden-item {
			display: none;
		}
	}

	& .icon-circle{
		background-color: $lightest-blue-gray;
		border-color: $lightest-blue-gray;
		width: 14px;
		height: 14px;
		margin: 0;
		top: 2px;
		position: relative;

		& .icon {
			margin: 0;
			font-size: 14px;
			line-height: 14px;
			position: absolute;
			top: -1px;
			right: -2px;
			color: $theme-color-1;
			width: 14px;
			height: 14px;
		}
	}

}

// Additional styling 2020
ul#all-essentials{
		margin: rem-calc(20 0);
		padding: 0;
		@include respond-min($medium-breakpoint){
			padding: 0;
		}

		& > li {
			padding-bottom: rem-calc(15)!important;
			width:100%!important;
		}

		& .essentials-icon{
			width:44px!important;
		}

		& .essentials-name{
			display: table-cell;
			vertical-align: middle;
		}

		& .title, & .item{
			font-size: 14px!important;
			font-weight: 600!important;
			float:none!important;
			width:auto!important;
			letter-spacing:0;
		}

		& .description{
			color:$text-gray!important;
			margin-left:0!important;
			font-size: 14px!important;
			letter-spacing:0;
		}

		& .icon-circle{
			background-color: rgba(white,0.1);
			border-color: rgba(white,0.1);
			width: 14px;
			height: 14px;
			margin: 0;
			top: 2px;
			position: relative;

			& .icon {
				margin: 0;
				font-size: 14px;
				line-height: 14px;
				position: absolute;
				top: -1px;
				right: -2px;
				color: white;
				width: 14px;
				height: 14px;
			}
		}
	}

	a.show-all {
	font-size: rem-calc(16);
	font-weight: 700;
	display: block;
	margin-top: rem-calc(35);

	.icon-add {
		position: relative;
		top: rem-calc(-1);
		font-size: 0.65em;
	}

	&:hover,
	&:focus {
		.icon {
			color: white;
		}
	}
}

.fancybox-inner {
	ul#all-essentials {
		li {
			color: $theme-color-2;

			&>.icon {
				color: $theme-color-2;
			}

			a {
				color: $theme-color-2;

				.icon {
					color: $theme-color-2;
				}
				&:hover,
				&:focus {
					color: $theme-color-1;
				}
			}
			&.hidden-item {
				display: block;
			}
		}
	}
}
