/*.remove{
    background: url("/fileadmin/tickets.png");
    background-position: top center;
    background-repeat: no-repeat;
}
*/


/* Local Menu =================================================== */
#sidebar .local-menu {
    display: none;

    @include respond-min($large-breakpoint) {
      display: block;
      overflow: visible;
      //padding-bottom: rem-calc(30) !important;
      padding-bottom: 0 !important;
        margin-bottom: 30px;
      padding-left: 14px;
    }

    .parent-item-menu h3{
        color:$theme-color-1;
        margin-bottom: 20px;
        font-size:20px;
    }

    .level-1-item {
        margin: 15px 0;

        &.current{
            margin-bottom:7px;
        }

        a {
            color: $title-gray;
            font-size: rem-calc(15);
            line-height: rem-calc(20);
            font-weight: 400;
            padding: 0;

            &.active {
                font-weight: bold;
            }

            &:hover{
                color:$theme-color-1;
            }
        }
    }


    .level-2-item{
        //margin: 15px 0;

        a{
            font-weight: 400;
            font-size: rem-calc(14);
            color:$title-gray;
            padding-top: 7px;
            padding-bottom: 7px;

            &.active{
                font-weight: bold;
            }

            .indicator{
                display: none;
            }

            &.current{
               /* .indicator{
                    display:inline-block;
                    position: absolute;
                    height: 100%;
                    background-color: var(--theme-color-1);
                    width: 2px;
                    left: 0;
                    top: 0;

                }

                */

                font-weight: 600;
                font-style: italic;
            }

            &:hover{
                color:$theme-color-1;
            }
        }
    }

}

@include respond-min($large-breakpoint) {
    #content-block-1 .local-menu {
        display: none;
    }
}

.local-menu {
    // @include single-transition(height, $duration-medium, ease-in-out);
    line-height: 1em;
    padding: rem-calc(0 0 0 15);
    font-weight: 700;
    margin-bottom: rem-calc(25);
    border: 1px solid $gray-highlight;
    border-radius: 5px;
    height: rem-calc(45);
    overflow: hidden;
    margin-top: rem-calc(30);
    position: relative;

    &.expanded {
        height: auto;

        .nav-button{
            margin-bottom:10px;
        }

        .level-1-item{

            border-top: 1px solid $light-gray;

            a{
                font-weight:normal;
            }

            .current{
                font-weight:700;
            }
        }

        .indicator{
            display:none;
        }
    }

    @include respond-min($large-breakpoint) {
        margin-top: 0;
        border: none;
        //padding: rem-calc(72 45);
        padding: rem-calc(45 45);
        height: auto;
    }

    @include respond-min(1220px) {
        padding-top: 85px;
    }

    .parent-item-menu {
        #content-block-1 &{
            display: none;
        }

        h3 {
            margin-left: -15px;
        }
    }
    .current-page-title {
        font-size: rem-calc(18);
        color: $theme-color-1;
        position: absolute;
        width: 78%;
        z-index: 10;
        padding: rem-calc(15 0 13);
        white-space: nowrap;
        overflow: hidden;

        @include respond-min($large-breakpoint) {
            display: none;
        }
    }

    .nav-button {
        float: right;
        cursor: pointer;
        padding: rem-calc(7 0);
        color: $theme-color-1;
        font-size: rem-calc(15);
        width: 100%;
        text-align: right;
        position: relative;
        right: 5px;
        top:5px;
        z-index: 100;

        @include respond-min($large-breakpoint) {
            display: none;
        }

         .icon {
             font-size:21px;
             margin-right:10px;
         }
    }

    ul {
        @include reset-ul;
        float: left;
        width: 85%;

		@include respond-min($large-breakpoint) {
            float: none;
            width: 100%;
        }

        li {
            line-height: 1.2;

            a {
                display: block;
                color: $theme-color-2;

                &:hover,
                &:focus {
                    color: $theme-color-1;
                    text-decoration: none;
                }
            }
        }
    }

    .level-1 {
       // background: image-url("local-menu-dot-line.png") top repeat-x;
    }

    @include respond-min($large-breakpoint) {
         .level-1,
         .level-2 {
            //background: image-url("local-menu-dot-line.png") bottom repeat-x;
            background: none;
        }
    }

    .level-1-item {
        &:first-child > a {
            background: none;
        }

        & > a {
            padding: rem-calc(13 0 12);
            display: block;
            font-size: rem-calc(15);

            @include respond-min($large-breakpoint) {
               font-size: rem-calc(16);
//				   background: image-url("local-menu-dot-line.png") top repeat-x;
            }

            @include respond-min($xlarge-breakpoint) {
                font-size: rem-calc(18);
            }

            &.active.sub {
                padding-bottom: 6px;
            }
        }

        &.current {
            position: relative;

            a {
                color: $theme-color-1;
            }

            .indicator {
                position: absolute;
                height: 100%;
                background-color: $theme-color-1;
                width: 2px;
                left: -13px;
                top: 0;
                display: none;

                @include respond-min($large-breakpoint){
                    display: inline-block;
                }
            }
        }
    }

    .level-2 {
        padding-bottom: rem-calc(10);
    }

    .level-2-item {
        a {
            font-size: rem-calc(16);
            padding: rem-calc(4 0 6 18);
            position: relative;

            @include respond-min($large-breakpoint) {
                font-size: rem-calc(14);

                .indicator {
                    background-color: $theme-color-1;
                }

                &:hover,
                &:focus {
                    .indicator {
                        background-color: $theme-color-2;
                    }
                }
            }

            @include respond-min($xlarge-breakpoint) {
                font-size: rem-calc(16);
                padding: rem-calc(10 0 10 18);
            }

            .indicator {
                position: absolute;
                height: 1px;
                width: 8px;
                left: 0;
                top: rem-calc(12);
                background-color: $theme-color-2;

                @include respond-min($xlarge-breakpoint) {
                    top: rem-calc(18);
                }
            }

            &:hover,
            &:focus {
                .indicator {
                    background-color: $theme-color-1;
                }
            }
        }

        &.current {
            a {
                color: $theme-color-1;

                .indicator {
                    background-color: $theme-color-1;
                }
            }
        }

        + .level-1-item {
            margin-top: rem-calc(10);
        }
    }
}
