// ======================================================================
// TABLES
// ======================================================================

table {
width: 100%;
background: transparent;
border: none;
margin-bottom: 1.25rem;
table-layout: auto;
border-collapse: collapse;

thead,
tfoot {
	background-color: transparent;
}

tr {
	&:nth-of-type(even) {
		background: transparent;
	}
}

tr:last-child td {
	border-bottom: none;
}

tr td,
thead tr th,
tr th {
	border-bottom: 1px solid $lightest-blue-gray;
	vertical-align: middle;
	padding: rem-calc(19) 0 rem-calc(19) rem-calc(18);
	color: $text-color-base;
	font-size: rem-calc(16);
}

thead tr th,
tr th {
	color: $text-color-base;
	text-transform: uppercase;
	font-weight: 600;
	font-size: rem-calc(10);
	letter-spacing: rem-calc(2);
}

caption {
	text-align: left;
	font-weight: normal;
	font-size: rem-calc(24);
	line-height: rem-calc(30);
	margin-bottom: rem-calc(20);
	color: $dark-gray;
}
}
