	// ======================================================================
	// CE Call Out
	// ======================================================================
.ce-call-out {

	font-family: $font-family-2;
	font-size: rem-calc(28);
	line-height: 37px;
	color: $theme-color-1;
	text-align: center;

	br,
	.break {
		display: none;
	}

	@include respond-min($medium-breakpoint) {
		font-size: rem-calc(33);
		line-height: 43px;
	}

	@include respond-min($xlarge-breakpoint) {
		font-size: rem-calc(42);
		line-height: 52px;
		br,
		.break {
			display: block;
		}
	}
}
