	// ======================================================================
	// Experience Pricing Table
	// ======================================================================

.experience-pricing-table {
	@include respond-min($xlarge-breakpoint) {
		padding-right: rem-calc(60);
	}
	table {
		thead {
			tr {
				th {
					text-align: center;
					font-size: rem-calc(12);
					padding: rem-calc(15) rem-calc(6) rem-calc(11);

					@include respond-min($medium-breakpoint) {
						padding: rem-calc(15) rem-calc(10) rem-calc(11);
						font-size: rem-calc(10);
						font-weight: 700;
					}
				}
				th.member {
					@include border-top-left-radius(20px);
					@include border-top-right-radius(20px);
					
					text-align: center;
				}
			}
		}

		tbody {
			td.type-wrap {
				padding: rem-calc(24) 0 rem-calc(18) 0;

				.type {
					color: $theme-color-2;
					font-size: rem-calc(20);
					font-weight: 700;

					@include respond-min($small-breakpoint) {
						margin-left: rem-calc(10);
						font-size: rem-calc(22);
					}
				}

				.ages {
					font-size: rem-calc(14);
					display: block;
					color: $theme-color-2-text-light;
					margin-top: rem-calc(2);

					@include respond-min($small-breakpoint) {
						margin-left: rem-calc(10);
					}
				}

			}

			td.price-wrap {
				padding-left: rem-calc(25);
				padding-right: rem-calc(25);
				font-size: rem-calc(17);
				color: $gray-30;
				width: 110px;
		    	text-align: center;

		    	@include respond-min($small-breakpoint) {
					padding-left: rem-calc(30);
					padding-right: rem-calc(30);
					width: 120px;
				}

				.price {
					color: $theme-color-1;
					font-size: rem-calc(25);
					font-weight: 700;
					margin-left: rem-calc(5);
				}
				.free {
					color: $theme-color-2;
					margin-left: rem-calc(0);
				}
			}
			tr:last-child {
				td.member {
					@include border-bottom-left-radius(20px);
					@include border-bottom-right-radius(20px);
				}
			}
		}
		.member {
					background-color: #f7f7f7;
				}
	}
}