	// ======================================================================
	// Photo Teaser
	// ======================================================================
.photo-teaser,
.photo-teaser-small-text {

	.tai__image-group,
	.image-group {

		a {
			position: relative;
			display: block;

			&.lazyload-child {

				&:before {
					background-image: none !important;
				}

				h3 {
					display: none;
				}
			}

			h3 {
				position: absolute;
				bottom: rem-calc(17);
				left: rem-calc(20);
				color: white;
				margin: 0;
				padding: 0;
				line-height: 0.85;
				font-weight: 700;

				@include respond-min($medium-breakpoint) {
					bottom: rem-calc(7);
					left: rem-calc(10);
				}

				@include respond-min($large-breakpoint) {
					bottom: rem-calc(12);
					left: rem-calc(15);
				}

				@include respond-min($xlarge-breakpoint) {
					bottom: rem-calc(27);
					left: rem-calc(30);
				}



				.icon {
					//color: $theme-color-1;
					color: rgba(white,0.5);
					font-size: 1.7rem;
					margin-left: rem-calc(8);
					position: relative;
					top: rem-calc(4);

					@include respond-min($medium-breakpoint) {
						font-size: 1.6rem;
						margin-left: rem-calc(5);
					}
					@include respond-min($large-breakpoint) {
						font-size: 1.85rem;
						margin-left: rem-calc(10);
					}

					&::before{
					}    font-weight: 200!important;
				}
			}
			&:hover,
			&:focus {
				h3 {
					text-decoration: none;
					color: $theme-color-1;

					.icon {
						color: $theme-color-1-text-light;
					}
				}
			}
			&:before {
				content: '';
				position: absolute;
				top: 50%;
				right: 0;
				bottom: 0;
				left: 0;
				background-image: linear-gradient(to top,rgba(0,0,0,0.7),rgba(0,0,0,0));
			}
		}
	}
}

.photo-teaser {
	.tai__image-group,
	.image-group {
		a {
			h3 {
				font-size: rem-calc(20);

				@include respond-min($medium-breakpoint) {
					font-size: rem-calc(15);
				}
				@include respond-min($large-breakpoint) {
					font-size: rem-calc(18);
				}
				@include respond-min($xlarge-breakpoint) {
					font-size: rem-calc(24);
				}
			}
		}
	}
}

.photo-teaser-small-text {
	.tai__image-group,
	.image-group {
		a {
			h3 {
				font-size: rem-calc(16);
				line-height: 0.5;

				@include respond-min($medium-breakpoint) {
					font-size: rem-calc(13);
				}
				@include respond-min($large-breakpoint) {
					font-size: rem-calc(15);
				}
				@include respond-min($xlarge-breakpoint) {
					font-size: rem-calc(18);
				}

				.icon {
					top: rem-calc(4);
					font-size: 1.5rem;
					margin-left: rem-calc(5);

					@include respond-min($medium-breakpoint) {
						font-size: 1.4rem;

					}
					@include respond-min($large-breakpoint) {
						font-size: 1.6rem;
					}
				}
			}
		}
	}
}
