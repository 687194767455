// Generated by grunt-webfont


@font-face {
	font-family:"icons";
	src:url("/typo3conf/ext/msi_skin/Resources/Fonts/icons.eot?0d3609129ecd18e7c4e8447203b35ec9");
	src:url("/typo3conf/ext/msi_skin/Resources/Fonts/icons.eot?#iefix") format("embedded-opentype"),
		url("/_assets/16c122cebb6dd10fd754319ee821f8f8/Fonts/icons.woff?0d3609129ecd18e7c4e8447203b35ec9") format("woff"),
		url("/_assets/16c122cebb6dd10fd754319ee821f8f8/Fonts/icons.ttf?0d3609129ecd18e7c4e8447203b35ec9") format("truetype");
	font-weight:normal;
	font-style:normal;
}

.icon {
	font-family:"icons";
	width: 1em;
	height: 1em;
	margin-right: .25em;
	display:inline-block;
	box-sizing: border-box;
	line-height:1;
	font-weight:normal;
	font-style:normal;
	font-variant: normal;
	speak:none;
	text-decoration:inherit;
	text-transform:none;
	text-rendering:auto;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale;
}


// @todo-ron Should vertical-align be above???

// Icons


.icon-accessibility:before {
	content:"\f101";
}

@mixin icon-accessibility {content: "\f101";}
%icon-accessibility { @include icon-accessibility;}



.icon-add-bold:before {
	content:"\f102";
}

@mixin icon-add-bold {content: "\f102";}
%icon-add-bold { @include icon-add-bold;}



.icon-add-to-visit:before {
	content:"\f103";
}

@mixin icon-add-to-visit {content: "\f103";}
%icon-add-to-visit { @include icon-add-to-visit;}



.icon-add:before {
	content:"\f104";
}

@mixin icon-add {content: "\f104";}
%icon-add { @include icon-add;}



.icon-adult:before {
	content:"\f105";
}

@mixin icon-adult {content: "\f105";}
%icon-adult { @include icon-adult;}



.icon-anglebracket-down:before {
	content:"\f106";
}

@mixin icon-anglebracket-down {content: "\f106";}
%icon-anglebracket-down { @include icon-anglebracket-down;}



.icon-anglebracket-left:before {
	content:"\f107";
}

@mixin icon-anglebracket-left {content: "\f107";}
%icon-anglebracket-left { @include icon-anglebracket-left;}



.icon-anglebracket-right:before {
	content:"\f108";
}

@mixin icon-anglebracket-right {content: "\f108";}
%icon-anglebracket-right { @include icon-anglebracket-right;}



.icon-anglebracket-up:before {
	content:"\f109";
}

@mixin icon-anglebracket-up {content: "\f109";}
%icon-anglebracket-up { @include icon-anglebracket-up;}



.icon-arrow-down:before {
	content:"\f10a";
}

@mixin icon-arrow-down {content: "\f10a";}
%icon-arrow-down { @include icon-arrow-down;}



.icon-arrow-left:before {
	content:"\f10b";
}

@mixin icon-arrow-left {content: "\f10b";}
%icon-arrow-left { @include icon-arrow-left;}



.icon-arrow-right:before {
	content:"\f10c";
}

@mixin icon-arrow-right {content: "\f10c";}
%icon-arrow-right { @include icon-arrow-right;}



.icon-arrow-up:before {
	content:"\f10d";
}

@mixin icon-arrow-up {content: "\f10d";}
%icon-arrow-up { @include icon-arrow-up;}



.icon-baby:before {
	content:"\f10e";
}

@mixin icon-baby {content: "\f10e";}
%icon-baby { @include icon-baby;}



.icon-calendar:before {
	content:"\f10f";
}

@mixin icon-calendar {content: "\f10f";}
%icon-calendar { @include icon-calendar;}



.icon-card:before {
	content:"\f110";
}

@mixin icon-card {content: "\f110";}
%icon-card { @include icon-card;}



.icon-check:before {
	content:"\f111";
}

@mixin icon-check {content: "\f111";}
%icon-check { @include icon-check;}



.icon-child:before {
	content:"\f112";
}

@mixin icon-child {content: "\f112";}
%icon-child { @include icon-child;}



.icon-clipboard:before {
	content:"\f113";
}

@mixin icon-clipboard {content: "\f113";}
%icon-clipboard { @include icon-clipboard;}



.icon-clock:before {
	content:"\f114";
}

@mixin icon-clock {content: "\f114";}
%icon-clock { @include icon-clock;}



.icon-coathanger:before {
	content:"\f115";
}

@mixin icon-coathanger {content: "\f115";}
%icon-coathanger { @include icon-coathanger;}



.icon-drops:before {
	content:"\f116";
}

@mixin icon-drops {content: "\f116";}
%icon-drops { @include icon-drops;}



.icon-elevator:before {
	content:"\f117";
}

@mixin icon-elevator {content: "\f117";}
%icon-elevator { @include icon-elevator;}



.icon-ellipsis:before {
	content:"\f118";
}

@mixin icon-ellipsis {content: "\f118";}
%icon-ellipsis { @include icon-ellipsis;}



.icon-entrance:before {
	content:"\f119";
}

@mixin icon-entrance {content: "\f119";}
%icon-entrance { @include icon-entrance;}



.icon-event:before {
	content:"\f11a";
}

@mixin icon-event {content: "\f11a";}
%icon-event { @include icon-event;}



.icon-exhibit:before {
	content:"\f11b";
}

@mixin icon-exhibit {content: "\f11b";}
%icon-exhibit { @include icon-exhibit;}



.icon-facebook:before {
	content:"\f11c";
}

@mixin icon-facebook {content: "\f11c";}
%icon-facebook { @include icon-facebook;}



.icon-family:before {
	content:"\f11d";
}

@mixin icon-family {content: "\f11d";}
%icon-family { @include icon-family;}



.icon-film:before {
	content:"\f11e";
}

@mixin icon-film {content: "\f11e";}
%icon-film { @include icon-film;}



.icon-game:before {
	content:"\f11f";
}

@mixin icon-game {content: "\f11f";}
%icon-game { @include icon-game;}



.icon-handicap:before {
	content:"\f120";
}

@mixin icon-handicap {content: "\f120";}
%icon-handicap { @include icon-handicap;}



.icon-hands-on:before {
	content:"\f121";
}

@mixin icon-hands-on {content: "\f121";}
%icon-hands-on { @include icon-hands-on;}



.icon-hourglass:before {
	content:"\f122";
}

@mixin icon-hourglass {content: "\f122";}
%icon-hourglass { @include icon-hourglass;}



.icon-i-circle:before {
	content:"\f123";
}

@mixin icon-i-circle {content: "\f123";}
%icon-i-circle { @include icon-i-circle;}



.icon-instagram:before {
	content:"\f124";
}

@mixin icon-instagram {content: "\f124";}
%icon-instagram { @include icon-instagram;}



.icon-list-bullet:before {
	content:"\f125";
}

@mixin icon-list-bullet {content: "\f125";}
%icon-list-bullet { @include icon-list-bullet;}



.icon-lock:before {
	content:"\f126";
}

@mixin icon-lock {content: "\f126";}
%icon-lock { @include icon-lock;}



.icon-lost-and-found:before {
	content:"\f127";
}

@mixin icon-lost-and-found {content: "\f127";}
%icon-lost-and-found { @include icon-lost-and-found;}



.icon-mail:before {
	content:"\f128";
}

@mixin icon-mail {content: "\f128";}
%icon-mail { @include icon-mail;}



.icon-map-pin:before {
	content:"\f129";
}

@mixin icon-map-pin {content: "\f129";}
%icon-map-pin { @include icon-map-pin;}



.icon-member:before {
	content:"\f12a";
}

@mixin icon-member {content: "\f12a";}
%icon-member { @include icon-member;}



.icon-minus-bold:before {
	content:"\f12b";
}

@mixin icon-minus-bold {content: "\f12b";}
%icon-minus-bold { @include icon-minus-bold;}



.icon-minus:before {
	content:"\f12c";
}

@mixin icon-minus {content: "\f12c";}
%icon-minus { @include icon-minus;}



.icon-nurse:before {
	content:"\f12d";
}

@mixin icon-nurse {content: "\f12d";}
%icon-nurse { @include icon-nurse;}



.icon-paper:before {
	content:"\f12e";
}

@mixin icon-paper {content: "\f12e";}
%icon-paper { @include icon-paper;}



.icon-play:before {
	content:"\f12f";
}

@mixin icon-play {content: "\f12f";}
%icon-play { @include icon-play;}



.icon-quote-close:before {
	content:"\f130";
}

@mixin icon-quote-close {content: "\f130";}
%icon-quote-close { @include icon-quote-close;}



.icon-quote-open:before {
	content:"\f131";
}

@mixin icon-quote-open {content: "\f131";}
%icon-quote-open { @include icon-quote-open;}



.icon-quote-pen:before {
	content:"\f132";
}

@mixin icon-quote-pen {content: "\f132";}
%icon-quote-pen { @include icon-quote-pen;}



.icon-refresh:before {
	content:"\f133";
}

@mixin icon-refresh {content: "\f133";}
%icon-refresh { @include icon-refresh;}



.icon-restroom:before {
	content:"\f134";
}

@mixin icon-restroom {content: "\f134";}
%icon-restroom { @include icon-restroom;}



.icon-ribbon:before {
	content:"\f135";
}

@mixin icon-ribbon {content: "\f135";}
%icon-ribbon { @include icon-ribbon;}



.icon-search:before {
	content:"\f136";
}

@mixin icon-search {content: "\f136";}
%icon-search { @include icon-search;}



.icon-see-all:before {
	content:"\f137";
}

@mixin icon-see-all {content: "\f137";}
%icon-see-all { @include icon-see-all;}



.icon-segway:before {
	content:"\f138";
}

@mixin icon-segway {content: "\f138";}
%icon-segway { @include icon-segway;}



.icon-share:before {
	content:"\f139";
}

@mixin icon-share {content: "\f139";}
%icon-share { @include icon-share;}



.icon-stairs:before {
	content:"\f13a";
}

@mixin icon-stairs {content: "\f13a";}
%icon-stairs { @include icon-stairs;}



.icon-stroller:before {
	content:"\f13b";
}

@mixin icon-stroller {content: "\f13b";}
%icon-stroller { @include icon-stroller;}



.icon-ticket:before {
	content:"\f13c";
}

@mixin icon-ticket {content: "\f13c";}
%icon-ticket { @include icon-ticket;}



.icon-tour:before {
	content:"\f13d";
}

@mixin icon-tour {content: "\f13d";}
%icon-tour { @include icon-tour;}



.icon-trash:before {
	content:"\f13e";
}

@mixin icon-trash {content: "\f13e";}
%icon-trash { @include icon-trash;}



.icon-tripadvisor:before {
	content:"\f13f";
}

@mixin icon-tripadvisor {content: "\f13f";}
%icon-tripadvisor { @include icon-tripadvisor;}



.icon-twitter:before {
	content:"\f140";
}

@mixin icon-twitter {content: "\f140";}
%icon-twitter { @include icon-twitter;}



.icon-video:before {
	content:"\f141";
}

@mixin icon-video {content: "\f141";}
%icon-video { @include icon-video;}



.icon-wheelchair:before {
	content:"\f142";
}

@mixin icon-wheelchair {content: "\f142";}
%icon-wheelchair { @include icon-wheelchair;}



.icon-youtube:before {
	content:"\f143";
}

@mixin icon-youtube {content: "\f143";}
%icon-youtube { @include icon-youtube;}


