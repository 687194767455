.webtolead-form {

  input[type="text"], select {
    color: $text-color-base;
  }

  input[type=submit] {
    @extend .button;
  }
}
