/* Columns =================================================== */

// @todo-ron If this stays will need to be lower and hyphen, but should it stay? The intent before was only to clear not clearfix.
@use "sass:math";

.clearOnly {
	@extend %clearfix;
}

// @todo-ron should this be here.
.column-group {
	@include grid-row();
	.column {
		// @todo-jeremyg Look at why transitions are broken
		// @include transition(all .5s ease-in-out);
	}
	@include respond-min($medium-breakpoint) {
		.column {
			@include grid-column($total-columns*0.5);
		}
		&.three-column-group .column {
			@include grid-column(math.div($total-columns, 3));
		}
	}
	@include respond-min($large-breakpoint) {
		.column {
			&.half { @include grid-column($total-columns*0.5); }
			&.third { @include grid-column(math.div($total-columns, 3)); }
			&.quarter { @include grid-column($total-columns*0.25); }
			&.three-quarter { @include grid-column(($total-columns*0.25)*3); }
			&.two-third { @include grid-column(math.div($total-columns, 3)*2); }
		}
	}
}


// @todo ron: this should probably exist elsewhere, but this fixes the columns not lining up with other content in content-block-1
/*
@include respond-min($small-breakpoint) {
	.row .column-group,
	.row .block-grid,
	.row .responsive-block-grid-3.feature-block-grid,
	.row .responsive-block-grid-3.teaser-block-grid.feature-block-grid {
		margin: 0 -.625rem;
		max-width: none;
		width: auto;
	}
}

 */
.row .column-group,
.row .block-grid,
.row .responsive-block-grid-3.feature-block-grid,
.row .responsive-block-grid-3.teaser-block-grid.feature-block-grid {
	margin: 0 -.625rem;
	max-width: none;
	width: auto;
}

