.full_image_box_teaser {
  margin: 0 auto 15px;
  position: relative;
  width: 100%;
  background: none;
  text-shadow: 0px 2px 4px black;
  //max-width: 450px;
  max-width: 600px;
  height: auto;
  min-height: 90px;

  @include respond-min($xlarge-2-breakpoint) {
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.1);
    margin: 0;
    height: 450px;
    width: 360px;
    overflow: hidden;
  }

  &:hover{

    .full_image_box_teaser__title
    .icon-anglebracket-right {
      opacity: 1;
      padding-left:5px;
      font-weight: 700;
      transition: all 0.3s ease-in-out;
    }


    @include respond-min($xlarge-2-breakpoint){
      .full_image_box_teaser__image-wrap {
        transform: scale(1.1);
        transition: all 1s ease-in;
      }
    }

   /* .fade-in {
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, $theme-color-3 100%);
      transition: all 0.3s ease-in-out;
    }

    */
  }

  > a {
    /*display: flex;
    justify-content: center;
    flex-direction: row;
    align-content: center;
     */
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 100%;
  }

  .full_image_box_teaser__image-wrap {
    // desired width divided by parent width times 100% gives you desired percent width
    //width: 150 / 600 * 100%; comment out by Ben, because if we compare with the mobile mockup, 25% is narrower.
    //width: 185 / 600 * 100%;
    width:26%;
    // table-cell on the image gives the content something to lock up to
    display: table-cell;
    //flex-basis: 30%;
    height: 100%;

    background-size: cover;
    background-position: center center;
    transform: scale(1);
    transition: all 0.5s ease-out!important;

    @include respond-min($xlarge-2-breakpoint) {
      width: 100%;
      height: 100%;
      //display: block;
    }
  }

  .full_image_box_teaser__image-wrap-border {
    position: absolute;
    top: 10px;
    bottom: 10px;
    right: 10px;
    left: 10px;
    border: 1px solid rgba(white, 0.25);
    @include respond-max($xlarge-2-breakpoint) {
      display: none;
    }
  }

  .full_image_box_teaser__image {
    width: 100%;
    height: auto;
    /* @include respond-min($medium-breakpoint) {
       margin-bottom: 0;
     }

     */
  }

  .full_image_box_teaser__content {
    // desired width divided by parent width times 100% gives you desired percent width
    //width: 450 / 600 * 100%;
    width: 71%;
    // padding of table cell in percent is calculated on table width
    padding: 15px 20px;
    display: table-cell;
    vertical-align: top;
    background: rgba(white,0.05);
    overflow: hidden;
    position: relative;

    @include respond-min($xlarge-2-breakpoint) {
      // padding: rem-calc(34 40 0);
      padding: 0 40px 0 40px;
      display: block;
      width: auto;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      //min-height: 30.1686746988%;
      //max-height: 37.1686746988%;
      height: 100%;
      max-height: 126px;
      z-index: 200;
      background: transparent;
      text-shadow: 0px 3px 2px black;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.9) 0%, transparent 100%);
    }
  }

  .full_image_box_teaser__title {
    color: white !important;
    font-size: rem-calc(22);
    line-height: rem-calc(22);
    letter-spacing: -0.8px;
    font-weight: 700;
    margin-bottom:0;
    @include respond-min($xlarge-2-breakpoint) {
      font-size: rem-calc(30);
      line-height: rem-calc(30);
      display: table;
      width: 100%;
      height: rem-calc(50);
    }

    &-inner-wrap {
      @include respond-min($xlarge-2-breakpoint) {
        display: table-cell;
        vertical-align: middle;
      }
    }

    .icon-anglebracket-right {
      position: absolute;
      margin-top: 1px;
      color: white;
      opacity: 0.5;
      padding-left:0;
      font-weight: normal;
      transition: all 0.3s ease-in-out;
    }
  }

  .full_image_box_teaser__description {
    color: $light-gray;
    //color: rgba(white,0.6);
    text-shadow: 0px 3px 2px black;
    font-size: rem-calc(14);
    line-height: rem-calc(17);
    letter-spacing: 0;
    margin-bottom: 0;
    max-height: 35px;
    overflow: hidden;
    @include respond-min($xlarge-2-breakpoint) {
      font-size: rem-calc(16);
      line-height: rem-calc(24);
      max-height: 45px;
    }
  }

  .full_image_box_teaser__link-underline {
    display: none;
  }

  .fade-in {
   /* position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 5rem;
    background: transparent;
    transition: background 0.3s ease-in-out;

    */
    display: none;
  }

  .box_teaser.box_teaser--full-image:hover, .box_teaser.box_teaser--full-image:focus {
    .full_image_box_teaser__content {
      min-height: 100%;
      max-height: 100%;
      transition: none;
    }

    .full_image_box_teaser__title:after {
      padding-left: 5px;
      transition: padding-left 0.3s ease-in-out;
    }

    .full_image_box_teaser__link-underline {
      display: none;
    }
  }
}

.lc__module-3-up {
  .lc__content-inner-wrap {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    @include respond-max($xlarge-2-breakpoint) {
      display: block;
      width: 100%;
    }

   & > div.full_image_box_teaser {
      flex-basis: 31%;
      @include respond-max($xlarge-2-breakpoint) {
        width: 100%;
        flex-basis: 100%;
      }
    }
  }
}

