	// ======================================================================
	// Teaser Module
	// Plan Your Visit :: http://msi.dev/visit/plan-your-visit/
	//     web/typo3conf/ext/experiences/Resources/Private/Templates/Experience/PlanYourVisit.html
	//
	// ======================================================================


.box-module {
	@extend .clearfix;
	margin-bottom: rem-calc(32);
	padding-bottom: rem-calc(32);
	display: block;
	clear: both;
	width: 100%;
	border-bottom: $border-1;

	.bm__image-wrap {

		width: 100%;
		padding-bottom: rem-calc(32);

		@include respond-min($small-breakpoint) {
			display: block;
			float: left;
			width: 41%;
			max-width: 240px;
			padding-right: rem-calc(40);
		}


		img {
			width: 100%;
		}
	}

	.bm__content-wrap {
		// padding-bottom: rem-calc(32);
		border-left: none;
		border-right: none;
		border-bottom: none;
		display: table;
		vertical-align: top;
		// width: 59%;
		.mh__headline-group {
			margin-bottom: 1em;
            h1, h2, h3, h4, h5, h6 {
                margin-bottom: rem-calc(15);
            }
		}

		h2 {
			font-size: rem-calc(38);
		}

		h3 {
			font-size: rem-calc(32);
		}

		h4 {
			font-size: rem-calc(24);
		}

		h5 {
			font-size: rem-calc(18);
		}
	}

	.bm__teaser-module-content {

		p{
			margin-bottom: rem-calc(14);
			line-height: rem-calc(27);
		}

	}

	// @todo-ron I don't think this is used in the CE
	// .icon-circle,
	// .label {
	// 	float: right;
	// 	margin-right: 0;
	// 	font-size: rem-calc(17);
	// }

	// @todo-ron I don't think this is used in the CE
	// .icon-circle.light {
	// 	background-color: transparent;
	// 	border: 1px solid $gray-20;
	// }

	// @todo-ron I don't think this is used in the CE
	// .icon-circle .icon {
	// 	font-size: em-calc(12);
	// 	margin-top: .45em;
	// }

	// @todo-ron I don't think this is used in the CE
	// .label.secondary {
	// 	color: $theme-color-1;
	// 	font-weight: bold;
	// 	text-transform: uppercase;
	// 	font-size: rem-calc(10);
	// 	letter-spacing: rem-calc(2.5);
	// 	padding: rem-calc(10);
	// }

	// @todo-ron I don't think this is used in the CE
	// .title {
	// 	font-size: rem-calc(18);
	// 	line-height: rem-calc(24);
	// 	margin-bottom: rem-calc(16);
	//
	// 	+ .subtitle {
	// 		margin-top: rem-calc(-6);
	// 		margin-bottom: rem-calc(5);
	// 	}
	// }

	// @todo-ron I don't think this is used in the CE
	// .subtitle {
	// 	font-size: rem-calc(10);
	// 	line-height: rem-calc(17);
	// 	font-weight: bold;
	// 	text-transform: uppercase;
	// 	color: inherit;
	// 	letter-spacing: rem-calc(2);
	// 	margin-bottom: rem-calc(2);
	// }

	.rte-content {
		padding-right: 0;
		margin-bottom: rem-calc(10);

		// &.rte-text-large {
		// 	p, ul, ol, dl {
		// 		font-size: rem-calc(14);
		// 		line-height: rem-calc(24);
		// 		margin-bottom: rem-calc(16);
		// 	}
		// }
	}

	// @todo-ron I think this needs to be reworked to allow for RTE text sizing
	// p, ul, ol, dl {
	// 	font-size: rem-calc(12);
	// 	line-height: rem-calc(21);
	// 	margin-bottom: rem-calc(12);
	// }

	// @todo-ron I don't think this is used in the CE
	// .info {
	// 	font-size: rem-calc(9);
	// 	text-transform: uppercase;
	// 	display: block;
	// 	letter-spacing: rem-calc(2.5);
	// 	font-weight: 600;
	//
	// 	// @todo-ron I don't think this is used in the CE
	// 	// .title {
	// 	// 	font-size: inherit;
	// 	// 	line-height: inherit;
	// 	// }
	// }
	//
	// .info + hr {
	// 	clear: none;
	// 	border-top: 2px dotted $lightest-blue-gray;
	// 	display: none;
	// 	margin: 0 0 rem-calc(3);
	// }
}

.box-module.bm__horizontal.bm__bottom-border-none {
	border-bottom: none;
	padding-bottom: 0;
}

.bm__horizontal.bm__image-thumbnail {

	.bm__image-wrap {
		width: 80px;
		float: left;
		padding: rem-calc(0 30 32 0);

		@include respond-min($medium-breakpoint) {
			width: 120px;
			padding-right: rem-calc(70)
		}

		// img {
		// 	max-height:rem-calc(64);
		// }
	}

	// .bm__content-wrap {
	// 	width: 78.81%;
	// }

	// p {
	// 	padding-right: 7.8%;
	// }

}

.column-group .box-module.bm__vertical {



	@include respond-min($medium-breakpoint) {

		.bm__image-wrap {
			width: 100%;
			max-width: 100%;
			padding: 0;
		}

		.bm__image {
			width: 100%;
			display: block;
		}

		.bm__content-wrap {
			//border: $border-1;
			// background-color: white;
			padding-top: rem-calc(20);
		}
	}
}

.box-module.bm__vertical {

	border-bottom:0;

	@include respond-min($large-breakpoint) {
		margin-bottom: rem-calc(40);
		box-shadow: 0px 2px 4px 1px rgba(0,0,0,0.15);
		padding: 0;
		background:$gray-05;
		border-radius:10px;


		.bm__image-wrap {
			width: 100%;
			max-width: 100%;
			padding: 0;
		}

		.bm__image {
			width: 100%;
			display: block;
		}

		// @todo-ron I don't think this is used in the CE
		// &.teaser-module-blue {
		// 	background-color: $theme-color-2;
		// 	color: white;
		//
		// 	.teaser-module-content {
		// 		border: none;
		// 	}
		// }

		// @todo-ron I don't think this is used in the CE
		// &.teaser-module-no-bottom-collapse {
		// 	margin-bottom: 0;
		// }

		.bm__footer {
			text-align: center;

			&.align-left {
				text-align: left;;
			}
			.button {
				margin-bottom: 0;
			}
		}
	}

	@include respond-min($xlarge-breakpoint) {

		.bm__content-wrap {
			padding: rem-calc(70 45);
		}
	}

    .bm__content-wrap {
        //border: $border-1;
        padding: rem-calc(40 20);
        //background-color: white;

        @include respond-min($xlarge-breakpoint) {
            padding: rem-calc(70 45);
        }
    }

	// @todo-ron I don't think this is used in the CE
	// .title {
	// 	margin-bottom: .5em;
	// }

	// @todo-ron I don't think this is used in the CE
	// .subtitle {
	// 	margin-bottom: 20px;
	// }

	// @todo-ron I don't think this is used in the CE
	// h3.title {
	// 	margin-bottom: 1em;
	// }
}


.box-module.bm__inset-text {

	@include respond-min($xxlarge-breakpoint) {
		border: 1px solid $gray-10;
		position: relative;

		.bm__content-wrap {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 44%;
			background-color: $theme-color-3;
			padding: rem-calc(30 40 40);

			// @todo-ron I don't think this is used in the CE
			// .title {
			// 	margin-bottom: rem-calc(25);
			// }

			p {
				margin-bottom: rem-calc(20);
				line-height: 1.5rem;
			}
		}
	}
}

.box-module.bm__horizontal.bm__image-large {

	.bm__image-wrap {

		@include respond-min($medium-breakpoint) {
			width: 45.7%;
			max-width: 320px;
		}
	}

	// .bm__content-wrap {
	// 	width: 45.3%;
	// }

	// @todo-ron I don't think this is used in the CE
	// .title {
	// 	font-size: rem-calc(24);
	// 	line-height: rem-calc(26);
	// }
	//
	// .info .title {
	// 	font-size: inherit;
	// 	line-height: inherit;
	// }

	// @todo-ron I don't think this is used in the CE
	// .info + hr {
	// 	display: block;
	// }

	// Plan Your Visit :: http://msi.dev/visit/plan-your-visit/
	//     web/typo3conf/ext/experiences/Resources/Private/Templates/Experience/PlanYourVisit.html
	//

}

#plan-your-visit-results .box-module-pyv{
	display:flex;
	flex-direction:row;
	justify-content: space-between;
	align-items: stretch;
	max-height: 80px;
	overflow: hidden;
	box-shadow: 0 1px 3px 0 rgba(82,98,105,0.16), 0 7px 15px 0 rgba(0,0,0,0.12);
	border-radius:10px;
	margin-bottom:0;

	@include respond-min($small-breakpoint){
		max-height: 100%;
	}

	@include respond-min($xlarge-breakpoint){
		border-radius:unset;
		box-shadow: unset;
		margin-bottom: 30px;
	}

	&:after{
		clear: unset;
		display: block;
		content: unset;
	}

	&:hover{

		.icon-anglebracket-right {
			opacity: 1;
			//padding-left:5px;
			right: -5px;
			font-weight: 700;
			transition: all 0.3s ease-in-out;
		}

	}

	.bm__image-wrap{
		float:none;
		width:auto;
		padding: 0;
		flex-basis: 36%;

		@include respond-min($small-breakpoint){
			max-width: 260px;
			max-height: 125px;
		}

		@include respond-min($xlarge-breakpoint){
			max-height: 210px;
		}


		img{
			padding: 0;
			border-top-left-radius: 10px;
			border-bottom-left-radius: 10px;
			max-width: 100px;

			@include respond-min($small-breakpoint){
				max-width: 260px!important;
			}

			@include respond-min($xlarge-breakpoint){
				border-radius: unset;
			}
		}

	}

	.bm__content-wrap{
      	display: block;
		float:none;
		flex-basis: 58%;
		margin-left: 6%;
		align-self: center;
		@include respond-min($xlarge-breakpoint) {
			align-self: stretch;
			position: relative;
			padding-bottom: 50px;
		}


		& .mh__headline-group{
			display: table;
			margin:0;
			width: 100%;
		}

		& .headline-group{
			display: table-cell;
		}


		h3.title {
			font-size: 16px;
			line-height: 20px;
			margin: 0;
			letter-spacing: -0.5px;
			font-weight: 600;
			@include respond-min($small-breakpoint) {
				font-size: 24px;
				line-height: 27px;
			}
		}

		& .subtitle{
			margin: 0;
			font-size: 9px;
			letter-spacing: 1.8px;
			color:$text-gray;
			font-weight: 600;
			display: none;

			@include respond-min($xsmall-breakpoint) {
				display: block;
			}

			@include respond-min($small-breakpoint) {
				margin-bottom: 12px;
				font-size: 12px;
				margin-top: 5px;
			}
		}

		.bm__teaser-module-content{
			display: none;
			@include respond-min($xlarge-breakpoint) {
				color:$text-gray;
				display: block;
				.description{
					line-height: 1.6rem;
					font-size: rem-calc(16);
					margin-bottom: rem-calc(10);
				}

				.small-note{
					font-weight: 600;
					font-size: rem-calc(14);
					margin: 0;

					& .icon-info{
						position: relative;
						top: 3px;
						margin-right: 3px;
						display: inline-block;
					}
				}
			}
		}


	}

	.bm__footer{
		position: absolute;
		bottom: 0;
	}

	.pyv-add-button{
		float:none;
		border-radius: 5px;
		padding: 0 8px;
		max-height: 48px;
		max-width:204px;
		font-size: rem-calc(16);
		display: none;

		@include respond-min($xlarge-breakpoint) {
			display: table;
		}

		&:hover, &:focus{
			background: $theme-color-2;
			color: $theme-color-1-text-light!important;

			.icon{
				color: $theme-color-1-text-light!important;
			}
		}

		.text-cell{
			padding:3px 27px 0 13px;
		}

		.last-icon-cell{
			padding: 0 0 0 0.2rem;
		}

	}

	.icon-table-cell{
		display: table-cell;
		vertical-align: middle;
		position: relative;
		@include respond-min($xlarge-breakpoint){
			display: none;
		}
	}
	.icon-anglebracket-right {
		//margin-top: 1px;
		color: $gray-highlight;
		opacity: 0.5;
		padding-left:0;
		font-weight: normal;
		transition: all 0.3s ease-in-out;
		vertical-align: middle;
		width:22px;
		height: 22px;
		font-size:rem-calc(25);
		position: relative;
		right: 0;
		top: -4px;

	}
}
