	// ======================================================================
	// Utility Classes
	// ======================================================================



.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

.center-on-mobile{
	display: inline-block;
	text-align: center;
	@include respond-min($small-breakpoint){
		text-align: left;
	}
}

ul.no-bullets {
	list-style: none;

	> li {
		text-indent: 0;

		&:before {
			content: none;
		}
	}
}

.hide-below-medium {
	display: none;

	@include respond-min($medium-breakpoint) {
		display: block !important;
	}


	&--inline{
		display: none;

		@include respond-min($medium-breakpoint) {
			display: inline-block !important;
		}
	}
}

.hide-above-medium{
	display: block;

	@include respond-min($medium-breakpoint) {
		display: none !important;
	}
}


.layout-container.hide-bg-image-below-medium {

	@include respond-max($medium-breakpoint) {
		.lc__inner-wrap > .container-background {
			background-image: none !important;
		}
	}
}

// RATIOS
.bn-ratio {
    position: relative;

    &::before {
        display: block;
        width: 0;
        height: 0;
        content: "";
    }
    &--square::before {
        padding-bottom: 100%;
    }
    &--16x9::before {
        padding-bottom: 56.25%;
    }
    &--3x2::before {
        padding-bottom: 66.66667%;
    }
    &--4x3::before {
        padding-bottom: 75%;
    }

    &--omnia-image-hero::before {
        padding-bottom: 38%;
    }

    &__content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
