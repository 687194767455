// ======================================================================
// 2020 BUTTONs
// ======================================================================

.button--blue-border,.button--border {
    display: block;
    margin: 0 auto;
    border-radius: 5px;
    font-size: rem-calc(18);
    line-height: rem-calc(22);
    padding: 12px;
}

.button-2020 {
        padding: 14px 25px;
        background:  $theme-color-1;
        border-radius: 5px;
        font-family: $font-family-1;
        font-weight: 700;
        font-size: 18px;
        color: #FFFFFF;
        letter-spacing: 0;
        text-align: center;
        text-shadow: 0 2px 3px rgba(0,0,0,0.15);
}

// Big Header Button
.bh__link-button a { 
    @extend .button-2020;
    width: 100%;
    display: inline-block;
    padding: 0px 21px;
    @include respond-min($large-breakpoint) {
        padding: 0px 10px;
        width: auto;
    }
    @include respond-min($xlarge-breakpoint) {
        //padding: 17px 21px;
        padding: 0px 21px;
    }
}

.button--transparent {
    background: transparent;
    color: $theme-color-1;
    border: 2px solid $light-gray;
    text-shadow: none;
    // margin-bottom: 30px;
}

.button--full-width {
    width: 100%;
}

.button-tickets{
    //min-width:310px;
    margin-bottom:0;
    min-height: 55px;
    @include respond-min($xlarge-breakpoint){
        min-width:360px;
    }
}


// ======================================================================
// ICON BUTTON
// ======================================================================
.button {
    text-shadow: 0 2px 2px hsla(1deg,100%,0%,19%);
    border-bottom: 0;
    border-radius: 5px;
    color: #FFFFFF !important;

    &:hover,
    &:focus {
        text-decoration: none;
    }

    &.secondary {
        background: white;
        border: 2px solid #c2c6d1;
        text-shadow: none;
        color: $theme-color-1;
    }
}
.cursor-hover {
	cursor: pointer;
}
// For RTE
// ===================================================================
.rte-button {
    @extend .button;
    margin-bottom: 0;
    border-radius:5px;
}


.rte-button-green {
    @extend .button-2020;
    @extend .success;
    margin-bottom: 0;
    background-color: $success-color;

}

.rte-button,
.rte-button-green {
    display: inline-block;
    &.small {
        @extend .tiny;
    }
}

// Button Table
// ===================================================================
.button.button-table {
    display: table;
	transition: .3s background-color ease-out, .3s text-shadow ease-out;
    border-radius: 5px;

    &.expand {
        width: 100%;
    }

    .first-icon-cell,
    .text-cell,
    .last-icon-cell {
        display: table-cell;
        vertical-align: middle;
        text-align: left;
        padding: rem-calc(0 18 0 18);
    }

    .first-icon-cell {
        width: rem-calc(40);
        font-size: rem-calc(40);
        padding: rem-calc(2 8 0 0);
    }

    .text-cell {
        padding: rem-calc(0 15 0 6);
		transition: .3s color ease-out 0s;
    }

    .last-icon-cell {
        padding: rem-calc(0 5 0 22);
        border-left: 2px solid $theme-color-1-white--mix-80;
        width: rem-calc(45);
		transition: .3s border-left-color ease-out 0s;
    }

    .icon {
        margin: rem-calc(-9 0 -12 0);
        display: block;
		transition: .3s color ease-out 0s;
    }

    &:hover,
    &:focus {
        .icon {
            color: white;
        }

		.last-icon-cell {
			border-left-color: $theme-color-2--lighten-20-desaturate-33;
		}
    }

	&.secondary {
		.last-icon-cell {
			border-left: none;
		}
	}

    &.large {
        padding: rem-calc(21 7 21 29);
        height: rem-calc(81);
        border-radius: 5px;
        #sidebar & {
            padding: rem-calc(21 7 21 15);
        }

		.right-off-canvas-menu & {
			width: rem-calc(214);
		}

        .icon-clipboard {
            font-size: rem-calc(39);
            margin: rem-calc(-15 0 -12 0);
        }

        .icon-ticket {
            font-size: rem-calc(48);
            margin: rem-calc(-15 -13 -15 -4);

            @include respond-min($large-breakpoint) {
                margin: rem-calc(-15 -4 -15 -13);
            }

            @include respond-min($xlarge-breakpoint) {
                margin: rem-calc(-15 -13 -15 -4);
            }

			.right-off-canvas-menu & {
				margin-right: rem-calc(-3);
			}
        }

		.text-cell {

			.right-off-canvas-menu & {
				padding: rem-calc(0 11 0 6);
				width: rem-calc(97);
			}
		}

        .icon-anglebracket-right {
            font-size: rem-calc(36);
            margin: rem-calc(-11) auto rem-calc(-12);

			.right-off-canvas-menu & {
				margin: 0;
				padding: 0;
        		margin-right: rem-calc(-10);
			}
        }

        .icon-add {
            font-size: rem-calc(17);
            margin: rem-calc(-9) auto rem-calc(-12);
			width: 1.883em;
			padding-left: .4414em;
			padding-right: .4414em;

            // @include respond-min($large-breakpoint) {
            //     margin-left: rem-calc(15);
            //     margin-right: 0;
            // }
        }

        .last-icon-cell {
            width: auto;
            padding: rem-calc(0 8 0 4);
			// border-left: 2px solid mix($theme-color-1, #ffffff, 80%);

			.right-off-canvas-menu & {
				margin: rem-calc(-15 -5 -15 -4);
				padding-left: rem-calc(14);
			}
        }

        #sidebar &{
            height: 55px;
            &.button--no-icon{
                padding:0 10px;

                span.text-cell{
                    text-align: center;
                    padding: 0;
                }
            }
        }
    }

    &.see-all {
        background-color: transparent;
        color: inherit;
        text-shadow: none;
        padding: 0;
        margin-left: rem-calc(-3);

        .icon {
            vertical-align: middle;
            display: inline-block;
        }

        .first-icon-cell,
        .text-cell,
        .last-icon-cell {
            padding-left: 0;
            padding-right: 0;
            color: inherit;
        }

        .first-icon-cell {
            padding: rem-calc(0 10 0 0);
            font-size: rem-calc(35);

            .icon {
                margin-top: -20px;
                color: inherit;
            }
        }

        .text-cell {
            font-size: rem-calc(17);
            padding-right: rem-calc(12);
        }

        .last-icon-cell {
            border: none;
            font-size: rem-calc(27);

            .icon {
                margin-top: rem-calc(-14);
                color: inherit;
            }
        }

        &.experience- {
            &event {
                color: $color-event !important;
            }

            &exhibit {
                color: $color-exhibit !important;
            }

            &film, &Film {
                color: $color-film !important;
            }

            &tour {
                color: $color-tours !important;
            }
        }

        &.activity- {
            &video {
                color: $color-video;
            }

            &game {
                color: $color-games;
            }

            &hands-on {
                color: $color-hands-on;
            }
        }

        &:hover,
        &:focus {
            background-color: transparent;
            color: $theme-color-2;

            .icon,
            .text-cell {
                color: $theme-color-2;
            }
        }
    }

	&.success {

		.last-icon-cell {
			border-left: 2px solid mix($success-color, #ffffff, 80%);
		}

		&:hover,
        &:focus {
			background: $theme-color-2;

			.last-icon-cell {
              border-left-color: $theme-color-2--lighten-20-desaturate-33;
            }
		}
	}
    &.add-button,
    &.plan-button,
    &.tickets-button {
        margin-bottom: 0;
        padding: rem-calc(33 10 33 29);
        font-size: rem-calc(24);

        @include respond-min($xsmall-breakpoint) {
            font-size: rem-calc(27);
        }

        @include respond-min($large-breakpoint) {
            float: right;
            // width: 31.914893617%;
            //font-size: rem-calc(20);
            font-size: rem-calc(18);
            margin-top: rem-calc(10);
            padding: rem-calc(21 7 21 29);
        }

        @include respond-min($xlarge-breakpoint) {
            //font-size: rem-calc(22);
            font-size: rem-calc(18);
        }

        &:hover,
        &:focus {
            background-color: $theme-color-3;
            color: white !important;
			text-shadow: 0 2px 2px rgba(0,0,0,0.0);

            .last-icon-cell {
                border-color: $theme-color-1-light;
            }

            .icon {
                color: white;
            }

            @include respond-min($large-breakpoint){
                background-color: white;
                color: $theme-color-1 !important;

                .last-icon-cell {
                    border-color: $theme-color-1-light;
                }

                .icon {
                    color: $theme-color-1;
                }
            }
        }
    }

    &.button-hollow{
        background-color: transparent;
        border-radius: 3px;
        border: 2px solid $theme-color-1;
        color: $theme-color-1;
        text-shadow: 0 2px 2px rgba(0,0,0,0.0);
        padding: rem-calc(16 0 14);
        margin-bottom: rem-calc(9);

        & span.text-cell{
            text-align: center;
            width: 100%;
            display: block;
        }

        &:hover, &:visited, &:focus,&:active{
            border-color:$theme-color-1;
            background-color: $theme-color-1;
            color:white;
            outline-color: $theme-color-1;
        }
    }

}


