.featured_teaser{
  margin: 0 auto 10px;
  position: relative;
  width: 100%;
  background: none;
  max-width: 661px;
  height: auto;
  box-shadow: 0 1px 3px 0 rgba(82,98,105,0.16), 0 7px 15px 0 rgba(0,0,0,0.12);
  border-radius:10px;

  @include respond-min($medium-breakpoint){
    //margin: 0 auto 25px;
    margin: 0 0 15px;
  }

  @include respond-min($xlarge-breakpoint){
    max-width: 661px;
    min-width: 661px;
  }

  &:hover{

    .featured_teaser__title
    .icon-anglebracket-right {
      opacity: 1;
      //padding-left:5px;
      right: -5px;
      font-weight: 700;
      transition: all 0.3s ease-in-out;
    }

  }

  > a {
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 100%;
  }

  &__image-wrap {
    // desired width divided by parent width times 100% gives you desired percent width
    //width: 150 / 600 * 100%; comment out by Ben, because if we compare with the mobile mockup, 25% is narrower.
    //width: 185 / 600 * 100%;
    width:35%;
    // table-cell on the image gives the content something to lock up to
    display: table-cell;
    //flex-basis: 30%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    @include respond-min(420px){
      width:28%;
    }

    @include respond-min($large-breakpoint){
      width:35%;
    }

    @include respond-min(950px){
      width:24.5%;
    }


  }

  &__content {
    width: 100%;
    //width: 65%;
    padding: 15px 10px 15px 30px;
    //display: table-cell;
    display: table;
    vertical-align: top;
    background: rgba(white,0.05);
    overflow: hidden;
    position: relative;
    min-height: 81px;

    @include respond-min($small-breakpoint){
      //width:70%;
      padding:20px 10px 20px 15px;
    }


    @include respond-min($medium-breakpoint){
      //width:70%;
      padding:20px 10px 28px 15px;
    }

    @include respond-min(850px){
      //width:70%;
      min-height: 160px;
      padding: 25px 10px 35px 40px;
    }
  }

  &__title {
    color: $theme-color-1;
    line-height: rem-calc(20);
    font-size: rem-calc(16);
    margin-bottom:0;
      display: table;
      width: 100%;
      height: rem-calc(50);

    &-inner-wrap {
        display: table-cell;
        vertical-align: middle;
    }

    @include respond-min($small-breakpoint){
      font-size: rem-calc(26);
      line-height: rem-calc(30);
    }

  }

  &__content-wrapper,.featured_teaser__icon {
    display: table-cell;
    vertical-align: middle;
  }

  &__content-wrapper{
    padding-right: 5px;
    @include respond-min($xlarge-breakpoint){
      padding-right: 45px;
    }
  }

  &__description{
    color: $title-gray;
    display: none;
    @include respond-min($medium-breakpoint){
      display: block ;
    }
  }

  .icon-anglebracket-right {
    //position: absolute;
    //margin-top: 1px;
    color: $gray-highlight;
    opacity: 0.5;
    padding-left:0;
    font-weight: normal;
    transition: all 0.3s ease-in-out;
    display: table-cell;
    vertical-align: middle;
    width:22px;
    height: 22px;
    font-size:rem-calc(25);
    position: relative;
    right: 0;
  }

  &--large{

    .featured_teaser__image-wrap{
      width: 39.5%;
    }

    .featured_teaser__content{
      padding: 45px 25px 53px 40px;

      &-wrapper{
        padding-right:0;
      }

      .featured_teaser__title {
        height: 40px;
      }
    }

    .featured_teaser__icon{
      display: none;
    }

    .featured_teaser__subheader{
      color:$title-gray;
      font-size: 10px;
      letter-spacing: 1.5px;
      text-transform:uppercase;

      @include respond-min($xlarge-breakpoint){
        font-size: 12px;
        letter-spacing: 2px;
      }
    }

    .featured_teaser__description{
      display: none;
      @include respond-min($xlarge-breakpoint){
        display: block ;
      }
    }
  }

}


hr{
  border:none;
  margin: rem-calc(20 0 25);
  height:2px;
  background-color: $light-gray;
}
