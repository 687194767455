/* CTA and Read More =================================================== */

@use "sass:math";

.cta-module {
	background-color: $theme-color-2;
	height: rem-calc(500);
	display: table;
	width: 100%;

	.cta-module-content {
		display: table-cell;
		vertical-align: middle;


		.title {
			font-size: rem-calc(70);
			margin-top: 4px;
			line-height: 1;
			margin: rem-calc(20) 0;
			color: white;
			@include single-text-shadow(0, 2px, 1px, 1px, rgba(0,0,0,0.9));
		}

		.subtitle {
			font-family: $font-family-2;
			font-style: italic;
			font-size: rem-calc(19);
			line-height: 1;
			color: white;
		}

		.more-link {
			display: block;
			float: left;
			border-bottom: 3px solid white;
			padding-bottom: 10px;
			font-size: rem-calc(16);
			line-height: 1.6;
			color: white;

			&:hover,
			&:focus {
				text-decoration: none;
			}
		}
	}
}

.read-more-module {
	height: rem-calc(140);
	display: table;
	width: 100%;
	@extend .clearfix;

	@include respond-min($medium-breakpoint) {
		height: rem-calc(200);
	}

	@include respond-min($xlarge-breakpoint) {
		height: rem-calc(296);
	}

	.arrow-link {
		&:hover,
		&:focus {
			color: $theme-color-1-text-light;
		}
	}
}

.rmm__ {
	&left {
		width: 50%;
		display: table-cell;
		vertical-align: middle;
		padding-left: rem-calc(30);

		@include respond-min($xlarge-breakpoint) {
			padding-left: 0;
		}
	}

	&title {
		font-size: rem-calc(26);
		margin-top: 0;
		margin-bottom: rem-calc(12);
		line-height: 1.3;
		color: white;
		font-family: $font-family-2;
		font-weight: 400;
		line-height: 1;

		@include respond-min($small-breakpoint) {
			font-size: rem-calc(38);
		}
	}



	&more-link-icon {
		display: table-cell;
		vertical-align: middle;
		text-align: right;
		padding-right: rem-calc(30);

		@include respond-min($xlarge-breakpoint) {
			padding-right: 0;
		}

		.icon-circle {
			font-size: 1.2em;
			margin: 0;
			border: 1px solid rgba(133,143,169,0.5);
			background-color: transparent;
			color: $theme-color-1-alt;


			@include respond-min($small-breakpoint) {
				font-size: 1.5em;
			}
			.icon {
				color: $theme-color-1-alt;
				$size: 1.25;
				font-size: $size + em;
				line-height: $size + em;
				width: math.div(2, $size) + em;
				height: math.div(2, $size) + em;
				@media screen\0 {
					line-height: math.div($size, 1.02489019) + em;
				}

				@include respond-min($small-breakpoint) {
					$size: 1.4;
					font-size: $size + em;
					line-height: $size + em;
					width: math.div(2, $size) + em;
					height: math.div(2, $size) + em;
					@media screen\0 {
						line-height: math.div($size, 1.02489019) + em;
					}
				}
			}
		}



		&:hover,
		&:focus {
			.icon-circle {
				// border: 1px solid $theme-color-1-text-light;

				.icon {
					color: $theme-color-1-text-light;
				}
			}

		}
	}
}
