// Table redesign 2020 Ticket Prices

.table-accordion {
  max-width: 760px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  width: 100%;

  &__row {
    flex-basis: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    align-content: flex-start;

    background: $light-gray !important;
    padding: 0 !important;
    border-bottom: 2px solid white;
    border-radius: 5px;
    min-height: 60px;
    @include respond-min($small-breakpoint) {
      min-height: 65px;
      max-height: 65px;
      flex-direction: row;
      align-items: center;
      align-content: space-between;
      justify-content: space-between;
    }

  }

  &__row--header {
    display: none;

    @include respond-min($small-breakpoint) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-content: space-between;
      background: transparent !important;
    }

    &__title {
      font-size: rem-calc(16);
      line-height: rem-calc(16);
      color: $text-gray;
      background: white;
      padding: 15px 17px 15px 20px;
      letter-spacing: 0;
      text-align: center;
      display: inline-block;
      width:50%;
      padding-bottom:0;

      &:first-child {
        text-align: left;
        padding-left: 25px;
      }

    }

    &--first, &--second {
      flex-basis: 55%;
    }

    &--first{
      .table-accordion__row--header__title{
        padding-left: 25px;
      }
    }

    &--second {
      flex-basis: 38%;
    }
  }

  &__title {
    color: $title-gray;
    flex-basis: 100%;
    padding-left: 22px;
    min-height: 60px;
    padding-top: 20px;
    padding-right: 35px;

    @include respond-min($xsmall-breakpoint){
      padding-right: 0;
    }

    @include respond-min($small-breakpoint) {
      flex-basis: 60%;
      padding-left: 25px;
      padding-top: 0;
      min-height: unset;
    }
  }

  .table-accordion__panel {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    align-content: flex-start;
    flex-basis: 35%;

    @include respond-max($small-breakpoint) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-basis: 100%;
      width: 100%;
      //min-height: 60px;
      overflow-y: auto;
      border:1px solid transparent;
      max-height: 0;
      transition: all ease-in-out 0.2s;
      transition-delay: 0.1s,0.1s;

      /* Hide scrollbar for IE, Edge and Firefox */
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &--open {
      @include respond-max($small-breakpoint) {
        border-top: 1px solid $gray-25;
        max-height: 80vh;
        transition: all ease-in-out 0.3s;
        transition-delay: 0.1s,0.1s;

        .icon:before{
          @include icon-anglebracket-up;
        }
      }
    }

    &__title {
      display: none;
      @include respond-max($small-breakpoint) {
        color:$title-gray;
        display: inline-block;
        flex-basis: 50%;
      }
    }
  }



  &__prices {
    flex-basis: 100%;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    align-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    padding: 18px 10px 0px;

    &__title {
      display: inline-block;
      flex-basis: 47%;
    }

    &__amount {
      flex-basis: 35%;
      display: flex;
      flex-direction: row;
      @include respond-min($small-breakpoint) {
        flex-direction: column-reverse;
      }
    }

    &:last-of-type {
      align-items: center;
      @include respond-max($small-breakpoint) {
        padding-bottom: 18px;
      }
    }

    @include respond-min($small-breakpoint) {
      padding: 0;
      flex-direction: column;
    }
  }

  span.old-price {
    text-decoration: line-through;
    color: $text-gray;
  }

  span.new-price {
    color: $title-gray;
    font-weight: bold;
  }

  span.old-price + span.new-price {
    margin-left: 10px;

    @include respond-min($small-breakpoint) {
      margin-left: 0;
    }
  }


  .icon-anglebracket-down {
    position: absolute;
    right: 40px;
    font-size: 1.5625rem;
    margin-right: 0;
    margin-top: 0;
    width: 1.75rem;
    height: 1.75rem;
    color: var(--theme-color-1);
    font-weight: lighter;
    @include respond-min($small-breakpoint) {
      display: none;
    }
  }


}


