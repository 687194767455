// ======================================================================
// Box Module Teasers
// .box-module-teaser
// ======================================================================
@use "sass:math";

.box-module-teaser {
    // margin-bottom: 20px;
    margin: 0 auto 20px;
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 600px;
    background: white;

    @include respond-min($xlarge-breakpoint) {
        // margin-bottom: 20px;
        // position: relative;
        // overflow: hidden;
        box-shadow: 0 3px 4px 0 rgba(0,0,0,0.1);
        width: 300px;
        // background: none;
        margin: 0;
    }

    br,
    .break {
        display: none;

        @include respond-min($xlarge-breakpoint) {
            display: block;
        }
    }

    > a {
        // this is the direct parent of what we want to be table-cells
        // table-cell width is calculated off this width
        display: table;
        table-layout: fixed;
        width: 100%;
    }

    .fade-in {
        display: none;
        // 	// @todo-ron Might be able to create a common class for this to share around
        // 	position: absolute;
        // 	left: 0;
        // 	right: 0;
        // 	bottom: 0;
        // 	height: rem-calc(30);
        // 	background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
        @include respond-min($xlarge-breakpoint) {
            display: block;
        }
    }
}

.btm__ {
    &image-wrap {
		// desired width divided by parent width times 100% gives you desired percent width
        //width: 150 / 600 * 100%; comment out by Ben, because if we compare with the mobile mockup, 25% is narrower.
        width: math.div(185, 600) * 100%;
        // table-cell on the image gives the content something to lock up to
        display: table-cell;

        @include respond-min($xlarge-breakpoint) {
            width: auto;
            display: block;
        }
    }

    &image {
        // width: 150px;
        // min-width: rem-calc(150);
        // float: left;
		width: 100%;
        @include respond-min($xlarge-breakpoint) {
            // z-index: 100;
            // @todo-ron Why is this a percentage?
            // setting margin-bottom as a percentage allows the width to dictate the height of the box-module
            margin-bottom: 66%;
            // width: auto;
            float: none;
            display: block;
        }
    }

    &content {
		// desired width divided by parent width times 100% gives you desired percent width
        width: math.div(450, 600) * 100%;
        // padding of table cell in percent is calculated on table width
        padding: rem-calc(5) 8.3333333333%;
        display: table-cell;
        vertical-align: middle;
        color: $text-color-base;
        // height: 150px;
        @include respond-min($xlarge-breakpoint) {
            // padding: rem-calc(34 40 0);
            padding: rem-calc(34) 13.3333333334% rem-calc(30);
            display: block;
            width: auto;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            min-height: 42.1686746988%;
            max-height: 42.1686746988%;
            height: auto;
            background: white;
            transition: min-height 0.3s ease, max-height 0.6s ease;
            z-index: 200;
        }
    }

    &subtitle {
        font-size: rem-calc(9);
        text-transform: uppercase;
        letter-spacing: rem-calc(3);
        font-weight: 400;
        line-height: rem-calc(13);
        margin-bottom: rem-calc(2);
        color: $theme-color-1 !important;

        @include respond-min($medium-breakpoint) {
            margin-bottom: rem-calc(10);
        }

        @include respond-min($xlarge-breakpoint) {
            min-height: rem-calc(28);
        }
    }

    &title {
        font-size: rem-calc(18);
        line-height: rem-calc(20);
        margin-top: 0;
        margin-bottom: 0;
        color: $theme-color-3 !important;

        @include respond-min($medium-breakpoint) {
            font-size: rem-calc(22);
            line-height: rem-calc(24);
        }

        @include respond-min($xlarge-breakpoint) {
            min-height: rem-calc(52);
            padding-bottom: 10px;
        }
    }

    &description {
        display: none;
        font-size: rem-calc(13);
        line-height: rem-calc(20);
        //moving the padding and margin to the top of the description instead of the bottom of the element above it allows perfect vertical centering when the description is not present
        padding-top: 10px;
        margin-bottom: 0;

        @include respond-min($medium-breakpoint) {
            display: block;
        }

        @include respond-min($xlarge-breakpoint) {
            padding-top: 0;
            margin-bottom: rem-calc(23);
        }
    }

    &link-underline {
        display: none;
        font-size: rem-calc(13);
        font-weight: bold;
        padding-bottom: 8px;
        color: $link-color;
        border-bottom: 2px solid $theme-color-1;
        opacity: 0;
        transition: opacity 0.3s ease;

        @include respond-min($xlarge-breakpoint) {
            display: inline-block;
            //margin-bottom matching the border seems to make the transition smoother
            margin-bottom: 2px;
        }
    }
}


.lc__module-3-up {
	@include respond-min($xlarge-breakpoint) {
	    .box-module-teaser {
	        float: left;
	        margin-right: rem-calc(20);

	        &:last-child {
	            float: right;
	            margin-right: 0;
	        }
	    }
	}
}

.lc__module-4-up {

    .box-module-teaser {
		@include respond-min($xlarge-breakpoint) {
	        float: left;
			width: 23.8095238%;
			margin-right: 1.5873016%;

	        &:last-child {
	            float: right;
	            margin-right: 0;
	        }

			.btm__content {
				padding: rem-calc(15);
			}

			.btm__subtitle{
				margin-bottom: 0;
			}
		}

		@include respond-min($xxlarge-breakpoint) {

			.btm__content {
				padding: rem-calc(34) 13.3333333334% rem-calc(30);
			}

			.btm__subtitle{
				margin-bottom: rem-calc(10);
			}
		}
    }
}

@include respond-min($xlarge-breakpoint) {
    .box-module-teaser {
        // margin-bottom: 20px;
        // position: relative;
        // overflow: hidden;
        // box-shadow: 0px 3px 4px 0px rgba(0,0,0,0.1);
        // width: rem-calc(300);
        // display: block;
        // background: none;
        // padding-right: 0;
        &:hover,
        &:focus {
            color: $text-color-base;
            // @todo-ron I don't think this is needed here. Probably for other area
            .btm__ {
                &corner {
                    display: block;
                    opacity: 1;
                }

                &content {
                    min-height: 52.2088353414%;
                    max-height: 100%;
                    transition: min-height 0.6s ease-out, max-height 1.2s ease-out;
                }

                &link-underline {
                    // @todo-ron see note above about name
                    opacity: 1;

                    &:hover,
                    &:focus {
                        color: $link-color-hover;
                        border-color: $link-color-hover;
                    }
                }
            }
        }
    }

    .btm__ {
        &image {
            // z-index: 100;
            // // @todo-ron Why is this a percentage?
            // margin-bottom: 66%;
            // width: auto;
            // float: none;
            // margin-right: 0;
            // display: block;
        }

        &content {
            // padding: 0 13.3333333334%;
            // display: block;
            // position: absolute;
            // left: 0;
            // right: 0;
            // bottom: 0;
            // min-height: 42.1686746988%;
            // max-height: 42.1686746988%;
            // height: auto;
            // background: white;
            // transition: min-height .3s ease, max-height .6s ease;
            // z-index: 200;
        }
        // &fade-in {
        // 	// @todo-ron Might be able to create a common class for this to share around
        // 	position: absolute;
        // 	left: 0;
        // 	right: 0;
        // 	bottom: 0;
        // 	height: rem-calc(30);
        // 	background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
        // }
        &title {
            // font-size: rem-calc(24);
            // min-height: rem-calc(52);
            // margin-top: rem-calc(10);
        }

        &subtitle {
            // font-size: rem-calc(9);
            // margin-top: rem-calc(34);
            // min-height: rem-calc(28);
        }

        &description {
            // display: block;
            // font-size: rem-calc(13);
            // line-height: rem-calc(20);
            // margin-bottom: rem-calc(23);
        }

        &link-underline {
            // @todo-ron if not shared should just be btm_link Might make a common transition?
            // font-size: rem-calc(13);
            // font-weight: bold;
            // padding-bottom: 0;
            // margin-bottom: rem-calc(30);
            // opacity: 0;
            // transition: opacity .3s ease;
            // display: block;
        }
    }
}
