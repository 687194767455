// ======================================================================
// jQuery Cycle2 Carousels
// ======================================================================

.skip-carousel-link, .skip-link {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  // &:focus {
  //     position: relative;
  //     left: 0;
  // }
}

a.keyboard-open-menu {
  display: block;
  float: right;
  margin-top: -25px;
  margin-right: -15px;

  span {
    transition: opacity .3s ease-out;
    opacity: 0;
    // visibility: hidden;
  }

  [data-whatinput=keyboard] &:focus span {
    opacity: 1;
    // visibility: visible;
  }

}

a.keyboard-close-menu {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px;

  span {
    transition: opacity .3s ease-out;
    opacity: 0;
    font-size: 2em;
    // visibility: hidden;
  }

  [data-whatinput=keyboard] &:focus span {
    // visibility: visible;
    opacity: 1;
  }
}

.carousel-container {
  width: 100%;
  overflow: hidden;
  position: relative;

  @include respond-min($large-breakpoint) {
    &.hide-pager .cycle-pager {
      display: none;
    }
  }

  .arrows {
    display: none;
    position: absolute;
    z-index: 1000;

    // only show arrows when screenshot is bigger than iPad vertical
    @include respond-min($large-breakpoint) {
      display: block;
      padding-right: $column-gutter;
      top: 50%;
      transform: translateY(-50%);
      left: 10px;
      //left: 0;
      margin-left: 1.5%;
      .layout-container &, #feature & {
        margin-left: 0;
      }
    }

    .arrow {
      height: 50px;
      width: 50px;
      cursor: pointer;
      display: block;

      &.prev, &.next {
        margin-top: em-calc(10);
      }

      .invisible-button {
        width: 50px;
        height: 50px;
        position: absolute;
      }

      .st2 {
        stroke: $theme-color-1;

        .lc__theme-color-1 & {
          stroke: $theme-color-4;
        }
      }

      object, svg {
        width: 50px;
        height: 50px;
      }

      a:focus svg .st0 {
        fill: $theme-color-1;

        .lc__theme-color-1 & {
          fill: $theme-color-4;
        }
      }
    }

  }

  .cycle-slideshow, .carousel {
    .cycle-slide {
      width: 100%;
      visibility: hidden;
      // display: none;
      &.first {
        visibility: visible;
        // display: block;
      }

      @include respond-min($medium-breakpoint) {
        & > .feature-quote {
          margin: 0 50px;
          padding-bottom: 20px;
        }
      }
    }
  }

  .cycle-pager {
    position: relative;
    top: rem-calc(-65);
    // margin-bottom: -4em;
    margin-bottom: -3.5em;
    z-index: 1000;
    left: 1.7em;

    // & > * {
    // 	cursor: pointer;
    // }

    a {
      font-size: rem-calc(40);
      // cursor: pointer;
      color: $white;
      opacity: .5;
      margin: 0 rem-calc(6);
      line-height: .5;

      &.cycle-pager-active {
        opacity: 1;
      }

      &:hover {
        opacity: .5 !important;
      }

      &:focus {
        color: $theme-color-1 !important;

        .lc__theme-color-1 & {
          color: $theme-color-4 !important;
        }
      }
    }

    &--left {
      display: none;
      top: 2px;
      margin-bottom: 0;
      left: 25%;

      // span {
      //     font-size: rem-calc(60);
      // }
    }

    @include respond-min($large-breakpoint) {
      &--right {
        position: absolute;
        //@todo_ben the width was 14px, yet it makes the dots cut off a bit on the right, adding 1px fix the problemm. I put a comment here in case my change mess up other things
        width: 15px;
        line-height: 22px;
        top: 50%;
        margin: {
          top: -40px;
          bottom: 0;
          left: 0;
          right: 1.5%;
        }
        left: auto;
        right: 5px;
        //right: 0;
        // .layout-container &, #feature & {
        // 	margin-right: 0;
        // }
      }
      a {
        display: block;
      }
    }
  }

  &__main {

    &--with-arrows {

      @include respond-max($xlarge-breakpoint){
        padding:50px 0;
      }

      .center-arrows {
        display: block;
        width: 100%;
        margin: 0 auto;
        text-align: center;
      }

      .center-arrow {
        background: $theme-color-1-alt;
        width: rem-calc(44);
        height: rem-calc(44);
        border-radius: 50%;
        display: inline-block;
        text-align: center;

        &--right {
          margin-left: 10px;
        }

        .icon {
          color: $theme-color-1-text-light;
          fill: $theme-color-1-text-light;
          position: relative;
          top: calc(50% - 10px);
          left: calc(50% - 20px);
        }
      }

      .arrow, .cycle-pager a {
        display: none !important;
      }
    }

    &--bg-dark{
      background: $theme-color-3;
      border-radius: 11px;
      padding: 40px 30px;

      @include respond-min($medium-breakpoint){
        padding: 50px 50px;
      }

      @include respond-min($xlarge-breakpoint){
        padding: 125px 125px 125px;
      }
    }

  }

  &--with-arrows {

    .arrows-wrap {
      width: 100%;
      position: absolute;
      bottom: 0;
      display: block;
      margin: auto;
      text-align: right;

      > div {
        position: relative;
        bottom: 10px;
        max-width: 1220px;
        text-align: right;
        margin: auto;
      }
    }

    .arrows {
      right: 8%;
      bottom: 22%;
      top: unset;
      left: unset;
      width: auto;

      @include respond-min($small-breakpoint) {
        display: block;
        padding: 0;
        width: 120px;
        // right: 0;
        // bottom: 0;
        top: unset;
        left: unset;
      }

      @include respond-min($large-breakpoint){
        left: unset;
        top: unset;
        display: block;
        padding: 0;
        width: 120px;
        bottom: 30px;
        right:0;
        //right: rem-calc(65);
      }

      @include respond-min($xlarge-breakpoint) {
        //bottom: 10px;
        //bottom: 0;
        bottom: 30px;
        //right: rem-calc(177);
      }

      @include respond-min($xlarge-2-breakpoint) {
        margin-right: 0;
      }

      .arrow{
        display:inline-block;
        background:rgba(white,0.15);
        border-radius: 50%;
        width:rem-calc(45);
        height:rem-calc(45);
        position: absolute;
        //bottom: 60px;
        bottom: 25px;
        right: 5px;

        // @include respond-min($small-breakpoint) {
        //   bottom: 0px;
        //   right: 50px;
        // }
        @include respond-min($large-breakpoint) {
          right: 40px;
          bottom: 30px;
        }

        @include respond-min(850px){
          right: 135px;
        }

        svg {
          width: rem-calc(45);
          height: rem-calc(45);
        }

        .invisible-button {
          width: rem-calc(45);
          height: rem-calc(45);
        }
      }
    }

    .center-arrows {
      display: none;

      @include respond-min(850px) {
        display: block;
        position: absolute;
        //right: 0;
        bottom: 30px;
        display: inline-block;
        display: block;
        right: unset;
        margin-left: -6px;
      }

    }

    .center-arrow {
      background: rgba(white, 0.15);
      width: rem-calc(44);
      height: rem-calc(44);
      border-radius: 50%;
      display: inline-block;
      text-align: center;

      &--right {
        margin-left: 10px;
      }

      .icon {
        color: $theme-color-1-text-light;
        fill: $theme-color-1-text-light;
        position: relative;
        top: calc(50% - 10px);
        left: calc(50% - 20px);
      }
    }

    .cycle-pager a {
      display: none !important;
    }

  }

}

$local-content-width: rem-calc(530);
$local-content-height: rem-calc(340);
$preview-content-width: rem-calc(668);

.structured-carousel {
  position: static;

  &__overflow{
  width:100%;
    @include respond-min($xlarge-breakpoint) {
      overflow: hidden;
    }
  }


  .background-image {
    // visibility: hidden;
    display: none;

    &.first {
      // visibility: visible;
      display: block;
    }
  }

  .cycle-slideshow-backgroundimage {
    float: right;
    z-index: 1000;

    .background-image {
      width: 100%;
    }

    @include respond-min($large-breakpoint) {
      min-height: 500px;
    }
  }

  .cycle-slideshow {
    .cycle-slide {
      // visibility: hidden;
      display: none;

      &.first {
        // visibility: visible;
        display: block;
      }
    }
  }

  .cycle-pager {
    display: none;
    @include respond-min($large-breakpoint) {
      display: block;
    }
    // position: absolute;
    // top: inherit;
    // bottom: 4em;
  }

  .cycle-slideshow-small {
    overflow: hidden;
    position: absolute;
    display: block;
    width: 75%;
    top: 5%;

    .carousel-overlay {
      z-index: 500;
      background-color: $theme-color-1;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      opacity: .7;
    }

    @include respond-min($large-breakpoint) {
      width: 668px;
      bottom: 50%;
      margin-bottom: -209px;
      top: auto;
    }
  }

  .cycle-slideshow-small-left {
    left: -70%;
    @include respond-min($large-breakpoint) {
      left: -$preview-content-width;
    }
  }

  .cycle-slideshow-small-right {
    right: -70%;
    @include respond-min($large-breakpoint) {
      right: -$preview-content-width;
    }
  }

  &__gradient {
    @include respond-min($large-breakpoint){

      &--light {
        background: linear-gradient(to right, rgba($title-gray, 0.4) 0%, rgba($title-gray, 0) 100%);
      }

      &--medium {
        background: linear-gradient(to right, rgba($title-gray, 0.8) 0%, rgba($title-gray, 0) 100%);
      }

      &--dark {
        background: linear-gradient(to right, rgba($title-gray, 1) 0%, rgba($title-gray, 0) 100%);
      }
    }
  }

  &-outer-wrap {
    .carousel-background-block{

      .slide{
        @include respond-min($medium-breakpoint){
          padding-left:0;
        }

        @include respond-min($large-breakpoint) {
          padding-left:50px;
        }
      }

      .content-area {

        background: $theme-color-3;
        width: calc(100vw - 30px);

        @include respond-min($xsmall-breakpoint) {
          width: calc(100vw - 60px);
        }

        @include respond-min($medium-breakpoint) {
          width: calc(100vw - 130px);
        }

        @include respond-min($large-breakpoint) {
          background: transparent;
          width:100%;
        }

        .content-area-inner-wrapper {
          @include respond-min($large-breakpoint) {
            max-width: 345px;
          }

          @include respond-min($xlarge-breakpoint) {
            bottom:rem-calc(130);
          }


          @include respond-min($xlarge-2-breakpoint) {
            bottom:rem-calc(65);
          }
        }

        .title {
          line-height: rem-calc(35);
          margin-bottom: 7px;
          margin-top: 17px;

          @include respond-max($small-breakpoint){
            line-height: 30px;
            margin-bottom: 10px;
            margin-top: 10px;
          }
        }

        .subtitle{
          letter-spacing: 2.4px;
        }

        .date{
          letter-spacing: -0.5px;
          color:white;
          @include respond-max($small-breakpoint) {
            line-height: 30px;
            margin-bottom: 5px;
            margin-top: 10px;
          }
        }

        .text p {
          margin-bottom: 15px;
          line-height: 27px;

          @include respond-max($small-breakpoint){
            margin-bottom:28px;
            line-height:25px;
          }
        }


      }
    }

    .carousel-background-inner {
      @include respond-min($large-breakpoint) {
        width: 100% !important;
      }
    }
  }

  &__link{
    display: inline-block!important;
    color: var(--theme-color-1);
    font-weight: bold;
    padding-bottom: 2px;
    border-bottom: 2px solid var(--theme-color-1);
  }

}


.carousel-background {
  background-color: $theme-color-3;

  .carousel-background-inner {
    margin: 0 auto;
    position: relative;
  }

  @include respond-min($large-breakpoint) {

    background-color: transparent;

    .carousel-background-inner {
      width: 84%;
      margin: auto;
    }

  }
}

.structured-carousel-outer-wrap {

  .arrow {
    display: inline-block;
    border-radius: 100%;
    background: rgba(white, .15);

    text-align: center;
    line-height: 3.25;
    height: 44px;
    width: 44px;
    position: absolute;

    z-index: 1000;
    bottom: 15px;

    @include respond-min($large-breakpoint) {
      bottom: 95px;
    }


    @include respond-min(900px) {
      bottom: 44px;
    }

    @include respond-min(1200px) {
      bottom: 50%;
      margin-bottom: -22px;
    }
  }

  .arrow-prev {
    right: 58px;

    @include respond-min($large-breakpoint) {

      right: 104px;

    }
    @include respond-min(1200px) {
      left: -98px;
    }
  }

  .arrow-next {
    right: 5px;

    @include respond-min($large-breakpoint) {
      right: 44px;
    }
    @include respond-min(1200px) {
      right: -98px;
    }
  }

}

.carousel-background-block-wrapper {
  @include respond-min($large-breakpoint) {

    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 1000;
  }
}

.carousel-background-block {
  @include respond-min($large-breakpoint) {

    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 1000;
  }
  overflow: hidden;

  .cycle-slideshow, .cycle-slide, .cycle-slide section, .content-area {
    @include respond-min($large-breakpoint) {

      height: 100%;
      width: 100%;
    }
  }

  div.slide.cycle-slide {
    margin-left: 0 !important;
  }

  .content-area {
    text-shadow: 0 2px 4px black;
    margin-top: 10px;

    .content-area-inner-wrapper {
      padding-bottom: 30px;
      @include respond-min($large-breakpoint) {
        padding-bottom: 0;
        position: absolute;
        bottom: 0;
      }
    }

    @include respond-min($large-breakpoint) {

      margin-top: 0px;
    }
    //padding: rem-calc(25) rem-calc(15);

    // Code for animation sliding
    .line-wrap {
      display: block;
      overflow: hidden;
    }

    .line {
      display: block;
    }

    .title {
      font-size: 1.6rem;
      line-height: 1.2;
      margin-top: 1rem;

      margin-bottom: rem-calc(10);
      color: white;

      &.smaller-title {
        font-size: rem-calc(30);
      }

      @include respond-min($large-breakpoint) {
        margin-top: rem-calc(25);

        font-size: 2.2rem;
      }
    }

    .date {
      font-family: $font-family-2;
      font-style: italic;
      font-size: 1rem;
      margin-bottom: 0.5rem;
      @include respond-min($large-breakpoint) {
        font-size: 1.2rem;
      }
    }

    .subtitle {
      text-transform: uppercase;
      font-weight: 700;
      letter-spacing: rem-calc(3);
      line-height: 1;
      color: white;
      opacity: .5;
      margin-top: 0;
      margin-bottom: 0;
      font-size: rem-calc(11);
    }

    .text {
      p {
        color: white;
        opacity: .5;
        font-size: 1rem;
        margin-bottom: rem-calc(20);
      }
    }

    a {
      color: $theme-color-1;
      border-bottom: 3px solid $theme-color-1;
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.6;
      text-decoration: none;
      display: inline-block;
      margin-top: 0;


      &:hover,
      &:focus {
        border-bottom-color: white;
        color: white;
      }
    }
  }

  @include respond-min($large-breakpoint) {
    z-index: 1000;
  }

  .slide{
    @include respond-min($medium-breakpoint) {
      padding: rem-calc(50) rem-calc(65);
      .content-area-inner-wrapper{
        bottom:rem-calc(50);
      }
    }

    @include respond-min($large-breakpoint) {
      padding: rem-calc(50);
      .content-area-inner-wrapper{
        bottom:rem-calc(130);
      }
    }

    @include respond-min($xlarge-breakpoint) {
      padding: rem-calc(50);
      .content-area-inner-wrapper{
        bottom:rem-calc(50);
      }
    }
  }
}


//the following codes are added by ben, it's for when carousel is inside of the feature area as the huge rotating image. meanwhile, I added some JS code to skin.js in order to make the width of the feature-ce be to 100%.
// Feature carousel container
#feature {

  .carousel-container {
    .cycle-pager {

      //default
      &--right {
        display: none;
      }

      &--left {
        display: inline-block;
        top: unset;
        margin-bottom: 0;
        left: 0;
        bottom: 0;
        @include respond-min($large-breakpoint) {
          top: 15px;
          margin-bottom: 0;
          left: 25%;
        }
      }

    }

    .arrows {
      display: block;
      margin-left: 10px !important;
      //bottom: 0px;
      bottom: 110px;
      left: 70%;
      @include respond-min($large-breakpoint) {
        margin-left: 0px !important;
        top: 63%;
        bottom: 0;
        left: em-calc(2);
      }

      @include respond-min($xlarge-breakpoint) {
        left: em-calc(15);
      }

    }


    .cycle-pager a {
      display: inline-block;
      @include respond-min($large-breakpoint) {
        display: block;
      }
    }

		// .bh__content {
		// 	@include respond-min($large-breakpoint) {
		// 		padding: 0 rem-calc(70);
		// 	}
		// 	@include respond-min($xlarge-2-breakpoint) {
		// 		padding: 0 rem-calc(10);
		// 	}
		// }

    // Navigation option Right pager
    &--right-pager {

      .cycle-pager--left {
        display: none;
      }

      .cycle-pager--right {
        left: 75%;
        display: inline-block;
        top: unset;
        margin-bottom: 0;
        right: -6%;
        bottom: 97px;

        @include respond-min($large-breakpoint) {
          display: inline-block;
          top: 50%;
          margin-bottom: 0;
          right: 0;
          left: unset;
        }
      }

      .arrows {
        bottom: 97px;
        @include respond-min($large-breakpoint) {
          bottom: 0;
        }
      }
    }

    &--with-arrows {

      .arrows-wrap {
        display: none !important;
        width: 100%;
        position: absolute;
        bottom: 0;
        display: block;
        margin: auto;
        text-align: right;
        @include respond-min($large-breakpoint) {
            display: block !important;
        }

        > div {
          position: relative;
          bottom: 0;
          max-width: 1220px;
          text-align: right;
          margin: auto;
        }
      }

      .arrows {
        right: 8%;
        bottom: 22%;
        top: unset;
        left: unset;
        width: auto;

        @include respond-min($small-breakpoint) {
          display: block;
          padding: 0;
          width: 120px;
          // right: 0;
          // bottom: 0;
          top: unset;
          left: unset;
        }

				@include respond-min($large-breakpoint){
					left: unset;
					top: unset;
					display: block;
					padding: 0;
					width: 120px;
					bottom:0;
					right:0;
					//right: rem-calc(65);
				}

        @include respond-min($xlarge-breakpoint) {
          //bottom: 10px;
          bottom: 0;
          //right: rem-calc(177);
        }

        @include respond-min($xlarge-2-breakpoint) {
          margin-right: 0;
        }

				.arrow{
					display:inline-block;
					background:rgba(white,0.15);
					border-radius: 50%;
					width:rem-calc(45);
					height:rem-calc(45);
					position: absolute;
					//bottom: 60px;
					bottom: 25px;
					right: 5px;

                  // @include respond-min($small-breakpoint) {
                  //   bottom: 0px;
                  //   right: 50px;
                  // }
          @include respond-min($large-breakpoint) {
            right: 40px;
            bottom: 30px;
          }

                  @include respond-min(850px){
                    right: 135px;
                  }

          svg {
            width: rem-calc(45);
            height: rem-calc(45);
          }

          .invisible-button {
            width: rem-calc(45);
            height: rem-calc(45);
          }
        }
      }

      .center-arrows {
        display: none;

        @include respond-min(850px) {
          display: block;
          position: absolute;
          //right: 0;
          bottom: 30px;
          display: inline-block;
          display: block;
          right: unset;
          margin-left: -6px;
        }

      }

      .center-arrow {
        background: rgba(white, 0.15);
        width: rem-calc(44);
        height: rem-calc(44);
        border-radius: 50%;
        display: inline-block;
        text-align: center;

        &--right {
          margin-left: 10px;
        }

        .icon {
          color: $theme-color-1-text-light;
          fill: $theme-color-1-text-light;
          position: relative;
          top: calc(50% - 10px);
          left: calc(50% - 20px);
        }
      }

      .cycle-pager a {
        display: none !important;
      }

    }

  }
}

//the following codes are added by ben, it's for when the carousel's arrows on the bottom left of the image and half of it overlay on the image.

.carousel-container.carousel-navigation-bottom-left {
  .arrows {
    top: auto;
    bottom: 60px;
  }
}

// when carousel is in bleed container (mainly for the quotes carousel)
.layout-container {
  .carousel-container {
    .feature-quote {

      @include respond-min($medium-breakpoint) {
       // padding: rem-calc(30 20 70);
      }

      @include respond-min($large-breakpoint) {
        //padding: rem-calc(30 30 60);
      }

      @include respond-min($xlarge-breakpoint) {
        //padding: rem-calc(40 40 80);
      }

      @include respond-min($xxlarge-breakpoint) {
        //padding: rem-calc(82 50 120);
      }
    }
  }

  .carousel-container__home{
    .feature-quote {
      padding: rem-calc(0 0 150);

      @include respond-min($medium-breakpoint) {
        padding: rem-calc(0 0 120);
      }

      @include respond-min($large-breakpoint) {
        padding: rem-calc(0 0 130);
      }

      @include respond-min($xlarge-breakpoint) {
        padding: rem-calc(0 0 80);
      }

      @include respond-min($xxlarge-breakpoint) {
        padding: rem-calc(0 0 50);
      }
    }

  }
  .cycle-pager {
    text-align: center;
    left: auto;
  }
}


// the following is for testing
.column {
  .carousel-container {
    .arrows {
      position: absolute;
      z-index: 1000;
      bottom: 60px;
      left: 0;
      margin-left: -14px;
      padding-right: 0;
      @include respond-min($medium-breakpoint) {
        display: block;
      }
    }
  }
}
