	// ======================================================================
	// MAIN STYLE SHEET
	// This is for variables for the site. Some placed here will override variables in Foundation
	// See foundation_settings_reference.txt for list of all Foundation variables and the default settings.
	// ======================================================================

	// ======================================================================
	// Modules and Variables
	// ======================================================================

@import '~ceaser-easing/ceaser-easings';

// @todo-ron See if fonts or base should come first.

@import 'generated/icons';
@import 'partials/base/base';
@import 'partials/base/columns';
@import 'partials/base/utility_classes';
@import 'partials/base/responsive_block_grid';
@import 'partials/misc/development';

	// ======================================================================
	// Partials
	// ======================================================================

@import 'partials/elements/elements';
@import 'partials/elements/special_headline_group';
@import 'partials/elements/tables';
@import 'partials/elements/typography';
@import 'partials/elements/video';
@import 'partials/content_elements/accordion';
@import 'partials/content_elements/activity_feature';
@import 'partials/content_elements/alert';
@import 'partials/content_elements/box_module_teaser';
@import 'partials/content_elements/carousels';
@import 'partials/content_elements/text_media';
@import 'partials/content_elements/containers';
@import 'partials/content_elements/cta_readmore';
@import 'partials/content_elements/ce_big_header';
@import 'partials/content_elements/full_image_box_teaser';
@import 'partials/content_elements/image_grid';
@import 'partials/content_elements/photo_teasers';
@import 'partials/content_elements/quote';
@import 'partials/content_elements/tabs';
@import 'partials/content_elements/table';
@import 'partials/content_elements/tableaccordion';
@import 'partials/content_elements/teaser_module';
@import 'partials/content_elements/box_module_ce';
@import 'partials/content_elements/ce_call_out';
@import 'partials/content_elements/box_teaser';
@import 'partials/content_elements/welcome_text';
@import 'partials/content_elements/benefits_ce';
@import 'partials/content_elements/glide';
@import 'partials/content_elements/membership';
@import 'partials/content_elements/ticket_experience_teaser';
@import 'partials/content_elements/featured_teaser';
@import 'partials/content_elements/button_teaser';
@import 'partials/design_elements/buttons';
@import 'partials/design_elements/icons';
@import 'partials/design_elements/icon_extended';
@import 'partials/design_elements/local_menu';
@import 'partials/design_elements/prev_next_pager';
@import 'partials/elements/wufoo';
@import 'partials/extensions/box_teasers';
@import 'partials/extensions/my_visit';
@import 'partials/extensions/plan_your_visit_tool';
//@todo-upgrade Remove ticketing styling that is not longer used.
@import 'partials/extensions/ticketing';
//@import 'partials/extensions/ticketing_interim';
@import 'partials/extensions/whats_here_filter';
@import 'partials/extensions/exhibit_home_sidebar';
@import 'partials/extensions/fancybox';
@import 'partials/extensions/membership_level';
@import 'partials/extensions/pricing_tables';
@import 'partials/extensions/search';
@import 'partials/extensions/experience';
@import 'partials/extensions/stories';
@import 'partials/misc/animation';
@import 'partials/misc/webtolead_form';
@import 'partials/pages/footer';
@import 'partials/pages/header';
@import 'partials/pages/pages';
@import 'partials/pages/sticky-footer';

@import 'vendor/fancybox';
@import 'vendor/ladda';
@import 'vendor/pikaday';
@import 'vendor/plyr';
@import 'vendor/spin';

@import 'dev/map';
@import 'dev/faq';

    @import 'partials/misc/custom_situations';

// Third-party


.background-slider{
  position: absolute;
  height: 100%;
  width: 100%;
  z-index:10;
  & > .bh__background{
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    transition: opacity 2s ease;
    video{
      transition: opacity 2s ease;
      pointer-events: none;
    }
    // [MSI-158] Fix for repeating background in home carousel.
    .bh__background-image {
      background-repeat: no-repeat;
      background-position-x: 50%;
    }
    &.active{
      opacity: 1;
      video{
        visibility: visible !important;
        opacity: 1  !important;
      }
    }
    & > div {
      width: 100%;
      height: 100%;
    }
  }
}



.cycle-slide .bh__content-inner-wrap{
  opacity: 0;
  transition: opacity 0.5s;
  transition-timing-function: cubic-bezier(0.770, 0.000, 0.175, 1.000); /* easeInOutQuart */
  transition-delay: 0.5s;
}
.cycle-slide.cycle-slide-active .bh__content-inner-wrap{
  opacity: 1;
}

.video-init{
  background-image: none !important;
}
