// ======================================================================
// Responsive Block Grid
// ======================================================================

@import '~fancybox/dist/scss/jquery.fancybox';

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
	background-image: url('/_assets/16c122cebb6dd10fd754319ee821f8f8/Images/fancybox/fancybox_sprite.png');
}

#fancybox-loading div {
	background: url('/_assets/16c122cebb6dd10fd754319ee821f8f8/Images/fancybox/fancybox_loading.gif') center center no-repeat;
}

.fancybox-nav {
	background: transparent url('/_assets/16c122cebb6dd10fd754319ee821f8f8/Images/fancybox/blank.gif'); /* helps IE */
}

.fancybox-overlay {
	background: url('/_assets/16c122cebb6dd10fd754319ee821f8f8/Images/fancybox/fancybox_overlay.png');
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
	   only screen and (min--moz-device-pixel-ratio: 1.5),
	   only screen and (min-device-pixel-ratio: 1.5){

	#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
		background-image: url('/_assets/16c122cebb6dd10fd754319ee821f8f8/Images/fancybox/fancybox_sprite@2x.png');
	}

	#fancybox-loading div {
		background-image: url('/_assets/16c122cebb6dd10fd754319ee821f8f8/Images/fancybox/fancybox_loading@2x.gif');
	}
}
