/* Footer =================================================== */


@use "sass:math";

#footer {
	padding: rem-calc(30 20 0 20);
	background-color: $theme-color-3;
	color: $theme-color-3-text-light;
	// @todo-ron see if this should be globally or not
	-webkit-font-smoothing: subpixel-antialiased;

	//IE11
	@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
		background-color: #030D26;

	}

	@include respond-min($small-breakpoint) {
		padding-top: 90px;
	}


	@include respond-min($large-breakpoint) {
		padding-bottom: 90px;
	}

	@include respond-min($xlarge-breakpoint){
		padding-bottom: 20px;
	}

	a {
        color: $theme-color-1-alt;
		&:hover,
		&:focus {
			text-decoration: none;
		}
	}

    ul.arrow-list > li::before {
        color: $theme-color-1-alt;
    }


	.newsletter-form {
		padding: 8px;
		background-color: $theme-color-2;
		@extend %clearfix;
		border-radius: 5px;

		.email {
			width: 70%;
			padding-left: rem-calc(10);
			padding-right: rem-calc(10);
			float: left;
			background: none;
			border: none;
			height: 32px;
			line-height: 32px;
			white-space: nowrap;
			margin: 0;
			font-family: $font-family-1;
			font-size: rem-calc(14);
			color: rgba(white,0.4);
			font-weight: 200;

			@include input-placeholder {
				color: rgba(white,0.4);
	        }

	        @include respond-min($small-breakpoint) {
				line-height: 40px;
				height: 40px;
			}

			@include respond-max($xsmall-breakpoint) {
				float:none;
				width: 100%;
				display: block;
			}
		}

		.button {
			float: left;
			width: 30%;
			height: 38px;
			margin: 0;
            background-color: $theme-color-1-alt;
			font-size: rem-calc(16);
			padding-left: 5%;
			padding-right: 5%;
			padding-top: 8px;
			font-weight: bold;
			border-radius: 5px;

			@include respond-min($small-breakpoint) {
				height: 40px;
				padding-top: rem-calc(10);
			}

			&:hover,
			&:focus {
				background-color: white;
				color: $theme-color-1-alt;
				@include single-text-shadow(none);
			}


			@include respond-max($xsmall-breakpoint) {
				float:none;
				width: 100%;
				display: block;
			}
		}

		@include respond-min($medium-breakpoint) {
			float: left;

			.email {
				width: 190px;
				padding-left: rem-calc(5);
				padding-right: rem-calc(5);
				font-size: rem-calc(14);
			}

			.button {
				width: 80px;
				font-size: rem-calc(13);
			}
		}


		@include respond-min($large-breakpoint) {
			float: left;

			.email {
				width: 240px;
				padding-left: rem-calc(10);
				padding-right: rem-calc(10);
				font-size: rem-calc(14);
			}

			.button {
				width: 120px;
				font-size: rem-calc(16);
			}
		}


	}

	.alert-box {
		width: 40%;
		color: $theme-color-1-text-light;
		border-style: none;
		background-color: #d2fbf0;
		color: #07a778;
		display: block;
		overflow: hidden;
		padding: 0;
		margin: 0;
		max-height: 0;
		transition: max-height .35s ease-in-out, padding .35s ease-in-out, margin .35s ease-in-out, opacity .35s ease-in-out;

		&.success {
			padding: rem-calc(14 24 14 14);
			margin-bottom: rem-calc(20);
			max-height: 1000px;
		}

		&.failure {
			background-color: #ffdacc;
			color: #ff4800;
			padding: rem-calc(14 24 14 14);
			margin-bottom: rem-calc(20);
			max-height: 1000px;
		}

		[data-newsletter-notification-message] {
			&.hide {
				opacity: 0;
			}

			&.show {
				opacity: 1;
			}

			transition: opacity	.35s ease-in-out;
		}

		[data-newsletter-education-optin] {
			&.hide {
				[data-update-on-click], [data-optin-text] {
					opacity: 0;
				}
			}

			&.show {
				[data-update-on-click], [data-optin-text] {
					opacity: 1;
				}
			}

			[data-update-on-click], [data-optin-text] {
				transition: opacity	.35s ease-in-out;
			}
		}

	}

	.newsletter-social-wrap {
		.divider {
			display: none;
			@include respond-min($large-breakpoint) {
				border-bottom: 2px solid #353d51;
				margin: 110px 0 67px;
				clear: both;
				display: block;
			}
		}

	}

	.social-wrap {
		@include reset-ul;
		border-top: 2px solid #353d51;
		margin: rem-calc(35) 0 ;
		padding: rem-calc(40) 0 0;
		text-align: center;
		@extend %clearfix;

		@include respond-min($medium-breakpoint) {
			float: right;
			border-top: none;
			margin: rem-calc(5) 0 rem-calc(35) 0;
			padding: 0;
		}


		@include respond-min($large-breakpoint) {
			margin-bottom: 0;
		}

		li {
			margin: 0 rem-calc(16) 0 0;
			display: inline-block;

			@include respond-min(375px) {
				margin-right: rem-calc(34);
			}

			@include respond-min($medium-breakpoint) {
				margin: 0 0 0 rem-calc(15);
			}

			&:last-child {
				margin: 0;

				@include respond-min($medium-breakpoint) {
					margin: 0 0 0 rem-calc(15);
				}
			}
		}

		.icon-circle {
			position: relative;
			overflow: hidden;
			background-color: $theme-color-2;
			font-size: rem-calc(53 * 0.5);
			border-color: $theme-color-2;
			display: block;
	        margin: 0;

			@include respond-min($small-breakpoint) {
				font-size: rem-calc(48 * 0.5);
			}
			&:hover,
			&:focus {
				.icon {
					color: white;
					@media screen\0 {
						padding: rem-calc(4 1);
					}
				}
			}
			.icon {
				margin: 0;
				$size: 0.9;
				font-size: $size + em;
				line-height: $size + em;
				width: math.div(2, $size) + em;
				height: math.div(2, $size) + em;
				@media screen\0 {
					line-height: 1.77em;
					width: 46px;
					padding: 1px;
				}
			}

			.icon-label {
				position: absolute;
				left: -4000px;
			}
		}

		.icon-circle-facebook {
			&:hover,
			&:focus {
				background-color: #3b5999;
			}
		}
		.icon-circle-twitter {
			&:hover,
			&:focus {
				background-color: #4099ff;
			}
		}
		.icon-circle-instagram {
			&:hover,
			&:focus {
				background-color: #496e97;
			}
		}
		.icon-circle-youtube {
			&:hover,
			&:focus {
				background-color: #bb270a;
			}
		}
	}

	.footer-link {
		.columns {
			.column-header {
				color: white;
				font-weight: 600;
				padding: rem-calc(24) 0;
				font-size: rem-calc(15);
				margin-bottom: 0;
				border-top: 2px solid #353d51;
				background: transparent image-url("footer-link-arrow-down.png") no-repeat right center;

				@include respond-min($large-breakpoint) {
					padding: 0;
					margin-bottom: rem-calc(30);
					border-top: none;
					background: none;
				}

				@include respond-min($large-breakpoint) {
					font-size: rem-calc(18);
				}

				&.title-open {
					border-bottom: none;
					background: transparent image-url("footer-link-arrow-up.png") no-repeat right center;

					@include respond-min($large-breakpoint) {
						border: none;
						background: none;
					}
				}
			}

			&:first-child {
				.column-header {
					border-top: none;
				}
			}
			&:last-child {
				.column-header {
					border-bottom: 2px solid #353d51;

					@include respond-min($large-breakpoint) {
						border: none;
					}
				}
				.column-header.title-open {
					border-bottom: none;
				}

				.footer-link-container {
					border-bottom: 2px solid #353d51;
					padding-bottom:10px;

					@include respond-min($large-breakpoint) {
						border: none;
					}
				}
			}
		}


		.link-list {
			margin-top: 32px;
			font-weight: bold;
			font-size: rem-calc(15);

			@include respond-min($medium-breakpoint) {
				font-size: rem-calc(12);
			}
			@include respond-min($large-breakpoint) {
				font-size: rem-calc(15);
			}
			li {
				a {
					&:hover,
					&:focus {
						color: $theme-color-1;
					}
				}
				&:before {
					width: rem-calc(33);
					top: -0.1em;
					@include opacity(.5);

					@include respond-min($small-breakpoint) {
						display: none;
					}

					@include respond-min($xlarge-breakpoint) {
						display: block;
					}
				}
			}
		}

		.footer-list {
			@include reset-ul;
				font-size: rem-calc(15);
				line-height: rem-calc(18);

			li {
				font-size: rem-calc(14);
				line-height: rem-calc(16);

				// clear pre-styled things
				margin-bottom: rem-calc(15);
			}

			a {
				color: $theme-color-3-text-light;

				&:hover,
				&:focus {
					color: white;
				}
			}
		}

		.intro {
			font-family: $font-family-2;
			font-size: rem-calc(21);
			line-height: rem-calc(35);
			margin-top: rem-calc(-5);
			color: white;

			@include respond-min($small-breakpoint) {
				font-size: rem-calc(14);
				line-height: rem-calc(29);
			}
		}

		.location {
			font-family: $font-family-2;
			line-height: rem-calc(28);
			margin-top: rem-calc(-6);
			margin-bottom: rem-calc(33);

			@include respond-min($small-breakpoint) {
				color: white;
				font-size: rem-calc(16);
			}

			abbr[title] {
				border-bottom: none;
				color: inherit;
			}
		}

		.open-hours {
			line-height: rem-calc(24);

			a {
				color: white;

				.sub-text {
					font-size: rem-calc(14);
					opacity: .33;
				}

				&:hover,
				&:focus {
					.sub-text {
						opacity: 1;
					}
				}
			}

			strong {

				font-size: rem-calc(15);
				margin-top: rem-calc(15);
				font-weight: 400;
			}
		}

		.map-link {
			font-size: rem-calc(14);
			margin-top: rem-calc(10);
			margin-bottom: rem-calc(10);
			padding-bottom: rem-calc(8);
			border-bottom: $border-link;
            border-bottom-color: $theme-color-1-alt;
			display: inline-block;
			font-weight: 600;

			@include respond-min($small-breakpoint) {
				font-size: rem-calc(15);
				margin-bottom: rem-calc(0);
			}

			.icon {
				margin-right: rem-calc(8)
			}

			&:hover,
			&:focus {
				border-color: white;
				color: white;

				.icon {
	    			color: white;
	    		}
			}
		}
	}

	.footer-support-links {
		font-size: rem-calc(14);
		line-height: rem-calc(20);

		li {
			margin-bottom: rem-calc(13);

			&:before{
				margin-right: 20px;
			}
		}
	}


	.footer-end {
		margin-top: rem-calc(17);

		&.translate-on {
			@include respond-min($medium-breakpoint) {
				margin-top: rem-calc(95);
			}

			.copyright {
				min-height: rem-calc(40);
			}
		}

		.copyright {
			font-size: rem-calc(11);
			color: white;
			@include opacity(.5);
			margin: 0 0 rem-calc(20) 0;
			text-align: center;

			@include respond-min(600px) {
				@include opacity(.5);
				margin-top: 0;
				margin-bottom: 0;
				text-align: left;

			}
			@include respond-min($large-breakpoint) {
				margin-top: rem-calc(14);
			}
		}

		.privacy {
			font-size: rem-calc(15);
			padding-top: rem-calc(35);
			padding-bottom: rem-calc(15);
			text-align: center;
			border-bottom: 2px solid #353d51;
			@include respond-min($small-breakpoint) {
				padding-bottom: rem-calc(35);
			}
			@include respond-min($large-breakpoint) {
				padding-top: rem-calc(15);
				padding-bottom: 0;
				margin-bottom: 0;
				font-size: rem-calc(11);
				border-bottom: none;
				float: right;
				line-height: 2.1em;
			}

			li {
				padding-left: 0;
				margin-left: rem-calc(8);
				margin-right: rem-calc(8);
				text-indent: 0;
				display: inline-block;
				float: none;

				@include respond-min($large-breakpoint) {
					margin-left: 0;
					margin-right: rem-calc(12);
					float: left;
				}

				&:before {
					content: none;
				}
				&:last-child {
					margin-right: 0;
				}
			}

			a {
				color: white;
				@include opacity(.5);
				display: inline;
				font-size: rem-calc(14);

				@include respond-min($large-breakpoint) {
					@include opacity(1);
					font-size: rem-calc(11);
				}

				&:hover,
				&:focus {
					text-decoration: underline;
				}
			}

			.icon {
				display: none;

				@include respond-min($large-breakpoint) {
					margin-left: rem-calc(8);
					font-size: rem-calc(12);
					vertical-align: middle;
					display: inline;
					color: white;
				}
			}
		}

        @at-root .translate-btn,
		.translate-btn {
			max-height: rem-calc(55);
			overflow: hidden;
			padding-left: rem-calc(16);
			width: 100%;

			.icon {
				display: inline;
			}

			@include respond-min($large-breakpoint) {
				padding: 0;
			}

			.last-icon-cell {
				position: absolute;
				top: rem-calc(16);
				right: rem-calc(28);
				z-index: 2;

				.icon {
					color: white;
				}
			}

			select {
				@extend .button;
				background-image: none;
				background-color:rgba(white, 0.1);
				border: 0;
				outline: 0;
				line-height: 2em;
				margin: rem-calc(-2) -10% 0;
				text-align: left;
				padding: rem-calc(16) 10%;
				font-size: rem-calc(14);
				width: 120%;
				height: rem-calc(55);
				text-transform: capitalize;

				@include respond-min($medium-breakpoint) {
					margin: 0;
					text-align: left;
					width: 100%;
					padding: rem-calc(16) rem-calc(22) rem-calc(16) rem-calc(32);
					line-height: 1.4;
				}

				&:focus {
				}
			}


          .translate-btn select:hover,
          .translate-btn select:focus {
            background-color: white;
            color: $theme-color-1;
            border: 0;
            // outline: 0;
            @include single-text-shadow(none);

            .icon {
              color: $theme-color-1;
            }
          }
		}
	}
}
